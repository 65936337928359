<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        数据汇总
      </div>
    </div>
    <div class="item-middle"
         flex="dir:top">
      <div class="item-row"
           flex="cross:center main:justify">
        <div class="item-box l1"
             flex="dir:top">
          <div>2000</div>
          <div style="opacity: 0.9;">
            地块数量
          </div>
          <div style="opacity: 0.5;">
            单位：块
          </div>
        </div>
        <div class="item-box l2"
             flex="dir:top">
          <div>30000</div>
          <div style="opacity: 0.9;">
            种植面积
          </div>
          <div style="opacity: 0.5;">
            单位：亩
          </div>
        </div>
      </div>
      <div class="item-row"
           flex="cross:center main:justify">
        <div class="item-box l3"
             flex="dir:top">
          <div>125</div>
          <div style="opacity: 0.9;">
            加工厂数
          </div>
          <div style="opacity: 0.5;">
            单位：座
          </div>
        </div>
        <div class="item-box l4"
             flex="dir:top">
          <div>6000</div>
          <div style="opacity: 0.9;">
            农机数量
          </div>
          <div style="opacity: 0.5;">
            单位：辆
          </div>
        </div>
      </div>
      <div class="circle"
           flex="dir:top cross:center main:center">
        <div>76</div>
        <div>农户数量</div>
      </div>
    </div>
    <div class="item-bottom"
         flex="box:mean cross:center">
      <div class="bottom-item"
           flex="dir:top cross:center">
        <div class="num">
          120232
        </div>
        <div flex="cross:center"
             style="margin-top:6px">
          <div class="status" />
          <div class="status-txt">
            在线
          </div>
        </div>
      </div>
      <div class="bottom-item"
           flex="dir:top cross:center">
        <div class="num">
          20000
        </div>
        <div flex="cross:center"
             style="margin-top:6px">
          <div class="status off" />
          <div class="status-txt">
            离线
          </div>
        </div>
      </div>
      <div class="bottom-item"
           flex="dir:top cross:center">
        <div class="num">
          1000
        </div>
        <div flex="cross:center"
             style="margin-top:6px">
          <div class="status on" />
          <div class="status-txt">
            作业中
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
  },

  methods: {
    getData () {
    }
  }
}
</script>

<style lang="scss" scoped>
.left-top-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 12px 0px rgba(129, 221, 192, 0.25);
  border: 1px solid #254a42;
  backdrop-filter: blur(1px);

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }

  .item-middle {
    margin: 0 auto;
    width: 90%;
    position: relative;

    .item-row {
      width: 100%;

      .item-box {
        box-sizing: border-box;
        > :first-child {
          margin-top: 16px;
          height: 18px;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          line-height: 18px;
          width: 120px;
          text-align: center;
        }

        > :nth-child(2) {
          margin-top: 6px;
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 12px;
          width: 120px;
          text-align: center;
        }

        > :last-child {
          margin-top: 14px;
          height: 10px;
          font-size: 10px;
          font-weight: 400;
          color: #ffffff;
          line-height: 10px;
          width: 120px;
          text-align: center;
        }

        &.l1 {
        }

        &.l2 {
          padding-left: 20px;
          margin-left: 10px;
        }

        &.l3 {
          margin-top: 10px;
        }

        &.l4 {
          padding-left: 20px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }

    .circle {
      width: 90px;
      height: 90px;
      background: rgba(0, 0, 0, 0.66);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      border: 6px solid #fff;
      position: absolute;
      top: 25%;
      left: 35%;

      > :first-child {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
      }

      > :last-child {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 12px;
      }
    }
  }

  .item-bottom {
    width: 90%;
    height: 30%;
    margin-top: 10px;

    .bottom-item {
      .num {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
      }

      .status {
        width: 10px;
        height: 10px;
        background: linear-gradient(180deg, #00a503 0%, #5dff5f 100%);
        line-height: 18px;

        &.off {
          background: linear-gradient(180deg, #b4b4b4 0%, #e7e7e7 100%);
        }

        &.on {
          background: linear-gradient(180deg, #ff4e00 0%, #ffb700 100%);
        }
      }

      .status-txt {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 12px;
      }
    }
  }
}
</style>