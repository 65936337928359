/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-10 18:20:43
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:00:45
 * @FilePath: \korlascreen\src\components\main-view2\top-btn2\bottom-btn1\asideLeft\leftTopItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    top: '5%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    }
  },
  xAxis: {
    type: 'category',
    data: [],//['2019', '2020', '2021', '2022', '2023'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '个'
      }
      return relVal
    }
  },
  yAxis: {
    type: 'value',
    splitLine: false,
    show: false
  },
  grid: {
    top: 60,
    botttom: 0,
  },
  series: [
    {
      name: '',//'办理数量',
      data: [],//[22, 45, 51, 13, 46],
      type: 'bar',
      barWidth: '20',
      label: {
        show: true,
        position: "top"
      },

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
