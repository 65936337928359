/*
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-09-30 17:23:21
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 17:43:11
 */
export default {
  countList: [
    '428.57 万', '434001 手'
  ]
}
