<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="config"
                       style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      config: {
        // rowNum: 6,
        // columnWidth: [150, 150, 150],
        // header: ['所属区域', '数据1', '数据2'],
        // headerBGC: 'transparent',
        // oddRowBGC: 'transparent',
        // evenRowBGC: 'transparent',
        // data: [
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        //   ['单位名称', '名称', '2022-2-3'],
        // ]
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {

    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-LY-WZHBGSJ').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one,
              item.msg_two,
              item.msg_three,
              ]
            )
          });
          this.title = res.data.form.name
          _GetAspScreenComponentData('NEWKEL-WZHBNBT-LT').then(res => {
            if (res) {
              const msgData2 = []
              res.data.data.forEach(item => {
                msgData2.push(
                  [item.msg_one,
                  item.msg_two,
                  item.msg_three,
                  ]
                )
              });
              let params = {
                rowNum: 6,
                columnWidth: [130, 110, 110],
                header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
                headerBGC: 'transparent',
                oddRowBGC: 'transparent',
                evenRowBGC: 'transparent',
                align: ['center', 'center', 'center'],
                data: msgData,
              }

              this.config = params
            }
          })
        }
      })

    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>