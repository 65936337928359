/*
 * @Description: 数据一张图管理接口
 * @Author: adlj
 * @Date: 2022-06-01 11:19:39
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-29 20:35:16
 */
import request from '@/api/requestForm'

/**
 * 获取大屏表单数据
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
export function _GetAspScreenFormData (query) {
  return request({
    url: '/platform/asp-screen-form-data/tag/' + query,
    method: 'get'
  })
}

/**
 * 获取大屏信息
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
export function _GetAspScreenData (query) {
  return request({
    url: '/platform/asp-screen/code/' + query,
    // headers: { Authorization: '1' },
    method: 'get'
  })
}

/**
 * 获取所有大屏标题信息
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
export function _GetAspScreenMenu (screenCode) {
  return request({
    url: '/platform/asp-screen-menu/options/code',
    // headers: { Authorization: '1' },
    method: 'get',
    params: { screenCode }
  })
}

/**
 * 获取单个大屏标题信息
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
/* export function _GetAspScreenMenu (query) {
  return request({
    url: '/platform/asp-screen-menu/code/' + query,
    // headers: { Authorization: '1' },
    method: 'get'
  })
} */

/**
 * 获取大屏组件数据
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
export function _GetAspScreenComponentData (query) {
  return request({
    url: '/platform/asp-screen-form-data/tag/' + query,
    // headers: { Authorization: '1' },
    method: 'get'
  })
}

/**
 * @description 获取字典项数据列表
 * @param {Object} query 参数列表
 */
export function _GetDictItemList (query) {
  return request({
    url: '/admin/dict/item/list',
    method: 'GET',
    data: query
  })
}

/**
 * 获取大屏组件数据
 * @param {String} query 大屏表单标签
 * @returns 接口返回数据及状态信息
 */
export function _GettenantIdLand (tenantId) {
  return request({
    url: '/platform/fore/fore/landsByTenant?tenantId=' + tenantId,
    method: 'get'
  })
}

/**
 * 获取监控视频
*/
export function _GetLive (data) {
  return request({
    url: '/platform/andmu/getLive',
    method: 'post',
    data: data
  })
}

/**
 * 获取某个监控视频
*/
export function _GetItemLive (id) {
  return request({
    url: '/platform/andmu/getPlayer',
    method: 'post',
    data: id
  })
}

// API 导出
export default {
  _GetAspScreenFormData,
  _GetAspScreenData,
  _GetAspScreenMenu,
  _GetAspScreenComponentData,
  _GetLive,
  _GetItemLive
}
