import security from "./util.security";

const util = {
  security,
};

// 获取 SessionStorage 对象
const SessionStorage = window.sessionStorage;

/**
 * getToken
 * @description 从 Session 中获取用户 Token
 * @return {Object}   返回用户 Token
 */
util.getToken = () => {
  let token;
  try {
    token = JSON.parse(SessionStorage.getItem("token"));
    return token;
  } catch (ex) {
    token = undefined;
    return token;
  }
};

/**
 * getPolygonCenter
 * @description  获取不规则面的中心点
 * @param {String}  面坐标点数组
 * @return {Array} 中心点坐标
 * @example 调用示例
 *  let dictLabel = util.getPolygonCenter([[x,y],[x,y],[x,y]])
 */
util.getPolygonCenter = (pointList) => {
  let area = 0;
  let x = 0;
  let y = 0;
  for (let i = 1; i <= pointList.length; i++) {
    let lat = pointList[i % pointList.length].lat;
    let lng = pointList[i % pointList.length].lng;
    let nextLat = pointList[i - 1].lat;
    let nextLng = pointList[i - 1].lng;
    let temp = (lat * nextLng - lng * nextLat) / 2;
    area += temp;
    x += (temp * (lat + nextLat)) / 3;
    y += (temp * (lng + nextLng)) / 3;
  }
  x = x / area;
  y = y / area;
  let params = {
    lat: x,
    lng: y,
  };
  return params;
};

/**
 * setToken
 * @description 将 Token 存储至 Session
 * @return {Object}   返回用户 Token
 */
util.setToken = (token) => {
  SessionStorage.setItem("token", token);
};

/**
 * 清理token
 */
util.removeToken = () => {
  SessionStorage.removeItem("token");
};

/**
 * setRegion
 * @description 将 region 存储至 Session
 * @return {Object}   返回用户 region
 */
util.setRegion = (region) => {
  SessionStorage.setItem("region", region);
};

/**
 * getRegion
 * @description 从 Session 中获取用户 Region
 * @return {Object}   返回用户 Region
 */
util.getRegion = () => {
  let region;
  try {
    region = SessionStorage.getItem("region");
    return region;
  } catch (ex) {
    region = undefined;
    return region;
  }
};

/**
 * setRegionName
 * @description 将 regionName 存储至 Session
 * @return {Object}   返回用户 regionName
 */
util.setRegionName = (regionName) => {
  SessionStorage.setItem("regionName", regionName);
};

/**
 * getRegionName
 * @description 从 Session 中获取用户 Region
 * @return {Object}   返回用户 regionName
 */
util.getRegionName = () => {
  let regionName;
  try {
    regionName = SessionStorage.getItem("regionName");
    return regionName;
  } catch (ex) {
    regionName = undefined;
    return regionName;
  }
};

/**
 * @description 存储 session 值
 * @param {String} name session name
 * @param {String} value session value
 */
util.set = function (name = "default", value = "") {
  SessionStorage.setItem(name, value);
};

/**
 * @description 拿到 session 值
 * @param {String} name session name
 */
util.get = function (name = "default") {
  return SessionStorage.getItem(name);
};

/**
 * @description 删除 session
 * @param {String} name session name
 */
util.remove = function (name = "default") {
  return SessionStorage.removeItem(name);
};

/**
 * isNull
 * @description 自动识别对象类型并判断是否为空
 * @param {Object} val 需要判断的对象
 * @return {Boolean}   返回 true 或 false
 * @example 调用示例
 *  let isNull = util.isNull(user)
 */
util.isNull = (val) => {
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val === "null" ||
      val === null ||
      val === "undefined" ||
      val === undefined ||
      val === ""
    )
      return true;
    return false;
  }
  return false;
};

/**
 * looseEqual
 * @description 判断值是否相等(宽松)
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.looseEqual = (a, b) => {
  const isObjectA = util.isObject(a);
  const isObjectB = util.isObject(b);
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b);
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
};

/**
 * isObject
 * @description 判断是否是对象
 * @param { Object}  obj
 * @return {Boolean}
 */
util.isObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

/**
 * arrayEquals
 * @description 判断数组是否相等
 * @param {Array}    arrayA
 * @param {Array}    arrayB
 * @return {Boolean}
 */
util.arrayEquals = (arrayA, arrayB) => {
  arrayA = arrayA || [];
  arrayB = arrayB || [];
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  for (let i = 0; i < arrayA.length; i++) {
    if (!util.looseEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }
  return true;
};

/**
 * isEqual
 * @description 判断值是否相等
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.isEqual = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return util.arrayEquals(value1, value2);
  }
  return util.looseEqual(value1, value2);
};

/**
 * getExpiresOn
 * @description  根据 expires_in 获取过期时间
 * @return {Number} expiresOn 过期日期与'1970/01/01'之间的毫秒值
 * @example 调用示例
 *  let token = util.getExpiresOn()
 */
util.getExpiresOn = (expiresIn) => {
  const date = new Date(); // 过期时间设为当前时间
  const expiresOn = date.getTime(); // 转化为时间戳毫秒数
  return expiresOn + 1000 * expiresIn;
};

// 时间戳转换
util.formatDate = (timestamp) => {
  let date = new Date(parseInt(timestamp));
  let Year = date.getFullYear();
  let Moth =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let GMT = Year + "-" + Moth + "-" + Day;
  return GMT;
};

export default util;
