/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:17:47
 */
export default {
  title: {
    text: '单位: 吨',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#399C47' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#4B4743',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}吨',
  },
  yAxis: [{
    type: 'category',
    data: ['铁克其乡', '英下乡', '恰尔巴格乡', '兰干乡', '阿瓦提乡', '哈拉玉宫乡', '和什力克乡', '托布里其乡', '普惠乡'],//['农业', '工业', '服务业', '生活用水'],
    axisLabel: {
      color: '#FFFFFF'
    }
  }],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#399C47'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  grid: {
    top: '13%',
    left: '16%',
    bottom: '10%',
  },
  series: [
    {
      name: '',//'产量/吨',
      data: [32000, 25000, 5000, 5000, 30000, 947, 10000, 54441, 6],// [12, 35, 41, 33],
      type: 'bar',
      barWidth: '15',

      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'right',
        color: '#FFFFFFFF'
      },

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
