/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-06 18:15:22
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 19:36:44
 * @FilePath: \korlascreen\src\components\main-view3\top-btn3\bottom-btn4\asideRight\rightBottomItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'category',
    data: [],//['高校名称1', '高校名称1', '高校名称1', '高校名称1', '高校名称1', '高校名称1'],
    axisLabel: {
      color: '#B1B6AE'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      show: false
    },
  },
  grid: {
    top: 50,
    botttom: 0,
    left: 80,
  },
  series: [
    {
      name: '',//'高校名称1',
      data: [],//[58, 56, 22, 28, 39, 30],
      type: 'bar',
      barWidth: '6',
      lineStyle: {
        color: '#00FFD4',
      },
      label: {
        show: true,
        position: "right",
        color: '#00E5B4'
      },
      showBackground: true,
      backgroundStyle: {
        color: '#112817',
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
