/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 19:12:00
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 3, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['鲜果','林果种植总面积', '年末挂果面积', '总株数', '挂果株数','亩均株数(株/亩)','果品总产量(吨)','亩均产量(千克/亩)'],
  columnWidth: [85,130, 120, 85, 90,140,140,140],
  align: ['center', 'center', 'center', 'center','center', 'center', 'center', 'center'],
  data: [
    ['杏','234', '72', '695', '216','20','540','500'],
    ['葡萄','1258', '136', '27940', '3019','148','2856','1400'],
    // ['鲜食','1258', '136', '2856', '9000','25704000','10281600','25704000'],
    ['香梨','27458', '20883', '112847', '84576','27','330451','1055'],
    ['苹果','52', '22', '158', '66','20','330','1000'],
    ['桃','453', '267', '2508', '1482','37','4005','1000'],
    ['酸(西)梅','74','16','288','62','26','192','800' ],
    ['杏李','72','7','291','28','27','52','495' ],
    ['樱桃','7','','31','','30','','' ],
  ]
}
