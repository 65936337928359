<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:11:14
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    },
    marklist: {
      type: Array,
      default: []
    },
  },

  data() {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonData: [],//龙山项目1
      cottonDataA: [],//龙山项目2
      cottonDataB: [],//龙山项目3
      cottonDataC: [],//龙山项目4
    }
  },

  mounted() {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    
    // var polygon = new this.T.Polygon([
    //   new this.T.LngLat(86.15064373535157, 41.71517988264258),
    //   new this.T.LngLat(86.14064373535157, 41.71417988264258),
    //   new this.T.LngLat(86.13064373535157, 41.71317988264258),
    //   // new this.T.LngLat(116.394884, 39.907184),
    //   // new this.T.LngLat(116.39384, 39.983975),
    //   // new this.T.LngLat(116.396775, 39.902285)
    //   // 添加更多的点坐标...
    // ], {
    //   color: '#FF0000',
    //   weight:3
    // });
    // // var polygon = new this.T.Polygon(polygonPoints); // 创建多边形对象
    // console.log(this.map,'map');
    // console.log(polygon,'polygonPoints');
    // this.map.addOverlay(polygon); // 将多边形添加到地图上显示

    // 创建多边形对象
    // let polygon = new this.T.Polygon([ 
    //   new this.T.LngLat(85.90373039245605, 41.748518877483804),
    //   new this.T.LngLat(85.90632677078247, 41.74654973734673),
    //   new this.T.LngLat(85.89505076408386, 41.74355588825552),
    //   new this.T.LngLat(85.89389204978943, 41.74656574685966),
    //    // 添加更多的点坐标...
    // ], {
    //   color: "#00ff00",
    //   weight: 3
    // });
    // this.map.addOverLay(polygon) // 将多边形添加到地图上显示

    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    let polygon = new this.T.Polygon([
      new this.T.LngLat(116.394084, 39.907184),
      new this.T.LngLat(116.39384, 39.903975),
      new this.T.LngLat(116.396775, 39.902285)
    ], {
      color: "#FF0000",
      weight: 3
    });
    this.map.addOverLay(polygon)
    this.getCottonData()//棉蛋mark
    // this.addMapClick()

    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState(val,arr) {
      console.log(val,"xXXXXXX")
      this.map.clearOverLays();
      this.markerList = []
      // if(val==='1'){
      //   this.getorchardA()
      // }
      if (val === '1') {
        this.getCottonData()//mark
      }

      if (val === '2') {
        this.getCottonDataA()//mark
      }

      if (val === '3') {
        this.getCottonDataB()//mark
      }

      if (val === '4') {
        // this.getCottonDataC()//mark
        this.getorchardA()
      }
      if (val === '5') {
        // this.getCottonDataC()//mark
        this.getorchardB()
      }
      if (val === '6') {
        // this.getCottonDataC()//mark
        this.getorchardC()
      }
    },
    marklist(arr){
      console.log("vvvvvv",arr)
    }
  },

  methods: {
    // 果一
    getorchardA(){
      let arr = [
        {lng:86.08524610,lat:41.66664869},
        {lng:86.08759811,lat:41.66797235},
      ]
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
            let polygon1 = new this.T.Polygon([
        new this.T.LngLat(86.08524610,41.66464869),
        new this.T.LngLat(86.08819811,41.66777235),
        new this.T.LngLat(86.08670719,41.66855838),
        new this.T.LngLat(86.08371254,41.66535684),
        new this.T.LngLat(86.08378225,41.66530470),

        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      arr.map(item=>{
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.map.addOverLay(marker)
      })
      this.map.addOverLay(polygon1) // 将多边形添加到地图上显示
      this.map.centerAndZoom(new this.T.LngLat(86.08524610, 41.66464869), 17)
    },
    // 果二
    getorchardB(){
      let arr = [
        {lng:86.06139465,lat:41.68631392},
        {lng:86.06139465,lat:41.685220581},
        {lng:86.06040465,lat:41.685220581},
      ]
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
       let polygon2 = new this.T.Polygon([
        new this.T.LngLat(86.06015779,41.68431392),
        new this.T.LngLat(86.06013103,41.68420581),
        new this.T.LngLat(86.06453828,41.68442311),
        new this.T.LngLat(86.06453825,41.68507603),
        new this.T.LngLat(86.06139465,41.68595818),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      arr.map(item=>{
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.map.addOverLay(marker)
      })
      this.map.addOverLay(polygon2) // 将多边形添加到地图上显示
      this.map.centerAndZoom(new this.T.LngLat(86.06015779, 41.68431392), 17)
    },
    // Latlng": [41.68404877, 86.06062365, 41.68222671, 86.06276035, 41.68421880, 86.06455436]
        // 果三
        getorchardC(){
          let arr = [
        {lng:86.06276035,lat:41.68404877},
        {lng:86.06206035,lat:41.68404877},
      ]
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      arr.map(item=>{
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.map.addOverLay(marker)
      })
       let polygon2 = new this.T.Polygon([
        new this.T.LngLat(86.06062365,41.68404877),
        new this.T.LngLat(86.06276035,41.68222671),
        new this.T.LngLat(86.06455436,41.68421880),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon2) // 将多边形添加到地图上显示
      this.map.centerAndZoom(new this.T.LngLat(86.06062365, 41.68404877), 17)
    },
  //  addMapClick() {
  //     // removeMapClick();
  //     this.map.addEventListener("click", this.MapClick);
  //     // console.log(11111);
  //   },
  //   MapClick(e){
	// 	console.log(e.lnglat.getLng()+","+e.lnglat.getLat());
	//   },
    getCottonData() {
      _GetAspScreenComponentData('NEWKEL-DT-LSXMA').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              url: item.data_url,
              value: item.data_value
            })
          })
          this.cottonData = arr4
          this.onCottonTwo()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 2 ? util.getPolygonCenter(arr4) : arr4[0]
          // this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
      // this.addMapClick()
      
       // 创建多边形对象
      let polygon = new this.T.Polygon([
        //g86.08930439,41.67009286  g86.09095126,41.66926337 g86.08803302,41.66616374 g86.08641565,41.66695118
        new this.T.LngLat(86.08631372, 41.66843989),
        new this.T.LngLat(86.08839512, 41.66768652),
        new this.T.LngLat(86.08524084, 41.66475312),
        new this.T.LngLat(86.08367443, 41.66558667),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon) // 将多边形添加到地图上显示
       // 创建多边形对象
      let polygon2 = new this.T.Polygon([
        new this.T.LngLat(85.90344071, 41.74818269),
        new this.T.LngLat(85.90407372, 41.74703803),
        new this.T.LngLat(85.90212107, 41.74608546),
        new this.T.LngLat(85.90077996, 41.74610947),
        new this.T.LngLat(85.89966416, 41.74594938),
        new this.T.LngLat(85.89867711, 41.74704603),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon2) // 将多边形添加到地图上显示
       let polygon3 = new this.T.Polygon([
        new this.T.LngLat(85.88897824, 41.73895277),
        new this.T.LngLat(85.89043736, 41.73916092),
        new this.T.LngLat(85.89108109, 41.73676718),
        new this.T.LngLat(85.88967562, 41.73654301),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon3) // 将多边形添加到地图上显示
       let polygon4 = new this.T.Polygon([
        new this.T.LngLat(85.88746548, 41.73868858),
        new this.T.LngLat(85.88888168, 41.73891274),
        new this.T.LngLat(85.88957906, 41.73655102),
        new this.T.LngLat(85.88848472, 41.73640691),
        new this.T.LngLat(85.88806629, 41.73753575),
        new this.T.LngLat(85.88759422, 41.73755176),
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon4) // 将多边形添加到地图上显示
       let polygon5 = new this.T.Polygon([
        new this.T.LngLat(85.88600636, 41.73851646),
        new this.T.LngLat(85.88728309, 41.73858451),
        new this.T.LngLat(85.88789463, 41.73613470),
        new this.T.LngLat(85.88654280, 41.73609467),
       
        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon5) // 将多边形添加到地图上显示
       let polygon6 = new this.T.Polygon([
        new this.T.LngLat(85.88460088, 41.73757578),
        new this.T.LngLat(85.88576496, 41.73862854),
        new this.T.LngLat(85.88660717, 41.73590253),
        new this.T.LngLat(85.88471889, 41.73585049),

        // 添加更多的点坐标...
      ], {
        color: "#00ff00",
        weight: 3
      });
      this.map.addOverLay(polygon6) // 将多边形添加到地图上显示
      this.map.centerAndZoom(new this.T.LngLat(85.88460088, 41.73757578), 18)
      let arr = [
            // 1:虫情设备，2：墒情仪，3气象仪，4：杀虫灯设备，5电动阀，6摄像头,7水肥一体机
          {lng:85.88460088,lat: 41.73757578,status:4},
          {lng:85.88471889,lat: 41.73685049,status:2},
          {lng:85.88471889,lat: 41.73595049,status:4},
          {lng:85.88471889,lat: 41.73625049,status:4},
          {lng:85.88471889,lat: 41.73645049,status:2},
          {lng:85.88471889,lat: 41.73665049,status:4},
          {lng:85.88471889,lat: 41.73705049,status:2},
          {lng:85.88471889,lat: 41.73725049,status:4},
          {lng:85.88471889,lat: 41.73745049,status:2},
          {lng:85.88571889,lat: 41.73765049,status:4},
          {lng:85.88571889,lat: 41.73585049,status:1},
          {lng:85.88571889,lat: 41.73605049,status:4},
          {lng:85.88571889,lat: 41.73625049,status:4},
          {lng:85.88571889,lat: 41.73645049,status:4},
          {lng:85.88571889,lat: 41.73665049,status:3},
          {lng:85.88571889,lat: 41.73685049,status:4},
          {lng:85.88571889,lat: 41.73705049,status:4},
          {lng:85.88571889,lat: 41.73725049,status:4},
          {lng:85.88571889,lat: 41.73745049,status:4},
          {lng:85.88531889,lat: 41.73625049,status:4},
          {lng:85.88531889,lat: 41.73625049,status:4},
          {lng:85.88531889,lat: 41.73745049,status:4},

          {lng:85.88600636,lat: 41.73851646,status:4},
          {lng:85.88650636,lat: 41.73811646,status:4},
          {lng:85.88650636,lat: 41.73751646,status:2},
          {lng:85.88650636,lat: 41.73701646,status:7},
          {lng:85.88650636,lat: 41.73848451,status:2},

          {lng:85.88889463,lat: 41.73758451,status:4},
          {lng:85.88789463,lat: 41.73858451,status:3},
          {lng:85.88789463,lat: 41.73758451,status:7},
          {lng:85.88789463,lat: 41.73788451,status:4},
          {lng:85.88809463,lat: 41.73808451,status:2},

          {lng:85.88957906,lat: 41.73721646,status:1},
          {lng:85.88957906,lat: 41.73670691,status:4},
          {lng:85.88937906,lat: 41.73790691,status:2},
          {lng:85.88997906,lat: 41.73770691,status:4},
          {lng:85.88957906,lat: 41.73850691,status:7},
      ]
      
     let icon ;
      // markerOptions={lng:85.88471889,lat:41.73585049}
      // markerLngLat = new this.T.LngLat(markerOptions.lng, markerOptions.lat); // 标注的经纬度
      let markerOptions;
      let pic_icon;
      arr.forEach((i)=>{
        if(i.status!==undefined){
          pic_icon =
            i.status == 1
              ? require("@/assets/images/index/chong.png")
              : i.status == 2
              ? require("@/assets/images/index/shang.png")
              : i.status == 3
              ? require("@/assets/images/index/qi.png")
              : i.status == 4
              ? require("@/assets/images/index/deng.png")
              : i.status == 5
              ? require("@/assets/images/index/fa.png")
              : i.status == 6
              ? require("@/assets/images/index/video.png")
              : i.status == 7
              ? require("@/assets/images/index/fei.png")
              :"";
        }
      
      let uniqueCode = new Date().valueOf();

        icon = new T.Icon({
            iconUrl:pic_icon,
            iconAnchor: new T.Point(35 / 3, 80),
            uniqueCode: uniqueCode,
          });
         markerOptions = new this.T.Marker(new this.T.LngLat(i.lng, i.lat), { icon:icon})
         this.map.addOverLay(markerOptions)

      })

    },
    onCottonTwo() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonData.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: .9375rem auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 1rem;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 1rem;height: 1rem;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -1.25rem;width: 110%;border-bottom: .0625rem solid #979797;padding-bottom: .5rem;opacity:0.2'></div>" +

          "<div style='margin: 1.25rem 0;width: 100%;height: 11.125rem;'><img src = '" + item.url + "' style='width:100%;height:100%'></div>" +
          "<div style='margin: 0 auto;width: 100%;font-size: .875rem;color: #FFF;text-indent:1.5rem;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },

    getCottonDataA() {
      _GetAspScreenComponentData('NEWKEL-DT-LSXMB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              url: item.data_url,
              value: item.data_value
            })
          })
          this.cottonDataB = arr4
          this.onCottonTwoA()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoA() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: .9375rem auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 1rem;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 1rem;height: 1rem;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -1.25rem;width: 110%;border-bottom: .0625rem solid #979797;padding-bottom: .5rem;opacity:0.2'></div>" +

          "<div style='margin: 1.25rem 0;width: 100%;height: 11.125rem;'><img src = '" + item.url + "' style='width:100%;height:100%'></div>" +
          "<div style='margin: 0 auto;width: 100%;font-size: .875rem;color: #FFF;text-indent:1.5rem;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },

    getCottonDataB() {
      _GetAspScreenComponentData('NEWKLE-DT-LSXMC').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              url: item.data_url,
              value: item.data_value
            })
          })
          this.cottonDataB = arr4
          this.onCottonTwoB()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoB() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: .9375rem auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 1rem;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 1rem;height: 1rem;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -1.25rem;width: 110%;border-bottom: .0625rem solid #979797;padding-bottom: .5rem;opacity:0.2'></div>" +

          "<div style='margin: 1.25rem 0;width: 100%;height: 11.125rem;'><img src = '" + item.url + "' style='width:100%;height:100%'></div>" +
          "<div style='margin: 0 auto;width: 100%;font-size: .875rem;color: #FFF;text-indent:1.5rem;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },

    getCottonDataC() {
      _GetAspScreenComponentData('NEWKEL-DT-LSXMD').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              url: item.data_url,
              value: item.data_value
            })
          })
          this.cottonDataC = arr4
          this.onCottonTwoC()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoC() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/zdxm.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: .9375rem auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 1rem;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 1rem;height: 1rem;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -1.25rem;width: 110%;border-bottom: .0625rem solid #979797;padding-bottom: .5rem;opacity:0.2'></div>" +

          "<div style='margin: 1.25rem 0;width: 100%;height: 11.125rem;'><img src = '" + item.url + "' style='width:100%;height:100%'></div>" +
          "<div style='margin: 0 auto;width: 100%;font-size: .875rem;color: #FFF;text-indent:1.5rem;opacity:0.9;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },
    /*关闭信息窗口 */
    onCloseInfoWindow() {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.5),
    inset 0rem .25rem .625rem 0rem rgba(163, 255, 227, 0.4);
  border: .0625rem solid #008166;
  backdrop-filter: blur(.375rem);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 100%;
  height: 110%;
}
</style>
