/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-08 13:01:50
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-21 19:24:30
 * @FilePath: \korlascreen\src\components\main-view4\top-btn1\bottom-btn1\asideLeft\leftBottomItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  title: {
    text: '单位: 吨',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['年产量/吨'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '吨'
      }
      return relVal
    }
  },
  yAxis: {
    type: 'category',
    data: ['肉类', '牛奶', '鸡蛋'],// ['畜牧产品', '畜牧产品', '畜牧产品', '畜牧产品', '畜牧产品', '畜牧产品'],
    axisLabel: {
      color: 'white'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  xAxis: {
    type: 'value',
    show: false,
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      show: true
    },
  },
  grid: {
    top: '5%',
    bottom: '10%',
    left: '10%',
  },
  series: [
    {
      name: '',// '年产量/吨',
      data: [11397.75, 5949, 3249],// [30, 39, 28, 22, 60, 60],
      type: 'bar',
      barWidth: '15',
      lineStyle: {
        color: '#00FFD4',
      },
      label: {
        show: true,
        position: "right",
        color: 'white'
      },
      showBackground: true,
      backgroundStyle: {
        color: '#112817',
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
