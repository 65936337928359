/*
 * @Description: 侧左上 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 13:55:45
 */
const differenceData = []
const visibityData = []
const xAxisData = ['2019年', '2020年', '2021年', '2022年', '2023年']

const ABCBankData = [4.45, 4.35, 4.25, 3.85, 3.85]
const PSBCBankData = [4.75, 4.75, 4.75, 4.75, 4.75]
const NationalVillageBankData = [6.7, 6.7, 6.7, 6.7, 6.7]
const CreditCooperativesBankData = [6.40, 6.40, 6.15, 4.75, 6.15]

for (var i = 0; i < CreditCooperativesBankData.length; i++) {
  differenceData.push(CreditCooperativesBankData[i] - ABCBankData[i])
  visibityData.push(PSBCBankData[i])
}

export default {
  // 添加折线图动态加载
  animationDuration: 10000,
  title: { show: false },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(255,255,255,0.2)',
    // 自定义显示
    formatter: function (params) {
      return params[0].name + '<br/>' +
        params[0].seriesName + ' : ' + params[0].value + '<br/>' +
        params[1].seriesName + ' : ' + params[1].value + '<br/>' +
        params[2].seriesName + ' : ' + params[2].value + '<br/>' +
        params[3].seriesName + ' : ' + params[3].value + '<br/>' +
        '<br/>'
    },
    textStyle: {
      color: '#FFF',
      fontSize: 18
    }
  },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: ['农行', '邮储', '国民', '农信社']
  },
  grid: {
    top: 60,
  },
  xAxis: {
    data: xAxisData,
    axisLabel: {
      textStyle: { // X轴文字
        color: '#FFF',
        fontSize: 18
      }
    },
    axisLine: { // X轴线
      lineStyle: {
        color: '#FFF'
      }
    },
    axisTick: {
      lineStyle: {
        color: '#FFF'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '利率',
    nameTextStyle: {
      padding: [0, 0, 0, -30], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#FFFFFF'
    },
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#FFFFFF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  series: [ // 数据
    {
      name: '农行',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#008CFF',
      lineStyle: {
        color: '#008CFF',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#008CFF"
      },
      data: ABCBankData
    },
    {
      name: '邮储',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#FF3939',
      lineStyle: {
        color: '#FF3939',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FF3939"
      },
      data: PSBCBankData
    },
    {
      name: '国民',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#00ff33',
      lineStyle: {
        color: '#00ff33',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#00ff33"
      },
      data: NationalVillageBankData
    },
    {
      name: '农信社',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#F90',
      lineStyle: {
        color: '#F90',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#F90"
      },
      data: CreditCooperativesBankData
    },
    // 线隐藏后设置不可见线 不会因缺少数据变动
    {
      name: '不可见',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      itemStyle: {
        normal: {
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          color: 'rgba(0,0,0,0)'
        }
      },
      data: visibityData
    },
    // 两线连接线
    {
      name: '变化',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      color: '#B7E1FF',
      data: differenceData
    }
  ]
}
