/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 18:43:04
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 3, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['放款年份', '姓名', '亩数', '实际放款额'],
  columnWidth: [90, 90, 80, 100],
  align: ['center', 'center', 'center', 'center'],
  data: [
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元'],
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元'],
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元'],
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元'],
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元'],
    ['2021', '周XX', '235', '200000元'],
    ['2021', '陈X', '1176.4', '100000元'],
    ['2021', '王XX', '5647', '4800000元']
  ]
}
