<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 14:37:35
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="拉运详情"
                 :visible.sync="drawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div v-if="transportData">
          <div class="box">
            <div class="left">
              拉运时间
            </div>
            <div class="right">
              2022-9-8
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手姓名
            </div>
            <div class="right">
              王伟
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              车牌号
            </div>
            <div class="right">
              新A 7D890
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              加工厂
            </div>
            <div class="right">
              乌鲁木齐加工厂
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      drawer: false,
      transportData: {}
    }
  },

  watch: {
    id (val) {
      if (val) {
        console.log(val)
        this.drawer = true
      }
    }
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('transportShow', null)
    },
  }
}
</script>

<style lang="scss" scoped >
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }
}
</style>
