// import 'echarts/lib/component/dataZoom'

// const cottonData = [
//   ['2022/5/16', '4638.58', '4652.06', '4672.65', '4522.47'],
//   ['2022/5/15', '4764.94', '4574.87', '4712.81', '4590.83'],
//   ['2022/5/14', '4663.44', '4673.74', '4631.73', '4569.83'],
//   ['2022/5/13', '4762.96', '4712.86', '4710.81', '4518.83'],
//   ['2022/5/12', '4661.28', '4671.97', '4809.27', '4567.83'],
//   ['2022/5/11', '4860.91', '4590.24', '4908.21', '4596.83'],
//   ['2022/5/10', '4759.77', '4669.22', '4807.92', '4565.83'],
//   ['2022/5/9', '4858.35', '4568.56', '4906.17', '4664.83'],
//   ['2022/5/8', '4757.26', '4667.85', '4805.34', '4563.83'],
//   ['2022/5/7', '4790.23', '4783.43', '4849.93', '4780.71'],
//   ['2022/5/6', '4631.79', '4882.57', '5048.23', '4829.71'],
//   ['2022/5/5', '4829.68', '4880.21', '4946.54', '4827.71'],
//   ['2022/5/4', '4728.76', '4779.31', '4845', '4726.71'],
//   ['2022/5/3', '4970.98', '4895.37', '5074.45', '4899.43'],
//   ['2022/5/2', '4869.88', '4934.67', '4993.57', '4998.43'],
//   ['2022/5/1', '4968.85', '4893.37', '5072.89', '4797.43'],
//   ['2022/4/30', '4817.36', '4932.34', '4971.34', '4896.43'],
//   ['2022/4/29', '4956.43', '4891.37', '4870.45', '4795.43'],
//   ['2022/4/28', '4925.54', '4830.99', '4969.64', '4894.43'],
//   ['2022/4/27', '4812.23', '4726.73', '4853.06', '4723.94'],
//   ['2022/4/26', '4763.98', '4828.37', '4967.45', '4992.43'],
//   ['2022/4/25', '4911.47', '4826.37', '4965.87', '4890.43'],
//   ['2022/4/24', '4960.86', '4925.37', '4864.98', '4789.43'],
//   ['2022/4/23', '4759.25', '4824.37', '4963.68', '4988.43'],
//   ['2022/4/22', '4858.76', '4923.37', '5062.64', '4887.43'],
//   ['2022/4/21', '4957.98', '4822.37', '4961.45', '4786.43'],
//   ['2022/4/20', '4724.15', '4946.37', '4865.14', '4884.65'],
//   ['2022/4/19', '4923.37', '4845.37', '4964.86', '4983.65'],
//   ['2022/4/18', '4822.76', '4964.37', '5063.35', '4882.65'],
//   ['2022/4/17', '4721.89', '4993.37', '4992.86', '4781.65'],
//   ['2022/4/16', '4920.90 ', '4942.37', '4961.87', '4980.65'],
//   ['2022/4/15', '4656.36', '4596.77', '4704.12', '4592.83'],
//   ['2022/4/14', '4755.97', '4565.96', '4803.16', '4561.83'],
//   ['2022/4/13', '4854.11', '4664.34', '4702.14', '4660.83'],
//   ['2022/4/12', '4753.94', '4563.77', '4801.1', '4559.83'],
//   ['2022/4/11', '4884.76', '4885.31', '4851.34', '4732.71'],
//   ['2022/4/10', '4733.54', '4799.75', '4950.55', '4931.71'],
//   ['2022/4/9', '4730.63', '4781.88', '4847.95', '4798.71'],
//   ['2022/4/8', '4862.57', '4727.37', '5066.67', '4791.43'],
//   ['2022/4/7', '4819.34', '4841.37', '5060.23', '4779.65'],
//   ['2022/4/6', '4918.15', '4940.37', '4959.14', '4878.65']
// ].reverse()
// const cottonDatas = cottonData.map(function (item) {
//   return [item[1], item[2], item[3], item[4]]
// })

// // 香梨数据
// const pearData = [
//   ['2022/5/16', '4638.58', '4652.06', '4672.65', '4522.47'],
//   ['2022/5/15', '4764.94', '4574.87', '4712.81', '4590.83'],
//   ['2022/5/14', '4663.44', '4673.74', '4631.73', '4569.83'],
//   ['2022/5/13', '4762.96', '4712.86', '4710.81', '4518.83'],
//   ['2022/5/12', '4661.28', '4671.97', '4809.27', '4567.83'],
//   ['2022/5/11', '4860.91', '4590.24', '4908.21', '4596.83'],
//   ['2022/5/10', '4759.77', '4669.22', '4807.92', '4565.83'],
//   ['2022/5/9', '4858.35', '4568.56', '4906.17', '4664.83'],
//   ['2022/5/8', '4757.26', '4667.85', '4805.34', '4563.83'],
//   ['2022/5/7', '4790.23', '4783.43', '4849.93', '4780.71'],
//   ['2022/5/6', '4631.79', '4882.57', '5048.23', '4829.71'],
//   ['2022/5/5', '4829.68', '4880.21', '4946.54', '4827.71'],
//   ['2022/5/4', '4728.76', '4779.31', '4845', '4726.71'],
//   ['2022/5/3', '4970.98', '4895.37', '5074.45', '4899.43'],
//   ['2022/5/2', '4869.88', '4934.67', '4993.57', '4998.43'],
//   ['2022/5/1', '4968.85', '4893.37', '5072.89', '4797.43'],
//   ['2022/4/30', '4817.36', '4932.34', '4971.34', '4896.43'],
//   ['2022/4/29', '4956.43', '4891.37', '4870.45', '4795.43'],
//   ['2022/4/28', '4925.54', '4830.99', '4969.64', '4894.43'],
//   ['2022/4/27', '4812.23', '4726.73', '4853.06', '4723.94'],
//   ['2022/4/26', '4763.98', '4828.37', '4967.45', '4992.43'],
//   ['2022/4/25', '4911.47', '4826.37', '4965.87', '4890.43'],
//   ['2022/4/24', '4960.86', '4925.37', '4864.98', '4789.43'],
//   ['2022/4/23', '4759.25', '4824.37', '4963.68', '4988.43'],
//   ['2022/4/22', '4858.76', '4923.37', '5062.64', '4887.43'],
//   ['2022/4/21', '4957.98', '4822.37', '4961.45', '4786.43'],
//   ['2022/4/20', '4724.15', '4946.37', '4865.14', '4884.65'],
//   ['2022/4/19', '4923.37', '4845.37', '4964.86', '4983.65'],
//   ['2022/4/18', '4822.76', '4964.37', '5063.35', '4882.65'],
//   ['2022/4/17', '4721.89', '4993.37', '4992.86', '4781.65'],
//   ['2022/4/16', '4920.90 ', '4942.37', '4961.87', '4980.65'],
//   ['2022/4/15', '4656.36', '4596.77', '4704.12', '4592.83'],
//   ['2022/4/14', '4755.97', '4565.96', '4803.16', '4561.83'],
//   ['2022/4/13', '4854.11', '4664.34', '4702.14', '4660.83'],
//   ['2022/4/12', '4753.94', '4563.77', '4801.1', '4559.83'],
//   ['2022/4/11', '4884.76', '4885.31', '4851.34', '4732.71'],
//   ['2022/4/10', '4733.54', '4799.75', '4950.55', '4931.71'],
//   ['2022/4/9', '4730.63', '4781.88', '4847.95', '4798.71'],
//   ['2022/4/8', '4862.57', '4727.37', '5066.67', '4791.43'],
//   ['2022/4/7', '4819.34', '4841.37', '5060.23', '4779.65'],
//   ['2022/4/6', '4918.15', '4940.37', '4959.14', '4878.65']
// ].reverse()
// const pearDatas = pearData.map(function (item) {
//   return [item[1], item[2], item[3], item[4]]
// })
// const dates = pearData.map(function (item) {
//   return item[0]
// })
// export default {
//   tooltip: {
//     trigger: 'axis',
//     backgroundColor: 'rgba(255,255,255,0.2)',
//     // 自定义显示
//     formatter: function (params) {
//       return params[0].name + '<br/>' +
//         '开盘:' + params[0].value[1] + '<br>' +
//         '收盘:' + params[0].value[2] + '<br>' +
//         '最低:' + params[0].value[3] + '<br>' +
//         '最高:' + params[0].value[4] + '<br>'
//     },
//     textStyle: {
//       color: '#FFF',
//       fontSize: 18
//     },
//     axisPointer: {
//       animation: false,
//       type: 'cross',
//       lineStyle: {
//         color: '#376df4',
//         width: 1,
//         opacity: 1
//       }
//     }
//   },
//   legend: {
//     data: ['棉花'],
//     selectedMode: 'single',
//     top: 'top',
//     textStyle: {
//       color: '#fff',
//       fontSize: 18,
//       fontFamily: '微软雅黑'
//     },
//     inactiveColor: '#777'
//   },
//   grid: {
//     top: '20%',
//     left: '5%',
//     right: '5%',
//     bottom: '20%'
//   },
//   xAxis: {
//     type: 'category',
//     data: dates,
//     axisLabel: {
//       textStyle: { // X轴文字
//         color: '#fff',
//         fontSize: 18
//       }
//     },
//     axisLine: { lineStyle: { color: '#8392A5' } }
//   },
//   yAxis: {
//     name: '',
//     nameTextStyle: {
//       padding: [0, 0, 5, -30], // 四个数字分别为上右下左与原位置距离
//       fontSize: 18,
//       color: '#fff'
//     },
//     scale: true,
//     axisLine: { lineStyle: { color: '#ffffff' } },
//     splitLine: { show: false },
//     axisLabel: {
//       textStyle: { // Y轴字体
//         color: '#fff',
//         fontSize: 18,
//         fontFamily: 'Arial'
//       }
//     }
//   },
//   dataZoom: [
//     {
//       textStyle: {
//         color: '#FFF'
//       },
//       dataBackground: {
//         areaStyle: {
//           color: '#FFF'
//         },
//         lineStyle: {
//           opacity: 0.8,
//           color: '#FFF'
//         }
//       },
//       brushSelect: true
//     },
//     {
//       type: 'inside'
//     }
//   ],
//   series: [
//     {
//       name: '棉花',
//       type: 'line',
//       data: cottonData,
//       smooth: true,
//       showSymbol: false,
//       lineStyle: {
//         width: 1
//       }
//     },
//     {
//       name: '香梨',
//       type: 'line',
//       data: pearData,
//       smooth: true,
//       showSymbol: false,
//       lineStyle: {
//         width: 1,
//         color: '#328554'
//       }
//     },
//     {
//       name: '棉花',
//       type: 'candlestick',
//       data: cottonDatas,
//       itemStyle: {
//         color: '#FD1050',
//         color0: '#0CF49B',
//         borderColor: '#FD1050',
//         borderColor0: '#0CF49B'
//       }
//     },
//     {
//       name: '香梨',
//       type: 'candlestick',
//       data: pearDatas,
//       itemStyle: {
//         color: '#FD1050',
//         color0: '#0CF49B',
//         borderColor: '#FD1050',
//         borderColor0: '#0CF49B'
//       }
//     }
//   ]
// }
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 6, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['果品品类','收购数量（吨）', '收购价（元/吨）', '销售数量（吨)', '单价(元/吨)','销售收入(元)','销售净利润(元)','收购总额(元)'],
  columnWidth: [110, 110, 110, 100],
  align: ['center', 'center', 'center', 'center'],
  data: [
    ['杏','540', '12000', '540', '12000','6480000','2592000','6480000'],
    ['葡萄','2856', '9000', '2856', '9000','25704000','10281600','25704000'],
    ['鲜食','2856', '9000', '2856', '9000','25704000','10281600','25704000'],
    ['香梨','330451', '7000', '330451', '7000','2313157000','702223205','2313157000'],
    ['苹果','330', '4000', '330', '4000','1320000','528000','1320000'],
    ['桃','4005', '6000', '4005', '6000','24030000','9612000','24030000'],
    ['酸(西)梅','192','12000','192','12000','2304000','921600','2304000' ],
    ['杏李','52','15000','52','15000','780000','312000','780000' ],
    // ['其他鲜果(无  花果、山楂、海棠果等)','30','6500','30','6500','195000','78000','195000' ],
    ['核桃','25','10000','25','10000','250000','100000','250000' ],
    ['红枣','11754','6000','11754','6000','70524000','28209600','70524000' ],
    ['枸杞','12','3000','12','30000','360000','144000','36000' ],


    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元'],
    // ['2021', '周XX', '235', '200000元'],
    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元'],
    // ['2021', '周XX', '235', '200000元'],
    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元'],
    // ['2021', '周XX', '235', '200000元'],
    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元'],
    // ['2021', '周XX', '235', '200000元'],
    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元'],
    // ['2021', '周XX', '235', '200000元'],
    // ['2021', '陈X', '1176.4', '100000元'],
    // ['2021', '王XX', '5647', '4800000元']
  ]
}