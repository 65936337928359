<!--
 * @Description: 用一句话描述文件的作用
 * @Author: yjk
 * @Date: 2023-11-22 11:09:20
 * @LastEditors: yjk
 * @LastEditTime: 2023-11-28 13:17:58
-->
<template>
  <div class="left-top-container" flex="dir:top cross:center">
    <div class="card-head" flex="main:center">
      <div class="title" style="color: rgb(254, 112, 2); margin-top: 5px">
        牛场智能项圈监测数据
      </div>
    </div>
    <div class="card-body" flex="dir:top">
      <dv-scroll-board
        :config="option"
        style="width: 100%; height: 100%"
        @click="getDetail"
      />
    </div>
  </div>
</template>

<script>
import { getIotWhNecklacePage } from "@/api/assp-trace-collectData/index";

export default {
  name: "LeftMiddleItem",
  data() {
    return {
      option: {},
      list: [],
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    getPage() {
      getIotWhNecklacePage({ current: 1, size: 99 }).then((res) => {
        const msgData = [];
        console.log(res);
        this.list = res.records;
        res.records.forEach((item) => {
          msgData.push([
            item.deviceId,
            item.battery,
            item.temperature,
            item.steps,
            item.sameDaySteps,
          ]);
        });
        let params = {
          rowNum: 6,
          columnWidth: [120, 90, 90, 90, 90],
          align: ["center", "center", "center", "center", "center"],
          header: ["耳标号", "电量/%", "温度/℃", "总运动量", "当日运动量"], //['申请单位', '专利数', '申请数', '授权数'],
          headerBGC: "transparent",
          oddRowBGC: "transparent",
          evenRowBGC: "transparent",
          data: msgData,
        };
        this.option = params;
      });
    },

    getDetail(row) {
      this.$emit("deatil", row.row[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>
