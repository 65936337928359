<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 14:30:05
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="农户信息"
                 :visible.sync="drawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              农户姓名
            </div>
            <div class="right">
              张三
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              面积
            </div>
            <div class="right">
              2000亩
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              作物
            </div>
            <div class="right">
              长绒棉
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      drawer: false,
      farmerData: {}//农户信息
    }
  },

  watch: {
    id (val) {
      if (val) {
        console.log(val)
        this.drawer = true
      }
    }
  },

  methods: {
    handleClose (done) {
      this.$emit('farmerShow', null)
      done();
    },
  }
}
</script>
<style lang="scss">
.messageDrawer {
  .el-drawer {
    width: 380px !important;
    background: #07513cff !important;
  }
  .el-drawer__close-btn {
    color: #fff !important;
  }

  .el-tree {
    background: transparent !important;
  }

  .el-tree-node__label {
    font-size: 18px !important;
    color: #fff !important ;
  }

  .el-tree-node__content {
    margin: 10px 0 0 20px !important;
    height: 50px !important;
  }

  .el-tree-node__content:hover {
    background-color: #17664eff !important;
    height: 50px !important;
  }

  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #17664eff !important;
    height: 50px !important;
  }
  .el-drawer__header {
    color: #fff !important;
    background: #07513cff !important;
  }
  .el-collapse-item__header {
    margin-top: 5px;
    border: none !important;
    background: linear-gradient(90deg, #24493e 0%, #18614d 100%) !important;
    color: #fff;
    font-size: 18px;
  }
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__wrap {
    background: transparent !important;
    border: none !important;
  }
}
</style>
<style lang="scss" scoped>
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }

    .right {
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
