<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 17:03:31
-->
<template>
  <div class="aside-right-container"
       flex="dir:top box:mean">
    <right-top-item :land-id="landId" />
    <right-middle-item :land-id="landId" />
    <right-bottom-item />
  </div>
</template>

<script>
import rightTopItem from './rightTopItem'
import rightMiddleItem from './rightMiddleItem'
import rightBottomItem from './rightBottomItem'

export default {
  name: 'AsideRight',
  components: {
    rightTopItem,
    rightMiddleItem,
    rightBottomItem
  },
  props: {
    landId: {
      type: Number,
      default: null
    }
  },
}
</script>

<style lang="scss" scoped>
@import url("@/assets/style/aside.scss");
</style>