
/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 18:14:17
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 16:41:56
 */
export default {
  color: ['#68FFD6', '#FFA400'],
  legend: {
    data: [],//['渠系名称1', '渠系名称2'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 10,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  xAxis: {
    type: 'category',
    data: [],//['一月', '二月', '三月', '四月', '五月', '六月'],
    axisLabel: {
      color: '#FFFFFF'
    },
    axisTick: {
      show: false
    },
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].seriesName + params[i].value + '亿立方'
      }
      return relVal
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  grid: {
    top: 60,
    botttom: 0,
    left: 50,
  },
  series: [
    {
      name: '',//'渠系名称1',
      data: [],//[20, 22, 41, 33, 0, 10],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#68FFD6',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#68FFD6"
      },
    },
    {
      name: '',//'渠系名称2',
      data: [],// [10, 25, 3, 20, 2, 10],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#FFA400',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FFA400"
      },
    }]
}
