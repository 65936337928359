<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-05 10:57:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 12:07:37
-->
<template>
  <div>
    <!-- 按钮 -->
    <div class="bubbleBox">
      <!-- <el-radio v-for="(item,index) in list"
                :key="index"
                v-model="radio"
                :label="JSON.stringify(index + 1)">
        {{ item }}
      </el-radio> -->
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="地块" /><br>
        <el-checkbox label="轧花厂" /><br>
        <el-checkbox label="车辆" /><br>
        <el-checkbox label="棉蛋" />
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'BubbleSelect',
  data () {
    return {
      radio: '1',
      list: [],
      checkList: ['地块', '轧花厂', '车辆', '棉蛋']
    }
  },
  watch: {
    checkList (val) {
      this.$emit('radio', val)
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-ZDHJC-AN').then(res => {
        if (res) {
          res.data.data.forEach(item => {
            this.list.push(
              item.name_data
            )
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.bubbleBox {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 0 !important;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: rgba(0, 38, 25, 0.66);
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fff;
    opacity: 0.9;
  }

  .el-checkbox {
    margin-top: 15px;
    color: #fff;
    opacity: 0.9;
  }
}
</style>
<style scoped lang="scss">
.bubbleBox {
  padding: 5px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}
</style>
