/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 05:03:52
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  rowNum: 6,
  columnWidth: [280, 100, 100],
  header: ['工厂', '价格', '衣份'],
  headerBGC: 'transparent',
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  data: [
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
    ['阿瓦提新雅棉业有限公司', '￥50', '20%'],
  ]

}