/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 17:06:15
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 17:05:20
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['地表水/万立方米'],
    textStyle: {
      color: '#FFFFFF'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ]
  },
  xAxis: {
    type: 'category',
    data: [],// ['一月', '二月', '三月', '四月', '五月', '六月'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}升'
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#E8E8E8',
        type: 'dashed'
      }
    },
  }, {
    type: 'value',
    splitLine: false,
    show: false,
  },
  {
    type: 'value',
    splitLine: false,
    show: false,
  }
  ],
  grid: {
    top: 60,
    botttom: 0,
  },
  series: [
    {
      name: '',//'地表水/万立方米',
      data: [],//[12, 35, 41, 33, 26, 30],
      type: 'bar',
      barWidth: '15',

      label: {
        show: true,
        position: 'top',
        color: '#FFFFFFFF'
      },

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }]
}
