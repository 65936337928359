<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 14:54:53
-->
<template>
  <div class="container">
    <div>
      <messagepriceShow @priceShow="getPriceShow"
                        :id="priceId" />
    </div>

    <div>
      <messageExamine @examineShow="getExamineShow"
                      :id="examineId" />
    </div>

    <div>
      <messagesell @sellShow="getSellShow"
                   :id="sellId" />
    </div>

    <div>
      <messageprocess @processShow="getProcessShow"
                      :id="processId" />
    </div>
  </div>
</template>
<script>
import messagepriceShow from './component/messagePrice'//牌价
import messageExamine from './component/messageExamine'//公检
import messagesell from './component/messagesell'//收购
import messageprocess from './component/messageprocess'//加工
export default {
  components: {
    messagepriceShow,
    messageExamine,
    messagesell,
    messageprocess
  },

  props: {
    plantParams: {
      type: Object,
      default: null
    },
  },

  name: 'CenterPopu',
  data () {
    return {
      plantDataId: null,//牌价id
      priceId: null,//牌价
      examineId: null,//公检
      sellId: null,//收购
      processId: null//加工
    }
  },

  watch: {
    plantParams (val) {
      if (val.type == 'price') {
        this.priceId = val.id
      }

      if (val.type == 'examine') {
        this.examineId = val.id
      }

      if (val.type == 'sell') {
        this.sellId = val.id
      }

      if (val.type == 'process') {
        this.processId = val.id
      }
    },
  },
  methods: {
    //牌价
    getPriceShow (val) {
      this.priceId = val
    },

    //公检
    getExamineShow (val) {
      this.examineId = val
    },

    //交售
    getSellShow (val) {
      this.sellId = val
    },

    //加工
    getProcessShow (val) {
      this.processId = val
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
</style>
