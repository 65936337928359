<template>
  <div flex="box:mean"
       style="height: 100%">
    <div class="top-banner-container"
         flex="box:justify cross:center">
      <div class="banner-left">
        <div flex="main:left cross:center">
          <div @click="onDrawer"
               class="menu-icon"
               flex="dir:top">
            <div />
            <div />
            <div />
          </div>
          <div class="menu-icon2">
            <img @click="onHome"
                 src="~@/assets/images/home.png"
                 v-if="roles&&roles.includes(161)">
          </div>
          <div class="date-time"
               flex="">
            <div class="time">
              {{ time }}
            </div>
            <div class="date">
              {{ date }}
            </div>
          </div>
        </div>
      </div>

      <div class="banner-center"
           flex="main:center cross:center">
        <div class="main-menu"
             flex="main:left cross:center">
          <div v-if="showState!=-1"
               :class="{'menu-item':true,'active1':curNavIndex === 0}"
               @click="handleNavClick(0)">
            数据一张图
          </div>
          <div v-if="showState!=-1"
               :class="{'menu-item2':true,'active2':curNavIndex === 1}"
               @click="handleNavClick(1)">
            数字化管理
          </div>
        </div>
        <div class="title">
          <!-- {{ titleTxt }} -->
        </div>
        <div class="main-menu"
             flex="main:right cross:center">
          <div v-if="showState!=-1"
               :class="{'menu-item3':true,'active3':curNavIndex === 2}"
               @click="handleNavClick(2)">
            产业化运营
          </div>
          <div style="position: relative;">
            <div v-if="showState!=-1"
                 :class="{'menu-item':true,'active1':curNavIndex === 3}"
                 @click="handleNavClick(3)">
              智能化决策
            </div>
            <div class="botomBtn2"
                 v-if="curNavIndex=== 3&&btn4 == true">
              <div class="btn3">
                <div :class="[btn4State === 0 ? 'btn1' : 'btn2']"
                     @click="onBtn(0)">
                  农事决策
                </div>
                <div style="height:1px;width:146px;background-color:#979797; opacity: 0.3;" />
                <div :class="[btn4State === 1 ? 'btn1' : 'btn2']"
                     @click="onBtn(1)">
                  政务决策
                </div>
                <div style="height:1px;width:146px;background-color:#979797; opacity: 0.3;" />
                <div :class="[btn4State === 2 ? 'btn1' : 'btn2']"
                     @click="onBtn(2)">
                  方案中心
                </div>
                <div :class="[btn4State === 3 ? 'btn1' : 'btn2']"
                     @click="onBtn(3)">
                  <a target="_blank" href="http://xjty.veima.com/" style="color: #56bee1; text-decoration: none;">溯源管理</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="banner-right">
        <div flex="main:right cross:center">
          <el-tooltip effect="dark"
                      content="更新：9月22日"
                      placement="bottom-end">
            <div class="update-time"
                 flex="cross:center">
              更新时间：{{ lastTime }}
            </div>
          </el-tooltip>
          <div class="qrcode"
               @click="toggleScreenFll">
            <img src="@/assets/images/qrcode.png"
                 alt="全屏切换"
                 title="全屏切换">
          </div>
          <div class="user-info"
               flex="cross:center">
            <div class="avatar">
              <img v-if="info&&info.avatar"
                   :src="baseUrl + info.avatar"
                   alt=""
                   srcset="">
              <img v-else
                   src="~@/assets/images/user.png"
                   alt=""
                   srcset="">
            </div>
            <div class="user-name">
              {{ info&&info.username }}
            </div>
            <div class="log-out"
                 @click="handleLogout">
              <img src="~@/assets/images/login/logout.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/libs/util'
import { _Logout } from '@/api/sys.login'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'MainTop',
  props: {
    drawerState: {
      type: Boolean,
      default: false
    },

    btnState: {
      type: Number,
      default: -1
    },

    topIndex: {
      type: Number,
      default: 0
    },
    top4Index: {
      type: Number,
      default: 0
    },
    info: {
      type: Object,
      default: null
    },
    roles: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      baseUrl: 'https://api.lztl.cn',
      time: null,
      week: null,
      date: null,
      timer: null,
      drawer: false,
      titleTxt: '库尔勒市智慧农业服务平台',
      region: '652928', // 区域
      state: false,
      btn4State: 0,
      btn4: false,
      showState: -1,
      nowWeatherInfo: null,
      curNavIndex: 0,
      lastTime: '',//最后一次更新时间
    }
  },
  watch: {
    drawerState (val) {
      this.drawer = val
    },

    topIndex (val) {
      this.curNavIndex = val
    },

    top4Index (val) {
      this.btn4State = val
    },

    btnState (val) {
      this.showState = val
      if (val === 0) {
        this.titleTxt = '农业大数据平台'
      }
      if (val === 1) {
        this.titleTxt = '林业大数据平台'
      }
      if (val === 2) {
        this.titleTxt = '水利大数据平台'
      }
      if (val === 3) {
        this.titleTxt = '畜牧大数据平台'
      }
    }
  },
  mounted () {
    this.getDateTime()
    this.timer = setInterval(() => {
      this.getDateTime()
    }, 1000);
    this.getTime()
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getTime () {
      _GetAspScreenComponentData('NEWKEL-ZHYCGXSJ').then(res => {
        if (res) {
          this.lastTime = res.data.data[0].ddata_time
        }
      })
    },
    handleNavClick (index) {
      this.curNavIndex = index
      if (index === 3) {
        this.btn4 = true
      }
      this.$emit('navIndex', index)
    },

    /*返回首页 */
    onHome () {
      this.$emit('navIndex', -1)
      this.titleTxt = '库尔勒市智慧农业服务平台'
    },
    // 全屏
    toggleScreenFll () {
      this.$emit('toggleScreenFll')
    },

    /*智能化决策 */
    onBtn (state) {
      this.$emit('top4Index', state)
      this.btn4State = state
      this.btn4 = false
      if(state==2){
        window.open("https://farm.lztl.cn/login?redirect=/")
        this.btn4State = 1
      this.$emit('top4Index', 1)
      }

    },

    // 获取当前日期时间
    getDateTime () {
      let date = new Date();
      let wk = date.getDay()
      /* 在日期格式中，月份是从0开始的，因此要加0
      * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
      * */
      let month = date.getMonth() + 1 < 10 ? "" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      this.time = hours + ":" + minutes + ":" + seconds
      this.week = weeks[wk]
      this.date = month + "月" + day + "日"
    },
    /**
     * 退出
     */
    handleLogout () {
      this.$confirm('确定退出登录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'none'
      }).then(() => {
        // 后端退出后,前台退出
        _Logout().then(() => {
          // 清空用户 session
          util.remove('token')
          util.remove('info')
          util.remove('roles')
          util.remove('permissions')
          // 清空 vuex 用户信息
          this.$store.dispatch('user/setInfo', {}, { root: true })
          // 清空 vuex 用户角色信息
          this.$store.dispatch('user/setRoles', {}, { root: true })
          // 清空 vuex 用户权限信息
          this.$store.dispatch('user/setPermissions', {}, { root: true })
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          location.reload()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    /*打开抽屉 */
    onDrawer () {
      this.$emit('drawer', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-banner-container {
  background-image: url("@/assets/images/banner0.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: linear-gradient(
    180deg,
    rgba(15, 74, 159, 0) 0%,
    #04243f 100%
  );

  .botomBtn2 {
    width: 150px;
    position: absolute;
    z-index: 999;
    right: 0;
    top: 50px;

    .btn3 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 100%;
      width: 150px;
      background: linear-gradient(180deg, #0e6eb9 0%, #012a56 100%);
      box-shadow: inset 0px 0px 6px 0px #08ade3;
      border-radius: 2px;
      // border: 2px solid #008c70;

      .btn1 {
        margin-bottom: 10px;
        margin-top: 10px;
        padding-bottom: 4px;
        width: 72px;
        font-size: 18px;
        color: #d0e7f9;
        border-bottom: 2px solid #d0e7f9;
      }

      .btn2 {
        margin-bottom: 10px;
        margin-top: 10px;
        width: 72px;
        font-size: 18px;
        color: #56bee1;
      }
    }
  }

  .main-menu {
    .menu-item {
      width: 152px;
      height: 40px;
      line-height: 40px;
      background: url("@/assets/images/topbtn11.png") no-repeat;
      text-align: center;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;

      @media (max-width: 1490px) {
        width: 100px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        color: #ffffff;
      }

      &.active1 {
        background: url("@/assets/images/topbtn1.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-weight: bold;
      }
    }
    .menu-item2 {
      width: 142px;
      height: 40px;
      margin-left: 10px;
      padding-right: 30px;
      line-height: 40px;
      background: url("@/assets/images/topbtn2.png") no-repeat;
      text-align: center;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;

      @media (max-width: 1490px) {
        width: 100px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        color: #ffffff;
      }

      &.active2 {
        background: url("@/assets/images/topbtn22.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-weight: bold;
      }
    }

    .menu-item3 {
      background: url("@/assets/images/topbtn3.png") no-repeat;
      width: 142px;
      height: 40px;
      margin-right: 10px;
      padding-left: 30px;
      line-height: 40px;
      background-size: 100% 100%;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;

      @media (max-width: 1490px) {
        width: 100px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        color: #ffffff;
      }

      &.active3 {
        background: url("@/assets/images/topbtn33.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .banner-left {
    margin-right: 222px;
    .menu-icon {
      cursor: pointer;
      margin-left: 20px;

      div {
        width: 25px;
        height: 1.5px;
        background: #56acff;
        margin-bottom: 7px;
      }
    }

    .menu-icon2 {
      cursor: pointer;
      margin-left: 20px;
      img {
        width: 26px;
        height: 26px;
      }
    }

    .date-time {
      cursor: default;
      margin-left: 20px;

      .time {
        font-size: 16px;
        font-weight: 400;
        color: #56acff;
        line-height: 14px;
      }

      .date {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #56acff;
        line-height: 14px;
      }
    }
  }

  .banner-center {
    width: 100%;
    height: 100%;
    cursor: default;

    .title {
      width: 30%;
      min-width: 20%;
      display: flex;
      justify-content: center;
    }

    @media (max-width: 1490px) {
      .title {
        width: 50%;
        font-size: 25px;
        font-weight: 600;
        color: #f1fff9;
        text-shadow: 0px 0px 9px #2fc66e;
      }
    }
  }

  .banner-right {
    cursor: default;
    // margin-left: 30px;
    .update-time {
      margin-right: 18px;
      font-size: 16px;
      font-weight: 400;
      color: #468fd4;
    }
    .qrcode {
      cursor: pointer;
      width: 26px;
      height: 26px;
      padding: 0 30px;
    }

    .user-info {
      .avatar {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #56acff;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .user-name {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #56acff;
        line-height: 16px;
      }
      .log-out {
        margin-right: 10px;
        margin-left: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }

    .weather-container {
      height: 100%;
      line-height: 66px;
      cursor: pointer;

      .temperature {
        font-size: 20px;
        font-weight: 400;
        color: #56acff;
        pointer-events: none;
      }

      .weather {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        pointer-events: none;
      }

      .icon {
        margin-left: 10px;
        margin-right: 50px;
        width: 22px;
        height: 22px;
        pointer-events: none;

        img {
          margin-top: 22px;
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-message-box {
  width: 290px !important;
  height: 195px !important;
  background: url("@/assets/images/login/logout-bg.png") no-repeat !important;
  background-position: -20px !important;
  border: none !important;

  .el-message-box__message {
    width: 128px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    margin: 30px auto;
  }

  .el-message-box__btns {
    text-align: center;

    .el-button {
      width: 106px;
      height: 36px;
      background: #009861;
      border: 2px solid #6affdf;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;

      &:first-child {
        color: #009861;
        background: #ffffff;
      }
    }
  }
}
</style>