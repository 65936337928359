<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 17:03:07
-->
<template>
  <div class="aside-left-container"
       flex="dir:top box:mean">
    <left-top-item />
    <left-middle-item />
    <left-bottom-item :land-id="landId" />
  </div>
</template>

<script>
import leftTopItem from './leftTopItem'
import leftMiddleItem from './leftMiddleItem'
import leftBottomItem from './leftBottomItem'
export default {
  props: {
    landId: {
      type: Number,
      default: null
    }
  },
  name: 'AsideLeft',
  components: {
    leftTopItem,
    leftMiddleItem,
    leftBottomItem
  },
}
</script>

<style lang="scss" scoped>
@import url("@/assets/style/aside.scss");
</style>