// /*
//  * @Author: hlc 1664800317@qq.com
//  * @Date: 2022-09-06 18:15:21
//  * @LastEditors: Adlj
//  * @LastEditTime: 2022-09-29 19:35:36
//  * @FilePath: \korlascreen\src\components\main-view2\top-btn1\bottom-btn3\asideLeft\leftMiddleItem\option.js
//  * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
//  */
// export default {
//   color: [{
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#5883FF' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#51BAFF' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }, {
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#00E5B4' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#AEFFE2' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }],
//   legend: {
//     data: [],// ['产量'],
//     textStyle: {
//       color: '#FFFFFF'
//     },
//     itemWidth: 26,
//     itemHeight: 6,
//     itemStyle: {
//       color: '#68FFD6'
//     },
//     padding: [
//       20,  // 上
//       0, // 右
//       20,  // 下
//       0, // 左
//     ],
//   },
//   xAxis: {
//     type: 'category',
//     data: [],//['果园1', '果园2', '果园3', '果园4'],
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     axisTick: {
//       show: false
//     },
//   },
//   tooltip: {
//     trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
//     axisPointer: {// 坐标轴指示器，坐标轴触发有效
//       type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
//     },
//     formatter: function (params) {
//       var relVal = params[0].name
//       for (var i = 0, l = params.length; i < l; i++) {
//         relVal += '<br/>' + params[i].marker + params[i].seriesName + params[i].value + '吨'
//       }
//       return relVal
//     }
//   },
//   yAxis: {
//     type: 'value',
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     splitLine: {
//       lineStyle: {
//         color: '#495A4D',
//         type: 'dashed'
//       }
//     },
//     data: [],// ['产量']
//   },
//   grid: {
//     top: 60,
//     botttom: 0,
//   },
//   series: [
//     {
//       name: '',//'产量',
//       data: [],//[12, 35, 41, 33],
//       type: 'line',
//       lineStyle: {
//         color: '#00FFD4',
//         shadowOffsetX: 0,
//         shadowOffsetY: 3,
//         shadowBlur: 6,
//         shadowColor: "#00FFD4"
//       }
//     }
//   ]
// }
// 折线图
const option = {
  title: {
    text: ''
  },
  legend: {
    show: true,
    // data: ['温度℃', '湿度%'],
       left: '60%',
       top:"1%",
    textStyle: {
      color: 'rgba(216, 240, 255, 0.80)',
      fontSize:"15"
    },
    itemWidth: 8,
    itemHeight: 8,
    backgroundColor: 'rgba(216, 240, 255, 0.08)',
    itemStyle: {
      // color: '#1C6DFC'
    },
  },
  xAxis: {
    type: 'category',
    //样式是x轴的样式为cate样式
    // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    data: ['0:00', '2:00', '4:00', '6:00',  '8:00',  '10:00','12:00','14:00','16:00','18:00','20:00','22:00'],
    axisLabel: {
      interval: 0,
      formatter: '{value}',//Y轴左侧文字的占位
      textStyle: {
        fontSize: 15,
        color: "#ccc" //Y轴文字颜色
      },
      interval: 0,
      rotate: 35
    },

    //里面的数据为周一到周天的全部数据
  },
  //这里是将y轴设计成左边是温度，右边是湿度这样的形式
  
  yAxis: [{
    type: 'value',
    name: '杀虫仓温度',
    max: 100,
    axisLine:{
      lineStyle:{
          // color:'#ccc',

          fontSize:"16"
      }
  },      
  axisLabel: {
    textStyle: {
        // color: '#ccc',
        fontSize:'18',
       
    },
},
    min: 0
  }, {
    type: 'value',
    name: '',
    max: 100,
    axisLine:{
      lineStyle:{
          color:'#ccc',
      },
  },   
  axisLabel: {
    textStyle: {
        color: '#ccc',
        fontSize:'18',
       
    },
},
    min: 0
  },],
  //设计温度和湿度进行的数据报表，设计的形式以line线的形式进行设计
  series: [{
    name: '烘干仓温度',
color: "rgba(255, 171, 145, 0.8)",
data: [],
    // data: [14, 14, 15, 19, 23, 26, 28,28,27,24,20,16,11],
    type: 'line',
    symbol:'circle',
    areaStyle: {//填充的颜色
      color: {//线性渐变前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
          type: 'linear',
          x: 0,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [{
              offset: 0, color: 'rgba(255,240,170,0)' // 0% 处的颜色
          }, {
              offset: 1, color: 'rgba(255, 171, 145, 0.8)' // 100% 处的颜色
          }],
          globalCoord: false// 缺省为 false
      }
    },
    itemStyle:{//折线拐点标志的样式
      borderColor:"rgba(255, 171, 145, 1)",//拐点的边框颜色
      borderWidth:3.5
    },
    lineStyle:{//折线的样式
      color:"rgba(255, 171, 145,0.8)"
    }

  }, {
    name: '杀虫仓温度',
    // data: [13, 14, 18, 21, 23, 26, 28,28.3,27,24.3,19,15,10],
    data: [],
color: "rgba(253, 115, 71, 0.8)",

    type: 'line',
    symbol:'circle',
    areaStyle: {//填充的颜色
      color: {//线性渐变前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
          type: 'linear',
          x: 0,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [{
              offset: 0, color: 'rgba(255,240,170,0)' // 0% 处的颜色
          }, {
              offset: 1, color: 'rgba(253, 115, 71, 0.8)' // 100% 处的颜色
          }],
          globalCoord: false// 缺省为 false
      }
    },
    
    itemStyle:{//折线拐点标志的样式
      borderColor:"rgba(253, 115, 71, 1)",//拐点的边框颜色
      borderWidth:3.5
    },
    lineStyle:{//折线的样式
      color:"rgba(253, 115, 71,0.8)"
    }
  }],
  //添入一个功能，当鼠标指到折线图上的点的位置的时候，就可以在图上显示他们相应位置的数值
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#ccc'
      },
    },
  },
  grid: {
    top: 50,
    height: 170,

  },

}
export default option