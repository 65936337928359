/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:17:47
 */
export default {
  title: {
    text: '单位: 万羽 - 亩',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  xAxis: {
    type: 'category',
    data: ['存栏蛋鸡', '海蓝鸡', '麻花鸡'],//['农业', '工业', '服务业', '生活用水'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#4B4743',
        type: 'dashed'
      }
    }
  }],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    padding: [
      30,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  grid: {
    top: '15%',
    left: '15%',
    bottom: '10%',
  },
  series: [
    {
      name: '数量',//'产量/吨',
      data: [80, 0.5, 0.5],// [12, 35, 41, 33],
      type: 'bar',
      barWidth: '20',
      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFFFF'
      },
      itemStyle: {
        // shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: '养殖面积',//'产量/吨',
      data: [6.75, 5, 30],// [12, 35, 41, 33],
      type: 'bar',
      barWidth: '20',
      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFFFF'
      },
      itemStyle: {
        // shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}