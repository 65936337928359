/*
 * @Description: 用户登陆接口
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: yjk
 * @LastEditTime: 2023-11-27 19:14:31
 */
import request from "@/libs/axios";
import setting from "@/setting";

const TENANT_ID = setting.server.tenantId;
const AUTHORIZATION = setting.server.authorization;

/*数据汇总 */
export function getTraceDataCount(data) {
  return request({
    url: "/tracing/tr-trace/data/count",
    method: "GET",
    params: data,
  });
}

/*根据区划统计农机在线离线作业中数量 */
export function getTrVehicleCount(region) {
  return request({
    url: "/platform/asp-vehicle/region/count/" + region,
    method: "GET",
  });
}

/*溯源进度 */
export function getTraceProgress(data) {
  return request({
    url: "/tracing/tr-trace/traceProgress/list/",
    method: "GET",
    params: data,
  });
}

/*交售情况 */
export function getTraceSellsituationList(data) {
  return request({
    url: "/tracing/tr-trace/sellsituation/list/",
    method: "GET",
    params: data,
  });
}

/*根据region获取今日牌价 */
export function getTraceTodayPrice(region) {
  return request({
    url: "/tracing/tr-trace/today/price/region/" + region,
    method: "GET",
  });
}

/*根据region获取交售排行 */
export function getTrsellrankingList(data) {
  return request({
    url: "/tracing/tr-trace/sellranking/list",
    method: "GET",
    params: data,
  });
}

/*根据region获取乡镇交售情况 */
export function gettownshipsellrankingList(data) {
  return request({
    url: "/tracing/tr-trace/townshipsellranking/list",
    method: "GET",
    params: data,
  });
}

/*根据region获取棉蛋数量 */
export function getcottonNumber(data) {
  return request({
    url: "/tracing/tr-trace/cotton/count",
    method: "GET",
    params: data,
  });
}

/*根据region获取棉蛋数量 */
export function getplatform(data) {
  return request({
    url: "/platform/eight/jl/device",
    method: "GET",
    params: data,
  });
}

let gatewayUrl = "https://admin.lztl.cn";

/*获取耳环设备列表 */
export function getIotWhEarringPage(data) {
  return request({
    url: gatewayUrl + "/open-api/iot/open/wh/earring/page",
    method: "GET",
    params: data,
    headers: {
      "TENANT-ID": 1,
    },
  });
}

/*获取项圈设备列表 */
export function getIotWhNecklacePage(data) {
  return request({
    url: gatewayUrl + "/open-api/iot/open/wh/necklace/page",
    method: "GET",
    params: data,
    headers: {
      "TENANT-ID": 1,
    },
  });
}

/*获取设备详情 */
export function getIotWhInfo(data) {
  return request({
    url: gatewayUrl + "/open-api/iot/open/wh/animal/info",
    method: "GET",
    params: data,
    headers: {
      "TENANT-ID": 1,
    },
  });
}
