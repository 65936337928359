<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 13:17:35
-->
<template>
  <div class="aside-left-container4">
    <div class="box">
      <div class="card-head"
           flex="main:center">
        <div class="txt">
          龙头企业
        </div>
      </div>

      <div style="height:100%"
           flex="dir:top main:first">
        <div style="height:22%">
          <div class="title">
            国家级农业产业化重点龙头企业
          </div>
          <div class="top"
               flex="main:justify cross:center box:mean">
            <div flex="cross:center">
              <div class="logo">
                <img src="@/assets/images/top3/logo1.png">
              </div>
              <div class="right">
                新疆瑞源乳业有限公司（库尔勒市）
              </div>
            </div>
            <div flex="cross:center">
              <div class="logo">
                <img src="@/assets/images/top3/logo1.png">
              </div>
              <div class="right">
                新疆瑞源乳业有限公司（库尔勒市）
              </div>
            </div>
          </div>
          <div class="top"
               flex="main:justify cross:center box:mean">
            <div flex="cross:center">
              <div class="logo">
                <img src="@/assets/images/top3/logo1.png">
              </div>
              <div class="right">
                新疆瑞源乳业有限公司（库尔勒市）
              </div>
            </div>
            <div flex="cross:center">
              <div class="logo">
                <img src="@/assets/images/top3/logo1.png">
              </div>
              <div class="right">
                新疆瑞源乳业有限公司（库尔勒市）
              </div>
            </div>
          </div>
        </div>

        <div class="bottom"
             flex="main:justify box:mean">
          <div style="height:100%">
            <div class="title2">
              自治区级农业产业化重点龙头企业
            </div>
            <div class="left">
              <div class="list">
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
              </div>
            </div>
          </div>

          <div>
            <div class="title2">
              自治区州农业产业化重点龙头企业
            </div>
            <div class="right">
              <div class="list">
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
                <span>新疆巴音郭楞蒙古自治州沙依东原牧场</span><br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsideLeft',

  data () {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>