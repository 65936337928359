/*
 * @Description: 种植概览 上部图表配置
 * @Author: Pancras
 * @Date: 2022-07-05 10:39:47
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 06:25:00
 */
export default {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    top: '15%',
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: ['24', '25', '26', '27', '28']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '阿瓦提镇',
      type: 'line',
      stack: 'Total',
      data: [50, 30, 40, 20, 10]
    },
    {
      name: '乌鲁却勒镇',
      type: 'line',
      stack: 'Total',
      data: [60, 20, 30, 40, 50]
    },
    {
      name: '英下乡',
      type: 'line',
      stack: 'Total',
      data: [20, 25, 30, 35, 40]
    },
    {
      name: '拜十艾日克镇',
      type: 'line',
      stack: 'Total',
      data: [50, 50, 53, 55, 60]
    },
    {
      name: '三河镇',
      type: 'line',
      stack: 'Total',
      data: [30, 30, 40, 50, 30]
    }
  ]
}
