/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 12:02:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 17:13:23
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['供水量'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  xAxis: {
    type: 'category',
    data: [],//['农业供水', '服务业供水'],
    axisLabel: {
      color: '#FFFFFF'
    },
    axisTick: {
      show: false
    },
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}亿立方'
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    },
    data: [],// ['供水量']
  },
  grid: {
    top: 60,
    botttom: 0,
    left: 60,
  },
  series: [
    {
      name: '',//'供水量',
      data: [],//[12, 35],
      type: 'line',
      lineStyle: {
        color: '#00FFD4',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#00FFD4"
      }
    }
  ]
}
