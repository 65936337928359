/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:03:16
 */
export default {
  color: ['#49AC00FF', '#FFFFFF', '#00CBFF', '#27EBBE'],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}亿立方'
  },
  legend: {
    left: '5%',
    top: '40%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 20
  },
  series: [
    {
      name: '名称',
      type: 'pie',
      radius: 65,
      center: ['65%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        // normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        // }
      },
      label: {
        color: '#fff',
        fontSize: 16,
        formatter: '{d}%\n\n',
        // formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
        // padding: [0, -10],//取消hr线跟延长线之间的间隙
        // rich: {
        //   a: {
        //     color: '#999',
        //     lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
        //     align: 'center'
        //   }
        // }
      },

      data: [
        { value: 8.015, name: '农业用水量' },
        { value: 1.059, name: '工业用水量' },
        { value: 0.491, name: '生活用水量' },
        { value: 0.713, name: '人工生态与环境补水量' },
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
