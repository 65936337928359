export default {
  title: {
    text: '单位: 万只、万头',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}只',
  },
  yAxis: {
    type: 'category',
    data: ['小反刍兽疫', '高致病性', '口蹄疫', '布鲁氏菌病', '牛结节性皮肤病', '狂犬病', '羊痘', '三联四防', '犬驱虫'],//['牛', '羊', '马', '驴', '狗', '兔'],
    axisLabel: {
      color: 'white'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      show: false
    },
  },
  grid: {
    top: '15%',
    bottom: '15%',
    left:'21%',
  },
  series: [
    {
      name: '',//'办理数量/件',
      data: [52,8, 89.15, 58.37, 13.94, 1.74, 0.09, 2.9, 0.58, 0.87],//[30, 39, 28, 22, 60, 60],
      type: 'bar',
      barWidth: '6',
      lineStyle: {
        color: '#00FFD4',
      },
      label: {
        show: true,
        position: "right",
        color: 'white'
      },
      showBackground: true,
      backgroundStyle: {
        color: '#112817',
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
