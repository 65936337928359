/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 16:47:47
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 16:24:14
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['流量/亿立方'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'category',
    data: [],//['2019年', '2020年', '2021年', '2022年'],
    axisLabel: {
      color: '#B1B6AE'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    }
  },
  xAxis: {
    splitLine: {
      show: false
    },
    axisLabel: { show: false },
  },
  grid: {
    top: 50,
    botttom: 0,
    left: 60,
  },
  series: [
    {
      name: '',//'流量/亿立方',
      data: [],// [58, 56, 22, 28],
      type: 'bar',
      barWidth: '6',
      lineStyle: {
        color: '#00FFD4',
      },
      label: {
        show: true,
        position: "right",
        color: '#00E5B4'
      },
      showBackground: true,
      backgroundStyle: {
        color: '#112817',
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
