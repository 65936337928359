/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:13:47
 */
export default {
  title: {
    text: '单位: 万只',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  xAxis: {
    type: 'value',
    data: ['牛', '羊', '猪'],// ['畜牧', '畜牧', '畜牧', '畜牧', '畜牧', '畜牧'],
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#4B4743',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '万只'
      }
      return relVal
    }
  },
  yAxis: [{
    type: 'category',
    data: ['托布力其乡', '兰干乡', '阿瓦提乡', '哈拉玉宫乡', '普惠乡', '西尼尔镇', '塌什店镇', '铁克其乡', '英下乡', '恰尔巴格乡', '上户镇', '和什力克乡'],
    axisLabel: {
      color: '#FFFFFF'
    },
  }],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
  },
  grid: {
    top: '15%',
    left: '17%',
    bottom: '10%',
  },
  series: [
    {
      name: '',// '存栏量/只',
      data: [5.83, 2.18, 5.33, 5.67, 3.62, 0.92, 0.31, 1.05, 2.46, 1.53, 2.15, 7.56],// [12, 35, 41, 33, 29, 42],
      type: 'bar',
      barWidth: '15',
      stack: 'animal',
      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'right',
        color: '#FFFFFFFF',
        // 等于0的label不展示
        formatter: ({ value }) => {
          return value === 0 ? '' : value + ''
        },
      },
      itemStyle: {
        color: 'Aqua',
        shadowColor: "gray",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
