<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-01 21:24:00
-->
<template>
  <div class="view0container">
    <div class="top">
      <mainTop :info="info"
               @drawer="getDrawerState"
               @toggleScreenFll="toggleScreenFll" />
    </div>
    <div class="bottom">
      <mainBottom />
    </div>
    <div class="circle" />
  </div>
</template>

<script>
import mainTop from './mainTop'
import mainBottom from './mainBottom'
export default {
  name: 'App',
  props: {
    info: {
      type: Object,
      default: null
    },
  },
  components: {
    mainTop,
    mainBottom
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
    /*获取抽屉状态 */
    getDrawerState (val) {
      this.$emit('drawer', val)
    }
    ,
    // 全屏
    toggleScreenFll () {
      console.log(123);
      this.$emit('toggleScreenFll')
    }
  }
}
</script>
<style scoped lang="scss">
.view0container {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/view0Banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;

  .top {
    z-index: 999;
    width: 100%;
    height: 7%;
    position: relative;
  }

  .bottom {
    height: 90%;
  }

  .circle {
    width: 1580px;
    height: 1580px;
    background-image: url("@/assets/images/circle.png");
    background-repeat: no-repeat;
    position: absolute;
    top: -230px;
    left: 930px;
    z-index: 1;
    animation: changeright 24s linear infinite;
  }

  @keyframes changeright {
    0% {
      -webkit-transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(180deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
</style>
