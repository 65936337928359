import * as echarts from 'echarts/core';
// /*
//  * @Description: 用一句话描述文件的作用
//  * @Author: Pancras
//  * @Date: 2022-09-08 08:39:44
//  * @LastEditors: Adlj
//  * @LastEditTime: 2022-09-29 19:45:41
//  */
// export default {
//   color: [{
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#5883FF' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#51BAFF' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }, {
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#00E5B4' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#AEFFE2' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }],
//   legend: {
//     data: ['数据1', '数据2'],
//     textStyle: {
//       color: '#FFFFFF'
//     },
//     itemWidth: 26,
//     itemHeight: 6,
//     itemStyle: {
//       color: '#68FFD6'
//     },
//     padding: [
//       20,  // 上
//       0, // 右
//       20,  // 下
//       0, // 左
//     ],
//   },
//   xAxis: {
//     type: 'category',
//     data: ['一月', '二月', '三月', '四月', '五月', '六月'],
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     axisTick: {
//       show: false
//     },
//   },
//   tooltip: {
//     trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
//     axisPointer: {// 坐标轴指示器，坐标轴触发有效
//       type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
//     },
//     formatter: '{b} : {c}万元',
//   },
//   yAxis: {
//     type: 'value',
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     splitLine: {
//       lineStyle: {
//         color: '#495A4D',
//         type: 'dashed'
//       }
//     }
//   },
//   grid: {
//     top: 60,
//     botttom: 0,
//     left: 50,
//   },
//   series: [
//     {
//       name: '数据1',
//       data: [20, 22, 41, 33, 34, 24],
//       type: 'line',
//       lineStyle: {
//         color: '#00FFD4',
//         shadowOffsetX: 0,
//         shadowOffsetY: 3,
//         shadowBlur: 6,
//         shadowColor: "#00FFD4"
//       },
//     },
//     {
//       name: '数据2',
//       data: [8, 30, 30, 20, 24, 34],
//       type: 'line',
//       lineStyle: {
//         color: '#FFA400',
//         shadowOffsetX: 0,
//         shadowOffsetY: 3,
//         shadowBlur: 6,
//         shadowColor: "#FFA400"
//       },
//     }
//   ]
// }
var data = [
  {
    name: "巴音布鲁克羊",
    value: 287,
  },
  {
    name: "杜泊羊",
    value: 83,
  },
  {
    name: "湖羊",
    value: 131,
  },
  {
    name: "德国美利奴羊",
    value: 79,
  },

];
var xData = [],
  yData = [];
var min = 50;
data.map(function (a, b) {
  xData.push(a.name);
  if (a.value === 0) {
    yData.push(a.value + min);
  } else {
    yData.push(a.value);
  }
});
const option = {
  // backgroundColor: "#111c4e",
  color: ["#3398DB"],
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "line",
      lineStyle: {
        opacity: 0,
      },
    },
    formatter: function (prams) {
      if (prams[0].data === min) {
      } else {
        return prams[0].name + prams[0].data + "只";
      }
    },
  },
  legend: {
    data: ["直接访问", "背景"],
    show: false,
  },
  grid: {
    left: "0%",
    right: "0%",
    bottom: "5%",
    top: "7%",
    height: "85%",
    containLabel: true,
    z: 22,
  },
  xAxis: [
    {
      type: "category",
      gridIndex: 0,
      data: xData,
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        lineStyle: {
          color: "#0c3b71",
        },
      },
      axisLabel: {
        show: true,
        color: "rgb(170,170,170)",
        fontSize: 16,
        interval: 0,
        // rotate: 25
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      gridIndex: 0,
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      min: min,
      max: 100,
      axisLine: {
        lineStyle: {
          color: "#0c3b71",
        },
      },
      axisLabel: {
        color: "rgb(170,170,170)",
        formatter: "{value} %",

      },

    },
    {
      type: "value",
      gridIndex: 0,
      min: min,
      max: 100,
      splitNumber: 12,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"],
        },
      },
    },
  ],
  series: [
    {
      name: "合格率",
      type: "bar",
      barWidth: "30%",
      xAxisIndex: 0,
      yAxisIndex: 0,
      itemStyle: {
        normal: {
          barBorderRadius: 30,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#00feff",
            },
            {
              offset: 0.5,
              color: "#027eff",
            },
            {
              offset: 1,
              color: "#0286ff",
            },
          ]),
        },
      },
      data: yData,
      zlevel: 11,
    },
    {
      name: "背景",
      type: "bar",
      barWidth: "50%",
      xAxisIndex: 0,
      yAxisIndex: 1,
      barGap: "-135%",
      data: [100, 100, 100, 100, 100, 100, 100],
      itemStyle: {
        normal: {
          color: "rgba(255,255,255,0.1)",
        },
      },
      zlevel: 9,
    },
  ],
};

export default option