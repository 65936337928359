/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-08 13:01:50
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-22 14:00:40
 * @FilePath: \korlascreen\src\components\main-view4\top-btn1\bottom-btn2\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#A88836' // 0% 处的颜色
    }, {
      offset: 1, color: '#A88836' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],// ['数据1', '数据2'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'category',
    data: ['绵羊自然交配', '人工授精'],//['一月', '二月', '三月', '四月', '五月', '六月'],
    axisLabel: {
      color: '#B1B6AE'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}只',
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      show: false
    },
  },
  grid: {
    top: 50,
    botttom: 0,
    left: 90,
  },
  series: [
    {
      name: '',//'数据1',
      data: [128500, 1470],//[58, 56, 22, 28, 60, 46],
      type: 'bar',
      barWidth: '6',
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    },
    {
      name: '',// '数据2',
      data: [],// [38, 46, 32, 48, 48, 58],
      type: 'bar',
      barWidth: '6',
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
