<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-11-20 03:24:08
 * @LastEditors: Hlc
 * @LastEditTime: 2023-02-06 17:10:26
-->
<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        今日牌价
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="option"
                       style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>
<style lang="scss">
.dv-scroll-board .header {
  background-color: rgba(21, 77, 160, 0.46) !important;
}
.dv-scroll-board {
  .rows {
    .row-item {
      height: 50px;
      &:nth-child(odd) {
        background: url("@/assets/images/td-bg.png") repeat-x;
      }
      &:nth-child(even) {
        background: none;
      }
    }
  }
}
</style>