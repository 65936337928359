/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:32:54
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#FF5700', '#D90FE3', '#0F5DE3'],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}万',
  },
  legend: {
    left: '3%',
    top: '5%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 15
  },
  series: [
    {
      type: 'pie',
      radius: 50,
      center: ['65%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        color: '#fff',
        fontSize: 14,
        // normal: {
        //   // formatter: '{d}%, {c} \n\n',
        //   // formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
        //   padding: [0, -10],//取消hr线跟延长线之间的间隙
        //   rich: {
        //     a: {
        //       color: '#fff',
        //       lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
        //       align: 'center'
        //     }
        //   }
        // }
      },

      data: [
        { value: 1.73, name: '牛口蹄疫' },
        { value: 45.74, name: '羊口蹄疫' },
        { value: 3.98, name: '猪口蹄疫' },
        { value: 14.14, name: '羊小反刍兽医' },
        { value: 81.52, name: '禽流感' }
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
