<template>
  <div class="left-top-container mt"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body">
      <div class="view4btn4">
        <div class="top">
          <div class="e1"
               @click="dialogShow = true">
            <el-progress type="circle"
                         :percentage="nums1"
                         style="white-space:pre"
                         :format="format"
                         width="100"
                         :stroke-width="4"
                         color="#a60900" />
          </div>
        </div>

        <div class="bottom">
          <div class="e2"
               @click="dialogShow = true">
            <el-progress type="circle"
                         :format="format2"
                         :percentage="nums2"
                         style="white-space:pre"
                         width="100"
                         :stroke-width="4"
                         color="#FF5C00" />
          </div>
          <div class="e3"
               @click="dialogShow = true">
            <el-progress type="circle"
                         :format="format2"
                         :percentage="nums3"
                         style="white-space:pre"
                         width="100"
                         :stroke-width="4"
                         color="#FFB100" />
          </div>
        </div>
      </div>
    </div>
    <centerDialog2 @show="getdialogShow"
                   :show="dialogShow" />
  </div>
</template>

<script>
import centerDialog2 from '../../centerDialog2'
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'RightTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  components: {
    centerDialog2
  },

  data () {
    return {
      title: '',
      names1: '',
      nums1: '',
      names2: '',
      nums2: '',
      names3: '',
      nums3: '',
      dialogShow: false,
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-ZWJC-XMY-WCLBJ').then(res => {
        if (res) {

          const msgData = []
          res.data.data.forEach(item => {
            msgData.push([
              item.name,
              item.num_data
            ])
          });
          this.names1 = msgData[0][0]
          this.nums1 = msgData[0][1]
          this.names2 = msgData[1][0]
          this.nums2 = msgData[1][1]
          this.names3 = msgData[2][0]
          this.nums3 = msgData[2][1]
          this.title = res.data.form.name
        }
      })
    },
    format (percentage) {
      return percentage + '\n\n' + this.names1
    },

    format2 (percentage) {
      return percentage + '\n\n' + this.names2
    },

    format3 (percentage) {
      return percentage + '\n\n' + this.names3
    },

    getdialogShow (val) {
      this.dialogShow = val
    },

    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>


<style lang="scss">
.card-body {
  width: 100%;

  .view4btn4 {
    width: 100%;
    margin-top: 20px;

    .top {
      display: flex;
      justify-content: center;

      .e1 {
        .el-progress-circle {
          width: 100px;
          height: 100px;
          background: linear-gradient(116deg, #a60900 0%, #003728 100%);
          box-shadow: inset 0px 0px 17px 7px #ae0000;
          border-radius: 50%;
          border-image: linear-gradient(
              116deg,
              rgba(255, 0, 0, 1),
              rgba(255, 134, 134, 1)
            )
            4 4;
        }
        .el-progress__text {
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .e2 {
        .el-progress-circle {
          width: 100px;
          height: 100px;
          background: linear-gradient(116deg, #005744 0%, #003728 100%);
          box-shadow: inset 0px 0px 17px 7px #542a00;
          border-image: linear-gradient(
              116deg,
              rgba(255, 92, 0, 1),
              rgba(255, 177, 114, 1)
            )
            4 4;
          border-radius: 50%;
        }
        .el-progress__text {
          color: #fff;
          font-size: 16px;
        }
      }

      .e3 {
        margin-right: 30px;
        .el-progress-circle {
          width: 100px;
          height: 100px;
          background: linear-gradient(116deg, #005744 0%, #003728 100%);
          box-shadow: inset 0px 0px 17px 7px #544f00;
          border-image: linear-gradient(
              115deg,
              rgba(255, 177, 0, 1),
              rgba(255, 250, 182, 1)
            )
            4 4;
          border-radius: 50%;
        }
        .el-progress__text {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.left-top-container {
  .card-body {
    width: 100%;
    height: 100%;

    .e1 {
      .el-progress-circle {
        background: linear-gradient(116deg, #005744 0%, #003728 100%);

        box-shadow: inset 0px 0px 17px 7px #00543e;

        border-image: linear-gradient(
            116deg,
            rgba(0, 255, 169, 1),
            rgba(255, 255, 255, 1)
          )
          4 4;
        border-radius: 50%;
      }
      .el-progress__text {
        color: #fff;
        font-size: 16px;
      }
    }

    .e2 {
      margin-left: 40px;
      .el-progress-circle {
        background: linear-gradient(116deg, #572900 0%, #003728 100%);
        box-shadow: inset 0px 0px 17px 7px #542e00;
        border-image: linear-gradient(
            116deg,
            rgba(255, 90, 0, 1),
            rgba(255, 203, 139, 1)
          )
          4 4;
        border-radius: 50%;
      }
      .el-progress__text {
        color: #fff;
        font-size: 16px;
      }
    }

    .row-1 {
      margin: 20px 0;

      div {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 14px;
        text-align: center;
      }

      span {
        color: #96a098;
        font-weight: 400;
        margin-right: 10px;
        font-size: 14px;
      }
    }

    .chart {
      width: 100%;
      height: 300px;
    }
  }
}
</style>