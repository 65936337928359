// 智能化决策 - 农事决策管理接口
import request from '@/libs/axios'

/**
 * 根据地块统计任务情况
 * @param {*} landId  
 * @returns 
 */
export function _GetTaskCount (landId) {
  return request({
    url: '/platform/asp-task/countByLand',
    method: 'GET',
    params: {
      landId
    }
  })
}

/**
 * 根据地块查询任务列表
 * @param {*} landId  
 * @returns 
 */
export function _GetTaskList (landId, limit) {
  return request({
    url: '/platform/asp-task/listByLand',
    method: 'GET',
    params: {
      landId,
      limit
    }
  })
}

/**
 * 根据地块ID获取最新工作项
 * @param {*} landId  
 * @returns 
 */
export function _GetPlantPlanStage (landId, limit) {
  return request({
    url: '/platform/asp-plant-plan-stage-item-self/listByLand',
    method: 'GET',
    params: {
      landId,
      limit
    }
  })
}

/**
 * 根据地块查询农艺师工作建议
 * @param {*} landId  
 * @returns 
 */
export function _GetTaskFlowNYS (landId, limit) {
  return request({
    url: '/platform/asp-task-flow/nys/listByLand',
    method: 'GET',
    params: {
      landId,
      limit
    }
  })
}

/**
 * 根据地块查询最新的小哥工作建议
 * @param {*} landId  
 * @returns 
 */
export function _GetTaskFlowXG (landId) {
  return request({
    url: '/platform/asp-task-flow/xg/getByLand',
    method: 'GET',
    params: {
      landId
    }
  })
}