/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-13 20:34:56
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 16:07:49
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],// ['报警频次'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'category',
    data: [],// ['地点1', '地点1', '地点1', '地点1', '地点1', '地点1'],
    axisLabel: {
      color: '#B1B6AE'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      show: false
    },
    data: [],//['报警频次']
  },
  grid: {
    top: 50,
    botttom: 0,
    left: 60,
  },
  series: [
    {
      name: '',// '报警频次',
      data: [],// [58, 56, 22, 28, 39, 30],
      type: 'bar',
      barWidth: '6',
      lineStyle: {
        color: '#00FFD4',
      },
      label: {
        show: true,
        position: "right",
        color: '#00E5B4'
      },
      showBackground: true,
      backgroundStyle: {
        color: '#112817',
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
