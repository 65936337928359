<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 17:16:40
-->
<template>
  <div class="aside-right-container"
       flex="dir:top box:mean">
    <right-top-item :region="regionId" />
    <right-middle-item :region="regionId" />
    <right-bottom-item :region="regionId" />
  </div>
</template>

<script>
import rightTopItem from './rightTopItem'
import rightMiddleItem from './rightMiddleItem'
import rightBottomItem from './rightBottomItem'

export default {
  name: 'AsideRight',
  components: {
    rightTopItem,
    rightMiddleItem,
    rightBottomItem
  },

  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      regionId: ''
    }
  },

  watch: {
    region (val) {
      this.regionId = val
    }
  }

}
</script>

<style lang="scss" scoped>
.aside-right-container {
  cursor: default;
  width: 100%;
  height: 100%;
}
</style>