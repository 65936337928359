<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-23 13:53:05
-->
<template>
  <div style="height:100%">
    <div style="height:100%">
      <bottomBtn1 v-if="btnState===2" />
      <bottomBtn2 v-if="btnState===3" />
      <bottomBtn3 v-if="btnState===0" />
      <bottomBtn4 v-if="btnState===1" />
    </div>
    <!-- 底部一级标签 -->
    <div class="botomBtn">
      <div :class="[btnState === 0 ? 'btn1' : 'btn2']"
           @click="onBtn(0)">
        农机管理
      </div>
      <div :class="[btnState === 1 ? 'btn1' : 'btn2']"
           @click="onBtn(1)">
        溯源管理
      </div>
      <div :class="[btnState === 2 ? 'btn1' : 'btn2']"
           @click="onBtn(2)">
        {{ one }}
      </div>
      <div :class="[btnState === 3 ? 'btn1' : 'btn2']"
           @click="onBtn(3)">
        {{ two }}
      </div>
    </div>
  </div>
</template>

<script>
import bottomBtn1 from './bottom-btn1'
import bottomBtn2 from './bottom-btn2'
import bottomBtn3 from './bottom-btn3'
import bottomBtn4 from './bottom-btn4'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    bottomBtn1,
    bottomBtn2,
    bottomBtn3,
    bottomBtn4
  },

  props: {
    bottomIndex: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      one: '',
      two: '',
      btnState: 0,//当前按钮
    }
  },

  watch: {
    bottomIndex: {
      handler (val) {
        console.log(val)
        this.btnState = val
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },
  },

  mounted () {
    this.getScreenData()
  },

  methods: {
    //获取大屏表单信息
    getScreenData () {
      _GetAspScreenComponentData('NEWKEL-SMY-SZHGL-DBAN').then(res => {
        if (res) {
          this.one = res.data.data[0].name_data
          this.two = res.data.data[1].name_data

        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    }
  }
}
</script>

<style scoped lang="scss">
.botomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;

  .btn1 {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
    height: 45px;
    background: linear-gradient(360deg, #00845a 0%, #154a31 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #82ffe6;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #00ecbf;
    line-height: 45px;
    text-align: center;
  }

  .btn2 {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
    height: 45px;
    background: linear-gradient(360deg, #002519 0%, #154a31 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #008c70;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #fff;
    line-height: 45px;
    text-align: center;

    img {
      margin-right: 10px;
    }
  }
}
</style>
