const option = {
  title: {
    text: '单位: 台',
    left: 'center',
    textStyle: {
      color: 'white'
    }
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: 'white',
    },
    splitLine: {
      lineStyle: {
        color: '#E8E8E8',
        type: 'dashed'
      }
    }
  },
  yAxis: {
    type: 'category',
    // data: ['铁克其乡', '英下乡', '恰尔巴格乡', '上户镇', '和什力克乡', '托布力其乡', '兰干乡', '阿瓦提乡',
    //       '哈拉玉宫乡', '普惠乡'],
    data:['土壤墒情仪','虫情监测仪','杀虫灯','水肥一体机','小型气象站'],
    axisLabel: {
      color: 'white',
    },
  },
  grid: {
    left: '1%',
    right: '5%',
    bottom: '3%',
    containLabel: true
  },
  series: [
    {
      itemStyle: {
        color: '#65B581'
      },
    // 土壤墒情仪：8台；虫情监测仪：2台；杀虫灯：22台；水肥一体机：2台。小型气象站：2台。

      // data: [7718, 10027, 7467.05, 49887.73, 34640, 53035.4, 19376.9, 82661, 49657.8, 2432.3],
      data: [8,2,22,2,2],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      },
      label: {
        show: true,
        position: 'right',
        textStyle: {
          color: 'white'
        }
      }
    }
  ]
};

export default option