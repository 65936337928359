<template>
  <div class="left-middle-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        溯源进度
      </div>
    </div>
    <div class="item-content">
      <div class="item-row"
           flex="cross:center">
        <div class="item-label">
          采收
        </div>
        <div class="progress p1">
          <el-progress :percentage="20"
                       color="#2A5AE1" />
        </div>
      </div>
      <div class="item-row"
           flex="cross:center">
        <div class="item-label">
          拉运
        </div>
        <div class="progress p2">
          <el-progress :percentage="15"
                       color="#2A5AE1" />
        </div>
      </div>
      <div class="item-row"
           flex="cross:center">
        <div class="item-label">
          交售
        </div>
        <div class="progress p3">
          <el-progress :percentage="50"
                       color="#2A5AE1" />
        </div>
      </div>
      <div class="item-row"
           flex="cross:center">
        <div class="item-label">
          加工
        </div>
        <div class="progress p4">
          <el-progress :percentage="60"
                       color="#2A5AE1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftMiddleItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      params: {
        region: '652928'
      },
      data: {}//溯源进度数据
    }
  },
  mounted () {
    this.getData()
  },

  watch: {
    region (val) {
      this.params.region = val
    }
  },

  methods: {
    getData () {

    }
  }
}
</script>

<style lang="scss" scoped>
.left-middle-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 12px 0px rgba(129, 221, 192, 0.25);
  border: 1px solid #254a42;
  backdrop-filter: blur(1px);

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }
  .item-content {
    margin: 10px 0 0 0;
    width: 85%;
    height: 100%;

    .item-row {
      width: 100%;
      height: 23%;

      .item-label {
        min-width: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }

      .progress::v-deep {
        margin-left: 6px;
        width: 95%;

        .el-progress-bar {
          width: 100%;
        }

        .el-progress-bar__outer {
          background: #04225a;
          border-radius: 3px;
        }

        &.p1 .el-progress-bar__inner {
          background: linear-gradient(270deg, #2a5ae1 0%, #86e6fe 100%);
        }

        &.p2 .el-progress-bar__inner {
          background: linear-gradient(270deg, #0096ff 0%, #12c700 100%);
        }

        &.p3 .el-progress-bar__inner {
          background: linear-gradient(270deg, #86e6fe 0%, #4500ff 100%);
        }

        &.p4 .el-progress-bar__inner {
          background: linear-gradient(270deg, #86e6fe 0%, #00ffa7 100%);
        }

        .el-progress__text {
          font-size: 12px;
          font-weight: 400;
          color: #85e4fd;
          line-height: 12px;
        }
      }
    }
  }
}
</style>