<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:14:05
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonTwoData: [],
      cottonTwoDataA: [],
      cottonTwoDataB: [],
      cottonTwoDataC: [],
      cottonTwoDataD: [],
      cottonTwoDataE: [],
      cottonData: [],
      cottonDataA: []

    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonTwoData()//棉蛋mark


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getCottonTwoData()//mark
      }

      if (val === '2') {
        this.getCottonTwoDataA()//mark
      }

      if (val === '3') {
        this.getCottonTwoDataB()//mark
      }

      if (val === '4') {
        this.getCottonTwoDataC()//mark
      }
      if (val === '5') {
        this.getCottonTwoDataD()//mark
      }

      if (val === '6') {
        this.getCottonTwoDataE()//mark
      }

      if (val === '7') {
        this.getCottonData()//mark
      }

      if (val === '8') {
        this.getCottonDataA()//mark
      }
    }
  },

  methods: {
    getCottonTwoData () {
      _GetAspScreenComponentData('NEWKEL-DT-FHGH').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })

          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonTwoData = arr4
          this.onCottonTwo()

        }
      })
    },
    onCottonTwo () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoData.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataA () {
      _GetAspScreenComponentData('NEWKLE-DT-CXYTNCYSGH').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })

          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title
            item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataA = arr4
          this.onCottonTwoA()

        }
      })
    },
    onCottonTwoA () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataA.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataB () {
      _GetAspScreenComponentData('NEWKEL-DT-STBHGH').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataB = arr4
          this.onCottonTwoB()

        }
      })
    },
    onCottonTwoB () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataC () {
      _GetAspScreenComponentData('NEWKEL-DT-GHYLA').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title
            item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataC = arr4
          this.onCottonTwoC()

        }
      })
    },
    onCottonTwoC () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataD () {
      _GetAspScreenComponentData('NEWKEL-DT-GHYLB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonTwoDataD = arr4
          this.onCottonTwoD()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoD () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataD.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },



    getCottonTwoDataE () {
      _GetAspScreenComponentData('NEWKEL-DT-GHYLC').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonTwoDataE = arr4
          this.onCottonTwoE()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoE () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/fzgh.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataE.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonData () {
      _GetAspScreenComponentData('NEWKEL-GHYLD').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: [
                { lng: Number(item.data_lng), lat: Number(item.data_lat) },
                { lng: Number(item.data_lnga), lat: Number(item.data_lata) },
                { lng: Number(item.data_lngb), lat: Number(item.data_latb) },
                { lng: Number(item.data_lngc), lat: Number(item.data_latc) }
              ]
            })
          })
          this.cottonData = arr1
          this.onCotton()
        }
      })
    },
    /*第一个 */
    onCotton () {
      var points = [];
      // points.push(new this.T.LngLat(85.97104374450684,41.74692532949951));
      // points.push(new this.T.LngLat(85.89413944763184,41.71412966297178));
      // points.push(new this.T.LngLat(85.95456425231934,41.66541772587578));
      // points.push(new this.T.LngLat(86.02460209411622,41.696700155343464));
      const pointsData = []
      this.cottonData.forEach(item => {
        pointsData.push(
          item.geoInfo
        )
        var sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        points = pointsData


        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13C081FF", weight: 2, opacity: 0.9, fillColor: "#416F4FFF", fillOpacity: 0.4
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          // _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          console.log(point);
        });
      });
    },


    getCottonDataA () {
      _GetAspScreenComponentData('NEWKEL-GHYLD').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: [
                { lng: Number(item.data_lng), lat: Number(item.data_lat) },
                { lng: Number(item.data_lnga), lat: Number(item.data_lata) },
                { lng: Number(item.data_lngb), lat: Number(item.data_latb) },
                { lng: Number(item.data_lngc), lat: Number(item.data_latc) }
              ]
            })
          })
          this.cottonDataA = arr1
          this.onCottonA()
        }
      })
    },
    /*第一个 */
    onCottonA () {
      var points = [];
      // points.push(new this.T.LngLat(85.97104374450684,41.74692532949951));
      // points.push(new this.T.LngLat(85.89413944763184,41.71412966297178));
      // points.push(new this.T.LngLat(85.95456425231934,41.66541772587578));
      // points.push(new this.T.LngLat(86.02460209411622,41.696700155343464));
      const pointsData = []
      this.cottonDataA.forEach(item => {
        pointsData.push(
          item.geoInfo
        )
        var sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        points = pointsData


        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13C081FF", weight: 2, opacity: 0.9, fillColor: "#416F4FFF", fillOpacity: 0.4
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          // _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          console.log(point);
        });
      });
    },
    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 100%;
  height: 110%;
}
</style>
