<!--
 * @Description: 首页-主体内容区域
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-02 12:32:08
-->
<template>
  <div class="bottomcontainer"
       flex="dir:top main:center">
    <div style="margin:0 auto"
         flex="main:center">
      <asideLeft />
      <asideCenter />
    </div>
  </div>
</template>

<script>

export default {
  name: 'MainBottom',
  components: {
    asideLeft: () => import('./asideLeft'),// 左侧内容区域
    asideCenter: () => import('./asideCenter') // 右侧内容区域
  },
}
</script>
<style scoped lang="scss">
.bottomcontainer {
  width: 100%;
  height: 100%;
}
</style>
