<template>
  <div class="left-top-container6"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
      <div class="unit">
        单位:万亩
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './option'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import chartResize from '@/mixins/chartResize'
export default {
  name: 'LeftTopItem',
  mixins: [chartResize],
  data () {
    return {
      option,
      title: '',
      myChart: null,
    }
  },

  mounted () {
    this.initChart()
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZWJC-LSZZQSBH').then(res => {
        if (res) {
          const neme_data = []
          const years = []
          const arr = []
          console.log(res)
          res.data.data.forEach(item => {
            years.push(item.years)
            neme_data.push(item.name_one)
            arr.push([item.years, item.num_data, item.name_one])
          });
          this.unit = res.data.data[0].unti
          this.title = res.data.form.name
          this.option.legend.data = [...new Set(neme_data)] //名称
          this.option.xAxis.data = [...new Set(years)] //年份


          const data1 = []
          let data2 = []
          this.option.legend.data.forEach(title => {
            arr.forEach(item => {
              if (title === item[2]) {
                if (data2.indexOf(title) === -1) {
                  data2.push(item[2], item[1])
                } else {
                  data2.push(item[1])
                }
              }
            })
            data1.push(data2)
            data2 = []
          })
          data1.forEach((item, index) => {
            this.option.series[index].name = item[0]
            this.option.series[index].data = item.slice(1)
          })
          this.myChart.setOption(this.option)
          let sizeFun = function () {
            this.myChart.resize()
          }
          window.addEventListener('resize', sizeFun)
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
      let sizeFun = function () {
        this.myChart.resize()
      }
      window.addEventListener('resize', sizeFun)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>