<!--
 * @Description: 登陆页面
 * @Author: Pancras
 * @Date: 2019-10-16 11:12:57
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-18 16:00:04
 -->
<template>
  <div class="login">
    <video muted
           autoplay="autoplay"
           loop="loop"
           width="100%"
           height="100%">
      <source src="https://lztl-assp.oss-cn-beijing.aliyuncs.com/lztl-assp/video/login-video.mp4"
              type="video/mp4">
      您的浏览器不支持 video 标签。
    </video>
    <div class="title">
      库尔勒市智慧农业服务平台
    </div>
    <div class="login-panel">
      <el-form ref="loginForm"
               :model="loginForm"
               :rules="rules"
               :inline="true"
               size="medium">
        <el-form-item prop="username">
          <span slot="label">用户名</span>
          <el-input v-model="loginForm.username"
                    placeholder="请输入用户名"
                    @keyup.enter.native="handleLogin"
                    clearable />
        </el-form-item>
        <el-form-item prop="password">
          <span slot="label">密码</span>
          <el-input v-model="loginForm.password"
                    placeholder="请输入密码"
                    @keyup.enter.native="handleLogin"
                    clearable
                    show-password />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="loading"
                     :disabled="loading"
                     @click="handleLogin"
                     @keyup.enter.native="handleLogin">
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'LoginItem',
  data () {
    return {
      loading: false,
      isLogin: false,
      windowWidth: document.documentElement.clientWidth, //获取屏幕宽度
      windowHeight: document.documentElement.clientHeight, //获取屏幕高度
      // 登录表单
      loginForm: {
        username: '',
        password: '',
        randomStr: 'blockPuzzle'
      },
      // 表单校验规则
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    /**
 * @description 获取 vuex 中方法
 */
    ...mapActions('account', [
      'loginByAccount'
    ]),
    /**
     * handleLogin
     * @description 用户登录页面
     */
    handleLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 对 loginForm 对象指定字段加密
          // let form = util.objectEncryption({
          //   data: this.loginForm,
          //   param: ['password']// 需要加密的字段
          // })
          // this.loading = true
          // _LoginByAccount(form).then(res => {
          //   setToken.setToken(res.data.access_token)
          //   setToken.setRegion('652928')
          //   this.$store.dispatch('loginState/inLogin')
          //   this.$emit('login')
          //   this.loading = false
          // }).catch(err => {
          //   this.loading = false
          //   this.$message.error(err);
          // })
          this.loading = true
          this.loginByAccount(
            this.loginForm // 执行登录
          ).then(() => {
            this.$emit('login')
            this.loading = false
          }).catch((err) => {
            // 登录表单校验失败
            this.$message.error(err.message)
            this.loading = false // 加载中
          }).finally(() => {
            this.loading = false // 加载中
          })
        }
      });

    },
  }
}
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  z-index: 997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  video {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 800px;
    margin-left: -400px;
    font-size: 58px;
    font-weight: bolder;
    color: #fff;
    text-shadow: 0px 1px 0px #c0c0c0, 0px 1px 0px #b0b0b0, 0px 1px 0px #a0a0a0,
      0px 1px 0px #909090, 0px 5px 10px rgba(0, 0, 0, 0.6);
  }
  .login-panel {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 800px;
    margin-left: -416px;
    padding: 16px;
    border-radius: 20px;
    backdrop-filter: blur(7px);
    color: #fff;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
    .el-form-item {
      margin-top: 22px; // Fix Element Bug
      span {
        color: #fff !important;
      }
      input {
        width: 500px !important;
      }
    }
  }
}
</style>
