/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: hlc 1664800317@qq.com
 * @LastEditTime: 2022-09-15 01:50:25
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  rowNum: 6,
  columnWidth:  [110, 70, 85, 85, 85, 70],
  header:['养殖场', '存栏量', '能繁母猪', '待遇肥猪', '仔猪中猪', '出栏量'],// ['总用量', '瞬时雨量', '日雨量', '当前雨量', '风速', '风向'],
  headerBGC: 'transparent',
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  textStyle: {
    fontSize: 16
  },
  data: [
    ['库尔勒骄龙', '5550头', '450头', '2000头', '3100头', '2073头'],
    ['新疆西部春天农业', '7845头', '1121头', '3983头', '2714头', '9472头'],
    ['库尔勒何川', '3442头', '300头', '500头', '2642头', '4053头'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
  ]
  }