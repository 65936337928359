<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-08 15:32:04
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-08 15:58:45
-->
<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="option"
                       style="width:100%; height: 100%;" />
    </div>
  </div>
</template>

<script>
import option from './option'

export default {
  name: 'LeftTopItem',

  data () {
    return {
      option,
      title: '农产品销售TOP3',
    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.left-top-container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background: rgba(0, 38, 25, 0.66);
  border: 1px solid #008166;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5),
    inset 0 4px 10px 0 rgba(163, 255, 227, 0.4);
  border-right: 0;

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;
    background-position: 0 -2px;

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }

  .card-body {
    width: 100%;
    height: 100%;

    .dv-scroll-board::v-deep {
// .dv-scroll-board .header .header-item

      .header {
        .header-item {
          border: 0.5px solid rgba(232, 232, 232, 0.1);
          // overflow: auto !important;
          text-overflow:clip;
          word-wrap:break-word;

        }
      }
      .rows {
        .row-item {
          font-size: 12px;
          font-weight: 400;
          color: #f2f2f2;
          line-height: 12px;


          .ceil {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
      }
    }
  }
}
</style>