<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:19:07
-->
<template>
  <div style="height:100%">
    <div class="container"
         flex="dir:top main:center"
         style="width: 100%;background: #000;">
      <div style="height:100%;"
           flex="dir:top">
        <div class="main-top"
             flex="dir:left box:mean">
          <MainTopLeft class="main-top-left" />
          <MainTopMain class="main-top-main" />
          <MainTopRight class="main-top-right" />
        </div>
        <div style="height:85%;"
             flex="main:justify">
          <div class="left">
            <aside-left />
          </div>
          <div class="centerWrapper">
            <centerCenter />
          </div>
          <div class="right">
            <aside-right />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainTopLeft from './main-top-left'
import MainTopMain from './main-top-main'
import MainTopRight from './main-top-right'
import asideLeft from './asideLeft'
import centerCenter from './centerCenter'
import asideRight from './asideRight'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    MainTopLeft,
    MainTopMain,
    MainTopRight,
    asideLeft,
    centerCenter,
    asideRight,
  },
  data () {
    return {
      openTxt: '收起',
      openRight: 23,
      bubbleRight: 20,
      openState: true,
      btnState: 0,//当前按钮
      markState: '1',//当前地图点位
      screenHeight: document.body.clientHeight, // 屏幕高度
      title1: '',
      nums1: '',
      title2: '',
      nums2: '',
      title3: '',
      nums3: ''
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SY-DBSJ').then(res => {
        if (res) {
          const arr = []
          res.data.data.forEach(item => {
            arr.push(
              item.name_data,
              item.vaule_data
            )
          });
          this.title1 = arr[0]
          this.nums1 = arr[1]
          this.title2 = arr[2]
          this.nums2 = arr[3]
          this.title3 = arr[4]
          this.nums3 = arr[5]
        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    },

    /*获取当前radio */
    getRadio (val) {
      this.markState = val
    },

    onOpen () {
      this.openState = !this.openState
      if (this.openState) {
        this.openTxt = '收起'
        this.openRight = 23
        this.bubbleRight = 20
      }

      if (!this.openState) {
        this.openTxt = '展开'
        this.openRight = 5
        this.bubbleRight = 5
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>

<style scoped lang="scss">
.centerWrapper {
  flex: 1;
  height: 100%;
}

.main-top {
  box-sizing: border-box;
  background: rgba(0, 38, 25, 0.66);
  border: 1px solid #008166;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5),
    inset 0 4px 10px 0 rgba(163, 255, 227, 0.4);
  padding: 10px 45px;
  width: 99%;
  margin: 0 auto 10px;
}
</style>
