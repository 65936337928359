const option = {
  title: {
    text: '单位: 亩',
    left: 'center',
    textStyle: {
      color: 'white'
    }
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: 'white',
    },
    splitLine: {
      lineStyle: {
        color: '#E8E8E8',
        type: 'dashed'
      }
    }
  },
  yAxis: {
    type: 'category',
    data: ['铁克其乡', '英下乡', '恰尔巴格乡', '上户镇', '和什力克乡', '托布力其乡', '兰干乡', '阿瓦提乡',
          '哈拉玉宫乡', '普惠乡'],
    axisLabel: {
      color: 'white',
    },
  },
  grid: {
    left: '1%',
    right: '13%',
    bottom: '3%',
    containLabel: true
  },
  series: [
    {
      itemStyle: {
        color: '#65B581'
      },
      data: [6305, 17811, 7270.82, 52481.76, 28967, 45556.37, 18392.10, 79699.63, 32523.49, 294.50],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      },
      label: {
        show: true,
        position: 'right',
        textStyle: {
          color: 'white'
        }
      }
    }
  ]
};

export default option