<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-10 12:12:50
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'

export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonData: [],
      cottonDataA: [],
      // 供水工程水源情况
      waterData: [
        {
          lng: 86.07455,
          lat: 41.77583,
          type: '乡镇场水厂',
          name: '兰干水厂',
          location: '兰干乡新村2组',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 86.03358,
          lat: 41.81757,
          type: '乡镇场水厂',
          name: '上户水厂',
          location: '上户镇西站社区安定路',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 85.90163,
          lat: 41.74329,
          type: '乡镇场水厂',
          name: '托和水厂',
          location: '和什力克乡和托布力其乡交界',
          waterType: '地下水',
          isProtect: '是'
        },
        {
          lng: 86.09069,
          lat: 41.75217,
          type: '乡镇场水厂',
          name: '英下水厂',
          location: '英下乡哈尔巴格村 1 组',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 86.10784,
          lat: 41.69189,
          type: '乡镇场水厂',
          name: '阿瓦提水厂',
          location: '阿瓦提乡阿克日克村 2 组',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 86.06624,
          lat: 41.60910,
          type: '乡镇场水厂',
          name: '哈拉玉宫水厂',
          location: '哈拉玉宫乡中道杆村 3 组',
          waterType: '地下水',
          isProtect: '是'
        },
        {
          lng: 85.90750,
          lat: 41.44280,
          type: '乡镇场水厂',
          name: '普惠水厂',
          location: '库普公路 34 公里处',
          waterType: '地下水',
          isProtect: '是'
        },
        {
          lng: 85.46094,
          lat: 41.92847,
          type: '乡镇场水厂',
          name: '库尔楚水厂',
          location: '库尔楚园艺场',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 86.11475,
          lat: 41.77526,
          type: '村级水源井',
          name: '恰尔巴格乡喀拉墩村农村饮水安全工程水源井',
          location: '库尔勒市恰尔巴格乡喀拉墩村',
          waterType: '地下水',
          isProtect: '否'
        },
        {
          lng: 86.44561,
          lat: 42.09963,
          type: '城市管网延伸工程',
          name: '库尔勒市城市供水一期工程',
          location: '焉者县查汗采开乡和农二师二十一团',
          waterType: '地下水',
          isProtect: '是'
        },
        {
          lng: 86.34535,
          lat: 42.20673,
          type: '城市管网延伸工程',
          name: '库尔勒市城市供水二期工程',
          location: '和静县乌拉斯台农场',
          waterType: '地下水',
          isProtect: '是'
        },
      ]
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    // this.getCottonData()//棉蛋mark
    this.getwaterData()


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        // this.getCottonData()//mark
        this.getwaterData()
      }

      // if (val === '2') {
      //   this.getCottonDataA()//mark
      // }
    }
  },

  methods: {

    // 农村供水数据
    getwaterData() {
      // 点位集合获取所有点位中间位置
      const latlng = this.waterData.length > 1 ? util.getPolygonCenter(this.waterData) : this.waterData[0]
      this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/rainfallStation.png',
        id: 'test',
        iconSize: new this.T.Point(40, 49),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      this.waterData.forEach(item => {

        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.name + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +


          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>类别</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.type + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 0%;'></div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>水源类型</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.waterType + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 0%;'></div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>是否划定保护范围</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.isProtect + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 0%;'></div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>所在地点</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.location + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 0%;'></div>" +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })

        this.map.addOverLay(marker)
      })
    },

    getCottonData () {
      _GetAspScreenComponentData('KELNEW-SL-DBSDW').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonData = arr1
          this.onCotton()
        }
      })
    },
    /*第一个 */
    onCotton () {
      var points = [];

      this.cottonData.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#13c081", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },


    getCottonDataA () {
      _GetAspScreenComponentData('KELNEW-SL-DXSJTC').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataA = arr1
          this.onCottonB()
        }
      })
    },
    /*第一个 */
    onCottonB () {
      var points = [];

      this.cottonDataA.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#f52cff", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },

    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 100%;
  height: 110%;
}
</style>
