/*
 * @Description: 中上 畜牧场等点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-04-26 20:59:09
 */
export default {
  animalList: [
    // 新增真实数据
    // 鸡
    {
      lng: '',
      lat: '',
      series: '鸡',
      holder: '李永显',
      phoneNum: '138****5406',
      name: '和什力克乡畜牧场4',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '3200+',
      outNum: '-',
      coordinator: '阿布来提•艾拜'
    },
    {
      lng: 86.497955,
      lat: 41.752380,
      series: '鸡',
      holder: '胡建平',
      phoneNum: '138****7717',
      name: '莲花社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '15000+',
      outNum: '-',
      coordinator: '阿米兰•那曼'
    },
    {
      lng: 86.497955,
      lat: 41.752380,
      series: '鸡',
      holder: '谭钧',
      phoneNum: '133****5072',
      name: '莲花社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '20000+',
      outNum: '-',
      coordinator: '阿米兰•那曼'
    },
    {
      lng: 86.332794,
      lat: 41.824115,
      series: '鸡',
      holder: '董德印',
      phoneNum: '137****0097',
      name: '文化社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '61000+',
      outNum: '-',
      coordinator: '阿米兰•那曼'
    },
    {
      lng: 86.113748,
      lat: 41.745761,
      series: '鸡',
      holder: '刘旭',
      phoneNum: '158****9118',
      name: '其兰巴克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '10000+',
      outNum: '-',
      coordinator: '坎买尔江•库尔班'
    },
    {
      lng: 86.113717,
      lat: 41.745578,
      series: '鸡',
      holder: '姚廷军',
      phoneNum: '158****6382',
      name: '其兰巴克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '18000+',
      outNum: '-',
      coordinator: '坎买尔江•库尔班'
    },
    {
      lng: 86.077099,
      lat: 41.723453,
      series: '鸡',
      holder: '王东明',
      phoneNum: '138****3562',
      name: '其兰巴克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '20000+',
      outNum: '-',
      coordinator: '坎买尔江•库尔班'
    },
    {
      lng: 86.094375,
      lat: 41.813298,
      series: '鸡',
      holder: '李晓龙',
      phoneNum: '136****8015',
      name: '下阔什巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '12000+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 86.13038,
      lat: 41.782461,
      series: '鸡',
      holder: '图拉古丽•克热木',
      phoneNum: '136****8015',
      name: '下阔什巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '11900+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 86.105757,
      lat: 41.81395,
      series: '鸡',
      holder: '郭锦霞',
      phoneNum: '181****3330',
      name: '下阔什巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '70000+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 86.150132,
      lat: 41.741309,
      series: '鸡',
      holder: '东德印1',
      phoneNum: '137****0097',
      name: '团结村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '200000+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    // 鸽
    {
      lng: 86.155244,
      lat: 41.871127,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    // 鸽
    {
      lng: 86.03366,
      lat: 41.76045,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 85.91281,
      lat: 41.7416,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 85.91995,
      lat: 41.67291,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.00455,
      lat: 41.66388,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.01224,
      lat: 41.71393,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.14874,
      lat: 41.59326,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.15204,
      lat: 41.63762,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.15094,
      lat: 41.67209,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    {
      lng: 86.14655,
      lat: 41.71393,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    // 牛
    {
      lng: 85.96825,
      lat: 41.672921,
      series: '牛',
      holder: '阿布都拉·木沙-库尔勒碧禾澜牛羊养殖专业合作社',
      phoneNum: '199****8599',
      coordinator: '阿巴斯•司马义',
      name: '艾兰巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '105+',
      outNum: '-'
    },
    {
      lng: '',
      lat: '',
      series: '牛',
      holder: '奥斯曼•胡达巴尔迪',
      phoneNum: '147****4333',
      coordinator: '',
      name: '上和什力克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '65+',
      outNum: '-'
    },
    {
      lng: '85.989272',
      lat: '41.703047',
      series: '牛',
      holder: '努尔艾合买提·艾白布拉-新疆努祖尔养殖专业合作社',
      phoneNum: '150****2999',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '833+',
      outNum: '-'
    },
    {
      lng: '85.991916',
      lat: '41.722102',
      series: '牛',
      holder: '艾则孜·阿布都热音-新疆艾孜孜养殖专业合作社',
      phoneNum: '180****8088',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '85+',
      outNum: '-'
    },
    {
      lng: '86.001461',
      lat: '41.728948',
      series: '牛',
      holder: '木沙•艾山',
      phoneNum: '191****8884',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '68+',
      outNum: '-'
    },
    {
      lng: '86.145531',
      lat: '41.740435',
      series: '牛',
      holder: '依达也提•阿布力米提',
      phoneNum: '176****7676',
      coordinator: '阿曼古丽•哈斯木',
      name: '托布力其村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '72+',
      outNum: '-'
    },
    {
      lng: '86.725861',
      lat: '41.158396',
      series: '牛',
      holder: '依达也提•阿布力米提',
      phoneNum: '180****6499',
      coordinator: '阿娜尔古丽•开日木',
      name: '第一村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '73+',
      outNum: '-'
    },
    {
      lng: '86.112471',
      lat: '41.814897',
      series: '牛',
      holder: '马强',
      phoneNum: '133****7998',
      coordinator: '阿娜古丽·亚克甫',
      name: '上阔什巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '77+',
      outNum: '-'
    },
    {
      lng: '86.004224',
      lat: '41.668071',
      series: '牛',
      holder: '阿布力米提•麦买提',
      phoneNum: '133****5552',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '铁克其村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '52+',
      outNum: '-'
    },
    {
      lng: '86.004224',
      lat: '41.668071',
      series: '牛',
      holder: '吾斯曼•艾尼瓦尔',
      phoneNum: '130****3302',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '146+',
      outNum: '-'
    },
    {
      lng: '85.97381',
      lat: '41.644449',
      series: '牛',
      holder: '阿不力孜•赛买提',
      phoneNum: '130****5596',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '上恰其村',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '80+',
      outNum: '-'
    },
    {
      lng: '85.965228',
      lat: '41.702396',
      series: '牛',
      holder: '吐尔地•依明',
      phoneNum: '189****0987',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '中恰其社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '57+',
      outNum: '-'
    },
    {
      lng: '86.004224',
      lat: '41.668072',
      series: '牛',
      holder: '艾合买提•巴拉提',
      phoneNum: '181****5559',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '下恰其社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '78+',
      outNum: '-'
    },
    {
      lng: '86.004224',
      lat: '41.668072',
      series: '牛',
      holder: '拜克力阿洪•麦麦提',
      phoneNum: '191****5971',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '阿克塔什社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '53+',
      outNum: '-'
    },
    {
      lng: '85.970416',
      lat: '41.644965',
      series: '牛',
      holder: '买买提•依米提',
      phoneNum: '176****1477',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '154+',
      outNum: '-'
    },
    {
      lng: '86.004224',
      lat: '41.668071',
      series: '牛',
      holder: '木沙•亚生',
      phoneNum: '130****0831',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格社区',
      address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '71+',
      outNum: '-'
    },
    {
      lng: '85.965548',
      lat: '41.644965',
      series: '牛',
      holder: '艾山•麦木提明',
      phoneNum: '151****9335',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '海勒派艾日克社区',
      // address: '新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市Y048',
      nums: '70+',
      outNum: '-'
    },
    {
      name: '小兰干村',
      holder: '艾尼玩•托乎提',
      phoneNum: '187****8333',
      series: '牛',
      nums: '94',
      lng: '',
      lat: '',
      coordinator: '艾买尔•吉乃都拉'
    },
    {
      name: '下和什力克村',
      holder: '塔力甫•莫依东',
      phoneNum: '157****6664',
      series: '牛',
      nums: '65',
      lng: '85.873034',
      lat: '41.737523',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '买买提•热合木托拉',
      phoneNum: '152****2575',
      series: '牛',
      nums: '75',
      lng: '85.893034',
      lat: '41.737624',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '喀伍力•吾普尔',
      phoneNum: '135****8208',
      series: '牛',
      nums: '123',
      lng: '85.860755',
      lat: '41.716557',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下牙克托格拉克村',
      holder: '克热木库尔班',
      phoneNum: '187****2202',
      series: '牛',
      nums: '191',
      lng: '85.942227',
      lat: '41.705862',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '其兰巴克村',
      holder: '开买尔•克热木',
      phoneNum: '135****8083',
      series: '牛',
      nums: '87',
      lng: '86.087132',
      lat: '41.723981',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '阿不拉江•牙生',
      phoneNum: '173****8883',
      series: '牛',
      nums: '67',
      lng: '86.120390 ',
      lat: '41.751336',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '买买提艾尼色',
      phoneNum: '180****9993',
      series: '牛',
      nums: '50',
      lng: '86.095792',
      lat: '41.725977',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '阿布力肯•吐依洪',
      phoneNum: '181****2525',
      series: '牛',
      nums: '109',
      lng: '86.068225',
      lat: '41.720518',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '艾海提•买买提',
      phoneNum: '191****3212',
      series: '牛',
      nums: '136',
      lng: '86.094054',
      lat: '41.744702',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '买买提江•木沙',
      phoneNum: '159****3367',
      series: '牛',
      nums: '100',
      lng: '86.09476',
      lat: '41.723273',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '牙生•衣马木',
      phoneNum: '131****8871',
      series: '牛',
      nums: '112',
      lng: '86.11374',
      lat: '41.745585',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '巴州奶牛场',
      phoneNum: '153****8308',
      series: '牛',
      nums: '285',
      lng: '86.119358',
      lat: '41.730779',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '买买提•阿卜都',
      phoneNum: '157****2757',
      series: '牛',
      nums: '79',
      lng: '86.074897',
      lat: '41.743041',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '其兰巴克村',
      holder: '亚合甫•阿不来提',
      phoneNum: '155****9338',
      series: '牛',
      nums: '55',
      lng: '86.157735',
      lat: '41.753701',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '喀拉巴克村',
      holder: '木沙•热依木',
      phoneNum: '135****8701',
      series: '牛',
      nums: '50',
      lng: '86.099905',
      lat: '41.751998',
      coordinator: '克然木•克维尔'
    },
    {
      name: '开发区村',
      holder: '艾合买提•斯马义',
      phoneNum: '135****7878',
      series: '牛',
      nums: '220',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '下多尕村',
      holder: '库尔勒市英吉沙玉宫农场',
      phoneNum: '138****4899',
      series: '牛',
      nums: '499',
      lng: '86.082562',
      lat: '41.498797',
      coordinator: '穆合塔尔•列提普'
    },
    {
      name: '艾力克坎土曼村',
      holder: '艾山•扎伊尔',
      phoneNum: '130****0688',
      series: '牛',
      nums: '170',
      lng: '85.873589',
      lat: '41.683159',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '库尔班•阿木提',
      phoneNum: '133****2888',
      series: '牛',
      nums: '68',
      lng: '',
      lat: '',
      coordinator: '努尔买买提'
    },
    {
      name: '下阔什巴格村',
      holder: '牙克甫·买买提-新疆爱得尔养殖农民专业合作社',
      phoneNum: '151****3535',
      series: '牛',
      nums: '198',
      lng: '86.121413',
      lat: '41.806058',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      name: '下阔什巴格村',
      holder: '阿布地艾尼·吾普尔',
      phoneNum: '138****9399',
      series: '牛',
      nums: '70',
      lng: '86.135995',
      lat: '41.780936',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      name: '下阔什巴格村',
      holder: '麦麦提敏•塔刀晋-库尔勒市塔力普养殖专业合作社',
      phoneNum: '138****5837',
      series: '牛',
      nums: '55',
      lng: '86.065783',
      lat: '41.817078',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      name: '哈拉玉宫村',
      holder: '阿布地热依木•库尔班',
      phoneNum: '135****5481',
      series: '牛',
      nums: '96',
      lng: '86.068451',
      lat: '41.618018',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '哈拉玉宫村',
      holder: '罗伟',
      phoneNum: '133****0606',
      series: '牛',
      nums: '67',
      lng: '86.079171',
      lat: '41.608685',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '团结村',
      holder: '热衣木•吾守尔',
      phoneNum: '131****7239',
      series: '牛',
      nums: '60',
      lng: '86.174983',
      lat: '41.760193',
      coordinator: '热依木•艾买日'
    },
    {
      name: '喀拉巴克村',
      holder: '阿力木•克衣木',
      phoneNum: '199****4500',
      series: '牛',
      nums: '70',
      lng: '86.113762',
      lat: '41.745476',
      coordinator: '赛买提·阿布拉'
    },
    {
      name: '喀拉巴克村',
      holder: '热合曼•肉孜',
      phoneNum: '133****5497',
      series: '牛',
      nums: '138',
      lng: '86.060087',
      lat: '41.743357',
      coordinator: '赛买提·阿布拉'
    },
    {
      name: '铁斯坎村',
      holder: '康文臣',
      phoneNum: '176****6333',
      series: '牛',
      nums: '52',
      lng: '85.96493',
      lat: '41.494180 ',
      coordinator: '赛买提•艾木都力'
    },
    {
      name: '萨依勒克村',
      holder: '帕日哈提•艾木尔',
      phoneNum: '139****4919',
      series: '牛',
      nums: '67',
      lng: '85.891806',
      lat: '41.745013',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '牙合甫托乎提',
      phoneNum: '133****1114',
      series: '牛',
      nums: '85',
      lng: '85.902968',
      lat: '41.751337',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '艾则孜•艾德力斯',
      phoneNum: '133****2737',
      series: '牛',
      nums: '56',
      lng: '85.900353',
      lat: '41.749963',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '哈拉玉宫村',
      holder: '亚森•克热木',
      phoneNum: '135****9111',
      series: '牛',
      nums: '89',
      lng: '86.09686',
      lat: '41.632686',
      coordinator: '吐尔洪•吐尔逊'
    },
    {
      name: '恰尔巴格村',
      holder: '热依木•托乎提',
      phoneNum: '136****9072',
      series: '牛',
      nums: '108',
      lng: '86.138895',
      lat: '41.789611',
      coordinator: '托合提古丽•阿布拉'
    },
    {
      name: '团结村',
      holder: '朱永辉',
      phoneNum: '181****2358',
      series: '牛',
      nums: '68',
      lng: '86.311061',
      lat: '41.644060 ',
      coordinator: '吾买尔•艾沙'
    },
    {
      name: '贡拉提村',
      holder: '喀德尔•沙吾尔',
      phoneNum: '180****8883',
      series: '牛',
      nums: '50',
      lng: '86.023607',
      lat: '41.766520 ',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '夏库尔村',
      holder: '艾尔肯•吐尔地',
      phoneNum: '139****2555',
      series: '牛',
      nums: '146',
      lng: '85.980081',
      lat: '41.836697',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '卡吾力克热木',
      phoneNum: '137****0025',
      series: '牛',
      nums: '50',
      lng: '86.038427',
      lat: '41.658639',
      coordinator: '玉苏普•艾力'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '艾合买提•尤努斯',
      phoneNum: '138****6087',
      series: '牛',
      nums: '50',
      lng: '86.086445',
      lat: '41.692061',
      coordinator: '玉苏普•艾力'
    },
    {
      name: '经济技术开发区',
      holder: '乃比江•吾买江',
      phoneNum: '181****0480',
      series: '牛',
      nums: '110',
      lng: '86.130400 ',
      lat: '41.783243',
      coordinator: '约麦尔•阿不地克兰木'
    },
    {
      name: '上和什力克村',
      holder: '衣明江•艾肯木',
      phoneNum: '185****5759',
      series: '牛',
      nums: '139',
      lng: '85.982970 ',
      lat: '41.730344',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '上和什力克村',
      holder: '吐尔逊江•吾买尔阿洪',
      phoneNum: '138****0990',
      series: '牛',
      nums: '206',
      lng: '85.944616',
      lat: '41.727811',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '阿克艾日克村',
      holder: '莫明•木沙',
      phoneNum: '176****1010',
      series: '牛',
      nums: '92',
      lng: '86.094590 ',
      lat: '41.692567',
      coordinator: '佐热古丽•萨拉木'
    },
    {
      name: '阿克艾日克村',
      holder: '艾孜孜喀斯木',
      phoneNum: '138****9400',
      series: '牛',
      nums: '103',
      lng: '86.106202',
      lat: '41.695886',
      coordinator: '佐热古丽•萨拉木'
    },
    {
      name: '阿克艾日克村',
      holder: '马海军',
      phoneNum: '177****5915',
      series: '牛',
      nums: '194',
      lng: '86.097289',
      lat: '41.684792',
      coordinator: '佐热古丽•萨拉木'
    },
    // 猪
    {
      name: '下牙克托格拉克村',
      holder: '库尔勒何川养殖场',
      phoneNum: '135****5550',
      series: '猪',
      nums: '1646',
      lng: '85.952139',
      lat: '41.708669',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '艾力克坎土曼村',
      holder: '易述英',
      phoneNum: '186****3976',
      series: '猪',
      nums: '488',
      lng: '85.871482',
      lat: '41.684126',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '下牙克托格拉克村',
      holder: '周饧茧',
      phoneNum: '137****4958',
      series: '猪',
      nums: '615',
      lng: '85.942181',
      lat: '41.705836',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '上牙克托格拉克村',
      holder: '祝健鹏',
      phoneNum: '150****1561',
      series: '猪',
      nums: '974',
      lng: '85.981232',
      lat: '41.719606',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '第三村',
      holder: '涂汉武',
      phoneNum: '180****8495',
      series: '猪',
      nums: '500',
      lng: '85.463402',
      lat: '41.928138',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第三村',
      holder: '汪伟',
      phoneNum: '152****0946',
      series: '猪',
      nums: '600',
      lng: '85.478622',
      lat: '41.865685',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '萨依勒克村',
      holder: '王义江',
      phoneNum: '186****5519',
      series: '猪',
      nums: '10000',
      lng: '85.942490 ',
      lat: '41.791018',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '哈拉苏村',
      holder: '周光荣',
      phoneNum: '181****5182',
      series: '猪',
      nums: '1645',
      lng: '85.850059',
      lat: '41.827555',
      coordinator: '王勇'
    },
    {
      name: '铁斯坎村',
      holder: '蔡四周',
      phoneNum: '152****2909',
      series: '猪',
      nums: '600',
      lng: '85.941669',
      lat: '41.488581',
      coordinator: '姚春生'
    },
    {
      name: '哈拉玉宫乡',
      holder: '谭英祥',
      phoneNum: '135****4264',
      series: '猪',
      nums: '500',
      lng: '85.922841',
      lat: '41.487906',
      coordinator: '姚春生'
    },
    {
      name: '哈拉玉宫乡',
      holder: '许可',
      phoneNum: '139****1182',
      series: '猪',
      nums: '618',
      lng: '86.050758',
      lat: '41.611936',
      coordinator: '姚春生'
    },
    {
      name: '农场第四村',
      holder: '赵洪东',
      phoneNum: '135****7853',
      series: '猪',
      nums: '499',
      lng: '86.205026',
      lat: '41.546242',
      coordinator: '姚春生'
    },
    {
      name: '巴州阿瓦提农场',
      holder: '刘映国',
      phoneNum: '136****2556',
      series: '猪',
      nums: '583',
      lng: '86.205018',
      lat: '41.546034',
      coordinator: '姚春生'
    },
    {
      name: '强布勒村',
      holder: '蔡四周',
      phoneNum: '152****2909',
      series: '猪',
      nums: '1277',
      lng: '86.015251',
      lat: '41.696719',
      coordinator: '姚春生'
    },
    {
      name: '巴州阿瓦提农场',
      holder: '范胜中',
      phoneNum: '189****3333',
      series: '猪',
      nums: '3182',
      lng: '86.128114',
      lat: '41.601094',
      coordinator: '姚春生'
    },
    {
      name: '哈拉玉宫乡',
      holder: '郑春江',
      phoneNum: '182****2769',
      series: '猪',
      nums: '487',
      lng: '85.922122',
      lat: '41.488514',
      coordinator: '姚春生'
    },
    {
      name: '哈拉玉宫乡',
      holder: '李玲',
      phoneNum: '138****0751',
      series: '猪',
      nums: '2092',
      lng: '85.950042',
      lat: '41.497821',
      coordinator: '姚春生'
    },
    //  羊
    {
      name: '托布力其村',
      holder: '阿布都衣艾海提',
      phoneNum: '135****7238',
      series: '羊',
      nums: '459',
      lng: '85.935535',
      lat: '41.69496',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '艾克木祖农',
      phoneNum: '189****4143',
      series: '羊',
      nums: '416',
      lng: '85.914969',
      lat: '41.682783',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '克音木笆吾东',
      phoneNum: '133****0617',
      series: '羊',
      nums: '1075',
      lng: '85.916541',
      lat: '41.679573',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '孜牙吾东挱迪力',
      phoneNum: '135****0213',
      series: '羊',
      nums: '424',
      lng: '85.932603',
      lat: '41.695732',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '吾学尔艾克木',
      phoneNum: '133****3861',
      series: '羊',
      nums: '482',
      lng: '85.932865',
      lat: '41.695634',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '克热木萨迪力',
      phoneNum: '182****2180',
      series: '羊',
      nums: '930',
      lng: '85.932731',
      lat: '41.695483',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '吾甫尔萨迪力',
      phoneNum: '135****1178',
      series: '羊',
      nums: '835',
      lng: '85.932739',
      lat: '41.695871',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '托布力其村',
      holder: '艾尼克热木',
      phoneNum: '138****3321',
      series: '羊',
      nums: '628',
      lng: '85.929137',
      lat: '41.694180 ',
      coordinator: '阿布来提·巴垃提'
    },
    {
      name: '夏库尔村',
      holder: '托乎提•吾休尔',
      phoneNum: '138****7528',
      series: '羊',
      nums: '761',
      lng: '',
      lat: '',
      coordinator: '阿布来提•艾拜'
    },
    {
      name: '上和什力克村',
      holder: '艾德力司•吾守尔',
      phoneNum: '133****4303',
      series: '羊',
      nums: '600',
      lng: '',
      lat: '',
      coordinator: '阿布来提•艾布祖拉'
    },
    {
      name: '上和什力克村',
      holder: '阿尤甫•阿不都热依木',
      phoneNum: '138****3030',
      series: '羊',
      nums: '450',
      lng: '',
      lat: '',
      coordinator: '阿布来提•艾布祖拉'
    },
    {
      name: '上和什力克村',
      holder: '吐尔地•买买提',
      phoneNum: '152****7199',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '阿布来提•艾布祖拉'
    },
    {
      name: '第二村',
      holder: '吴建勇',
      phoneNum: '150****0304',
      series: '羊',
      nums: '512',
      lng: '85.952139',
      lat: '41.667055',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '上牙克托格拉克村',
      holder: '艾则孜·阿布都热音-新疆艾孜孜养殖专业合作社',
      phoneNum: '180****8088',
      series: '羊',
      nums: '450',
      lng: '85.991916',
      lat: '41.722102',
      coordinator: '阿曼古丽•哈斯木'
    },
    {
      name: '巴格吉格代村',
      holder: '尼牙孜•牙合甫',
      phoneNum: '130****3062',
      series: '羊',
      nums: '530',
      lng: '86.182673',
      lat: '41.770564',
      coordinator: '阿米娜•克热木'
    },
    {
      name: '第一村',
      holder: '艾比布拉•热合曼',
      phoneNum: '152****2009',
      series: '羊',
      nums: '440',
      lng: '85.461526',
      lat: '41.940304',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '凯迪日耶•阿不都热合曼',
      phoneNum: '191****2311',
      series: '羊',
      nums: '550',
      lng: '86.158107',
      lat: '41.725998',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '塔依尔•亚森',
      phoneNum: '133****3974',
      series: '羊',
      nums: '1000',
      lng: '86.158396',
      lat: '41.725261',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '阿卜拉•艾买尔',
      phoneNum: '133****5329',
      series: '羊',
      nums: '400',
      lng: '85.516287',
      lat: '41.908951',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '加那比尓•吐地',
      phoneNum: '133****7144',
      series: '羊',
      nums: '405',
      lng: '85.158396',
      lat: '41.725861',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '吐地•吾麦尔',
      phoneNum: '150****3232',
      series: '羊',
      nums: '520',
      lng: '85.158396',
      lat: '41.725861',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '艾则孜•麦麦提',
      phoneNum: '136****9609',
      series: '羊',
      nums: '650',
      lng: '85.910696',
      lat: '41.910696',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '依布拉音•热合曼',
      phoneNum: '133****8520',
      series: '羊',
      nums: '400',
      lng: '86.139173',
      lat: '41.777181',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '先里甫•克热木',
      phoneNum: '158****9545',
      series: '羊',
      nums: '400',
      lng: '85.472401',
      lat: '41.933968',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '买明江•阿不拉',
      phoneNum: '133****4250',
      series: '羊',
      nums: '450',
      lng: '85.455086',
      lat: '41.942344',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '第一村',
      holder: '托呼提•买买提',
      phoneNum: '199****7279',
      series: '羊',
      nums: '725',
      lng: '86.158085',
      lat: '41.726009',
      coordinator: '阿娜尔古丽•开日木'
    },
    {
      name: '海勒派艾日克村',
      holder: '努尔麦麦提•艾艾沙',
      phoneNum: '189****1919',
      series: '羊',
      nums: '600',
      lng: '',
      lat: '',
      coordinator: '阿孜古丽•吉乃都拉'
    },
    {
      name: '铁克其村',
      holder: '吾甫尔•吾麦尔',
      phoneNum: '139****6198',
      series: '羊',
      nums: '480',
      lng: '',
      lat: '',
      coordinator: '阿孜古丽•吉乃都拉'
    },
    {
      name: '大墩子村',
      holder: '达吾提•麦麦提',
      phoneNum: '135****6608',
      series: '羊',
      nums: '550',
      lng: '',
      lat: '',
      coordinator: '艾合麦提•提力瓦尔迪'
    },
    {
      name: '大墩子村',
      holder: '艾海提•卡哈尔',
      phoneNum: '152****4727',
      series: '羊',
      nums: '550',
      lng: '',
      lat: '',
      coordinator: '艾合麦提•提力瓦尔迪'
    },
    {
      name: '上户村',
      holder: '玉帑苏艾力',
      phoneNum: '181****4287',
      series: '羊',
      nums: '500',
      lng: '',
      lat: '',
      coordinator: '艾合麦提江•莫依墩'
    },
    {
      name: '哈拉苏村',
      holder: '严学贵',
      phoneNum: '135****3068',
      series: '羊',
      nums: '650',
      lng: '',
      lat: '',
      coordinator: '艾合麦提江•莫依墩'
    },
    {
      name: '上户村',
      holder: '阿布里米提阿吾提',
      phoneNum: '177****8738',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '艾合麦提江•莫依墩'
    },
    {
      name: '上户村',
      holder: '阿布拉克热木',
      phoneNum: '135****7885',
      series: '羊',
      nums: '780',
      lng: '',
      lat: '',
      coordinator: '艾克然木司马义'
    },
    {
      name: '下和什力克村',
      holder: '吐依翁•努尔丁',
      phoneNum: '135****0735',
      series: '羊',
      nums: '480',
      lng: '',
      lat: '',
      coordinator: '艾力夏提·曼合木提'
    },
    {
      name: '下和什力克村',
      holder: '艾散•阿布拉',
      phoneNum: '133****2477',
      series: '羊',
      nums: '700',
      lng: '',
      lat: '',
      coordinator: '艾力夏提·曼合木提'
    },
    {
      name: '下和什力克村',
      holder: '热合麦提•如则',
      phoneNum: '135****4527',
      series: '羊',
      nums: '1070',
      lng: '',
      lat: '',
      coordinator: '艾力夏提·曼合木提'
    },
    {
      name: '下和什力克村',
      holder: '吾甫尔•木依丁',
      phoneNum: '130****7152',
      series: '羊',
      nums: '650',
      lng: '',
      lat: '',
      coordinator: '艾力夏提·曼合木提'
    },
    {
      name: '下和什力克村',
      holder: '牙生•买买提',
      phoneNum: '189****4693',
      series: '羊',
      nums: '500',
      lng: '',
      lat: '',
      coordinator: '艾力夏提·曼合木提'
    },
    {
      name: '小兰干村',
      holder: '艾合买提•艾买提',
      phoneNum: '130****6841',
      series: '羊',
      nums: '450',
      lng: '',
      lat: '',
      coordinator: '艾买尔•吉乃都拉'
    },
    {
      name: '杜尔比勒村',
      holder: '吾斯曼•哈森',
      phoneNum: '138****0424',
      series: '羊',
      nums: '720',
      lng: '',
      lat: '',
      coordinator: '艾尼•毛依东'
    },
    {
      name: '杜尔比勒村',
      holder: '木萨•克日木',
      phoneNum: '158****7724',
      series: '羊',
      nums: '600',
      lng: '',
      lat: '',
      coordinator: '艾尼•毛依东'
    },
    {
      name: '杜尔比勒村',
      holder: '拍祖拉•玉散',
      phoneNum: '135****3540',
      series: '羊',
      nums: '730',
      lng: '',
      lat: '',
      coordinator: '艾尼•毛依东'
    },
    {
      name: '大墩子村',
      holder: '米吉提•热慢',
      phoneNum: '135****5757',
      series: '羊',
      nums: '496',
      lng: '',
      lat: '',
      coordinator: '艾尼瓦尔•木沙'
    },
    {
      name: '下和什力克村',
      holder: '塔依尔•阿布都喀依尔',
      phoneNum: '173****6361',
      series: '羊',
      nums: '440',
      lng: '85.874336',
      lat: '41.723579',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '塔力甫•莫依东',
      phoneNum: '157****6664',
      series: '羊',
      nums: '1640',
      lng: '85.893034',
      lat: '41.737624',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '吐尔逊•努尔丁',
      phoneNum: '180****9356',
      series: '羊',
      nums: '540',
      lng: '85.733216',
      lat: '41.735261',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '克依木•艾克木',
      phoneNum: '130****9343',
      series: '羊',
      nums: '530',
      lng: '85.762684',
      lat: '41.686166',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '阿布杜•依明',
      phoneNum: '133****1171',
      series: '羊',
      nums: '560',
      lng: '85.876917',
      lat: '41.737733',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '喀伍力•吾普尔',
      phoneNum: '135****8208',
      series: '羊',
      nums: '2620',
      lng: '85.860755',
      lat: '41.716557',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '吾斯曼•艾力',
      phoneNum: '136****0008',
      series: '羊',
      nums: '450',
      lng: '85.89941',
      lat: '41.722641',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '艾散•开然木',
      phoneNum: '183****3388',
      series: '羊',
      nums: '450',
      lng: '85.873928',
      lat: '41.709399',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '强布勒村',
      holder: '艾克木•吐尔迪',
      phoneNum: '133****1240',
      series: '羊',
      nums: '640',
      lng: '',
      lat: '',
      coordinator: '艾孜孜买买提'
    },
    {
      name: '强布勒村',
      holder: '阿不都依木•艾力',
      phoneNum: '180****6421',
      series: '羊',
      nums: '700',
      lng: '',
      lat: '',
      coordinator: '艾孜孜买买提'
    },
    {
      name: '第六村',
      holder: '新疆嘉泰畜牧业有限公司',
      phoneNum: '152****0000',
      series: '羊',
      nums: '800',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第三村',
      holder: '热克甫•阿布都',
      phoneNum: '173****8380',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第一村',
      holder: '冯潮辉',
      phoneNum: '136****0678',
      series: '羊',
      nums: '860',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第三村',
      holder: '海力力•吉力力',
      phoneNum: '176****2697',
      series: '羊',
      nums: '500',
      lng: '85.873313',
      lat: '41.426151',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第一村',
      holder: '阿布来提•热合曼',
      phoneNum: '187****9395',
      series: '羊',
      nums: '550',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第五村',
      holder: '张福军',
      phoneNum: '139****5686',
      series: '羊',
      nums: '630',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第五村',
      holder: '热合曼•吾斯曼',
      phoneNum: '181****5557',
      series: '羊',
      nums: '850',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第五村',
      holder: '艾尼完·阿布力孜',
      phoneNum: '139****9553',
      series: '羊',
      nums: '580',
      lng: '85.873754',
      lat: '41.426533',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第五村',
      holder: '程飞',
      phoneNum: '173****5819',
      series: '羊',
      nums: '600',
      lng: '853851701',
      lat: '41.410739',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第五村',
      holder: '依布拉英•阿布力孜',
      phoneNum: '133****1919',
      series: '羊',
      nums: '420',
      lng: '85.864846',
      lat: '41.425229',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第四村',
      holder: '木沙江·艾则孜',
      phoneNum: '187****4744',
      series: '羊',
      nums: '450',
      lng: '85.873664',
      lat: '41.427497',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第四村',
      holder: '达吾提•吐逊',
      phoneNum: '133****6000',
      series: '羊',
      nums: '1400',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第四村',
      holder: '阿里木江·买买提',
      phoneNum: '133****7046',
      series: '羊',
      nums: '500',
      lng: '',
      lat: '',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '第四村',
      holder: '艾则孜•买买提',
      phoneNum: '136****7771',
      series: '羊',
      nums: '750',
      lng: '85.873664',
      lat: '41.427497',
      coordinator: '拜合迪•吐逊'
    },
    {
      name: '下牙克托格拉克村',
      holder: '买买提开然木',
      phoneNum: '138****7050',
      series: '羊',
      nums: '454',
      lng: '85.94451',
      lat: '41.667623',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '下牙克托格拉克村',
      holder: '努日阿布都拉',
      phoneNum: '180****4447',
      series: '羊',
      nums: '538',
      lng: '85.942097',
      lat: '41.705741',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '下牙克托格拉克村',
      holder: '吐拉甫牧沙',
      phoneNum: '180****4169',
      series: '羊',
      nums: '433',
      lng: '85.938677',
      lat: '41.698055',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '下牙克托格拉克村',
      holder: '买买提卡德尔',
      phoneNum: '191****5759',
      series: '羊',
      nums: '415',
      lng: '85.942453',
      lat: '41.705768',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '下牙克托格拉克村',
      holder: '阿布拉阿布都卡',
      phoneNum: '132****0371',
      series: '羊',
      nums: '510',
      lng: '85.942412',
      lat: '41.705627',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '下牙克托格拉克村',
      holder: '艾白•艾堤',
      phoneNum: '133****6133',
      series: '羊',
      nums: '433',
      lng: '85.942143',
      lat: '41.705818',
      coordinator: '喀依沙尔•艾白'
    },
    {
      name: '第五村',
      holder: '程红义',
      phoneNum: '139****5178',
      series: '羊',
      nums: '496',
      lng: '86.042386',
      lat: '41.740928',
      coordinator: '卡德尔•热合曼'
    },
    {
      name: '巴州沙依东园艺场',
      holder: '木沙•司马义',
      phoneNum: '150****9018',
      series: '羊',
      nums: '550',
      lng: '86.056344',
      lat: '41.729900 ',
      coordinator: '卡德尔•热合曼'
    },
    {
      name: '巴州沙依东园艺场',
      holder: '艾木杜拉•伊卜拉伊木',
      phoneNum: '180****4571',
      series: '羊',
      nums: '1500',
      lng: '86.056269',
      lat: '41.729752',
      coordinator: '卡德尔•热合曼'
    },
    {
      name: '其兰巴克村',
      holder: '阿布都拉•阿布都卡',
      phoneNum: '132****0371',
      series: '羊',
      nums: '550',
      lng: '85.930656',
      lat: '41.62699',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '英下村',
      holder: '卡义尔•肉孜',
      phoneNum: '157****6032',
      series: '羊',
      nums: '420',
      lng: '86.178297',
      lat: '41.738307',
      coordinator: '坎买尔江•库尔班'
    },
    {
      name: '喀拉巴克村',
      holder: '热依木•克衣木',
      phoneNum: '181****7657',
      series: '羊',
      nums: '430',
      lng: '41.751998',
      lat: '86.099905',
      coordinator: '克然木•克维尔'
    },
    {
      name: '喀拉巴克村',
      holder: '阿不拉江•买买提',
      phoneNum: '189****6638',
      series: '羊',
      nums: '411',
      lng: '41.753902',
      lat: '86.11258',
      coordinator: '克然木•克维尔'
    },
    {
      name: '喀拉巴克村',
      holder: '木沙•热依木',
      phoneNum: '135****8701',
      series: '羊',
      nums: '415',
      lng: '41.751998',
      lat: '86.099905',
      coordinator: '克然木•克维尔'
    },
    {
      name: '阿拉多尕村',
      holder: '达吾提马木提',
      phoneNum: '131****1085',
      series: '羊',
      nums: '500',
      lng: '',
      lat: '',
      coordinator: '库尔班•艾买提'
    },
    {
      name: '普惠村',
      holder: '斯迪克•托迪尤木',
      phoneNum: '138****9307',
      series: '羊',
      nums: '1350',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '普惠村',
      holder: '肉孜•艾山',
      phoneNum: '151****0293',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '普惠村',
      holder: '吐沙汗•库尔班',
      phoneNum: '138****7406',
      series: '羊',
      nums: '480',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '普惠村',
      holder: '居买尔•艾合买提',
      phoneNum: '137****4488',
      series: '羊',
      nums: '695',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '库米石阔坦村',
      holder: '郑安停',
      phoneNum: '135****6783',
      series: '羊',
      nums: '2170',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '普惠村',
      holder: '木沙•吾休',
      phoneNum: '173****2895',
      series: '羊',
      nums: '650',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '普惠村',
      holder: '买合木提•阿不力山',
      phoneNum: '189****8682',
      series: '羊',
      nums: '810',
      lng: '',
      lat: '',
      coordinator: '马丽亚木•依明'
    },
    {
      name: '上牙克托格拉克村',
      holder: '卡依尔•斯迪克',
      phoneNum: '135****0170',
      series: '羊',
      nums: '650',
      lng: '85.879636',
      lat: '41.531027',
      coordinator: '玛依努尔•卡哈尔'
    },
    {
      name: '牧场虚拟村',
      holder: '吾买尔•吉力力',
      phoneNum: '138****4748',
      series: '羊',
      nums: '675',
      lng: '85.886291',
      lat: '41.412103',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '买力彦木•依不拉音',
      phoneNum: '139****4147',
      series: '羊',
      nums: '600',
      lng: '85.881763',
      lat: '41.373787',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '艾孜则•依米提',
      phoneNum: '187****0188',
      series: '羊',
      nums: '780',
      lng: '85.89168',
      lat: '41.354241',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '亚森•热合曼',
      phoneNum: '133****1914',
      series: '羊',
      nums: '500',
      lng: '85.886291',
      lat: '41.34439',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '热合木吐拉•热吉甫',
      phoneNum: '135****4814',
      series: '羊',
      nums: '500',
      lng: '85.899442',
      lat: '41.492126',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '木沙•吾甫尔',
      phoneNum: '152****3536',
      series: '羊',
      nums: '650',
      lng: '85.899873',
      lat: '41.475427',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '艾海提•依明',
      phoneNum: '150****7800',
      series: '羊',
      nums: '850',
      lng: '85.93523',
      lat: '41.510819',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '卡地尔•依米提',
      phoneNum: '135****1896',
      series: '羊',
      nums: '710',
      lng: '85.860177',
      lat: '41.353725',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '亚库甫•依米提',
      phoneNum: '135****8255',
      series: '羊',
      nums: '750',
      lng: '85.800673',
      lat: '41.385775',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '艾合买提•依明',
      phoneNum: '135****4458',
      series: '羊',
      nums: '650',
      lng: '85.746057',
      lat: '41.301068',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '依不拉音•托合提',
      phoneNum: '135****5120',
      series: '羊',
      nums: '1270',
      lng: '85.886623',
      lat: '41.374083',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '玉山•米吉提',
      phoneNum: '133****5579',
      series: '羊',
      nums: '780',
      lng: '85.798086',
      lat: '41.387507',
      coordinator: '买买提•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '买热艳木·阿不都热依木',
      phoneNum: '181****1827',
      series: '羊',
      nums: '1208',
      lng: '85.80901',
      lat: '41.450692',
      coordinator: '买买提•热合曼'
    },
    {
      name: '开发区村',
      holder: '托合提•普拉提',
      phoneNum: '133****0699',
      series: '羊',
      nums: '700',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '比拉拉•阿斯木',
      phoneNum: '187****3947',
      series: '羊',
      nums: '450',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '孜娜提汗•依米提',
      phoneNum: '156****3327',
      series: '羊',
      nums: '580',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '吐尔地•巴拉提',
      phoneNum: '133****3988',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '肉孜•买买提',
      phoneNum: '138****1061',
      series: '羊',
      nums: '520',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '吾休尔•米吉提',
      phoneNum: '137****5580',
      series: '羊',
      nums: '670',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '热合木吐拉•多力昆',
      phoneNum: '133****0518',
      series: '羊',
      nums: '450',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '买买提•斯依提',
      phoneNum: '199****4164',
      series: '羊',
      nums: '1000',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '塔勒切盖村',
      holder: '库尔班•买买提',
      phoneNum: '136****1331',
      series: '羊',
      nums: '500',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '开发区村',
      holder: '艾合买提•斯马义',
      phoneNum: '135****7878',
      series: '羊',
      nums: '2760',
      lng: '',
      lat: '',
      coordinator: '买买提•亚森'
    },
    {
      name: '农场第四村',
      holder: '托合提买苏木',
      phoneNum: '135****0370',
      series: '羊',
      nums: '1873',
      lng: '86.118451',
      lat: '41.517455',
      coordinator: '米热古丽赛买提'
    },
    {
      name: '农场第四村',
      holder: '翟向阳',
      phoneNum: '189****4372',
      series: '羊',
      nums: '1362',
      lng: '86.1845',
      lat: '41.5409',
      coordinator: '米热古丽赛买提'
    },
    {
      name: '塔勒切盖村',
      holder: '牙里坤•艾力',
      phoneNum: '152****2178',
      series: '羊',
      nums: '400',
      lng: '85.886291',
      lat: '41.412103',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '克然木•哈斯木',
      phoneNum: '138****7838',
      series: '羊',
      nums: '500',
      lng: '85.881763',
      lat: '41.373787',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '库尔班•米吉提',
      phoneNum: '150****4459',
      series: '羊',
      nums: '650',
      lng: '85.89168',
      lat: '41.354241',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '买买提•艾木都',
      phoneNum: '158****9629',
      series: '羊',
      nums: '550',
      lng: '85.886291',
      lat: '41.34439',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '吐尼沙汗•阿依都依木',
      phoneNum: '138****2464',
      series: '羊',
      nums: '750',
      lng: '85.899442',
      lat: '41.492126',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '艾买提•艾木都',
      phoneNum: '133****4245',
      series: '羊',
      nums: '850',
      lng: '85.899873',
      lat: '41.475427',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '阿西木•艾力',
      phoneNum: '150****7388',
      series: '羊',
      nums: '550',
      lng: '85.93523',
      lat: '41.510819',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '依明•肉孜',
      phoneNum: '133****7000',
      series: '羊',
      nums: '1100',
      lng: '85.860177',
      lat: '41.353725',
      coordinator: '木沙•热合曼'
    },
    {
      name: '塔勒切盖村',
      holder: '买买提•依明',
      phoneNum: '135****9861',
      series: '羊',
      nums: '1820',
      lng: '85.800673',
      lat: '41.385775',
      coordinator: '木沙•热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '衣米提•木沙',
      phoneNum: '158****0103',
      series: '羊',
      nums: '500',
      lng: '85.746057',
      lat: '41.301068',
      coordinator: '穆合普力•托乎提'
    },
    {
      name: '牧场虚拟村',
      holder: '汗里期木•热和曼',
      phoneNum: '133****4009',
      series: '羊',
      nums: '500',
      lng: '85.886623',
      lat: '41.374083',
      coordinator: '穆合普力•托乎提'
    },
    {
      name: '牧场虚拟村',
      holder: '亚克甫。衣米提',
      phoneNum: '135****3043',
      series: '羊',
      nums: '500',
      lng: '85.798086',
      lat: '41.387507',
      coordinator: '穆合普力•托乎提'
    },
    {
      name: '下多尕村',
      holder: '艾孜木•艾买尔',
      phoneNum: '135****3292',
      series: '羊',
      nums: '852',
      lng: '85.80901',
      lat: '41.450692',
      coordinator: '穆合塔尔•列提普'
    },
    {
      name: '下多尕村',
      holder: '热亚提•艾木都力',
      phoneNum: '139****3832',
      series: '羊',
      nums: '523',
      lng: '85.928542',
      lat: '41.385236',
      coordinator: '穆合塔尔•列提普'
    },
    {
      name: '艾力克坎土曼村',
      holder: '艾海提•阿皮子',
      phoneNum: '135****5077',
      series: '羊',
      nums: '521',
      lng: '86.151957',
      lat: '41.751600 ',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '艾力克坎土曼村',
      holder: '买买提•热木提',
      phoneNum: '158****7149',
      series: '羊',
      nums: '425',
      lng: '85.872206',
      lat: '41.683739',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '艾力克坎土曼村',
      holder: '艾比不拉•吾舒尔',
      phoneNum: '138****1731',
      series: '羊',
      nums: '1917',
      lng: '85.872206',
      lat: '41.683655',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '艾力克坎土曼村',
      holder: '热和木吐拉•热木提',
      phoneNum: '135****4544',
      series: '羊',
      nums: '1070',
      lng: '85.872236',
      lat: '41.683583',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '艾力克坎土曼村',
      holder: '努尔丁•托乎提',
      phoneNum: '187****0741',
      series: '羊',
      nums: '480',
      lng: '85.903879',
      lat: '41.701953',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '艾力克坎土曼村',
      holder: '拜合提•吾舒尔',
      phoneNum: '157****5750',
      series: '羊',
      nums: '905',
      lng: '85.865613',
      lat: '41.677887',
      coordinator: '穆萨•拜合提'
    },
    {
      name: '英买里村',
      holder: '吾麦尔托合提',
      phoneNum: '135****8738',
      series: '羊',
      nums: '550',
      lng: '',
      lat: '',
      coordinator: '乃吉米丁'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '五买尔买买提',
      phoneNum: '133****9705',
      series: '羊',
      nums: '850',
      lng: '86.124311',
      lat: '41.715846',
      coordinator: '努尔买买提'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '沙衣•五买尔',
      phoneNum: '155****3718',
      series: '羊',
      nums: '600',
      lng: '86.124519',
      lat: '41.715786',
      coordinator: '努尔买买提'
    },
    {
      name: '喀拉巴克村',
      holder: '排祖拉•哈力克',
      phoneNum: '135****2748',
      series: '羊',
      nums: '870',
      lng: '86.129793',
      lat: '41.747409',
      coordinator: '努仁古力•达吾提'
    },
    {
      name: '上牙克托格拉克村',
      holder: '克热木•乃再尓',
      phoneNum: '191****1033',
      series: '羊',
      nums: '505',
      lng: '86.155862',
      lat: '41.752999',
      coordinator: '帕提古力·柔孜'
    },
    {
      name: '英下村',
      holder: '卡衣尔•阿布力肯木',
      phoneNum: '199****2048',
      series: '羊',
      nums: '500',
      lng: '86.085778',
      lat: '41.548639',
      coordinator: '帕夏古力·麦合木提'
    },
    {
      name: '下阔什巴格村',
      holder: '依斯拉木•艾买提',
      phoneNum: '158****5424',
      series: '羊',
      nums: '450',
      lng: '86.491830 ',
      lat: '41.941753',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      name: '哈拉玉宫村',
      holder: '青应平',
      phoneNum: '135****3266',
      series: '羊',
      nums: '435',
      lng: '86.606034',
      lat: '41.606034',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '哈拉玉宫村',
      holder: '刘忠永',
      phoneNum: '138****0050',
      series: '羊',
      nums: '860',
      lng: '86.002251',
      lat: '41.537353',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '哈拉玉宫村',
      holder: '曹有功',
      phoneNum: '135****5678',
      series: '羊',
      nums: '960',
      lng: '86.026635',
      lat: '41.52577',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '哈拉玉宫村',
      holder: '阿布地热依木•库尔班',
      phoneNum: '135****5481',
      series: '羊',
      nums: '765',
      lng: '86.068451',
      lat: '41.618018',
      coordinator: '热沙来提吉力力'
    },
    {
      name: '明昆格尔村',
      holder: '艾山•艾拜',
      phoneNum: '158****6514',
      series: '羊',
      nums: '410',
      lng: '',
      lat: '',
      coordinator: '热依汉古丽•阿卜杜热伊木'
    },
    {
      name: '萨依勒克村',
      holder: '扎依尔•喀司木',
      phoneNum: '131****9457',
      series: '羊',
      nums: '620',
      lng: '',
      lat: '',
      coordinator: '热依汗古丽。阿布力孜'
    },
    {
      name: '萨依勒克村',
      holder: '尤努斯•赫依提',
      phoneNum: '157****4013',
      series: '羊',
      nums: '622',
      lng: '',
      lat: '',
      coordinator: '热依汗古丽。阿布力孜'
    },
    {
      name: '萨依勒克村',
      holder: '艾散•吾甫尔',
      phoneNum: '173****1707',
      series: '羊',
      nums: '1270',
      lng: '',
      lat: '',
      coordinator: '热依汗古丽。阿布力孜'
    },
    {
      name: '阿瓦提村',
      holder: '托合提•买买提',
      phoneNum: '133****7473',
      series: '羊',
      nums: '600',
      lng: '86.1142',
      lat: '41.716761',
      coordinator: '热孜完古丽•托合提'
    },
    {
      name: '喀拉巴克村',
      holder: '艾仙•阿孜子',
      phoneNum: '138****8452',
      series: '羊',
      nums: '480',
      lng: '',
      lat: '',
      coordinator: '赛买提·阿布拉'
    },
    {
      name: '喀拉巴克村',
      holder: '孜克如拉•排祖拉',
      phoneNum: '138****8178',
      series: '羊',
      nums: '450',
      lng: '86.107532',
      lat: '41.793448',
      coordinator: '赛买提·阿布拉'
    },
    {
      name: '铁斯坎村',
      holder: '哈德•巴吾东',
      phoneNum: '138****4702',
      series: '羊',
      nums: '615',
      lng: '',
      lat: '',
      coordinator: '赛买提•艾木都力'
    },
    {
      name: '铁斯坎村',
      holder: '周贺成',
      phoneNum: '152****5888',
      series: '羊',
      nums: '450',
      lng: '',
      lat: '',
      coordinator: '赛买提•艾木都力'
    },
    {
      name: '铁斯坎村',
      holder: '艾山•铁力瓦地',
      phoneNum: '191****6549',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '赛买提•艾木都力'
    },
    {
      name: '萨依勒克村',
      holder: '艾佰都拉•牙生',
      phoneNum: '180****3850',
      series: '羊',
      nums: '650',
      lng: '85.900683',
      lat: '41.761057',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '木吐拉宰依东',
      phoneNum: '133****9949',
      series: '羊',
      nums: '450',
      lng: '85.933188',
      lat: '41.758626',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '伊敏喀斯木',
      phoneNum: '133****1040',
      series: '羊',
      nums: '1000',
      lng: '85.915301',
      lat: '41.766539',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '依米提•艾吾拉',
      phoneNum: '138****9405',
      series: '羊',
      nums: '1000',
      lng: '85.905993',
      lat: '41.753625',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '木吐拉•牙生',
      phoneNum: '135****9597',
      series: '羊',
      nums: '970',
      lng: '85.90028',
      lat: '41.752708',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '萨依勒克村',
      holder: '吐拉赛潘尔',
      phoneNum: '181****9100',
      series: '羊',
      nums: '700',
      lng: '85.90028',
      lat: '41.752708',
      coordinator: '赛麦提。艾则孜'
    },
    {
      name: '恰尔巴格村',
      holder: '依马木•买买提',
      phoneNum: '130****6121',
      series: '羊',
      nums: '650',
      lng: '86.130179',
      lat: '41.83619',
      coordinator: '托合提古丽•阿布拉'
    },
    {
      name: '喀赞其村',
      holder: '玉苏甫·吾斯曼-新疆戴伟然畜牧养殖农民专业合作社',
      phoneNum: '138****2407',
      series: '羊',
      nums: '720',
      lng: '41.774025',
      lat: '86.13785',
      coordinator: '托合提古丽•阿布拉'
    },
    {
      name: '第三村',
      holder: '麦合木提•伊力亚斯',
      phoneNum: '186****1003',
      series: '羊',
      nums: '750',
      lng: '',
      lat: '',
      coordinator: '瓦热斯江•买买提'
    },
    {
      name: '第一村',
      holder: '依明•哈力克',
      phoneNum: '131****9250',
      series: '羊',
      nums: '400',
      lng: '85.831634',
      lat: '41.674932',
      coordinator: '吾司曼•赛麦提'
    },
    {
      name: '第二村',
      holder: '阿布都衣木•巴斗',
      phoneNum: '135****5319',
      series: '羊',
      nums: '435',
      lng: '85.822466',
      lat: '41.630555',
      coordinator: '吾司曼•赛麦提'
    },
    {
      name: '第一村',
      holder: '克然木•热合曼',
      phoneNum: '135****7534',
      series: '羊',
      nums: '435',
      lng: '85.831697',
      lat: '41.674956',
      coordinator: '吾司曼•赛麦提'
    },
    {
      name: '第一村',
      holder: '赛福丁•乃吉米丁',
      phoneNum: '133****3530',
      series: '羊',
      nums: '405',
      lng: '85.834209',
      lat: '41.690196',
      coordinator: '吾司曼•赛麦提'
    },
    {
      name: '第一村',
      holder: '克然木•肉孜',
      phoneNum: '136****0892',
      series: '羊',
      nums: '545',
      lng: '85.828938',
      lat: '41.675383',
      coordinator: '吾司曼•赛麦提'
    },
    {
      name: '夏库尔村',
      holder: '艾尔肯•吐尔地',
      phoneNum: '139****2555',
      series: '羊',
      nums: '560',
      lng: '85.980081',
      lat: '41.836697',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '夏库尔村',
      holder: '穆萨•麦麦提',
      phoneNum: '133****0481',
      series: '羊',
      nums: '420',
      lng: '85.984389',
      lat: '41.761944',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '夏库尔村',
      holder: '孙刚',
      phoneNum: '135****5958',
      series: '羊',
      nums: '1500',
      lng: '85.973694',
      lat: '41.764928',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '夏库尔村',
      holder: '拜科日•吾甫',
      phoneNum: '135****3777',
      series: '羊',
      nums: '950',
      lng: '86.027162',
      lat: '41.766947',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '夏库尔村',
      holder: '吾斯曼•热合慢',
      phoneNum: '133****6038',
      series: '羊',
      nums: '450',
      lng: '85.962685',
      lat: '41.761227',
      coordinator: '牙生江•吐尔洪'
    },
    {
      name: '哈拉玉宫乡',
      holder: '张海明',
      phoneNum: '137****7936',
      series: '羊',
      nums: '1253',
      lng: '85.922841',
      lat: '41.487986',
      coordinator: '姚春生'
    },
    {
      name: '牧场虚拟村',
      holder: '热吉甫•克音木',
      phoneNum: '180****8284',
      series: '羊',
      nums: '1045',
      lng: '85.896684',
      lat: '41.311256',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '卡斯木•亚库甫',
      phoneNum: '138****0787',
      series: '羊',
      nums: '645',
      lng: '85.877425',
      lat: '41.289795',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '尼牙孜•提力瓦迪',
      phoneNum: '159****8184',
      series: '羊',
      nums: '839',
      lng: '85.974873',
      lat: '41.265723',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '塞买提•依明',
      phoneNum: '152****4088',
      series: '羊',
      nums: '1105',
      lng: '85.974298',
      lat: '41.31169',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '艾比力•吉力力',
      phoneNum: '135****4720',
      series: '羊',
      nums: '540',
      lng: '85.916519',
      lat: '41.320142',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '依不拉依木•克依木',
      phoneNum: '152****9767',
      series: '羊',
      nums: '791',
      lng: '85.842355',
      lat: '41.308872',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '麦麦提•卡迪尔',
      phoneNum: '137****6336',
      series: '羊',
      nums: '742',
      lng: '85.949176',
      lat: '41.480516',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '艾力•阿木提',
      phoneNum: '135****4950',
      series: '羊',
      nums: '577',
      lng: '86.009255',
      lat: '41.432939',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '司马义•托合提',
      phoneNum: '137****4141',
      series: '羊',
      nums: '1075',
      lng: '85.929054',
      lat: '41.433804',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '吾甫尔•买克苏木',
      phoneNum: '131****3898',
      series: '羊',
      nums: '451',
      lng: '85.873288',
      lat: '41.42991',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '喀斯木•木沙',
      phoneNum: '135****0472',
      series: '羊',
      nums: '923',
      lng: '85.862652',
      lat: '41.398748',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '热依木·克热木',
      phoneNum: '137****3833',
      series: '羊',
      nums: '933',
      lng: '85.814646',
      lat: '41.380563',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '牧场虚拟村',
      holder: '亚生•吉力力',
      phoneNum: '176****0581',
      series: '羊',
      nums: '763',
      lng: '85.865814',
      lat: '41.305171',
      coordinator: '尤努斯·热合曼'
    },
    {
      name: '库勒村',
      holder: '麦麦提•坎合曼',
      phoneNum: '150****1572',
      series: '羊',
      nums: '500',
      lng: '85.825213',
      lat: '41.717135',
      coordinator: '玉山•肉孜'
    },
    {
      name: '库勒村',
      holder: '坎合曼•阿司木',
      phoneNum: '150****7194',
      series: '羊',
      nums: '550',
      lng: '85.825213',
      lat: '41.717135',
      coordinator: '玉山•肉孜'
    },
    {
      name: '库勒村',
      holder: '肉孜•艾沙',
      phoneNum: '157****2391',
      series: '羊',
      nums: '550',
      lng: '85.806781',
      lat: '41.707324',
      coordinator: '玉山•肉孜'
    },
    {
      name: '库勒村',
      holder: '艾沙•买买提',
      phoneNum: '133****0174',
      series: '羊',
      nums: '1035',
      lng: '',
      lat: '',
      coordinator: '玉山•肉孜'
    },
    {
      name: '下多尕村',
      holder: '范远涛',
      phoneNum: '138****3331',
      series: '羊',
      nums: '522',
      lng: '',
      lat: '',
      coordinator: '玉山江•妮亚孜'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '玉素甫•衣不拉音',
      phoneNum: '135****4686',
      series: '羊',
      nums: '400',
      lng: '',
      lat: '',
      coordinator: '玉苏普•艾力'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '赛买提•沙衣皮丁',
      phoneNum: '199****0346',
      series: '羊',
      nums: '400',
      lng: '86.086445',
      lat: '41.692061',
      coordinator: '玉苏普•艾力'
    },
    {
      name: '喀拉亚尕奇村',
      holder: '艾合买提•尤努斯',
      phoneNum: '138****6087',
      series: '羊',
      nums: '400',
      lng: '86.086445',
      lat: '41.692061',
      coordinator: '玉苏普•艾力'
    },
    {
      name: '上和什力克村',
      holder: '卡斯司木•牙合甫',
      phoneNum: '150****9648',
      series: '羊',
      nums: '1300',
      lng: '85.949128',
      lat: '41.74526',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '上和什力克村',
      holder: '热合曼•先木西丁',
      phoneNum: '138****3279',
      series: '羊',
      nums: '450',
      lng: '85.933096',
      lat: '41.743303',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '上和什力克村',
      holder: '玉奴司•买买提',
      phoneNum: '133****0737',
      series: '羊',
      nums: '400',
      lng: '86.306246',
      lat: '41.521085',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '上和什力克村',
      holder: '吾曼尔•先木西丁',
      phoneNum: '199****8814',
      series: '羊',
      nums: '600',
      lng: '85.935119',
      lat: '41.748349',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '上和什力克村',
      holder: '库尔班•热衣木',
      phoneNum: '180****0277',
      series: '羊',
      nums: '550',
      lng: '85.935119',
      lat: '41.748349',
      coordinator: '再努热•尼亚孜'
    },
    {
      name: '阿克艾日克村',
      holder: '热合曼吾甫尔',
      phoneNum: '180****0926',
      series: '羊',
      nums: '543',
      lng: '86.066115',
      lat: '41.608445',
      coordinator: '佐热古丽•萨拉木'
    },
    {
      name: '阿克艾日克村',
      holder: '喀迪尔•吾普尔',
      phoneNum: '152****8020',
      series: '羊',
      nums: '945',
      lng: '86.086456',
      lat: '41.692077',
      coordinator: '佐热古丽•萨拉木'
    },
    // 3月3新增数据
    {
      name: '英下乡喀尔巴格三队养殖区',
      holder: '-',
      phoneNum: '17777777777',
      series: '-',
      nums: '-',
      lng: '86.06784161',
      lat: '41.73407459',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.3265245',
      lat: '41.81992533',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.01471',
      lat: '41.70675',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.16165,',
      lat: '41.64829',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.06195',
      lat: '41.59326',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.3265245',
      lat: '41.81992533',
      coordinator: '-'
    },
    {
      name: '水产养殖',
      holder: '-',
      phoneNum: '-',
      series: '水产养殖',
      nums: '-',
      lng: '86.21233257',
      lat: '41.82901162',
      coordinator: '-'
    },
    {
      name: '养殖场',
      holder: '-',
      phoneNum: '-',
      series: '羊',
      nums: '-',
      lng: '86.3265245',
      lat: '41.81992533',
      coordinator: '-'
    },
    {
      name: '养殖场',
      holder: '-',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.93673665',
      lat: '41.7310116',
      coordinator: '-'
    },
    {
      name: '养殖场',
      holder: '-',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.80833848',
      lat: '41.32476601',
      coordinator: '-'
    },
    {
      name: '养殖场',
      holder: '-',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '86.02493743',
      lat: '41.6729186',
      coordinator: '-'
    },
    {
      name: '哈拉玉宫乡新建畜牧养殖小区',
      holder: '哈拉玉宫乡新建畜牧养殖小区',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '86.0981579',
      lat: '41.61766893',
      coordinator: '-'
    },
    {
      name: '库尔勒市西部春天农业科技有限公司',
      holder: '库尔勒市西部春天农业科技有限公司',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '86.11903172',
      lat: '41.59426507',
      coordinator: '-'
    },
    {
      name: '铁克其乡畜牧养殖小区',
      holder: '铁克其乡畜牧养殖小区',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.98568131',
      lat: '41.66930198',
      coordinator: '-'
    },
    {
      name: '养鸡场',
      holder: '-',
      phoneNum: '-',
      series: '鸡',
      nums: '-',
      lng: '86.27268942',
      lat: '41.66533147',
      coordinator: '-'
    },
    {
      name: '库尔勒市包头湖农场现代化养殖小区',
      holder: '库尔勒市包头湖农场现代化养殖小区',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.81746445',
      lat: '41.60910829',
      coordinator: '-'
    },
    {
      name: '托布力其乡畜牧养殖小区',
      holder: '托布力其乡畜牧养殖小区',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.93260514',
      lat: '41.70865544',
      coordinator: '-'
    },
    {
      name: '禾什力克养殖小区',
      holder: '禾什力克养殖小区',
      phoneNum: '-',
      series: '-',
      nums: '-',
      lng: '85.85452061',
      lat: '41.74431411',
      coordinator: '-'
    }
  ]

}
