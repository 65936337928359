<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <!-- <div style="position: absolute;top:20px;left:8px;color:#fff;opacity: 0.7;font-size:10px">
      单位：万亩
    </div>
    <div style="position: absolute;top:20px;right:6px;color:#fff;opacity: 0.7;font-size:10px">
      单位：千公顷
    </div> -->
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './option'
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '商业保险',
      myChart: null,
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }, zoom: 1
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.initChart()
    // this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZWJC-ZS').then(res => {
        if (res) {
          const neme_data = []
          const years = []
          const arr = []
          console.log(res)
          res.data.data.forEach(item => {
            years.push(item.years)
            neme_data.push(item.name_one)
            arr.push([item.years, item.num_data, item.name_one])
          });
          this.unit = res.data.data[0].unti
          this.title = res.data.form.name
          this.option.legend.data = [...new Set(neme_data)] //名称
          this.option.xAxis.data = [...new Set(years)] //年份


          const data1 = []
          let data2 = []
          this.option.legend.data.forEach(title => {
            arr.forEach(item => {
              if (title === item[2]) {
                if (data2.indexOf(title) === -1) {
                  data2.push(item[2], item[1])
                } else {
                  data2.push(item[1])
                }
              }
            })
            data1.push(data2)
            data2 = []
          })
          data1.forEach((item, index) => {
            this.option.series[index].name = item[0]
            this.option.series[index].data = item.slice(1)
          })
          this.myChart.setOption(this.option)
          let sizeFun = function () {
            this.myChart.resize()
          }
          window.addEventListener('resize', sizeFun)
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
      let sizeFun = function () {
        this.myChart.resize()
      }
      window.addEventListener('resize', sizeFun)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-top-container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background: rgba(0, 38, 25, 0.66);
  border: 1px solid #008166;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5),
    inset 0 4px 10px 0 rgba(163, 255, 227, 0.4);
  border-right: 0;

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;
    background-position: 0 -2px;

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }

  .card-body {
    width: 100%;
    height: 100%;

    .row-1 {
      margin: 20px 0;

      div {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 14px;
        text-align: center;
      }

      span {
        color: #96a098;
        font-weight: 400;
        margin-right: 10px;
        font-size: 14px;
      }
    }

    .chart {
      width: 100%;
      height: 360px;
    }
  }
}
</style>