/*
 * @Description: 侧右上 柱图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:07:18
 */
const class_xAxis = ['2021年', '2022年', '2023年'] // 列标
const classData1 = [52300, 87000, 94900] // 柱图1数据
const classData2 = [42000, 60000, 67803] // 柱图2数据
const classData3 = [48600, 52500, 53900] // 柱图3数据
const classData4 = [62300, 68400, 73100] // 柱图4数据
const classType = ['太平洋', '平安', '中华联合', '人民'] // 图例
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#AADE55' // 0% 处的颜色
    }, {
      offset: 1, color: '#F9FFAE' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#008567' // 0% 处的颜色
    }, {
      offset: 1, color: '#49AC00' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: 50,
    left: 100
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#FFFFFF',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#FFFFFF'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '万吨',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#FFFFFF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#FFFFFF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'bar',
      stack: 'one',
      barWidth: 15,
      data: classData1,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: classType[1],
      type: 'bar',
      stack: 'two',
      barGap: '0%',
      barWidth: 15,
      data: classData2,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: classType[2],
      type: 'bar',
      stack: 'three',
      barGap: '0%',
      barWidth: 15,
      data: classData3,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: classType[3],
      type: 'bar',
      stack: 'four',
      barGap: '0%',
      barWidth: 15,
      data: classData4,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
