/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: hlc 1664800317@qq.com
 * @LastEditTime: 2022-09-15 01:13:27
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  rowNum: 6,
  columnWidth: [110, 100, 100, 100],
  header:[],// ['总用量', '瞬时雨量', '日雨量', '当前雨量', '风速', '风向'],
  headerBGC: 'transparent',
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  textStyle: {
    fonntSize: 16
  },
  data: [
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
    // ['-', '-', '-', '-', '-', '-'],
  ]
  }