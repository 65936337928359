export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#AADE55' // 0% 处的颜色
    }, {
      offset: 1, color: '#F9FFAE' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#AEFFE2' // 0% 处的颜色
    }, {
      offset: 1, color: '#00E5B4' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#DE6755' // 0% 处的颜色
    }, {
      offset: 1, color: '#FFB3AE' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    show: true,
    // data: ['农业用水量', '工业用水量', '生活用水量', '人工生态与环境补水量'],//['服务业', '农业', '工业', '生活用水'],
    textStyle: {
      color: '#FFFFFF'
    },
    padding: [
      10,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ]
  },
  xAxis: {
    type: 'category',
    data: ['2014', '2015', '2016', '2017', '2018'],// ['2019年', '2020年', '2021年', '2022年'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}亿立方'
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#E8E8E8',
        type: 'dashed'
      }
    },
  }, {
    type: 'value',
    splitLine: false,
    show: false,
  },
  {
    type: 'value',
    splitLine: false,
    show: false,
  }
  ],
  grid: {
    top: 60,
    botttom: 0,
  },
  series: [
    {
      name: '农业用水量',//'服务业',
      data: [8.264, 8.421, 8.280, 8.280, 6.828],
      type: 'bar',
      barWidth: '15',
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: '工业用水量',//'农业',
      data: [1.311, 1.102, 1.180, 1.180, 0.522],//[22, 45, 51, 13, 46],
      type: 'bar',
      barWidth: '15',
      // yAxisIndex: 1,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: '生活用水量',// '工业',
      data: [0.593, 0.641, 0.620, 0.151, 0.449, 0.491],//[22, 45, 51, 13, 46],
      type: 'bar',
      barWidth: '15',
      // yAxisIndex: 1,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    },
    {
      name: '人工生态与环境补水量',//'生活用水',
      data: [0.588, 0.488, 0.400, 0.620, 1.469],//[22, 45, 51, 13, 46],
      type: 'bar',
      barWidth: '15',
      // yAxisIndex: 1,
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
