<template>
  <div class="left-top-container mt"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex>
      <div class="thumb">
        <img v-if="taskFlow.img"
             :src="baseUrl + taskFlow.img"
             alt=""
             srcset="">
        <img v-else
             :src="img"
             alt=""
             srcset="">
      </div>
      <div class="content"
           flex="dir:top main:justify">
        <div>{{ taskFlow.taskName || '-' }}</div>
        <div>任务编号：{{ taskFlow.taskId || '-' }}</div>
        <div>提交日期：{{ taskFlow.time&&taskFlow.time.split(' ')[0] || '-' }} </div>
      </div>
    </div>
    <div class="remak">
      “{{ taskFlow.remark || '暂无' }}”
    </div>
  </div>
</template>

<script>
import { _GetTaskFlowXG } from '@/api/assp-task'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    landId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      baseUrl: 'https://api.lztl.cn',
      title: '',
      one: '',
      two: '',
      three: '',
      four: '',
      img: '',
      data: {},//数据
      taskFlow: {}
    }
  },
  watch: {
    landId: {
      handler (val) {
        if (!val) return
        this.getFlowData()
      }
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    // 获取小哥任务反馈
    getFlowData () {
      _GetTaskFlowXG(this.landId).then(res => {
        if (res) {
          this.taskFlow = res
          this.taskFlow.img = this.taskFlow.img.split(',')[0]
        }
      })
    },
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-ZNHJC-XGRWFK').then(res => {
        if (res) {
          console.log(res);
          this.one = res.data.data[0].data_name
          this.two = res.data.data[0].data_bianhao
          this.three = res.data.data[0].data_shijian
          this.four = res.data.data[0].data_msg
          this.img = res.data.data[0].data_url
          this.title = res.data.form.name
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.left-top-container {
  .card-body {
    .thumb {
      margin: 20px;
      width: 130px;
      height: 130px;
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }

    .content {
      margin-top: 20px;
      width: 180px;
      height: 100px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;

      :last-child {
        line-height: 24px;
      }
    }
  }

  .remak {
    width: 90%;
    height: 20%;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
}
</style>