<template>
  <div class="leftDrawer">
    <el-drawer :visible.sync="drawerState"
               direction="ltr"
               :before-close="handleClose">
      <div class="collapseBox"
           v-if="drawerState">
        <el-collapse accordion
                     v-model="activeNames">
          <div class="brief"
               @click="onV1Btn">
            <span>页面导航</span>
          </div>
          <el-collapse-item v-for="(item,i1) in list2"
                            :key="i1"
                            :name="i1">
            <template slot="title">
              <div style="margin-left: 20px;margin-top: 5px;">
                <img v-if="i1===0"
                     src="@/assets/images/leftDraw/ny.png">
                <img v-if="i1===1"
                     src="@/assets/images/leftDraw/ly.png">
                <img v-if="i1===2"
                     src="@/assets/images/leftDraw/sl.png">
                <img v-if="i1===3"
                     src="@/assets/images/leftDraw/xmy.png">
              </div>
              <div style="margin-left: 15px;">
                {{ item.name }}
              </div>
            </template>
            <div class="towTitle">
              <el-collapse accordion
                           v-model="activeNames2">
                <el-collapse-item v-for="(item2,i2) in item.list"
                                  :key="i2"
                                  :name="i2">
                  <template slot="title">
                    <span style="margin-left: 20px;">{{ item2.name }}</span>
                  </template>
                  <div class="collapseList">
                    <div class="item"
                         @click="onBtn(item3)"
                         v-for="(item3,i3) in item2.list"
                         :key="i3">
                      {{ item3.name }}
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'LeftDrawerItem',
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      drawerState: false,
      listState: false,
      activeNames: [0],
      activeNames2: [0, 1, 2, 3],
      list2: [],
      list: [
        {
          name: '农业',
          list: [
            {
              name: '数据一张图',
              list: [
                {
                  btnIndex: 0,
                  topIndex: 0,
                  bottomIndex: 0,
                  name: '基本情况'
                },
                {
                  btnIndex: 0,
                  topIndex: 0,
                  bottomIndex: 1,
                  name: '发展规则'
                },
                {
                  btnIndex: 0,
                  topIndex: 0,
                  bottomIndex: 2,
                  name: '作物种植'
                },
                {
                  btnIndex: 0,
                  topIndex: 0,
                  bottomIndex: 3,
                  name: '重点项目'
                },
                {
                  btnIndex: 0,
                  topIndex: 0,
                  bottomIndex: 4,
                  name: '智慧农业'
                }
              ]
            },
            {
              name: '数字化管理',
              list: [
                {
                  btnIndex: 0,
                  topIndex: 1,
                  bottomIndex: 0,
                  name: '农机管理'
                },
                {
                  btnIndex: 0,
                  topIndex: 1,
                  bottomIndex: 1,
                  name: '溯源管理'
                },
                {
                  btnIndex: 0,
                  topIndex: 1,
                  bottomIndex: 2,
                  name: '信息化管理'
                },
                {
                  btnIndex: 0,
                  topIndex: 1,
                  bottomIndex: 3,
                  name: '自动化检测'
                }
              ]
            },
            {
              name: '产业化运营',
              list: [
                {
                  btnIndex: 0,
                  topIndex: 2,
                  bottomIndex: 0,
                  name: '品牌建设'
                },
                {
                  btnIndex: 0,
                  topIndex: 2,
                  bottomIndex: 1,
                  name: '金融服务'
                },
                {
                  btnIndex: 0,
                  topIndex: 2,
                  bottomIndex: 2,
                  name: '平台交易'
                },
                {
                  btnIndex: 0,
                  topIndex: 2,
                  bottomIndex: 3,
                  name: '科技成果转化'
                },
              ]
            },
            {
              name: '智能化决策',
              list: [
                {
                  btnIndex: 0,
                  topIndex: 3,
                  bottomIndex: 0,
                  name: '农事决策'
                },
                {
                  btnIndex: 0,
                  topIndex: 3,
                  bottomIndex: 1,
                  name: '政务决策'
                }
              ]
            }
          ]
        },
        {
          name: '林业',
          list: [
            {
              name: '数据一张图',
              list: [
                {
                  btnIndex: 1,
                  topIndex: 0,
                  bottomIndex: 0,
                  name: '基本情况'
                },
                {
                  btnIndex: 1,
                  topIndex: 0,
                  bottomIndex: 1,
                  name: '发展规划'
                },
                {
                  btnIndex: 1,
                  topIndex: 0,
                  bottomIndex: 2,
                  name: '林果科技'
                },
                {
                  btnIndex: 1,
                  topIndex: 0,
                  bottomIndex: 3,
                  name: '重点项目'
                },
              ]
            },
            {
              name: '数字化管理',
              list: [
                {
                  btnIndex: 1,
                  topIndex: 1,
                  bottomIndex: 0,
                  name: '农机管理'
                },
                {
                  btnIndex: 1,
                  topIndex: 1,
                  bottomIndex: 1,
                  name: '溯源管理'
                },
                {
                  btnIndex: 1,
                  topIndex: 1,
                  bottomIndex: 2,
                  name: '信息化管理'
                },
                {
                  btnIndex: 1,
                  topIndex: 1,
                  bottomIndex: 3,
                  name: '自动化检测'
                }
              ]
            },
            {
              name: '产业化运营',
              list: [
                {
                  btnIndex: 1,
                  topIndex: 2,
                  bottomIndex: 0,
                  name: '品牌建设'
                },
                {
                  btnIndex: 1,
                  topIndex: 2,
                  bottomIndex: 1,
                  name: '金融服务'
                },
                {
                  btnIndex: 1,
                  topIndex: 2,
                  bottomIndex: 2,
                  name: '平台交易'
                },
                {
                  btnIndex: 1,
                  topIndex: 2,
                  bottomIndex: 3,
                  name: '科技成果转化'
                }
              ]
            },
            {
              name: '智能化决策',
              list: [
                {
                  btnIndex: 1,
                  topIndex: 3,
                  bottomIndex: 0,
                  name: '农事决策'
                },
                {
                  btnIndex: 1,
                  topIndex: 3,
                  bottomIndex: 1,
                  name: '政务决策'
                }
              ]
            }
          ]

        },
        {
          name: '水利',
          list: [
            {
              name: '数据一张图',
              list: [
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 0,
                  name: '基本情况'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 1,
                  name: '水科资源管理'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 2,
                  name: '水科规划'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 3,
                  name: '骨干工程'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 4,
                  name: '渠系分布'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 5,
                  name: '水库'
                },
                {
                  btnIndex: 2,
                  topIndex: 0,
                  bottomIndex: 6,
                  name: '河(湖)长治'
                }
              ]
            },
            {
              name: '数字化管理',
              list: [
                {
                  btnIndex: 2,
                  topIndex: 1,
                  bottomIndex: 0,
                  name: '农机管理'
                },
                {
                  btnIndex: 2,
                  topIndex: 1,
                  bottomIndex: 1,
                  name: '溯源管理'
                },
                {
                  btnIndex: 2,
                  topIndex: 1,
                  bottomIndex: 3,
                  name: '信息化管理'
                },
                {
                  btnIndex: 2,
                  topIndex: 1,
                  bottomIndex: 2,
                  name: '自动化检测'
                }
              ]
            },
            {
              name: '产业化运营',
              list: [
                {
                  btnIndex: 2,
                  topIndex: 2,
                  bottomIndex: 0,
                  name: '品牌建设'
                },
                {
                  btnIndex: 2,
                  topIndex: 2,
                  bottomIndex: 1,
                  name: '金融服务'
                },
                {
                  btnIndex: 2,
                  topIndex: 2,
                  bottomIndex: 2,
                  name: '平台交易'
                },
                {
                  btnIndex: 2,
                  topIndex: 2,
                  bottomIndex: 3,
                  name: '科技成果转化'
                },
              ]
            },
            {
              name: '智能化决策',
              list: [
                {
                  btnIndex: 2,
                  topIndex: 3,
                  bottomIndex: 0,
                  name: '农事决策'
                },
                {
                  btnIndex: 2,
                  topIndex: 3,
                  bottomIndex: 1,
                  name: '政务决策'
                }
              ]
            }
          ]
        },
        {
          name: '畜牧业',
          list: [
            {
              name: '数据一张图',
              list: [
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 0,
                  name: '基本情况'
                },
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 1,
                  name: '畜牧良种'
                },
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 2,
                  name: '饲草产业'
                },
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 3,
                  name: '畜禽养殖'
                },
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 4,
                  name: '屠宰加工'
                },
                {
                  btnIndex: 3,
                  topIndex: 0,
                  bottomIndex: 5,
                  name: '防疫监督'
                },
              ]
            },
            {
              name: '数字化管理',
              list: [
                {
                  btnIndex: 3,
                  topIndex: 1,
                  bottomIndex: 0,
                  name: '农机管理'
                },
                {
                  btnIndex: 3,
                  topIndex: 1,
                  bottomIndex: 1,
                  name: '溯源管理'
                },
                {
                  btnIndex: 3,
                  topIndex: 1,
                  bottomIndex: 2,
                  name: '信息化管理'
                },
                {
                  btnIndex: 3,
                  topIndex: 1,
                  bottomIndex: 3,
                  name: '自动化检测'
                }
              ]
            },
            {
              name: '产业化运营',
              list: [
                {
                  btnIndex: 3,
                  topIndex: 2,
                  bottomIndex: 0,
                  name: '品牌建设'
                },
                {
                  btnIndex: 3,
                  topIndex: 2,
                  bottomIndex: 1,
                  name: '金融服务'
                },
                {
                  btnIndex: 3,
                  topIndex: 2,
                  bottomIndex: 2,
                  name: '平台交易'
                },
                {
                  btnIndex: 3,
                  topIndex: 2,
                  bottomIndex: 3,
                  name: '科技成果转化'
                },
              ]
            },
            {
              name: '智能化决策',
              list: [
                {
                  btnIndex: 3,
                  topIndex: 3,
                  bottomIndex: 0,
                  name: '农事决策'
                },
                {
                  btnIndex: 3,
                  topIndex: 3,
                  bottomIndex: 1,
                  name: '政务决策'
                }
              ]
            }
          ]
        }
      ]
    }
  },

  watch: {
    drawer (val) {
      this.list2 = []
      this.list2.push(this.list[0])
      this.drawerState = val
    }
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('drawer', false)
    },

    onBtn (item3) {
      this.drawerState = false
      this.$emit('drawer', false)
      this.$emit('params', item3)
    },

    onV1Btn () {
      // this.$emit('index', -1)
      // this.$emit('drawer', false)
      this.listState = !this.listState
      this.activeNames = []
      this.activeNames2 = []
      this.list2 = []
      if (this.listState) {
        this.list2 = this.list
      } else {
        this.list2.push(this.list[0])
      }
    }
  }
}
</script>

<style lang="scss">
.leftDrawer {
  .el-drawer {
    width: 300px !important;
    background: #0a5085 !important;
  }
  .el-drawer__close-btn {
    color: #fff !important;
  }

  .el-tree {
    background: transparent !important;
  }

  .el-tree-node__label {
    font-size: 18px !important;
    color: #fff !important ;
  }

  .el-tree-node__content {
    margin: 10px 0 0 20px !important;
    height: 50px !important;
  }

  .el-tree-node__content:hover {
    background-color: #56acff !important;
    height: 50px !important;
  }

  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #56acff !important;
    height: 50px !important;
  }
  .el-drawer__header {
    color: #fff !important;
    background: #0a5085 !important;
  }
  .collapseBox {
    .el-collapse-item__header {
      margin-top: 5px;
      border: none !important;
      background: linear-gradient(90deg, #0a5085 0%, #0e6eb9 100%) !important;
      color: #fff;
      font-size: 18px;
    }
    .el-collapse {
      border: none !important;
    }
  }

  .el-collapse-item__wrap {
    background: transparent !important;
    border: none !important;
  }

  .towTitle {
    .el-collapse-item__header {
      border: none !important;
      background: transparent !important;
      color: #fff;
      font-size: 14px;
    }
    .el-collapse {
      border: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.collapseBox {
  position: fixed;
  top: 40px;
  left: 0;
  width: 300px;
  height: 100%;
  background: #0a5085;
  z-index: 999;

  .brief {
    margin: 25px auto;
    background: url("@/assets/images/topbtn1.png") no-repeat;
    background-size: cover;
    text-align: center;
    width: 152px;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -30px;
      width: 50px;
      height: 40px;
      // background: url("@/assets/images/leftDraw/tl.png") no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      right: -30px;
      width: 50px;
      height: 40px;
      // background: url("@/assets/images/leftDraw/tr.png") no-repeat;
    }
  }

  .towTitle {
    .collapseList {
      margin-top: -20px;
      margin-bottom: -25px;
      .item {
        cursor: pointer;
        margin: 20px 0 0 40px;
        opacity: 0.7;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>