<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 15:45:28
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="采收记录"
                 :visible.sync="gatherListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="gatherData"
                    @cell-click="onGatherDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="name"
                             label="农户"
                             width="90" />
            <el-table-column prop="area"
                             label="面积"
                             width="90" />
            <el-table-column prop="carNumber"
                             label="车牌号"
                             width="90" />
            <el-table-column prop="time"
                             label="采收日期"
                             width="90" />
          </el-table>
        </div>
      </el-drawer>

      <el-drawer title="采收详情"
                 :visible.sync="gatherDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              开始时间
            </div>
            <div class="right">
              2022-7-6
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              结束时间
            </div>
            <div class="right">
              2022-5-8
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              结算面积
            </div>
            <div class="right">
              1000亩
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              单产预估
            </div>
            <div class="right">
              2000kg/亩
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              总产预估
            </div>
            <div class="right">
              20216kg
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手姓名
            </div>
            <div class="right">
              张三
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手手机
            </div>
            <div class="right">
              17685985895
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              交售人手机
            </div>
            <div class="right">
              15685256585
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              所属工厂
            </div>
            <div class="right">
              库尔勒工厂
            </div>
          </div>
          <div class="line" />

          <!-- <div class="btn"
               @click="onTrack">
            查看轨迹
          </div> -->
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="交售记录"
                 :visible.sync="sellListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="sellDataarr"
                    @cell-click="onSellDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="farmerName"
                             label="农户"
                             width="90" />
            <el-table-column prop="price"
                             label="价格"
                             width="90" />
            <el-table-column prop="landArea"
                             label="面积"
                             width="90" />
            <el-table-column prop="time"
                             label="交售时间"
                             width="90" />
          </el-table>
        </div>
      </el-drawer>

      <el-drawer title="交售详情"
                 :visible.sync="sellDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              磅票号
            </div>
            <div class="right">
              252515525
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              垛位
            </div>
            <div class="right">
              20
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              净重
            </div>
            <div class="right">
              200
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              价格
            </div>
            <div class="right">
              ￥200
            </div>
          </div>
          <div class="line" />
          <div class="box">
            <div class="left">
              过磅时间
            </div>
            <div class="right">
              2022-8-9
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              交售人姓名
            </div>
            <div class="right">
              张三
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              交售人手机
            </div>
            <div class="right">
              15866958858
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              所属工厂
            </div>
            <div class="right">
              乌鲁木齐工厂
            </div>
          </div>
          <div class="line" />

          <!-- <div class="btn"
               @click="onTrack">
            查看轨迹
          </div> -->
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    vehicleIdSell: {
      type: Number,
      default: null
    },

    vehicleIdGather: {
      type: Object,
      default: null
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      vehicleId: null,//车辆id
      sellList: [],//保存交售列表
      sellData: {},//交售详情
      gatherlist: [],//保存采收列表
      getharData: {},
      gatherDrawer: false,
      gatherListDrawer: false,
      sellDrawer: false,
      sellListDrawer: false,
      licensePlateNumber: '',
      //交售
      sellDataarr: [],
      //采收
      gatherData: []
    }
  },

  watch: {
    //交售
    vehicleIdSell (val) {
      this.vehicleId = val
      this.sellDataarr = [
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        },
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        },
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        },
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        },
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        },
        {
          id: 1,
          farmerName: '张三',
          price: '¥200',
          time: '2022-2-5',
          landArea: '300'
        }
      ]
      this.sellListDrawer = true
    },

    //采收
    vehicleIdGather (val) {
      this.vehicleId = val
      this.gatherData = [
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        },
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        },
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        },
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        },
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        },
        {
          id: 1,
          name: '李四',
          carNumber: '新A jh897',
          area: '200亩',
          time: '2022-3-8'
        }
      ]
      this.gatherListDrawer = true
    }
  },

  methods: {
    handleClose (done) {
      this.$emit('sellDrawer', false)
      done();
    },

    /*点击交售列表详情 */
    onSellDetail () {
      this.sellDrawer = true
    },

    /*点击采收列表详情 */
    onGatherDetail () {
      this.gatherDrawer = true
    },

    /*点击轨迹 */
    onTrack () {
      this.sellDrawer = false
      this.sellListDrawer = false
      this.$emit('sellDrawer', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    cursor: pointer;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
