/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-29 20:36:29
 */
export default {
  title: {
    text: '单位: 头、羽/天',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  xAxis: {
    type: 'category',
    data: ['牛', '羊', '生猪', '家禽'],//['一月', '二月', '三月', '四月', '五月', '六月'],
    axisLabel: {
      color: '#FFFFFF'
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '头、羽/天'
      }
      return relVal
    }
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#4B4743',
        type: 'dashed'
      }
    }
  }],
  // legend: {
  //   textStyle: {
  //     color: '#FFFFFF'
  //   },
  //   itemWidth: 26,
  //   itemHeight: 6,
  //   itemStyle: {
  //     color: '#68FFD6'
  //   },
  //   padding: [
  //     20,  // 上
  //     0, // 右
  //     20,  // 下
  //     0, // 左
  //   ],
  // },
  grid: {
    top: 60,
    left: 50,
    botttom: 0,
  },
  series: [
    {
      name: '',//'屠宰量/万只',
      data: [95, 850, 340, 7500],// [12, 35, 41, 33, 23, 44],
      type: 'bar',
      barWidth: '20',

      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFFFF'
      },

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
