<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 07:07:24
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="采收详情"
                 :visible.sync="gatherDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div v-if="harvestData">
          <div class="box">
            <div class="left">
              开始时间
            </div>
            <div class="right">
              2022-3-9
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              结束时间
            </div>
            <div class="right">
              2022-5-6
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              结算面积
            </div>
            <div class="right">
              200亩
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              单产预估
            </div>
            <div class="right">
              100kg/亩
            </div>
          </div>
          <div class="line" />
          <div class="box">
            <div class="left">
              总产预估
            </div>
            <div class="right">
              300kg
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手姓名
            </div>
            <div class="right">
              张三
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手手机
            </div>
            <div class="right">
              15899956665
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              棉蛋列表
            </div>
            <div class="right"
                 style="cursor: pointer;"
                 @click="gatherListDrawer = true">
              详情>
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="棉蛋列表"
                 :visible.sync="gatherListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="tableData"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="qrCode"
                             label="棉蛋编号"
                             width="150" />
            <el-table-column prop="time"
                             label="采收日期"
                             width="150" />
          </el-table>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gatherCode: {
      type: String,
      default: '',
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      gatherDrawer: false,
      gatherListDrawer: false,
      harvestData: {},
      harvestId: null,
      qrCode: '',
      tableData: [],
    }
  },

  watch: {
    gatherCode (val) {
      if (val != '') {
        this.tableData = [
          {
            qrCode: 2526541554565,
            time: 2022 - 2 - 6
          },
          {
            qrCode: 2526541554565,
            time: 2022 - 2 - 6
          }, {
            qrCode: 2526541554565,
            time: 2022 - 2 - 6
          }, {
            qrCode: 2526541554565,
            time: 2022 - 2 - 6
          }, {
            qrCode: 2526541554565,
            time: 2022 - 2 - 6
          }
        ]
        this.gatherDrawer = true
      }
    }
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('gatherShow', '')
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
