/*
 * @Description: 中上 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 16:05:19
 */

export default {
  headerHeight: 52.5,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 14, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  columnWidth: [140, 280, 140, 140],
  header: ['姓名', '身份证', '告警', '信用变动', '当前信用', '信用等级'],
  align: ['center', 'center', 'center', 'center', 'center', 'center'],
  data: [
    ['张*', '652801********1235', '<span style="color:#04a424;">正常</span>', '+1', '1002', '极好'],
    ['李**', '452311********8461', '<span style="color:#ce1010;">异常</span>', '-5', '0', '较差'],
    ['安**', '652801********1784', '<span style="color:#04a424;">正常</span>', '+76', '716', '良好'],
    ['马**', '652801********2345', '<span style="color:#04a424;">正常</span>', '+23', '643', '良好'],
    ['王*', '652801********1425', '<span style="color:#04a424;">正常</span>', '+21', '8312', '极好'],
    ['刘**', '652801********2242', '<span style="color:#ce1010;">异常</span>', '-12', '0', '较差'],
    ['李**', '653412********3142', '<span style="color:#04a424;">正常</span>', '+23', '562', '良好'],
    ['王*', '654512********9632', '<span style="color:#04a424;">正常</span>', '+4', '122', '一般'],
    ['张**', '652801********1632', '<span style="color:#04a424;">正常</span>', '+67', '1232', '极好'],
    ['荀**', '652801********1323', '<span style="color:#04a424;">正常</span>', '+2', '2311', '极好'],
    ['潘**', '652801********3576', '<span style="color:#04a424;">正常</span>', '+34', '3123', '极好'],
    ['菅*', '651721********4673', '<span style="color:#ce1010;">异常</span>', '-9', '0', '较差'],
    ['劳**', '652801********8543', '<span style="color:#04a424;">正常</span>', '+54', '765', '良好'],
    ['朱**', '653457********1754', '<span style="color:#04a424;">正常</span>', '+87', '1289', '极好'],
    ['许**', '658421********1758', '<span style="color:#ce1010;">异常</span>', '+4', '0', '较差'],
    ['王**', '652801********2527', '<span style="color:#04a424;">正常</span>', '+7', '234', '一般']
  ]
}
