<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 14:58:41
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="今日牌价"
                 :visible.sync="Drawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              价格
            </div>
            <div class="right">
              ￥200
            </div>
          </div>
          <div class="line" />
          <div class="box">
            <div class="left">
              衣分
            </div>
            <div class="right">
              20%
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      Drawer: false,
      priceData: {}//牌价信息
    }
  },

  watch: {
    id (val) {
      if (val != '') {
        this.Drawer = true
      }
    }
  },

  methods: {
    handleClose (done) {
      this.$emit('priceShow', '')
      done();
    },
  }
}
</script>

<style lang="scss" scoped >
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }
}
</style>
