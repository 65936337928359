<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        <!-- {{ title }} -->
        土壤墒情趋势
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './option'
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
// import date from "@/components/main-view2/top-btn1/bottom-btn3/asideLeft/index.json"
const hours = [
  "00",
  "02",
  "04",
  "06",
  "08",
  "10",
  "12",
  "14",
  "16",
  "18",
  "20",
  "22",
];
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
    soilTrend:{
      type:Array,
      default:[]
    }
  },

  data () {
    return {
      option,
      data: {},//数据
      title: '',
      myChart: null,
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      zoom: 1,
      msgData:[]
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    },
    soilTrend(val){
      const d = new Date();
      let getYear = d.getFullYear(); //年
        let getMonth = d.getMonth() + 1; //月
        let getDate = d.getDate(); //日
        const hour = d.getHours(); // 获取小时
        let data = getYear + "-" + getMonth + "-" + getDate;
        const _arr = [];
        // console.log(data,"_arr")
        val.forEach((item) => {
          const timeArr = item.createdAt
            .replace(" ", ":")
            .replace(/\:/g, "-")
            .split("-");
          //筛选出时间
          let indexs = hours.findIndex(function (r) {
            if (r === timeArr[3].trim()) {
              item.state = r;
            }
            return r === timeArr[3].trim();
          });
          // console.log(indexs,"indexs")
          if (indexs !== -1) {
          //判断数组元素是否有重复
          if (hour >= timeArr[3].trim()) {
            _arr.push(item);
            const removeDuplicateObj = (arr) => {
              let obj = {};
              arr = arr.reduce((newArr, next) => {  
                obj[next.state]
                  ? ""
                  : (obj[next.state] = true && newArr.push(next));
                return newArr;
              }, []);
              return arr;
            };
            this.msgData = removeDuplicateObj(_arr);
            const sortId=(a, b)=> {
              return a.state - b.state;
            }
            this.msgData.sort(sortId)
          }
        }
        })
        this.option.series[0].data=[]
      this.option.series[1].data=[]
      this.msgData.forEach(item=>{
        this.option.series[0].data.push(item.humValueStr)
        this.option.series[1].data.push(item.temValueStr)
      })
    this.initChart()
      // this.option.series[0].data=[]
      //      val.forEach(item=>{
      //   this.option.series[0].data.push(item.humValueStr)
      // })
    }
  },

  mounted () {
    // this.option.series[0].data=[]

    // const d = new Date();

    // let getHours = d.getHours(); //日
        // date.data.map((item)=>{
            // if(item.createdAt<=getHours){
              // this.option.series[0].data.push(item.humValueStr)
            // }
        // })
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.initChart()
    setTimeout(() => {
      this.getSreenData()
    }, 1000);
  },

  methods: {
    //获取数据
    getSreenData (val) {
 
      // _GetAspScreenComponentData('NEWKEL-NY-NCMJBHQSS').then(res => {
      //   if (res) {
      //     const neme_data = []
      //     const years = []
      //     const arr = []
          // console.log(res,"xxxxx")
          // res.data.data.sort((a, b) => {
          //   return b.years > a.years ? -1 : 1
          // }).forEach(item => {
          //   years.push(item.years)
          //   neme_data.push(item.name_one)
          //   arr.push([item.years, item.num_data, item.name_one])
          // });
          // this.unit = res.data.data[0].unti
          // this.title = res.data.form.name
          // this.option.legend.data = [...new Set(neme_data)] //名称
          // this.option.xAxis.data = [111,222] //年份

          // const data1 = []
          // let data2 = []
          // this.option.legend.data.forEach(title => {
          //   arr.forEach(item => {
          //     if (title === item[2]) {
          //       if (data2.indexOf(title) === -1) {
          //         data2.push(item[2], item[1])
          //       } else {
          //         data2.push(item[1])
          //       }
          //     }
          //   })
          //   data1.push(data2)
          //   data2 = []
          // })
          // data1.forEach((item, index) => {
          //   this.option.series[index].name = item[0]
          //   this.option.series[index].data = item.slice(1)
          // })
          this.myChart.setOption(this.option)
          window.addEventListener('resize', () => {
            this.myChart.resize()
          })
        // }
      // })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>