<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-05 10:57:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 17:12:58
-->
<template>
  <div>
    <!-- 按钮 -->
    <div class="bubbleBox">
      <el-radio v-for="(item,index) in list"
                :key="index"
                v-model="radio"
                :label="JSON.stringify(index + 1)">
        {{ item }}
      </el-radio>
    </div>
  </div>
</template>

<script>
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'BubbleSelect',
  data () {
    return {
      radio: '1',
      list: []
    }
  },
  watch: {
    radio (val) {
      this.$emit('radio', val)
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-LY-KJCGZH-DTAN').then(res => {
        if (res) {
          res.data.data.forEach(item => {
            this.list.push(
              item.name_data
            )
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.bubbleBox {
  .el-radio__inner {
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 0 !important;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #fff;
    opacity: 0.9;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background: rgba(0, 38, 25, 0.66);
  }

  .el-radio {
    margin-top: 15px;
    color: #fff;
    opacity: 0.9;
  }
}
</style>
<style scoped lang="scss">
.bubbleBox {
  padding: 5px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: #002619b3;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.bubble {
  position: absolute;
  right: 150%;
  top: -50px;
  width: 450px;
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);

  .title {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #defff6;
  }

  .line {
    width: 100%;
    opacity: 0.3;
    border-bottom: 1px solid #979797;
  }

  .content {
    margin: 5px auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      .label {
        margin-left: 3px;
        font-size: 14px;
        opacity: 0.8;
        font-weight: bold;
        color: #fff;
      }

      .name {
        margin-right: 3px;
        font-size: 14px;
        color: #fff;
        opacity: 0.6;
      }
    }

    .describe {
      text-indent: 24px;
      opacity: 0.7;
      line-height: 25px;
      width: 90%;
      margin: 19px auto;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
