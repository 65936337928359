/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 12:02:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 17:14:25
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#FF1D1D', '#FF8A04', '#FDFF22', '#008567', '#FF40F8', '#4F00FF', '#FF869F', '#A076FF'],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}升'
  },
  legend: {
    left: '23%',
    top: '80%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 4
  },
  series: [
    {
      name: '名称',
      type: 'pie',
      radius: ['35%', '50%'],
      center: ['50%', '40%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
        }
      },
      label: {
        normal: {
          formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
          padding: [0, -10],//取消hr线跟延长线之间的间隙
          rich: {
            a: {
              color: '#999',
              lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
              align: 'center'
            }
          }
        }
      },

      data: [],
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },

    }
  ]
}
