/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-06 18:15:21
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 16:08:47
 * @FilePath: \korlascreen\src\components\main-view2\top-btn3\bottom-btn4\asideRight\rightBottomItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],// ['消防', '巡护'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  xAxis: {
    type: 'category',
    data: [],// ['地点1', '地点2', '地点3', '地点4'],
    axisLabel: {
      color: '#FFFFFF'
    },
    axisTick: {
      show: false
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  grid: {
    top: 60,
    botttom: 0,
    left: 60,
  },
  series: [
    {
      name: '',// '消防',
      data: [],//[20, 22, 41, 33],
      type: 'line',
      lineStyle: {
        color: '#00FFD4',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#00FFD4"
      },
    },
    {
      name: '',// '巡护',
      data: [],// [8, 30, 30, 20],
      type: 'line',
      lineStyle: {
        color: '#FFA400',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FFA400"
      },
    }
  ]
}
