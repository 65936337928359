/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-06 18:15:21
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-29 20:27:25
 * @FilePath: \korlascreen\src\components\main-view2\top-btn3\bottom-btn4\asideLeft\leftTopItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE'],
  tooltip: {
    trigger: 'item'
  },
  legend: {
    left: '5%',
    top: '20%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 20
  },
  series: [
    {
      name: '名称',
      type: 'pie',
      radius: ['40%', '55%'],
      center: ['65%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        normal: {
          // formatter: '{d}%, {c} \n\n',
          formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
          padding: [0, -10],//取消hr线跟延长线之间的间隙
          rich: {
            a: {
              color: '#999',
              lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
              align: 'center'
            }
          }
        }
      },

      data: [
        // { value: 12, name: '名称1' },
        // { value: 14, name: '名称2' },
        // { value: 16, name: '名称3' },
        // { value: 12, name: '名称4' },
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
