<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-10-27 16:25:15
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
import axios from "axios";
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonData: [],
      cottonDataA: [],
      cottonDataB: [],
      cottonDataC: [],
      geoData: null,
      wmsLayer: null,
      valNumber: null
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonData()//棉蛋mark


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (this.wmsLayer) {
        this.map.removeLayer(this.wmsLayer);
      }
      if (val === '1') {
        this.getCottonData()//mark
      }

      if (val === '2') {
        this.getCottonDataA()//mark
      }

      if (val === '3') {
        this.getCottonDataB()//mark
      }

      if (val === '4') {
        this.getCottonDataC()//mark
      }

      if (val === '5') {
        this.getTile('HYDA')//mark
      }
    }
  },

  methods: {
    getCottonData () {
      _GetAspScreenComponentData('KELNEW-SL-KQH').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonData = arr1
          this.onCotton()
        }
      })
    },
    /*第一个 */
    onCotton () {
      var points = [];

      this.cottonData.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#c0be13", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getCottonDataA () {
      _GetAspScreenComponentData('KELNEW-SL-KDH').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataA = arr1
          this.onCottonA()
        }
      })
    },
    /*第一个 */
    onCottonA () {
      var points = [];

      this.cottonDataA.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#00b5fd", weight: 1, opacity: 0.9, fillColor: "#b8ecd9", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getCottonDataB () {
      _GetAspScreenComponentData('KELNEW-SL-TLMH').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataB = arr1
          this.onCottonB()
        }
      })
    },
    /*第一个 */
    onCottonB () {
      var points = [];

      this.cottonDataB.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#6a8cfb", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesC(e)
        });
      });
    },

    onLandMesC (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },


    getCottonDataC () {
      _GetAspScreenComponentData('KELNEW-SL-HYH').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataC = arr1
          this.onCottonC()
        }
      })
    },
    /*第一个 */
    onCottonC () {
      var points = [];

      this.cottonDataC.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#89ff82", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesD(e)
        });
      });
    },

    onLandMesD (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getTile(id) {
      console.log('123')
      
      if (this.wmsLayer) {
        this.map.removeLayer(this.wmsLayer);
      }
      var config = {
        REQUEST: "GetMap",//操作名称
        VERSION: "1.1.1",//请求服务的版本
        SERVICE: "WMS",//服务类型标识符
        LAYERS: `ASSP:${id}`,//用","分隔的多个图层列表
        TRANSPARENT: true,//输出图像背景是否透明
        FORMAT: "image/png",//输出图像的类型
        SRS: 'EPSG:4490',//地图投影类型
        WIDTH: 256,//输出地图图片的像素宽
        HEIGHT: 256//输出地图图片的像素高
      };
      //创建WMS图层对象
      this.wmsLayer = new this.T.TileLayer.WMS("http://geo.lztl.cn/ASSP/wms", config);
      //将WMS图层添加到地图上
      this.map.addLayer(this.wmsLayer);
      // let _this = this
      // _this.map.on("click", function (e) {
      //   const {
      //     lng,
      //     lat
      //   } = e.lnglat
      //   const min = [lng - 0.1, lat - 0.1]
      //   const max = [lng + 0.1, lat + 0.1]
      //   console.log(e)
        
      //   _this.map.centerAndZoom(new this.T.LngLat(lng, lat), 12)
      //   // 查询WMS要素属性
      //   axios.get(
      //     `/ASSP/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=ASSP%3A${id}&STYLES&LAYERS=ASSP%3A${id}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=50&Y=50&SRS=EPSG%3A4490&WIDTH=101&HEIGHT=101&BBOX=${min[0]}%2C${min[1]}%2C${max[0]}%2C${max[1]}`
      //   ).then(function (response) {
      //     _this.geoData = response.data
      //     e.landId = _this.geoData.features[0].properties.biz_id
      //     // _this.landpolygonClick(e)
      //   }).catch(function (error) {
      //     console.log(error);
      //   })
      // })
    },


    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 100%;
  height: 110%;
}
</style>
