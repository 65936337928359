<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 06:53:40
-->
<template>
  <div class="container">
    <div>
      <messagegather :gather-code="gatherCode"
                     @gatherShow="getLandGatherShow" />
    </div>

    <div>
      <messagetransport @transportShow="getTransportShow"
                        :id="transportId" />
    </div>

    <div>
      <messagesell @sellShow="getSellShow"
                   :id="sellId" />
    </div>
  </div>
</template>
<script>
import messagegather from './component/messagegather'//采收
import messagetransport from './component/messagetransport'//拉运
import messagesell from './component/messagesell'
export default {
  components: {
    messagegather,
    messagetransport,
    messagesell
  },

  props: {
    landParams: {
      type: Object,
      default: null
    }
  },
  name: 'CenterPopu',

  data () {
    return {
      gatherCode: '',//采收
      transportId: null,//拉运
      sellId: null//交售
    }
  },

  watch: {
    landParams (val) {
      if (val.type === 'gather') {
        this.gatherCode = val.id
      }

      if (val.type === 'transport') {
        this.transportId = val.id
      }

      if (val.type === 'sell') {
        this.sellId = val.id
      }
    },
  },

  methods: {
    getLandGatherShow (val) {
      this.gatherCode = val
    },

    getTransportShow (val) {
      this.transportId = val
    },

    getSellShow (val) {
      this.sellId = val
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
</style>
