// export default {
//   color: [{
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#5883FF' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#51BAFF' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }, {
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#00E5B4' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#AEFFE2' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }, {
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#AADE55' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#F9FFAE' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }],
//   legend: {
//     data: [],//['本地', '外地进口'],
//     textStyle: {
//       color: '#FFFFFF'
//     },
//     padding: [
//       20,  // 上
//       0, // 右
//       20,  // 下
//       0, // 左
//     ]
//   },
//   xAxis: {
//     type: 'category',
//     data: [],// ['饲料类型1', '饲料类型2', '饲料类型3', '饲料类型4'],
//     axisLabel: {
//       color: '#FFFFFF'
//     }
//   },
//   tooltip: {
//     trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
//     axisPointer: {// 坐标轴指示器，坐标轴触发有效
//       type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
//     },
//     formatter: '{b} : {c}吨',
//   },
//   yAxis: [{
//     type: 'value',
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     splitLine: {
//       lineStyle: {
//         color: '#4B4743',
//         type: 'dashed'
//       }
//     },
//   }, {
//     type: 'value',
//     splitLine: false,
//     show: false,
//   },
//   {
//     type: 'value',
//     splitLine: false,
//     show: false,
//   }
//   ],
//   grid: {
//     top: 60,
//     left: 50,
//     botttom: 0,
//   },
//   series: [
//     {
//       name: '',//'本地',
//       data: [],// [12, 35, 41, 33],
//       type: 'bar',
//       barWidth: '15',
//       itemStyle: {
//         shadowColor: "#545454",
//         shadowBlur: 6,
//         shadowOffsetX: 3,
//         shadowOffsetY: 1,
//       },
//     },
//     {
//       name: '',//'外地进口',
//       data: [],//[22, 45, 51, 13],
//       type: 'bar',
//       barWidth: '15',
//       yAxisIndex: 1,
//       itemStyle: {
//         shadowColor: "#545454",
//         shadowBlur: 6,
//         shadowOffsetX: 3,
//         shadowOffsetY: 1,
//       },
//     }
//   ]

// import option from "../leftBottomItem/option";

// }
const dataStyle = {
  normal: {
    label: {
      show: false,
    },
    labelLine: {
      show: false,
    },
    shadowBlur: 0,
    shadowColor: "#203665",
  },
};
const option = {
  // backgroundColor: "#20263f",
  series: [
    {
      name: "第一个圆环",
      type: "pie",
      clockWise: false,
      radius: [50, 55],
      itemStyle: dataStyle,
      hoverAnimation: false,
      center: ["30%", "25%"],
      data: [
        {
          value: 23,
          label: {
            normal: {
              rich: {
                a: {
                  color: "#3a7ad5",
                  align: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                },
                b: {
                  color: "#fff",
                  align: "center",
                  fontSize: 16,
                },
              },
              formatter: function (params) {
                return (
                  "{b|面积}\n\n" +
                  "{a|" +
                  params.value +
                  "万亩}"
                );
              },
              position: "center",
              show: true,
              textStyle: {
                fontSize: "14",
                fontWeight: "normal",
                color: "#fff",
              },
            },
          },
          itemStyle: {
            normal: {
              color: "#2c6cc4",
              shadowColor: "#2c6cc4",
              shadowBlur: 0,
            },
          },
        },
        {
          value: 75,
          name: "invisible",
          itemStyle: {
            normal: {
              color: "#24375c",
            },
            emphasis: {
              color: "#24375c",
            },
          },
        },
      ],
    },
    {
      name: "第二个圆环",
      type: "pie",
      clockWise: false,
      radius: [50, 55],
      itemStyle: dataStyle,
      hoverAnimation: false,
      center: ["30%", "73%"],
      data: [
        {
          value: 65,
          label: {
            normal: {
              rich: {
                a: {
                  color: "#d03e93",
                  align: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                },
                b: {
                  color: "#fff",
                  align: "center",
                  fontSize: 16,
                },
              },
              formatter: function (params) {
                return (
                  "{b|享受政策牧民数量}\n\n" +
                  "{a|" +
                  params.value +
                  "户}"
                );
              },
              position: "center",
              show: true,
              textStyle: {
                fontSize: "14",
                fontWeight: "normal",
                color: "#fff",
              },
            },
          },
          itemStyle: {
            normal: {
              color: "#ef45ac",
              shadowColor: "#ef45ac",
              shadowBlur: 0,
            },
          },
        },
        {
          value: 50,
          name: "invisible",
          itemStyle: {
            normal: {
              color: "#412a4e",
            },
            emphasis: {
              color: "#412a4e",
            },
          },
        },
      ],
    },
    {
      name: "第三个圆环",
      type: "pie",
      clockWise: false,
      radius: [50, 55],
      itemStyle: dataStyle,
      hoverAnimation: false,
      center: ["70%", "25%"],
      data: [
        {
          value: 138,
          label: {
            normal: {
              rich: {
                a: {
                  color: "#603dd0",
                  align: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                },
                b: {
                  color: "#fff",
                  align: "center",
                  fontSize: 16,
                },
              },
              formatter: function (params) {
                return (
                  "{b|补助资金}\n\n" +
                  "{a|" +
                  params.value +
                  "万元}" 
                );
              },
              position: "center",
              show: true,
              textStyle: {
                fontSize: "14",
                fontWeight: "normal",
                color: "#fff",
              },
            },
          },
          itemStyle: {
            normal: {
              color: "#613fd1",
              shadowColor: "#613fd1",
              shadowBlur: 0,
            },
          },
        },
        {
          value: 25,
          name: "invisible",
          itemStyle: {
            normal: {
              color: "#453284",
            },
            emphasis: {
              color: "#453284",
            },
          },
        },
      ],
    },
        {
      name: "第四个圆环",
      type: "pie",
      clockWise: false,
      radius: [50, 55],
      itemStyle: dataStyle,
      hoverAnimation: false,
      center: ["70%", "75%"],
      data: [
        {
          value: 6,
          label: {
            normal: {
              rich: {
                a: {
                  color: "#00FFFF",
                  align: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                },
                b: {
                  color: "#ffffff",
                  align: "center",
                  fontSize: 16,
                },
              },
              formatter: function (params) {
                return (
                  "{b|每年每亩补助}\n\n" +
                  "{a|" +
                  params.value +
                  "元}" 
                );
              },
              position: "center",
              show: true,
              textStyle: {
                fontSize: "14",
                fontWeight: "normal",
                color: "#20263f",
              },
            },
          },
          itemStyle: {
            normal: {
              color: "#20263f",
              shadowColor: "#20263f",
              shadowBlur: 0,
            },
          },
        },
        {
          value: 25,
          name: "invisible",
          itemStyle: {
            normal: {
              color: "#00FFFF",
            },
            emphasis: {
              color: "#00FFFF",
            },
          },
        },
      ],
    },
  ],
};
export default option