<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 17:03:55
-->
<template>
  <div style="height:100%">
    <div class="bubble"
         :style="{right: bubbleRight + '%'}">
      <bubbleSelect @radio="getRadio" />
    </div>

    <div class="container"
         flex="dir:top main:center">
      <div style="height:100%"
           flex="main:justify cross:center">
        <div class="left"
             v-show="openState">
          <aside-left :land-id="landId" />
        </div>
        <div class="center"
           flex="box:mean">
        <main-map :mark-state="markState"
                  @cottonClick="handleCottonClick" />
      </div>
      <div style="width:100%;height:100%; position: absolute; display: flex; justify-content: center;">
        <div style="width:60%;height:100%;"
             flex="dir:bottom">
          <div style="width:80%;height:30%;margin:0 auto;z-index: 999;">
            <farming-task :land-id="landId" />
          </div>
        </div>
      </div>
        
        <div class="right"
             v-show="openState">
          <aside-right :land-id="landId" />
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import asideRight from './asideRight'
import mainMap from './map/mainMap'
import bubbleSelect from './bubble-select'
import farmingTask from './farmingTask'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'
import FarmingTask from './farmingTask/index.vue'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    asideLeft,
    asideRight,
    mainMap,
    bubbleSelect,
    farmingTask,
    FarmingTask
  },
  data () {
    return {
      openTxt: '收起',
      openRight: 20,
      bubbleRight: 20,
      openState: true,
      btnState: 0,//当前按钮
      markState: '1',//当前地图点位
      title1: '',
      nums1: '',
      title2: '',
      nums2: '',
      title3: '',
      nums3: '',
      landId: null
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SY-DBSJ').then(res => {
        if (res) {
          const arr = []
          res.data.data.forEach(item => {
            arr.push(
              item.name_data,
              item.vaule_data
            )
          });
          this.title1 = arr[0]
          this.nums1 = arr[1]
          this.title2 = arr[2]
          this.nums2 = arr[3]
          this.title3 = arr[4]
          this.nums3 = arr[5]
        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    },

    /*获取当前radio */
    getRadio (val) {
      this.markState = val
    },

    onOpen () {
      this.openState = !this.openState
      if (this.openState) {
        this.openTxt = '收起'
        this.openRight = 20
        this.bubbleRight = 20
      }

      if (!this.openState) {
        this.openTxt = '展开'
        this.openRight = 1
        this.bubbleRight = 1
      }
    },
    handleCottonClick (e) {
      this.landId = Number(e)
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>
