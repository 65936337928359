<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 15:06:48
-->
<template>
  <div class="container box"
       flex="dir:top main:center">
    <div style="height:100%"
         flex="main:justify cross:center">
      <div class="left">
        <aside-left />
      </div>
      <div class="centerWrapper">
        <centerCenter />
      </div>
      <div class="right">
        <aside-right />
      </div>
    </div>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import centerCenter from './centerCenter'
import asideRight from './asideRight'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    asideLeft,
    centerCenter,
    asideRight,
  },
  data () {
    return {
      openTxt: '收起',
      openRight: 23,
      bubbleRight: 20,
      openState: true,
      btnState: 0,//当前按钮
      markState: '1',//当前地图点位
      title1: '',
      nums1: '',
      title2: '',
      nums2: '',
      title3: '',
      nums3: ''
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SY-DBSJ').then(res => {
        if (res) {
          const arr = []
          res.data.data.forEach(item => {
            arr.push(
              item.name_data,
              item.vaule_data
            )
          });
          this.title1 = arr[0]
          this.nums1 = arr[1]
          this.title2 = arr[2]
          this.nums2 = arr[3]
          this.title3 = arr[4]
          this.nums3 = arr[5]
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/style/index.scss";
.box {
  background: #000;
  height: 100%;

  .centerWrapper {
    height: 100%;
    flex: 1;
  }
}
</style>
