// /*
//  * @Author: hlc 1664800317@qq.com
//  * @Date: 2022-09-06 18:15:21
//  * @LastEditors: Pancras
//  * @LastEditTime: 2022-09-21 19:13:01
//  * @FilePath: \korlascreen\src\components\main-view2\top-btn1\bottom-btn3\asideRight\rightTopItem\option.js
//  * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
//  */
// export default {
//   color: [{
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#00E5B4' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#AEFFE2' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }, {
//     type: 'linear',
//     x: 0,
//     y: 0,
//     x2: 0,
//     y2: 1,
//     colorStops: [{
//       offset: 0, color: '#00E5B4' // 0% 处的颜色
//     }, {
//       offset: 1, color: '#AEFFE2' // 100% 处的颜色
//     }],
//     global: false // 缺省为 false
//   }],
//   legend: {
//     data: ['种植面积'],
//     textStyle: {
//       color: '#FFFFFF'
//     },
//     itemWidth: 26,
//     itemHeight: 6,
//     itemStyle: {
//       color: '#68FFD6'
//     },
//     padding: [
//       20,  // 上
//       0, // 右
//       20,  // 下
//       0, // 左
//     ],
//   },
//   yAxis: {
//     type: 'category',
//     data: [],//['地点1', '地点1', '地点1', '地点1', '地点1', '地点1'],
//     axisLabel: {
//       color: '#B1B6AE'
//     },
//     axisTick: {
//       show: false
//     },
//     axisLine: {
//       show: false
//     }
//   },
//   tooltip: {
//     trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
//     axisPointer: {// 坐标轴指示器，坐标轴触发有效
//       type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
//     }
//   },
//   xAxis: {
//     type: 'value',
//     axisLabel: {
//       color: '#FFFFFF'
//     },
//     splitLine: {
//       show: false
//     },
//     data: [],//['种植面积']
//   },
//   grid: {
//     top: 50,
//     botttom: 0,
//     left: 60,
//   },
//   series: [
//     {
//       name: '',//'种植面积',
//       data: [],// [58, 56, 22, 28, 39, 30],
//       type: 'bar',
//       barWidth: '6',
//       lineStyle: {
//         color: '#00FFD4',
//       },
//       label: {
//         show: true,
//         position: "right",
//         color: '#00E5B4'
//       },
//       showBackground: true,
//       backgroundStyle: {
//         color: '#112817',
//       },
//       itemStyle: {
//         normal: {
//           barBorderRadius: [0, 3, 3, 0]
//         }
//       },
//     }
//   ]
// }
// const option = {
//     title: {
//         text: '',
//         left: 'center',
//         top: 20,
//         textStyle: {
//             color: '#ccc'
//         }
//     },
 
//     tooltip : {
//         trigger: 'item',
//         formatter: "{a} <br/>{b} : {c} ({d}%)"
//     },
 
//     visualMap: {
//         show: false,
//         min: 80,
//         max: 600,
//         inRange: {
//             colorLightness: [0, 1]
//         }
//     },
//     series : [
//         {
//             name:'面积',
//             type:'pie',
//             radius : '50%',
//             center: ['50%', '50%'],
//             data:[
//                 {value:667, name:'新疆库尔勒香梨股份有限公司'},
//                 {value:93, name:'库尔勒美旭香梨农民专业合作社'},
//                 {value:333, name:'库尔勒睿坤香梨专业合作社'},
//                 {value:333, name:'库尔勒硕种植农民专业合作社'},
//                 {value:333, name:'库尔勒名城香梨专业合作社'}
//             ],
//             roseType: 'radius',
//             label: {
//                 normal: {
//                     textStyle: {
//                         color: '#ffffff'
//                     }
//                 }
//             },
//             labelLine: {
//                 normal: {
//                     lineStyle: {
//                         color: 'rgba(255, 255, 255, 0.3)'
//                     },
//                     smooth: 0.2,
//                     length: 10,
//                     length2: 20
//                 }
//             },
//             itemStyle: {
//                 normal: {
//                     color: '#c23531',
//                     shadowBlur: 200,
//                     shadowColor: 'rgba(0, 0, 0, 0.5)'
//                 }
//             },
 
//             animationType: 'scale',
//             animationEasing: 'elasticOut',
//             animationDelay: function (idx) {
//                 return Math.random() * 200;
//             }
//         }
//     ]
// };
//                 {value:667, name:'新疆库尔勒香梨股份有限公司'},
//                 {value:93, name:'库尔勒美旭香梨农民专业合作社'},
//                 {value:333, name:'库尔勒睿坤香梨专业合作社'},
//                 {value:333, name:'库尔勒硕种植农民专业合作社'},
//                 {value:333, name:'库尔勒名城香梨专业合作社'}
let countValue = 1759;
let amountValue = 6450;
const option = {
  legend: {
    x: "center",
    textStyle: {
      color: ["#fff"],
    },
    icon: "circle", // 图例的形状
    // 环图之间间隔，图例防抖动
    inactiveBorderWidth: 0,
    itemStyle: {
      borderWidth: 0,
    },
  },
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  title: [
    {
      left: "75%",
      top: "45%",
      textAlign: "center",
      text: "{a|" + amountValue + "}{c|}",
      textStyle: {
        fontSize: 14,
        rich: {
          a: {
            fontSize: 30,
            color: "#fff",
          },
          c: {
            fontSize: 10,
            color: "#fff",
          },
        },
      },
      subtext: "株(百株)",
      subtextStyle: {
        fontSize: 15,
        color: ["#fff"],
      },
    },
    {
      left: "25%",
      top: "45%",
      textAlign: "center",
      text: "{a|" + countValue + "}{c|}",
      textStyle: {
        fontSize: 14,
        rich: {
          a: {
            fontSize: 30,
            color: "#fff",
          },
          c: {
            fontSize: 10,
            color: "#fff",
          },
        },
      },
      subtext: "面积(公顷)",
      subtextStyle: {
        fontSize: 15,
        color: ["#535353"],
      },
    },
  ],
  series: [

    {
      name: "面积",
      type: "pie",
      radius: ["50%", "45%"],
      center: ["75%", "50%"],
      data: [
        {
          value: 2500,
          name: "新疆库尔勒香梨股份有限公司",
        },
        {
          value: 350,
          name: "库尔勒美旭香梨农民专业合作社",
        },
        {
          value: 1200,
          name: "库尔勒睿坤香梨专业合作社",
        },
        {
          value: 1200,
          name: "库尔勒硕种植农民专业合作社",
        },
        {
          value: 1200,
          name: "库尔勒名城香梨专业合作社",
        },
       
      ],
      label: {
        normal: {
          show: false,
        },
      },
      // 环图之间间隔
      itemStyle: {
        normal: {
          borderWidth: 2,
          borderColor: "#fff",
        },
    	},
    },
    {
      name: "面积",
      type: "pie",
      radius: ["50%", "45%"],
      center: ["25%", "50%"],
      data: [
        {
          value: 667,
          name: "",
        },
        {
          value: 93,
          name: "",
        },
        {
          value: 333,
          name: "",
        },
        {
          value: 333,
          name: "",
        },
        {
          value: 333,
          name: "",
        },
        
      ],
      label: {
        normal: {
          show: false,
        },
      },
      // 环图之间间隔
      itemStyle: {
        normal: {
          borderWidth: 2,
          borderColor: "#fff",
        },
    	},
    },
  ],
  color: [
    "#D4DEEC",
    "#006AB2",
    "#15D2D6",
    "#61D4AD",
    "#FEC054",
    "#44A0CF",
  ],
};
export default option
