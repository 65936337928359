/*
 * @Description: 用户登陆接口
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: Pancras
 * @LastEditTime: 2022-10-27 21:25:22
 */
import request from '@/libs/axios'
import setting from '@/setting'
import QS from 'qs'

const SCOPE = 'server'
const TENANT_ID = setting.server.tenantId
const AUTHORIZATION = setting.server.authorization

export function _LoginByAccount (data) {
  const grantType = 'password'
  let dataObj = QS.stringify({ 'username': data.username, 'password': data.password })
  return request({
    url: '/auth/oauth/token',
    headers: {
      'Authorization': AUTHORIZATION
    },
    method: 'post',
    params: { randomStr: data.randomStr, code: data.code, grant_type: grantType },
    data: dataObj
  })
}

export function _Logout () {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

export function _GetUserInfo () {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

export function _RefreshToken (refreshToken) {
  const grantType = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'post',
    params: { refresh_token: refreshToken, grant_type: grantType, scope: SCOPE }
  })
}