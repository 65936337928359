/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: hlc 1664800317@qq.com
 * @LastEditTime: 2022-09-14 23:54:58
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  rowNum: 6,
  columnWidth: [130, 110, 110],
  header:[],// ['所属地区', '数据1', '数据2'],
  headerBGC: 'transparent',
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  data: [
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
    // ['单位名称', '名称', '2022-10-22'],
  ]
  }