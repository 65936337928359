<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-09-30 11:44:19
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:05:32
-->
<template>
  <div class="center-bottom-container"
       flex="box:mean">
    <div class="left-box">
      <center-left />
    </div>
    <div class="right-box">
      <center-right />
    </div>
  </div>
</template>

<script>
import centerLeft from './components/centerLeftItem'
import centerRight from './components/centerRightItem'
export default {
  name: 'CenterBottomItem',
  components: {
    centerLeft,
    centerRight
  },
  data () {
    return {
    }
  },

  mounted () {

  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.center-bottom-container {
  padding: 2px 24px 0;
  box-sizing: border-box;
  height: 100%;

  .left-box {
    height: 90%;
  }

  .right-box {
    height: 90%;
  }
}
</style>