const calc = (number)  => {
  return number ? (number * 0.01).toFixed(2).replace(/(\.\d*?)0+$/, '$1') : 0;
}

const option1 = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
    }
  },
  legend: {
    textStyle: {
      color: 'white'
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: 'white',
    }
  },
  yAxis: {
    type: 'category',
    data: ['铁克其乡', '英下乡', '恰尔巴格乡', '上户镇', '和什力克乡', '托布力其乡', '兰干乡', '阿瓦提乡',
          '哈拉玉宫乡', '普惠乡'],
    axisLabel: {
      color: 'white',
    }
  },
  series: [
    {
      name: '苹果',
      type: 'bar',
      stack: 'total',
      label: {
        show: false,
        formatter: (number) => number ? (number * 0.01).toFixed(2).replace(/(\.\d*?)0+$/, '$1') : 0
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: '梨',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: 'white'
      },
      emphasis: {
        focus: 'series'
      },
      data: [5007.7, 13540.36, 10331.46, 55825.79, 40634.65, 59830.68, 23905.75, 106804.35, 46733.1, 2239.15]
    },
    {
      name: '葡萄',
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [75, 148, 0, 0, 13, 0, 800, 180, 70, 0]
    },
    {
      name: '桃',
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 20, 0, 0, 0, 0, 0, 95, 72, 0]
    },
    {
      name: '杏',
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 2, 0, 0, 0, 0, 0, 21, 23, 0]
    },
    {
      name: '红枣',
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [16, 0, 0, 0, 60, 125, 12, 255, 80, 0]
    },
    {
      name: '石榴',
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: '其他',
      type: 'bar',
      stack: 'total',
      label: {
        show: false,
      },
      emphasis: {
        focus: 'series'
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ]
};

const option = {
  title: {
    text: '单位: 吨',
    left: 'center',
    textStyle: {
      color: 'white'
    },
  },
  grid: {
    left: '1%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      color: 'white',
    },
    splitLine: {
      lineStyle: {
        color: '#E8E8E8',
        type: 'dashed'
      }
    }
  },
  yAxis: {
    type: 'category',
    data: ['铁克其乡', '英下乡', '恰尔巴格乡', '上户镇', '和什力克乡', '托布力其乡', '兰干乡', '阿瓦提乡',
          '哈拉玉宫乡', '普惠乡'],
    axisLabel: {
      color: 'white',
    },
  },
  series: [
    {
      itemStyle: {
        color: '#FFCE34'
      },
      data: [4103.29, 18964.63, 8384.54, 33336.03, 25889.37, 26150.38, 7544.58, 86091.70, 17026.66, 146.04],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      },
      label: {
        show: true,
        position: 'right',
        textStyle: {
          color: 'white'
        }
      }
    }
  ]
};

export default option