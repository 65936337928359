<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-02 11:36:44
-->
<template>
  <div class="topcontainer"
       flex="main:justify">
    <div class="topTitle">
      <!-- 库尔勒市智慧农业服务平台 -->
    </div>
    <div class="topright"
         flex="main:right cross:center">
      <!-- <div class="weather">
        <img src="@/assets/images/weather.png">
      </div> -->
      <div class="weather"
           flex="cross:center"
           v-if="weatherHourInfo">
        <img :src="weatherHourInfo.day_weather_pic">
        <span>{{ weatherHourInfo.night_weather }}</span>
        <span>{{ weatherHourInfo.day_air_temperature }}℃</span>
      </div>
      <!-- <div class="homepage">
        <img src="@/assets/images/homepage.png">
      </div> -->
      <div class="more"
           @click="onDrawer">
        <img src="@/assets/images/more.png">
      </div>
      <div class="qrcode"
           @click="toggleScreenFll">
        <img src="@/assets/images/qrcode.png"
             alt="全屏切换"
             title="全屏切换">
      </div>

      <div style="height:100%;main-width:140px;margin:0 20px 0 30px"
           flex="main:justify cross:center">
        <div class="head">
          <img v-if="info&&info.avatar"
               :src="baseUrl + info.avatar"
               alt=""
               srcset="">
          <img v-else
               src="~@/assets/images/user.png"
               alt=""
               srcset="">
        </div>
        <div class="userName">
          {{ info&&info.username }}
        </div>
      </div>
      <div class="outImg"
           @click="handleLogout">
        <img src="~@/assets/images/login/logout.png">
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/libs/util'
import axios from "axios";
import { _Logout } from '@/api/sys.login'
export default {
  name: 'MainTop',
  props: {
    info: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      baseUrl: 'https://api.lztl.cn',
      weatherHourInfo: null,
    }
  },
  mounted () {
    this.getWeather()
  },
  methods: {
    /*打开抽屉 */
    onDrawer () {
      this.$emit('drawer', true)
    },

    /**
 * 退出
 */
    handleLogout () {
      this.$confirm('确定退出登录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'none'
      }).then(() => {
        // 后端退出后,前台退出
        _Logout().then(() => {
          // 清空用户 session
          util.remove('token')
          util.remove('info')
          util.remove('roles')
          util.remove('permissions')
          // 清空 vuex 用户信息
          this.$store.dispatch('user/setInfo', {}, { root: true })
          // 清空 vuex 用户角色信息
          this.$store.dispatch('user/setRoles', {}, { root: true })
          // 清空 vuex 用户权限信息
          this.$store.dispatch('user/setPermissions', {}, { root: true })
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          location.reload()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    /*天气*/
    getWeather () {
      let params = {
        area: '库尔勒市',
      };
      //发送get请求
      axios
        .get("http://weather01.market.alicloudapi.com/area-to-weather-date", {
          params: params,
          //请求头认证
          headers: {
            Authorization: "APPCODE a1b505ad9b8742c198185705f021512e",
          },
        })
        .then((res) => {
          this.weatherHourInfo = res.data.showapi_res_body.f1;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 全屏
    toggleScreenFll () {
      this.$emit('toggleScreenFll')
    }
  },

}
</script>
<style scoped lang="scss">
.topcontainer {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/view0Top2.png");
  background-position: center center;
  background-repeat: no-repeat;

  .topTitle {
    margin-left: calc(100vw * 30 / 1920);
    font-size: calc(100vw * 22 / 1920);
    display: flex;
  }

  .topright {
    min-width: 300px;
    height: 100%;
    margin-right: 25px;
    .weather {
      cursor: pointer;
      width: 60%;
      height: 50%;
      margin-right: 50px;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        margin-left: 15px;
        font-size: 0.08rem;
        color: #56acff;
      }
    }
    .more {
      cursor: pointer;
      width: 10%;
      height: 40%;
      margin-right: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .qrcode {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }

    .head {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1px solid #56acff;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .userName {
      font-size: calc(100vw * 20 / 1920);
      color: #56acff;
      margin-left: 6px;
    }

    .outImg {
      cursor: pointer;
      width: 12%;
      height: 40%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
