<template>
  <div class="left-top-container" flex="dir:top cross:center">
    <div class="card-head" flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body" flex="dir:top">
      <dv-scroll-board :config="option" style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      // config: {
      //   rowNum: 6,
      //   columnWidth: [120, 110, 110, 110],
      //   header: ['水源类型', '采集量', '采集区域', '水源情况'],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //   ]
      // }
    }
  },

  watch: {
    region(val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted() {
    this.getData()
    this.getSreenData()
  },

  methods: {
    getSreenData() {
      // _GetAspScreenComponentData('NEWKEL-SL-DBSSJCJ').then(res => {
      //   if (res) {
      //     console.log('res', res)

      //     const msgData = []
      //     res.data.data.forEach(item => {
      //       msgData.push(
      //         [item.msg_one,
      //         item.msg_two,
      //         item.msg_three,
      //         item.msg_four]
      //       )
      //     });
      //     this.title = res.data.form.name
      //     _GetAspScreenComponentData('NEWKEL-SL-DBSSJCJBT').then(res => {
      //       if (res) {
      //         console.log('res2', res)

      //         const msgData2 = []
      //         res.data.data.forEach(item => {
      //           msgData2.push(
      //             [item.msg_one,
      //             item.msg_two,
      //             item.msg_three,
      //             item.msg_four]
      //           )
      //         });
      //         let params = {
      //           rowNum: 6,
      //           columnWidth: [120, 110, 110, 110],
      //           align: ['center', 'center', 'center', 'center'],
      //           header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
      //           headerBGC: 'transparent',
      //           oddRowBGC: 'transparent',
      //           evenRowBGC: 'transparent',
      //           data: msgData,
      //         }

      //         this.option = params
      //       }
      //     })
      //   }
      // })
      this.title = '农村饮水水价标准'
      this.option = {
        rowNum: 5,
        columnWidth: [120, 60, 60, 100, 60],
        align: ['center', 'center', 'center', 'center', 'center'],
        header: ['工程名称', "<div class='header-item' title='全成本水价(元/m³)'>全成本水价(元/m³)</div>", "<div class='header-item' title='运行成本水价(元/m³)'>运行成本水价(元/m³)</div>", '计费方式', "<div class='header-item' title='居民生活执行水价(元/m³)'>居民生活执行水价(元/m³)</div>"],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        data: [
          ["<div class='ceil' title='库尔勒市英下乡农村饮水安全工程'>库尔勒市英下乡农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔勒市托和联网农村饮水安全工程'>库尔勒市托和联网农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='包头湖农场农村饮水安全巩固提升工程'>包头湖农场农村饮水安全巩固提升工程</div>", '5.42', '2.35', '固定收费', '1.8'],
          ["<div class='ceil' title='库尔勒市阿瓦提乡农村饮水管道联网工程'>库尔勒市阿瓦提乡农村饮水管道联网工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔勒市Ⅰ区农村饮水安全工程'>库尔勒市Ⅰ区农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔勒市兰干乡农村饮水安全工程'>库尔勒市兰干乡农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='上户镇农村饮水安全工程'>上户镇农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='普惠地区农村饮永安全管网更新改造工程(2016年PVC更换PE工程)'>普惠地区农村饮永安全管网更新改造工程(2016年PVC更换PE工程)</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔勒市恰尔巴格乡喀拉墩村农村饮水安全工程'>库尔勒市恰尔巴格乡喀拉墩村农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔勒市Ⅱ区农村饮水安全工程'>库尔勒市Ⅱ区农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.8'],
          ["<div class='ceil' title='库尔楚园艺场农村饮水安全工程'>库尔楚园艺场农村饮水安全工程</div>", '5.42', '2.35', '计量收费', '1.3'],
          ["<div class='ceil' title='库尔勒市城市管网延伸工程'>库尔勒市城市管网延伸工程</div>", '5.42', '2.35', '计量收费', "<div class='ceil' title='2.53(A≤12m³/月)3.80(12<A≤24m³/月)5.06(A≥24m³/月)'>2.53(A≤12m³/月)3.80(12<A≤24m³/月)5.06(A≥24m³/月)</div>"],
        ],
      }
    },
    getData() {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>