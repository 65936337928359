<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 20:04:27
-->
<template>
  <div style="height:100%">
    <div class="bubble"
         :style="{right: bubbleRight + '%'}">
      <bubbleSelect @radio="getRadio" />
    </div>

    <div class="openAndClose">
      <div class="box"
           :style="{right: openRight + '%'}"
           @click="onOpen">
        <img src="@/assets/images/close.png">
        <div class="txt">
          {{ openTxt }}
        </div>
      </div>
    </div>

    <div class="container"
         flex="dir:top main:center">
      <div style="height:100%"
           flex="main:justify cross:center">
        <div class="left"
             v-show="openState">
          <aside-left />
        </div>
        <div class="center"
             flex="box:mean">
          <main-map :mark-state="markState" />
        </div>
        <div class="right"
             v-show="openState">
          <aside-right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import asideRight from './asideRight'
import mainMap from './map/mainMap'
import bubbleSelect from './bubble-select'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    asideLeft,
    asideRight,
    mainMap,
    bubbleSelect
  },
  data () {
    return {
      openTxt: '收起',
      openRight: 20,
      bubbleRight: 20,
      openState: true,
      btnState: 0,//当前按钮
      markState: '1',//当前地图点位
      title1: '',
      nums1: '',
      title2: '',
      nums2: '',
      title3: '',
      nums3: '',
      screenHeight: document.body.clientHeight, // 屏幕高度
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SY-DBSJ').then(res => {
        if (res) {
          const arr = []
          res.data.data.forEach(item => {
            arr.push(
              item.name_data,
              item.vaule_data
            )
          });
          this.title1 = arr[0]
          this.nums1 = arr[1]
          this.title2 = arr[2]
          this.nums2 = arr[3]
          this.title3 = arr[4]
          this.nums3 = arr[5]
        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    },

    /*获取当前radio */
    getRadio (val) {
      this.markState = val
    },

    onOpen () {
      this.openState = !this.openState
      if (this.openState) {
        this.openTxt = '收起'
        this.openRight = 20
        this.bubbleRight = 20
      }

      if (!this.openState) {
        this.openTxt = '展开'
        this.openRight = 15
        this.bubbleRight = 15
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>

<style scoped lang="scss">
.botomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;

  .btn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #82ffe6;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;

    img {
      margin-right: 10px;
    }
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;

    img {
      margin-right: 10px;
    }
  }
}
</style>
