/*
 * @Description: 中上 畜牧场等点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-08 19:13:01
 */
export default {
  radioMonths: [
    { month: '5月', content: '', size: 'large' },
    { month: '6月', content: '', size: 'large' },
    { month: '7月', content: '', size: 'large' },
    { month: '8月', content: '', size: 'large' },
    { month: '9月', content: '', size: 'large' }
  ],
  radioLnglat: [
    'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/xiangli5shang.png',
    'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/xiangli6zhong.png',
    'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/xiangli7zhong.png',
    'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/xiangli8xia.png',
    'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/xiangli9zhong.png'
  ]
}
