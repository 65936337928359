<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-23 00:08:31
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 11:31:38
-->
<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="txt">
        单位：个
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './option'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',

  data () {
    return {
      option,
      title: '',
      myChart: null,
      data: {},//数据,
      zoom: 1
    }
  },

  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-NY-QSNYTTSL').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              {
                value: item.vaule_data,
                name: item.name_data
              }
            )
          });

          this.option.series[0].data = msgData
          this.title = res.data.form.name

          this.initChart()
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
      let sizeFun = function () {
        this.myChart.resize()
      }
      window.addEventListener('resize', sizeFun)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>