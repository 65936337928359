/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 19:07:45
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 3, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['年份', '棉花农业保险', '路探险', '人员险'],
  columnWidth: [60, 120, 90, 90],
  align: ['center', 'center', 'center', 'center'],
  data: [
    ['2021', '54.33万亩', '81.48万亩', '54.33万亩'],
    ['2020', '50.48万亩', '78.20万亩', '50.48万亩'],
    ['2019', '44.45万亩', '90.48万亩', '44.45万亩'],
    ['2018', '44.45万亩', '90.48万亩', '44.45万亩']
  ]
}
