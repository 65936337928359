<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 10:19:24
-->
<template>
  <div id="app">
    <ScaleBox :screen-full="screenFull">
      <div style="height: 100%;">
        <!-- 默认首页 -->
        <mainView0 @drawer="getDrawerState"
                   :info="info"
                   v-if="isLogin&&btnState === -1"
                   @toggleScreenFll="screenFull = !screenFull" />
        <div v-if="isLogin"
             style="height: 100%"
             flex="dir:top box:first">
          <!-- 头部 -->
          <div v-show="btnState != -1"
               style="height:7%;z-index: 999;background: linear-gradient(360deg, rgba(0,213,255,0.1) -50%, #000000 100%);">
            <main-top @drawer="getDrawerState"
                      :drawer-state="drawer"
                      :btn-state="btnState"
                      @top4Index="getTop4Index"
                      :top-index="topNavIndex"
                      :top4-index="top4Index"
                      @navIndex="getNavIndex"
                      :info="info"
                      :roles="roles"
                      @toggleScreenFll="screenFull = !screenFull" />
          </div>
          <div flex="dir:top"
               style="position: relative;">
            <div style="height: 90%;margin:-20px 0 0 0;">
              <!-- 农业 -->
              <mainView1 :top-index="topNavIndex"
                         @topIndex="getTopIndex"
                         :top4-index="top4Index"
                         :bottom-index="bottomState"
                         v-if="btnState===0"
                         :show-sub-menu="showSubMenu"
                         @change="subMenuChange" />
              <!-- 林业 -->
              <mainView2 :top-index="topNavIndex"
                         @topIndex="getTopIndex"
                         :top4-index="top4Index"
                         :bottom-index="bottomState"
                         v-if="btnState===1"
                         :show-sub-menu="showSubMenu"
                         @change="subMenuChange" />

              <!-- 水利 -->
              <mainView3 :top-index="topNavIndex"
                         @topIndex="getTopIndex"
                         :top4-index="top4Index"
                         :bottom-index="bottomState"
                         v-if="btnState===2"
                         :show-sub-menu="showSubMenu"
                         @change="subMenuChange" />
              <!-- 畜牧 -->
              <mainView4 :top-index="topNavIndex"
                         @topIndex="getTopIndex"
                         :top4-index="top4Index"
                         :bottom-index="bottomState"
                         v-if="btnState===3"
                         :show-sub-menu="showSubMenu"
                         @change="subMenuChange" />
            </div>
          </div>
        </div>
      </div>

      <!-- 侧边栏 -->
      <leftDeawer :drawer="drawer"
                  @index="getV1Index"
                  @params="getParams"
                  @drawer="getDrawerState"
                  :roles="roles" />
    </ScaleBox>
    <!-- 登录 -->
    <login-item v-if="!isLogin"
                @login="handleLogin" />
  </div>
</template>

<script>
import ScaleBox from '@/components/SacleBox'
import DevicePixelRatio from '@/libs/devicePixelRatio'
import loginItem from '@/components/mainView/loginItem'//登录
import mainTop from '@/components/mainView/mainTop'//头部
import leftDeawer from '@/components/mainView/leftDrawer.vue'//侧边栏
import mainView0 from '@/components/main-view0'//默认首页
import mainView1 from '@/components/main-view1' // 农业
import mainView2 from '@/components/main-view2'//林业
import mainView3 from '@/components/main-view3'//水利
import mainView4 from '@/components/main-view4'//畜牧
import { _GetAspScreenMenu } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'

export default {
  name: 'App',
  components: {
    ScaleBox,
    loginItem,
    mainTop,
    leftDeawer,
    mainView0,
    mainView1,
    mainView2,
    mainView3,
    mainView4,
  },
  data () {
    return {
      isLogin: false,//登录状态
      drawer: false,//左侧抽屉状态
      btnList: [],//一级页面底部按钮数据
      btnState: -1,//一级页面底部按钮索引
      bottomState: 0,//二级页面底部按钮索引
      topNavIndex: 0,//二级页面头部按钮索引
      top4Index: 0,//智能化决策索引
      info: null, // 用户基本信息
      roles: null, // 用户角色信息
      showSubMenu: true, // 是否显示二级子菜单
      screenFull: false
    }
  },

  created () {
    new DevicePixelRatio().init()
  },

  mounted () {
    // 检测用户是否登录
    this.$store.dispatch('user/load').then(res => {
      if (!util.isNull(res)) {
        // 已登录
        this.info = res
        this.isLogin = true
        this.getScreenData()
        this.getUserRoles()
      }
    })
  },
  methods: {
    // 获取用户角色信息
    /**
     * roles 161 - 库尔勒
     * roles 162 - 库尔勒农业
     * roles 163 - 库尔勒水业
     * roles 164 - 库尔勒林业
     * roles 165 - 库尔勒牧业
     */
    getUserRoles () {
      this.$store.dispatch('user/roles').then(res => {
        this.roles = res
        // 农业
        if (this.roles.includes(162)) {
          this.btnState = 0
        }
        // 水业
        if (this.roles.includes(163)) {
          this.btnState = 2
        }
        // 林业
        if (this.roles.includes(164)) {
          this.btnState = 1
        }
        // 牧业
        if (this.roles.includes(165)) {
          this.btnState = 3
        }
      })
    },
    //获取大屏表单信息
    getScreenData () {
      _GetAspScreenMenu('NEWKELXL_SCREEN').then(res => {
        if (res) {
          res.data.forEach((item, index) => {
            if (index > 0) {
              this.btnList.push(item.name)
            }
          });
        }
      })
    },
    debounce (fn, delay) {
      const delays = delay || 500;
      let timer;
      return function () {
        const th = this;
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },

    // 获取当前点击的顶部菜单序号
    getNavIndex (index) {
      this.topNavIndex = index
      this.bottomState = 0
      if (index === -1) {
        this.btnState = index
        this.topNavIndex = 0
      }
      this.showSubMenu = true
    },

    /*获取抽屉状态 */
    getDrawerState (val) {
      this.drawer = val
    },

    /*获取抽屉下标 */
    getParams (val) {
      if (this.checkRole(val.btnIndex)) {
        this.btnState = val.btnIndex
        this.topNavIndex = val.topIndex
        this.bottomState = val.bottomIndex
      }
    },

    /*点击底部二级标题 */
    onBtn (state) {
      if (this.checkRole(state)) {
        this.btnState = state
        this.bottomState = 0
        this.top4Index = 0
      }
    },
    // 鉴权
    checkRole (btnIndex) {
      // 农业
      if (this.roles.includes(162) && btnIndex !== 0) {
        this.$message.error('无权限访问')
        return false
      }
      // 水业
      if (this.roles.includes(163) && btnIndex !== 2) {
        this.$message.error('无权限访问')
        return false
      }
      // 林业
      if (this.roles.includes(164) && btnIndex !== 1) {
        this.$message.error('无权限访问')
        return false
      }
      // 牧业
      if (this.roles.includes(165) && btnIndex !== 3) {
        this.$message.error('无权限访问')
        return false
      }
      return true
    },
    /*登录 */
    handleLogin () {
      this.isLogin = true
      location.reload()
    },

    /*就收二级页面top按钮索引 */
    getTopIndex (val) {
      this.topNavIndex = val
    },

    /*智能化决策按钮索引 */
    getTop4Index (val) {
      this.top4Index = val
      console.log(this.top4Index)
    },

    /*就收一级页面top按钮索引 */
    getV1Index (val) {
      if (this.checkRole(val)) {
        this.topNavIndex = 0
        this.btnState = val
      }
    },
    subMenuChange (state) {
      this.showSubMenu = state
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/style/index.scss";

.drawerBox {
  width: 260px;
}
</style>
