<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        2023年林果产值情况
        <!-- {{ title }}111 -->
      </div>
    </div>
    <div class="txt" style="color: #ffffff;font-size:15px;">
      单位:亩
    </div>
    <div class="card-body"
         flex>
      <div class="chart"
           ref="chart"
           style="height:220px;"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      zoom:'1',
      params: {
        region: '652928'
      },
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      // this.getData()
    }
  },

  mounted () {
    
    this.getData()
    this.initChart()

    // this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-LY-ZNXHLB').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one,
              item.msg_two,
              item.msg_three,
              ]
            )
          });
          this.option.data = msgData
          this.title = res.data.form.name
          _GetAspScreenComponentData('NEWKEL-LY-ZNXHLBBT').then(res => {
            if (res) {
              const msgData2 = []
              res.data.data.forEach(item => {
                msgData2.push(
                  [item.msg_one,
                  item.msg_two,
                  item.msg_three,
                  ]
                )
              });

              let params = {
                rowNum: 6,
                columnWidth: [130, 110, 110],
                header: msgData2[0],//['所属地区', '数据1', '数据2'],
                headerBGC: 'transparent',
                oddRowBGC: 'transparent',
                evenRowBGC: 'transparent',
                data: msgData,
              }

              this.option = params
            }
          })
        }
      })

    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>