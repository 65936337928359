<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-10 18:55:35
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      plantThreeData: [],//棉花
      plantThreeDataA: [],//小麦
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getThreeData()//mark
      }

      if (val === '2') {
        this.getThreeDatA()//mark
      }

    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getThreeData()//mark

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  methods: {
    //棉花
    getThreeData () {
      _GetAspScreenComponentData('NEWKEL-DT-MH').then(res => {
        if (res) {
          const arr3 = []
          res.data.data.forEach(item => {
            arr3.push({
              title: item.data_title,
              value: item.data_value,
              landId: item.data_dkid,
              geoInfo: [
                { lng: Number(item.data_lng), lat: Number(item.data_lat) },
                { lng: Number(item.data_lngone), lat: Number(item.data_latone) },
                { lng: Number(item.data_lngtwo), lat: Number(item.data_latbtwo) },
                { lng: Number(item.data_lngthree), lat: Number(item.data_latthree) }
              ]
            })
          })
          this.plantThreeData = arr3
          this.$emit('cottonClick', this.plantThreeData[0].landId)
          this.onPlantthree()
        }
      })
    },
    onPlantthree () {
      var points = [];

      this.plantThreeData.forEach(item => {
        const pointsData = []
        console.log(item)
        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#00b5fd", weight: 1, opacity: 0.9, fillColor: "#b8ecd9", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          _this.$emit('cottonClick', item.landId)
          e.conent = item
          _this.onLandMesB(e)
        });
      });
    },

    onLandMesB (e) {
      let latlng = util.getPolygonCenter(e.conent.geoInfo)
      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div class='pop-container' style='width: 100%;color:#fff;'>" +
        "<div class='top'></div>" +
        "<div class='content'>" +
        "<div style='margin: 15px auto;width: 95%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 90%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

        "<div style='margin: 19px auto;width: 90%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +
        "</div>" +
        "</div>" +
        "<div class='bottom'></div>" +
        "</div>"

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },

    //小麦
    getThreeDatA () {
      _GetAspScreenComponentData('NEWKEL-DT-XM').then(res => {
        if (res) {
          const arr3 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr3.push({
              title: item.data_title,
              value: item.data_value,
              landId: item.data_dkid,
              geoInfo: [
                { lng: Number(item.data_lng), lat: Number(item.data_lat) },
                { lng: Number(item.data_lngone), lat: Number(item.data_latone) },
                { lng: Number(item.data_lngtwo), lat: Number(item.data_latbtwo) },
                { lng: Number(item.data_lngthree), lat: Number(item.data_latthree) }
              ]
            })
          })
          this.plantThreeDataA = arr3
          this.onPlantthreeA()
        }
      })
    },
    onPlantthreeA () {
      var points = [];

      this.plantThreeDataA.forEach(item => {
        const pointsData = []
        console.log(item)
        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#6a8cfb", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          _this.$emit('cottonClick', item.landId)
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {
      let latlng = util.getPolygonCenter(e.conent.geoInfo)
      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div class='pop-container' style='width: 100%;color:#fff;'>" +
        "<div class='top'></div>" +
        "<div class='content'>" +

        "<div style='margin: 15px auto;width: 95%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 0 auto;width: 90%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +

        "<div style='margin: 19px auto;width: 90%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +
        "</div>" +
        "</div>" +
        "<div class='bottom'></div>" +
        "</div>"

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
      console.log(point);
    },


    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style scoped lang="scss">
@import url("~@/assets/style/map.scss");
:deep(#mapDiv) {
  .tdt-infowindow-content-wrapper {
    background: none;
    box-shadow: none;
    border: none;
    backdrop-filter: none;
    .pop-container {
      .top {
        width: 100%;
        height: 23px;
        background-image: url("@/assets/images/pop-bg.png");
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .content {
        border-left: 2px solid #2c9af1;
        border-right: 2px solid #2c9af1;
        background-color: rgba($color: #000000, $alpha: 0.5);
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
      }

      .bottom {
        width: 100%;
        height: 20px;
        background-image: url("@/assets/images/pop-bg.png");
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-size: 100%;
      }
    }
  }

  .tdt-infowindow-tip {
    display: none;
  }

  #mapDiv {
    height: 110%;
    width: 100%;
  }
}
</style>
