<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-23 11:08:02
-->
<template>
  <div style="height:100%">
    <div style="height:100%">
      <bottomBtn1 v-if="btnState===0" />
      <bottomBtn2 v-if="btnState===1" />
      <bottomBtn3 v-if="btnState===2" />
      <bottomBtn4 v-if="btnState===3" />
    </div>

    <!--金融服务弹窗 -->
    <div class="botomBtn3"
         v-show="show1"
         @click="hide(1)">
      <div style="height:60px;width:150px;" />
      <div class="btn4"
           style="height:179px;">
        <div>
          <a :href="sevenUrl"
             target="_blank"> {{ seven }}</a>
        </div>
        <div style="height:1px;width:146px;background-color:#979797; opacity: 0.3;" />
        <div>
          <a :href="eightUrl"
             target="_blank"> {{ eight }}</a>
        </div>
        <div style="height:1px;width:146px;background-color:#979797; opacity: 0.3;" />
        <div>
          <a :href="nineUrl"
             target="_blank"> {{ nine }}</a>
        </div>
      </div>
      <div style="height:60px;width:150px;margin-left: 17px;" />
      <div style="height:60px;width:150px;margin-left: 16px;" />
    </div>

    <!-- 科技成果转化弹窗 -->
    <div class="botomBtn2"
         v-show="show"
         @click="hide(2)">
      <div style="height:60px;width:150px;" />
      <div style="height:60px;width:150px;margin-left: 17px;" />
      <div style="height:60px;width:150px;margin-left: 16px;" />
      <div class="btn3"
           style="height:117px;">
        <div>
          <a :href="fiveUrl"
             target="_blank">{{ five }}</a>
        </div>
        <div style="height:1px;width:146px;background-color:#979797; opacity: 0.3;" />
        <div>
          <a :href="sixUrl"
             target="_blank"> {{ six }}</a>
        </div>
      </div>
    </div>
    <!-- 底部一级标签 -->
    <div class="botomBtn">
      <div :class="[btnState === 1 ? 'btn1' : 'btn2']"
           @click="onBtn(1)">
        {{ two }}
      </div>
      <div :class="[btnState === 2 ? 'btn1' : 'btn2']"
           @click="onBtn(2)">
        {{ three }}
      </div>
      <div :class="[btnState === 3 ? 'btn1' : 'btn2']"
           @click="onBtn(3)">
        {{ four }}
      </div>
      <div :class="[btnState === 0 ? 'btn1' : 'btn2']"
           @click="onBtn(0)">
        {{ one }}
      </div>
    </div>
  </div>
</template>

<script>
import bottomBtn1 from './bottom-btn1'
import bottomBtn2 from './bottom-btn2'
import bottomBtn3 from './bottom-btn3'
import bottomBtn4 from './bottom-btn4'
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'App',
  components: {
    bottomBtn1,
    bottomBtn2,
    bottomBtn3,
    bottomBtn4,
  },

  props: {
    bottomIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      one: '',
      two: '',
      three: '',
      threeUrl: '',
      four: '',
      five: '',
      fiveUrl: '',
      six: '',
      sixUrl: '',
      seven: '',
      sevenUrl: '',
      eight: '',
      eightUrl: '',
      nine: '',
      nineUrl: '',
      btnState: 1,//当前按钮
      show: false,
      show1: false,
    }
  },

  watch: {
    bottomIndex (val) {
      this.btnState = val
    },
  },

  mounted () {
    this.getScreenData()
  },
  methods: {
    //获取大屏表单信息
    getScreenData () {
      _GetAspScreenComponentData('NEWKEL-CYHYY-AN').then(res => {
        if (res) {
          this.one = res.data.data[0].name_data
          this.two = res.data.data[1].name_data
          this.three = res.data.data[2].name_data
          this.four = res.data.data[3].name_data
          //科技成果转化跳转按钮   
          _GetAspScreenComponentData('NEWKEL-NY-KJCGZH-TZAN').then(res => {
            if (res) {
              const arr = []
              res.data.data.forEach(item => {
                arr.push(
                  item.data_name,
                  item.data_value
                )
              });
              this.five = arr[0]
              this.fiveUrl = arr[1]
              this.six = arr[2]
              this.sixUrl = arr[3]
            }
          })
          //平台交易跳转按钮   
          _GetAspScreenComponentData('NEWKEL-NY-PTJY-TZAN').then(res => {
            if (res) {
              const arr1 = []
              res.data.data.forEach(item => {
                arr1.push(
                  item.data_name,
                  item.data_value
                )
              });
              this.threeUrl = arr1[1]
            }
          })
          //金融服务跳转按钮   
          _GetAspScreenComponentData('NEWKEL-NY-JRFW-TZAN').then(res => {
            if (res) {
              const arr2 = []
              res.data.data.forEach(item => {
                arr2.push(
                  item.data_name,
                  item.data_value
                )
              });
              this.seven = arr2[0]
              this.sevenUrl = arr2[1]
              this.eight = arr2[2]
              this.eightUrl = arr2[3]
              this.nine = arr2[4]
              this.nineUrl = arr2[5]
            }
          })
        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
      if (state === 3) {
        this.show = true
        this.show1 = false
      } else {
        this.show1 = false
        this.show = false
      }
    },
    onBtnGo (state) {
      if (state === 0) {
        this.show1 = true
        this.show = false
      } else {
        this.show1 = false
        this.show = false
      }

    },
    hide (state) {
      if (state === 1) {
        this.show1 = false
      }
      if (state === 2) {
        this.show = false
      }

    }
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  outline: none;
  color: #fff;
}
.botomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;

  .btn1 {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
    height: 45px;
    background: linear-gradient(360deg, #00845a 0%, #154a31 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #82ffe6;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #00ecbf;
    line-height: 45px;
    text-align: center;
  }

  .btn2 {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
    height: 45px;
    background: linear-gradient(360deg, #002519 0%, #154a31 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #008c70;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;
    color: #fff;
    line-height: 45px;
    text-align: center;

    img {
      margin-right: 10px;
    }
  }
}

.botomBtn2 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 70px;

  .btn3 {
    // cursor: pointer;
    margin-left: -300px;
    margin-right: 50px;
    margin-bottom: 30px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 0px 0px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #008c70;
    font-size: 18px;
    color: #fff;
    line-height: 60px;
    text-align: center;

    img {
      margin-right: 10px;
    }
  }
}

.botomBtn3 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 88px;

  .btn4 {
    // cursor: pointer;
    margin-left: 24px;
    margin-right: 5px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 0px 0px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #008c70;
    font-size: 18px;
    color: #fff;
    line-height: 60px;
    text-align: center;

    img {
      margin-right: 10px;
    }
  }
}
</style>
