<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 15:12:07
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="收购记录"
                 :visible.sync="sellListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="tableData"
                    @cell-click="onSellDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="name"
                             label="农户"
                             width="110" />
            <el-table-column prop="price"
                             label="价格"
                             width="70" />
            <el-table-column prop="time"
                             label="收购日期"
                             width="110" />
            <el-table-column prop="settleWeight"
                             label="净重"
                             width="70" />
          </el-table>
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="交售详情"
                 :visible.sync="sellDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              交售时间
            </div>
            <div class="right">
              2022-6-2
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              磅票号
            </div>
            <div class="right">
              256256266
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              垛位
            </div>
            <div class="right">
              20
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              净重
            </div>
            <div class="right">
              20 kg
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              过磅时间
            </div>
            <div class="right">
              20223-5-9
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    popuShow: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      sellListDrawer: false,
      sellDrawer: false,
      sellData: {},//收购详情数据
      tableData: [],
    }
  },

  watch: {
    id (val) {
      if (val != '') {
        this.tableData = [
          {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          }, {
            id: 1,
            name: '张三',
            price: '¥15',
            time: '2022-8-8',
            settleWeight: '20kg'
          }
        ]
        this.sellListDrawer = true
      }
    }
  },

  methods: {
    onSellDetail () {
      this.sellDrawer = true
    },

    handleClose (done) {
      done();
      this.$emit('sellShow', '')
    },
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>

