/*
 * @Description: Axios 请求封装
 * @Author: Pancras
 * @Date: 2022-01-15 17:05:38
 * @LastEditors: Pancras
 * @LastEditTime: 2022-10-27 21:38:46
 */
import axios from 'axios'
import util from '@/libs/util'
import setting from '@/setting'

// 创建 Axios 实例
const service = axios.create({
  baseURL: '',
  timeout: 10000 // request timeout
})

// service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截
service.interceptors.request.use(
  // request interceptor
  service.interceptors.request.use(
    config => {
      // 从配置文件获取租户 ID
      const TENANT_ID = setting.server.tenantId
      // 每个请求添加租户 ID
      config.headers['TENANT-ID'] = TENANT_ID
      return config
    },
    error => {
      console.log(error)
      return Promise.reject(error)
    }
  )
)

// 响应拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    const { code } = res
    // 当 Code 不为 0，发生业务异常
    if (code !== 0) {
      return res
    }
    return res
  }
)

export default service
