<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-08-30 17:10:20
-->
<template>
  <div class="aside-left-container"
       flex="dir:top box:mean">
    <left-top-item :region="regionId" />
    <left-middle-item :region="regionId" :msgdata="msgData"/>
    <left-bottom-item :region="regionId" :soilTrend="soilTrend"/>
  </div>
</template>

<script>
import leftTopItem from './leftTopItem'
import leftMiddleItem from './leftMiddleItem'
import leftBottomItem from './leftBottomItem'
import { getplatform } from "@/api/assp-trace-collectData/index";
// import date from "./index.json"
const hours = [
  "00",
  "02",
  "04",
  "06",
  "08",
  "10",
  "12",
  "14",
  "16",
  "18",
  "20",
  "22",
];
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  name: 'AsideLeft',
  components: {
    leftTopItem,
    leftMiddleItem,
    leftBottomItem
  },

  data () {
    return {
      regionId: '',
      msgData: [],//湿度温度
      // msgDatanot: [],//温度
      soilTrend:[]
    }
  },

  watch: {
    region (val) {
      this.regionId = val
    },

  },
  mounted() {
    this.getSreenData();
    // console.log(date.data,"date")
  },
  methods: {
    getSreenData() {
      let l = {
        farmId: "8c1c5119bd2640cdb630ed8793b242fa",
        landId: "098682ecc1124a21892ec1dcd4fe0ae9",
      };
      const d = new Date();

      getplatform({ ...l }).then((res) => {
        this.soilTrend=res.soilTrend
        // this.msgData
        console.log(res,"res")
        let getYear = d.getFullYear(); //年
        let getMonth = d.getMonth() + 1; //月
        let getDate = d.getDate(); //日
        const hour = d.getHours(); // 获取小时
        let data = getYear + "-" + getMonth + "-" + getDate;
        const _arr = [];
        // console.log(data,"_arr")
        res.insectData.sort().forEach((item) => {
          const timeArr = item.createdAt
            .replace(" ", ":")
            .replace(/\:/g, "-")
            .split("-");
          //筛选出时间
          let indexs = hours.findIndex(function (r) {
            if (r === timeArr[3].trim()) {
              item.state = r;
            }
            return r === timeArr[3].trim();
          });
          // console.log(indexs,"indexs")
          if (indexs !== -1) {
          //判断数组元素是否有重复
          if (hour >= timeArr[3].trim()) {
            _arr.push(item);
            const removeDuplicateObj = (arr) => {
              let obj = {};
              arr = arr.reduce((newArr, next) => {  
                obj[next.state]
                  ? ""
                  : (obj[next.state] = true && newArr.push(next));
                return newArr;
              }, []);
              return arr;
            };
            this.msgData = removeDuplicateObj(_arr);
            const sortId=(a, b)=> {
              return a.state - b.state;
            }
            this.msgData.sort(sortId)
          }
        }
        })


      });
    },
  },
}
</script>

<style lang="scss" scoped>
.aside-left-container {
  cursor: default;
  width: 100%;
  height: 100%;
}
</style>