<template>
  <div class="center-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <!-- <div class="txt">
        单位:次
      </div> -->
    <div class="card-body">
      <dv-scroll-board :config="option"
                       style="width: 100%; height: 90%;" />
    </div>
  </div>
</template>

<script>
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  components: {

  },
  name: 'CenterTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      name1: '',
      num1: '',
      name2: '',
      num2: '',
      title: '绿洲信用体系',
      dialogShow: false,
      data: {},//数据
      lineState: {},//在线状态数据
      screenWeight: document.body.clientWidth - 700, // 屏幕宽度
    }
  },

  mounted () {
    // this.getSreenData()
    // this.initChart()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-CYYTHYJ').then(res => {
        if (res) {
          this.name1 = res.data.data[0].data_name
          this.num1 = res.data.data[0].data_num
          this.name2 = res.data.data[1].data_name
          this.num2 = res.data.data[1].data_num
          this.title = res.data.form.name
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption(this.option)
    },

    getdialogShow (val) {
      this.dialogShow = val
    }
  }
}
</script>

<style lang="scss" scoped>
.center-top-container {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background: url("@/assets/images/card-bodyCenter.png") no-repeat;
  background-size: 100% 112%;
  background-position-y: -35px;

  .txt {
    position: absolute;
    top: 20px;
    right: 40px;
    opacity: 0.5;
    font-size: 12px;
    color: #ffffff;
    line-height: 12px;
  }

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;
    background-position: 0 -2px;
    position: relative;

    .txt {
      position: absolute;
      top: 30px;
      right: 10px;
      opacity: 0.5;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }

  .card-body {
    box-sizing: border-box;
    width: 100%;
    height: 95%;
    padding: 0 50px;

    .dv-scroll-board::v-deep {
      .header {
        .header-item {
          border: 0.5px solid rgba(232, 232, 232, 0.1);
        }
      }
      .rows {
        .row-item {
          font-size: 14px;
          font-weight: 400;
          color: #f2f2f2;

          .ceil {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
      }
    }
  }
}
</style>