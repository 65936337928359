<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-05 10:57:28
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 17:01:50
-->
<template>
  <div class="bubbleBox">
    <div class="top" />
    <div class="content">
      <el-radio v-for="(item,index) in list"
                :key="index"
                v-model="radio"
                :label="JSON.stringify(index + 1)">
        {{ item }}
      </el-radio>
    </div>
    <div class="bottom" />
  </div>
</template>

<script>
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'BubbleSelect',
  data () {
    return {
      radio: '1',
      list: []
    }
  },
  watch: {
    radio (val) {
      this.$emit('radio', val)
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZNHJC-DTAN').then(res => {
        if (res) {
          res.data.data.forEach(item => {
            this.list.push(
              item.name_data
            )
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/bubble.scss";
</style>
