/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 19:44:22
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#1C6EFF' // 0% 处的颜色
    }, {
      offset: 1, color: 'rgba(28, 110, 255, 0.26)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  xAxis: {
    type: 'category',
    data: [],//['系统报警', '设备报警', '数据报警', '程序报警', '农事报警', '植保报警', '农机报警', '气象报警', '巡航报警', '仓储报警'],
    axisLabel: {
      color: 'rgba(216, 240, 255, 0.80)'
    }
  },
  yAxis: [{
    type: 'value',
    axisLabel: {
      color: 'rgba(216, 240, 255, 0.80)'
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(216, 240, 255, 0.80)',
        type: 'dashed'
      }
    }
  }],
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].value + '次'
      }
      return relVal
    }
  },
  legend: {
    show: false
  },
  grid: {
    top: 15,
    botttom: 0,
    left: 80,
    width: 1300,
    height: 260
  },
  series: [
    {
      data: [],//[500, 1000, 1500, 1200, 2000, 2000, 1000, 3000, 2600, 1000],
      type: 'bar',
      barWidth: '15',
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
      emphasis: {
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: '#5DDAAD' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(0, 213, 255, 0.10)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      }
    }
  ]
}
