/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-30 18:33:11
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#FF5700', '#D90FE3', '#0F5DE3'],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}份',
  },
  legend: {
    left: '5%',
    top: '5%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 15
  },
  series: [
    {
      type: 'pie',
      radius: ['35', '50'],
      center: ['65%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        color: '#fff',
        fontSize: 14,
        // normal: {
        //   // formatter: '{d}%, {c} \n\n',
        //   // formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来？
        //   padding: [0, -10],//取消hr线跟延长线之间的间隙
        //   rich: {
        //     a: {
        //       color: '#999',
        //       lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
        //       align: 'center'
        //     }
        //   }
        // }
      },

      data: [
        { value: 40, name: '羊棉拭子配对血清' },
        { value: 65, name: '牛羊O/P液采集' },
        { value: 150, name: '家禽棉拭子及配对血清' },
        { value: 10, name: '猪全血' },
        { value: 30, name: '猪血清' },
        { value: 50, name: '猪淋巴结' },
        { value: 100, name: '马血清' },
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
