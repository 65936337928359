<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-01 13:52:55
-->
<template>
  <div style="height:100%">
    <div
      class="bubble"
      :style="{right: bubbleRight + '%'}"
    >
      <bubbleSelect @radio="getRadio" />
    </div>

    <div class="openAndClose">
      <div
        class="box"
        :style="{right: openRight + '%'}"
        @click="onOpen"
      >
        <img src="@/assets/images/close.png">
        <div class="txt">
          {{ openTxt }}
        </div>
      </div>
    </div>

    <div
      class="container"
      flex="dir:top main:center"
    >
      <div
        style="height:100%"
        flex="main:justify cross:center"
      >
        <div
          class="left"
          v-show="openState"
        >
          <aside-left />
        </div>
        <div
          style="height:100%;width:60%"
          flex="dir:bottom"
        >
          <!-- <div
            class="zhongjian"
            flex="box:mean cross:center"
          > -->
             <el-carousel direction="vertical" :interval="10000" :autoplay="isAutoplay" indicator-position="none">
              <el-carousel-item v-for="(item, index) in liveList" :key="index">
                <div
                  class="zhongjian"
                >
                  <div class="video" v-for="item1 in item" :key="item1.deviceId" @click.prevent="liveView(item1.deviceId)">
                    <iframe 
                      :src="item1.url"
                      frameborder="0"
                      width="99%"
                      height="99%"
                    />
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <video
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              src="https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/monitor/dapeng/num1/0e60b24750e4e0b40ef0a6cf99fb28ba.mp4"
            />

            <video
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              src="https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/monitor/dapeng/num3/2e4e4a8c648e8ebb352e8b8feb20f564.mp4"
            />

            <video
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              src="https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/monitor/dapeng/num22/0dcbe4d5f80f876d9fcbd9379f6badeb.mp4"
            />

            <video
              muted="muted"
              autoplay="autoplay"
              loop="loop"
              src="https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/monitor/dapeng/num7/55fefa01454871a45b8b9a9097eb9415.mp4"
            /> -->
          <!-- </div> -->
        </div>
        <div
          class="right"
          v-show="openState"
        >
          <aside-right />
        </div>
      </div>
      <div flex="box:mean">
        <main-map :mark-state="markState" />
      </div>
    </div>
    <el-dialog
        title="监控详情"
        :visible.sync="dialogVisible"
        width="50%"
        :modal="false"
        @closed="dialogClosed"
      >
        <iframe 
          :src="itemurl"
          frameborder="0"
          width="100%"
          height="100%"
        />
      </el-dialog>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import asideRight from './asideRight'
import mainMap from './map/mainMap'
import bubbleSelect from './bubble-select'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData , _GetLive , _GetItemLive } from '@/api/form/screenFormData'//获取大屏表单信息


export default {
  name: 'App',
  components: {
    asideLeft,
    asideRight,
    mainMap,
    bubbleSelect
  },
  data () {
    return {
      itemurl: '',
      liveList: [],
      isAutoplay: true,
      dialogVisible: false,
      openTxt: '收起',
      openRight: 20,
      bubbleRight: 20,
      openState: true,
      btnState: 0,//当前按钮
      markState: '1',//当前地图点位
      title1: '',
      nums1: '',
      title2: '',
      nums2: '',
      title3: '',
      nums3: '',
      screenHeight: document.body.clientHeight, // 屏幕高度
    }
  },
  mounted () {
    this.getSreenData()
    this.getLive()
  },
  methods: {
    
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SY-DBSJ').then(res => {
        if (res) {
          const arr = []
          res.data.data.forEach(item => {
            arr.push(
              item.name_data,
              item.vaule_data
            )
          });
          this.title1 = arr[0]
          this.nums1 = arr[1]
          this.title2 = arr[2]
          this.nums2 = arr[3]
          this.title3 = arr[4]
          this.nums3 = arr[5]
        }
      })
    },
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    },

    /*获取当前radio */
    getRadio (val) {
      this.markState = val
    },

    onOpen () {
      this.openState = !this.openState
      if (this.openState) {
        this.openTxt = '收起'
        this.openRight = 20
        this.bubbleRight = 20
      }

      if (!this.openState) {
        this.openTxt = '展开'
        this.openRight = 15
        this.bubbleRight = 15
      }
    },
    // 获取监控视频
    getLive() {
      let data = {
        authorization: 'Bearer f631e1fa-23c7-4131-b1e9-e641c0cf49ed'
      }
      _GetLive(data).then((res) => {
        // console.log(res, 'resereserse');
        if (res.code == 0) {
          const data = res.data
          const list = Array.from(
            { length: Math.ceil(data.length / 5) },
            (_, i) => data.slice(i * 5, i * 5 + 5)
          )
          this.liveList = list
          console.log(this.liveList, 'liveList');
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取某一个监控视频
    getItemLive(id) {
      let data = {
        deviceId: id
      }
      _GetItemLive(data).then((res) => {
        if (res.code == 0) {
          this.itemurl = res.data.url
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 打开监控弹窗
    liveView(id) {
      this.dialogVisible = true
      this.isAutoplay = false
      this.getItemLive(id)
    },
    // 关闭监控弹窗
    dialogClosed() {
      this.isAutoplay = true
    },
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>

<style scoped lang="scss">
::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0.8);
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}

::v-deep .el-dialog__body {
  height: 60vh;
  padding: 0px 0px;
}
.el-carousel {
  // margin-bottom: 5%;
  z-index: 999;
  height: 21%;
  width: 100%;
  padding-top: 2px;
}
.zhongjian {
  width: 100%;
  height: 50%;
  display: flex;
   .video {
    margin: 0 10px;
    height: 100%;
    width: 19%;
    border: 2px solid #fff;
    opacity: 1;
    cursor: pointer;
    iframe {
      pointer-events: none;
     
    }
  }

  video {
    margin: 0 10px;
    height: 90%;
    border: 2px solid #fff;
    opacity: 1;
  }
}

.botomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 999;

  .btn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #82ffe6;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;

    img {
      margin-right: 10px;
    }
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 150px;
    height: 60px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 14px 30px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;

    img {
      margin-right: 10px;
    }
  }
}
</style>
