<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:26:38
-->
<template>
  <div>
    <div id="mapDiv" />
    <div>
      <vehiclePopu :vehicle-id-sell="vehicleId_Sell"
                   @sellDrawer="getSellDrawer"
                   :vehicle-id-gather="vehicleId_Gather" />
    </div>
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
import vehiclePopu from './components/vehiclePopu'

export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  components: {
    vehiclePopu
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonData: [],//农业重点时评监控
      cottonDataA: [],//土壤墒情设备
      cottonDataB: [],//虫情检测仪设备
      cottonDataC: [],//水肥一体设备
      cottonDataD: [],//遥感光谱
      cottonDataE: [],
      vehicleId_Sell: null,//车辆id查询交售
      vehicleId_Gather: null,//车辆id查询采收
      marker: null,
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getCottonData()//mark
      }

      if (val === '2') {
        this.getCottonDataA()//mark
      }

      if (val === '3') {
        this.getCottonDataB()//mark
      }

      if (val === '4') {
        this.getCottonDataC()//mark
      }

      if (val === '5') {
        this.getCottonDataD()//mark
      }

      if (val === '6') {
        this.getCottonDataE()//mark
      }
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonData()//mark

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }

    window.onVehicleGatherDetails = () => {
      this.onVehicleGatherDetails()
    };

    window.onVehicleSellDetails = () => {
      this.onVehicleSellDetails()
    };
  },

  methods: {
    getCottonData () {
      _GetAspScreenComponentData('NEKWKEL-DT-NYZDSPJK').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {
            arr1.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lag),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              name3: item.data_namec,
              msg3: item.data_msgc,
              name4: item.data_named,
              msg4: item.data_msgd,
              name5: item.data_namee,
              msg5: item.data_msge,
              value: item.data_value
            })
          })
          this.cottonData = arr1
          this.onCotton()
          // 点位集合获取所有点位中间位置
          const latlng = arr1.length > 1 ? util.getPolygonCenter(arr1) : arr1[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    //坐标点
    onCotton () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/nj.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      var bounds = this.map.getBounds();
      var sw = bounds.getSouthWest();
      var ne = bounds.getNorthEast();
      var lngSpan = Math.abs(sw.lng - ne.lng);
      var latSpan = Math.abs(ne.lat - sw.lat);

      for (var i = 0; i < 15; i++) {
        //创建图片对象
        // 点位经纬度坐标
        const point = new this.T.LngLat(sw.lng + lngSpan * (Math.random() * 0.7), ne.lat - latSpan * (Math.random() * 0.7))
        var marker = new this.T.Marker(point, { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        marker.on('click', this.vehiclemarkerClick);
      }

    },

    //接收车辆弹窗返回参数
    getSellDrawer (val) {
      console.log(val)
      this.vehicleId_Sell = null
      this.vehicleId_Gather = null
    },

    /*点击车辆mark */
    vehiclemarkerClick (e) {
      let lnglat = e.lnglat
      let _this = this
      _this.map.panTo(lnglat, 12)
      let content = "<div>" +
        "<div style='margin: 14px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #fff;'>车辆信息</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>终端设备号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>" + 205521 + "</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;width:150px'>当前具体位置</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>库尔勒</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>农机牌号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>新A 90cs</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主姓名</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>张三</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车主手机号</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>15869588589</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>速度</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>20</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div style='font-size: 14px;font-weight: bold;color: #ffffff;'>车机类型</div>" +
        "<div style='font-size: 14px;font-weight: 400;color: #ffffff;'>农机</div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>采收记录</div>" +
        "<div onClick='onVehicleGatherDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +

        "<div style='margin: 7px auto;width: 95%;display:flex;justify-content: space-between;opacity: 0.5;align-items: center;border-bottom: 1px solid #979797;padding:8px 0;'>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: bold;color: #ffffff;'>交售记录</div>" +
        "<div onClick='onVehicleSellDetails()' style='font-size: 14px;font-weight: 400;color: #ffffff;cursor: pointer;'>详情></div>" +
        "</div>" +
        "</div>"

      var point = lnglat
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '360',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true,// 开启点击地图关闭弹窗
        offset: new _this.T.Point(20, 0)
      }); // 创建信息窗口对象
      _this.infoWin.setContent(content);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
    },

    getCottonDataA () {
      _GetAspScreenComponentData('NEWKEL-DT-TRSQSB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {

            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 2 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonDataA = arr4
          this.onCottonTwo()
        }
      })
    },

    /*打开二级窗口 */
    onVehicleSellDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Sell = state
    },

    /*打开二级窗口 */
    onVehicleGatherDetails () {
      let state = Math.round(Math.random() * 100)//随机数
      this.vehicleId_Gather = state
    },

    onCottonTwo () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/trsqy.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataA.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;width:80px'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },

        })
      })
    },

    getCottonDataB () {
      _GetAspScreenComponentData('NEKWLE-DT-CQJCSB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {

            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonDataB = arr4
          this.onCottonTwoB()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoB () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/cqy.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },

        })
      })
    },

    getCottonDataC () {
      _GetAspScreenComponentData('NEKWLE-DT-SFYTSB').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {

            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonDataC = arr4
          this.onCottonTwoC()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 2 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoC () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/sfyt.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 15px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },

        })
      })
    },

    getCottonDataD () {
      _GetAspScreenComponentData('KELNEW-NY-QXZ').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: item.geos.split('\n').map(geo => {
                let lngLat = geo.split(',')
                return { lng: Number(lngLat[0]), lat: Number(lngLat[1]) }
              })
            })
          })
          this.cottonDataD = arr1
          this.onCottonD()
        }
      })
    },
    /*第一个 */
    onCottonD () {
      var points = [];

      this.cottonDataD.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#f52cff", weight: 1, opacity: 0.9, fillColor: "#a7dbc8", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesE(e)
        });
      });
    },

    onLandMesE (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 15 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    getCottonDataE () {
      _GetAspScreenComponentData('NEWKEL-DT-YGGP').then(res => {
        if (res) {
          const arr1 = []
          res.data.data.forEach(item => {

            arr1.push({
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value,
              geoInfo: [
                { lng: Number(item.data_lng), lat: Number(item.data_lat) },
                { lng: Number(item.data_lnga), lat: Number(item.data_lata) },
                { lng: Number(item.data_lngb), lat: Number(item.data_latb) },
                { lng: Number(item.data_lngc), lat: Number(item.data_latc) }
              ]
            })
          })
          this.cottonDataE = arr1
          this.onCottonE()
        }
      })
    },
    /*第一个 */
    onCottonE () {
      var points = [];

      this.cottonDataE.forEach(item => {
        const pointsData = []

        pointsData.push(
          item.geoInfo
        )
        points = pointsData
        //创建面对象
        var polygon = new this.T.Polygon(points, {
          color: "#c0be13", weight: 1, opacity: 0.9, fillColor: "#ade4d7", fillOpacity: 0.2
        });
        //向地图上添加面
        this.map.addOverLay(polygon);

        let _this = this
        polygon.addEventListener("click", function (e) {
          e.conent = item
          _this.onLandMesA(e)
        });
      });
    },

    onLandMesA (e) {


      let latlng = util.getPolygonCenter(e.conent.geoInfo)

      let _this = this
      _this.map.panTo(latlng, 12)

      var sContent =
        "<div style='width: 100%;color:#fff'>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + e.conent.title + "</div>" +
        "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
        "</div>" +
        "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

        "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
        "<div style='width: 43%'>" +
        "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name1 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg1 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='width: 43%'>" +
        "<div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
        "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + e.conent.name2 + "</div>" +
        "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + e.conent.msg2 + "</div>" +
        "</div>" +
        "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
        "</div>" +

        "<div style='margin: 19px auto;width:100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
        e.conent.value +
        "</div>" +

        "</div>";

      var point = latlng
      _this.infoWin = new _this.T.InfoWindow(e, {
        minWidth: '450',
        closeButton: true, // 显示关闭按钮
        closeOnClick: true // 开启点击地图关闭弹窗
      }); // 创建信息窗口对象
      _this.infoWin.setContent(sContent);
      _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

    },

    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  z-index: 997;
  width: 100%;
  height: 110%;
  position: fixed;
  left: 0;
  top: 7%;
}
</style>
