/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:10:57
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 19:36:28
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#1C6EFF' // 0% 处的颜色
    }, {
      offset: 1, color: 'rgba(28, 110, 255, 0.26)' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],

  legend: {
    left: '70%',
    textStyle: {
      color: 'rgba(216, 240, 255, 0.80)'
    },
    itemWidth: 8,
    itemHeight: 8,
    backgroundColor: 'rgba(216, 240, 255, 0.08)',
    itemStyle: {
      color: '#1C6DFC'
    },
  },

  grid: {
    top: 50,
    height: 160
  },
  xAxis: {
    data: [],//['2017', '2018', '2019', '2020', '2021'],
    axisLine: {  //这是x轴文字颜色
      lineStyle: {
        color: "rgba(216, 240, 255, 0.80)",
      }
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        if (params[i].seriesIndex === 0) {
          relVal += '<br/>' + params[i].marker + params[i].value + '万亩'
        } else {
          relVal += '<br/>' + params[i].marker + params[i].value + '千公顷'
        }
      }
      return relVal
    }
  },
  yAxis: [{
    splitLine: {   //控制刻度横线的显示
      show: false
    },
    type: 'value',
    axisLabel: {
      textStyle: {
        color: 'rgba(216, 240, 255, 0.80)',
      }
    },
    axisLine: {  // Y轴线的颜色、和轴线的宽度
      lineStyle: false
    }
  },
  {
    splitLine: {
      show: false
    },
    type: 'value',
    max: 60,
    min: 0,
    axisLabel: {
      textStyle: {
        color: 'rgba(216, 240, 255, 0.80)',
      }
    },
    axisLine: {
      lineStyle: {
        color: 'rgba(216, 240, 255, 0.80)',
        width: 2
      }
    }
  }
  ],

  series: [
    {
      barWidth: 20,
      data: [],//["22", "45", "51", "13", "46"],
      name: '',//'产量',
      type: "bar",
      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
      emphasis: {
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: '#5DDAAD' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(0, 213, 255, 0.10)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      }
    },

    {
      name: '',//'面积',
      data: [],//["22", "45", "51", "13", "46"],/* 这里要和第一组数据一样、才能保持在最高处*/
      type: 'line',
      symbol: 'circle',//拐点样式
      symbolSize: 8,//拐点大小
      itemStyle: {
        normal: {
          lineStyle: {
            width: 3,//折线宽度
            color: "#A88836"//折线颜色
          },
          color: '#A88836',//拐点颜色
          borderColor: '#A88836',//拐点边框颜色
          borderWidth: 3//拐点边框大小
        },
        emphasis: {
          color: '#A88836'//hover拐点颜色定义
        }
      },
      // 实现数字展示在柱状图
      label: {
        show: true,
        position: 'top',
        color: '#FFFFFFFF'
      },
    }
  ]
}
