/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-06 18:15:21
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-21 19:13:10
 * @FilePath: \korlascreen\src\components\main-view2\top-btn1\bottom-btn3\asideRight\rightBottomItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#5883FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#51BAFF' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#00E5B4' // 0% 处的颜色
    }, {
      offset: 1, color: '#AEFFE2' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }],
  legend: {
    data: [],//['种植面积'],
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    itemStyle: {
      color: '#68FFD6'
    },
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  xAxis: {
    type: 'category',
    data: ['桃树', '杏树', '葡萄', '无花果','红枣','樱桃','核桃','西梅','石榴','李子','桑树','枸杞','苹果','利'],// ['2019年', '2020年', '2021年', '2022年'],
    axisLabel: {
      color: '#FFFFFF',
      interval: 0,  //间隔几条显示
      rotate: 35,  // 切斜度数
      fontSize: 12,   //字体大小
    },
    
    axisTick: {
      show: false
    },
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: function (params) {
      var relVal = params[0].name
      for (var i = 0, l = params.length; i < l; i++) {
        relVal += '<br/>' + params[i].marker + params[i].seriesName + params[i].value + '亩'
      }
      return relVal
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    },
    data: ['种植面积']
  },
  // 整体大小
  grid: {
    left: '3%',
    right: '4%',
    top: '10%',
    bottom: '5%',
    containLabel: true
},
  series: [
    {
      name: '合计',//'种植面积',
      data: [5250.5,3609.1,2128.7,405.5,34893,760,437.5,857.2,18,1148,769.4,1840,368.2,405942.7],// [12, 35, 41, 33],
      type: 'line',
      lineStyle: {
        color: '#00FFD4'
      },
      areaStyle: {
        color: '#68FFD6'
      }
    },
    {
      name: '2023年新种植',//'种植面积',
      data: [8159,199,482.7,2,0,16.3,0,200,0,0,268.7,13.8,1941,0,5017.5],// [12, 35, 41, 33],
      type: 'line',
      lineStyle: {
        color: '#5883FF'
      },
      areaStyle: {
        color: '#5883FF'
      }
    },
  ]
}
