<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:15:51
-->
<template>
  <div>
    <div id="mapDiv" />
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data () {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonTwoData: [],
      cottonTwoDataA: [],
      cottonTwoDataB: [],
      cottonTwoDataC: [],
      cottonTwoDataD: [],
      cottonTwoDataE: [],
      // 农村供水工程点位数据
      waterData: [
        {
          lng: 86.09069,
          lat: 41.75217,
          name: '库尔勒市英下乡农村饮水安全工程', // 名称
          location: '英下乡哈尔巴格村一组', // 所在位置
          investment: '2049.3', // 工程总投资
          startTime: '2009-12', // 开始供水时间
          isCompleted: '是', // 竣工验收   
          properties: '改扩建', // 工程性质
          designedWaterSupply: '1981.65', // 设计供水能力
          ActualWaterSupply: '660.55', // 实际供水能力
          supplyHouseholds: '1677', // 供水户数
          supplyPeople: '6005', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 85.90163,
          lat: 41.74329,
          name: '库尔勒市托和联网农村饮水安全工程', // 名称
          location: '和什力克乡和托布力其乡边界', // 所在位置
          investment: '4547.3', // 工程总投资
          startTime: '2006-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '改扩建', // 工程性质
          designedWaterSupply: '5316.96', // 设计供水能力
          ActualWaterSupply: '1772.32', // 实际供水能力
          supplyHouseholds: '4810', // 供水户数
          supplyPeople: '16112', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 85.83652,
          lat: 41.69105,
          name: '包头湖农场农村饮水安全巩固提升工程', // 名称
          location: '包头湖农场', // 所在位置
          investment: '2196', // 工程总投资
          startTime: '2019-12', // 开始供水时间
          isCompleted: '否', // 竣工验收
          properties: '改扩建', // 工程性质
          designedWaterSupply: '825', // 设计供水能力
          ActualWaterSupply: '326', // 实际供水能力
          supplyHouseholds: '720', // 供水户数
          supplyPeople: '2500', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.10075,
          lat: 41.69370,
          name: '库尔勒市阿瓦提乡农村饮水管道联网工程', // 名称
          location: '阿瓦提乡阿克艾日克村2组', // 所在位置
          investment: '2677.4', // 工程总投资
          startTime: '2006-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '改扩建', // 工程性质
          designedWaterSupply: '3693.03', // 设计供水能力
          ActualWaterSupply: '1231.01', // 实际供水能力
          supplyHouseholds: '3402', // 供水户数
          supplyPeople: '11913', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.06624,
          lat: 41.60910,
          name: '库尔勒市Ⅰ区农村饮水安全工程', // 名称
          location: '哈拉玉宫乡中道杆村3组', // 所在位置
          investment: '2801.2', // 工程总投资
          startTime: '2009-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '改扩建', // 工程性质
          designedWaterSupply: '2970.49', // 设计供水能力
          ActualWaterSupply: '950', // 实际供水能力
          supplyHouseholds: '2555', // 供水户数
          supplyPeople: '8952', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.07455,
          lat: 41.77583,
          name: '库尔勒市兰干乡农村饮水安全工程', // 名称
          location: '兰干乡新村2组', // 所在位置
          investment: '253.71', // 工程总投资
          startTime: '2006-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '已建', // 工程性质
          designedWaterSupply: '857.46', // 设计供水能力
          ActualWaterSupply: '597.26', // 实际供水能力
          supplyHouseholds: '3008', // 供水户数
          supplyPeople: '9721', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.03358,
          lat: 41.81757,
          name: '上户镇农村饮水安全工程', // 名称
          location: '上户镇西站社区安定路', // 所在位置
          investment: '582.02', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '已建', // 工程性质
          designedWaterSupply: '1543.02', // 设计供水能力
          ActualWaterSupply: '1037', // 实际供水能力
          supplyHouseholds: '1149', // 供水户数
          supplyPeople: '3642', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.14582,
          lat: 41.70540,
          name: '普惠地区农村饮水安全管网更新改造工程(2016年PVC更换PE工程）', // 名称
          location: '库普公路34公里处', // 所在位置
          investment: '1696.7', // 工程总投资
          startTime: '2006-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '改扩建', // 工程性质
          designedWaterSupply: '1788.82', // 设计供水能力
          ActualWaterSupply: '890', // 实际供水能力
          supplyHouseholds: '3516', // 供水户数
          supplyPeople: '13265', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.11475,
          lat: 41.77526,
          name: '库尔勒市恰尔巴格乡喀拉墩村农村饮水安全工程', // 名称
          location: '喀拉墩村', // 所在位置
          investment: '13.48', // 工程总投资
          startTime: '1994-11', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '已建', // 工程性质
          designedWaterSupply: '124.5', // 设计供水能力
          ActualWaterSupply: '41.51', // 实际供水能力
          supplyHouseholds: '160', // 供水户数
          supplyPeople: '800', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.04154,
          lat: 41.73990,
          name: '库尔勒市Ⅱ区农村饮水安全工程', // 名称
          location: '沙依东园艺场', // 所在位置
          investment: '663', // 工程总投资
          startTime: '2009-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '已建', // 工程性质
          designedWaterSupply: '4560.52', // 设计供水能力
          ActualWaterSupply: '1150', // 实际供水能力
          supplyHouseholds: '1553', // 供水户数
          supplyPeople: '11000', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 85.45880,
          lat: 41.95147,
          name: '库尔楚园艺场农村饮水安全工程', // 名称
          location: '库尔楚园艺场', // 所在位置
          investment: '227', // 工程总投资
          startTime: '2010-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '已建', // 工程性质
          designedWaterSupply: '429.13', // 设计供水能力
          ActualWaterSupply: '326', // 实际供水能力
          supplyHouseholds: '947', // 供水户数
          supplyPeople: '3500', // 供水人数
          type: '地下水' // 水源类型
        },
        {
          lng: 86.17213,
          lat: 41.75688,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '铁克其乡', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '联合水源' // 水源类型
        },
        {
          lng: 86.13599,
          lat: 41.77784,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '恰尔巴格乡', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '' // 水源类型
        },
        {
          lng: 86.10548,
          lat: 41.69252,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '阿瓦提乡', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '联合水源' // 水源类型
        },
        {
          lng: 86.11504,
          lat: 41.74560,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '英下乡', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '联合水源' // 水源类型
        },
        {
          lng: 86.03049,
          lat: 41.81952,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '上户镇', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '联合水源' // 水源类型
        },
        {
          lng: 86.18875,
          lat: 41.62127,
          name: '库尔勒市城市管网延伸工程', // 名称
          location: '西尼尔镇', // 所在位置
          investment: '741.63', // 工程总投资
          startTime: '2012-12', // 开始供水时间
          isCompleted: '是', // 竣工验收
          properties: '城市管网延伸', // 工程性质
          designedWaterSupply: '4769.08', // 设计供水能力
          ActualWaterSupply: '/', // 实际供水能力
          supplyHouseholds: '18949', // 供水户数
          supplyPeople: '59327', // 供水人数
          type: '联合水源' // 水源类型
        }
      ],
    }
  },

  mounted () {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonTwoData()//棉蛋mark


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState (val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getCottonTwoData()//mark
      }

      if (val === '2') {
        this.getCottonTwoDataA()//mark
      }

      if (val === '3') {
        this.getCottonTwoDataB()//mark
      }

      if (val === '4') {
        this.getCottonTwoDataC()//mark
      }

      if (val === '5') {
        // this.getCottonTwoDataD()//mark
        this.getWaterEngineeringData()
      }

      // if (val === '6') {
      //   this.getCottonTwoDataE()//mark
      // }

    }
  },

  methods: {

    // 农村供水工程点位
    getWaterEngineeringData() {
      // 点位集合获取所有点位中间位置
      const latlng = this.waterData.length > 1 ? util.getPolygonCenter(this.waterData) : this.waterData[0]
      this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/dbs.png',
        id: 'test',
        iconSize: new this.T.Point(40, 49),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      this.waterData.forEach(item => {

        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 18px;font-weight: bold;color: #fff;'>" + item.name + "(" + item.type +")" + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +


          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-right: 10px;border-right: 1px solid rgba(255,255,255,0.2);'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>工程总投资(万元)</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.investment + "</div>" +
          "</div>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-left: 10px'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>开始供水时间</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.startTime + "</div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-right: 10px;border-right: 1px solid rgba(255,255,255,0.2);'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>竣工验收</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.isCompleted + "</div>" +
          "</div>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-left: 10px'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>工程性质</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.properties + "</div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-right: 10px;border-right: 1px solid rgba(255,255,255,0.2);'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>设计供水能力(m³/d)</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.designedWaterSupply + "</div>" +
          "</div>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-left: 10px'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>实际供水能力(m³/d)</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.ActualWaterSupply + "</div>" +
          "</div>" +
          "</div>" +

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-right: 10px;border-right: 1px solid rgba(255,255,255,0.2);'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>供水户数(户)</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.supplyHouseholds + "</div>" +
          "</div>" +
          "<div style='margin: 10px auto 2px;width: 50%;display:flex;justify-content: space-between;align-items: center;padding-left: 10px'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>供水人数(人)</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.supplyPeople + "</div>" +
          "</div>" +
          "</div>" +

          

          "<div style='width: 100%;display:flex;justify-content: space-between;border-bottom: 1px solid #979797;padding-bottom: 8px;'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>所在位置</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.location + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 0%;'></div>" +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })

        this.map.addOverLay(marker)
      })
    },

    getCottonTwoData () {
      _GetAspScreenComponentData('NEWKEL-DT-GQY').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title
            item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoData = arr4
          this.onCottonTwo()
        }
      })
    },
    onCottonTwo () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/gq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoData.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataA () {
      _GetAspScreenComponentData('NEWKEL-DT-GQE').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title
            item.name1 = arr4[0].name1
            item.msg1 = arr4[0].msg1
            item.name2 = arr4[0].name2
            item.msg2 = arr4[0].msg2
            item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataA = arr4
          this.onCottonTwoA()

        }
      })
    },
    onCottonTwoA () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/gq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataA.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },

    getCottonTwoDataB () {
      _GetAspScreenComponentData('NEWKEL-DT-DAMSLSN').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataB = arr4
          this.onCottonTwoB()

        }
      })
    },
    onCottonTwoB () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/gq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataC () {
      _GetAspScreenComponentData('NEWKEL-DT-SNE').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })

          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)

          this.cottonTwoDataC = arr4
          this.onCottonTwoC()

        }
      })
    },
    onCottonTwoC () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/nq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:28px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataD () {
      _GetAspScreenComponentData('NEWKEL-DT-SNS').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonTwoDataD = arr4
          this.onCottonTwoD()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoD () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/nq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataD.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },


    getCottonTwoDataE () {
      _GetAspScreenComponentData('NEWKEL-DT-SNSS').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_namea,
              msg1: item.data_msga,
              name2: item.data_nameb,
              msg2: item.data_msgb,
              value: item.data_value
            })
          })
          this.cottonTwoDataE = arr4
          this.onCottonTwoE()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)
        }
      })
    },
    onCottonTwoE () {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/nq.png',
        id: 'test',
        iconSize: new this.T.Point(30, 37),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonTwoDataE.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='margin: 19px auto;width: 100%;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9;overflow: hidden;text-overflow: ellipsis;text-overflow: -o-ellipsis-lastline;text-overflow: ellipsis;'>" +
          item.value +
          "</div>" +

          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
        })
      })
    },



    /*关闭信息窗口 */
    onCloseInfoWindow () {
      this.infoWin.closeInfoWindow()
    }
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  width: 100%;
  height: 110%;
}
</style>
