<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-23 11:14:09
-->
<template>
  <div style="height:100%">
    <!-- <div class="bubble"
         :style="{right: bubbleRight + '%'}">
      <bubbleSelect @radio="getRadio" />
    </div> -->

    <div class="openAndClose">
      <div class="box"
           :style="{right: openRight + '%'}"
           @click="onOpen">
        <img src="@/assets/images/close.png">
        <div class="txt">
          {{ openTxt }}
        </div>
      </div>
    </div>

    <div class="container"
         flex="dir:top main:center">
      <div style="height:100%"
           flex="main:justify cross:center">
        <div class="left"
             v-show="openState">
          <aside-left />
        </div>
        <div flex="box:mean">
          <main-map :mark-state="markState" />
        </div>
        <div class="right"
             v-show="openState">
          <aside-right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import asideRight from './asideRight'
import mainMap from './map/mainMap.vue'
// import bubbleSelect from './bubble-select'

export default {
  name: 'App',
  components: {
    asideLeft,
    asideRight,
    mainMap,
    // bubbleSelect
  },
  data () {
    return {
      openTxt: '收起',
      openRight: 20,
      openState: true,
      markState: '1',//当前地图点位
      btnState: 0,//当前按钮
      bubbleRight: 20,
    }
  },

  methods: {
    /*获取当前radio */
    getRadio (val) {
      this.markState = val
    },

    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    },

    onOpen () {
      this.openState = !this.openState
      if (this.openState) {
        this.openTxt = '收起'
        this.openRight = 20
        this.bubbleRight = 20
      }

      if (!this.openState) {
        this.openTxt = '展开'
        this.openRight = 1
        this.bubbleRight = 1
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>

<style scoped lang="scss">
</style>
