/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-06 12:02:28
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-29 20:31:59
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#FF1D1D', '#FF8A04', '#FDFF22', '#008567', '#FF40F8', '#4F00FF', '#FF869F', '#A076FF'],
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}千瓦',
  },
  legend: {
    left: '25%',
    top: '80%',
    // orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 6
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: ['40%', '55%'],
      center: ['50%', '40%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        normal: {
          // formatter: '{d}%, {c} \n\n',
          formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
          padding: [0, -10],//取消hr线跟延长线之间的间隙
          rich: {
            a: {
              color: '#999',
              lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
              align: 'center'
            }
          }
        }
      },

      data: [
        // { value: 12, name: '一月' },
        // { value: 14, name: '二月' },
        // { value: 16, name: '三月' },
        // { value: 12, name: '四月' },
        // { value: 12, name: '五月' },
        // { value: 12, name: '六月' },
        // { value: 12, name: '七月' },
        // { value: 12, name: '八月' },
        // { value: 12, name: '九月' },
        // { value: 12, name: '十月' },
        // { value: 12, name: '十一月' },
        // { value: 12, name: '十二月' },
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },

    }
  ]
}
