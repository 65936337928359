/*
 * @Description: 侧右中 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:26:42
 */
// 农机补贴
// eslint-disable-next-line camelcase
const classxAxis = ['2017年', '2018年', '2019年', '2020年', '2021年'] // 列标

const classData = [5000, 6000, 7000, 5500, 7000] // 柱图1数据
const classData1 = [1800, 4000, 3900, 5900, 8000] // 柱图2数据
const classData2 = [1700, 2100, 4100, 4900, 7000] // 柱图3数据
const classData3 = [1900, 2000, 3000, 4000, 5000] // 柱图4数据

const classType = ['棉花', '香梨', '牛', '羊'] // 图例
export default {
  animationDuration: 10000,
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: '10',
    textStyle: {
      color: '#FFF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: 50,
    left: 65
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: classxAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#ffffff',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#FFF'
      }
    }
  },
  yAxis: {
    min: 0,
    max: 8000,
    inverse: false,
    name: '吨',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#FFF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#FFF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#0000FF',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FF0000"
      },
      symbolSize: 9,
      data: classData
    },
    {
      name: classType[1],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#FF0000',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FF0000"
      },
      symbolSize: 9,
      data: classData1
    },
    {
      name: classType[2],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#00FF33',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#00FF33"
      },
      symbolSize: 9,
      data: classData2
    },
    {
      name: classType[3],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#FF9900',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#FF9900"
      },
      symbolSize: 9,
      data: classData3
    }
  ]
}
