<!--
 * @Description: 上右
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 17:33:47
-->
<template>
  <div flex="box:mean">
    <!--  左一-->
    <div class="card"
         flex="box:first">
      <img src="@/assets/images/top3/monthuser.png">
      <div class="card-main">
        <div class="card-main-title">
          {{ option.countList[0].title }}
        </div>
        <div class="card-main-count">
          {{ option.countList[0].value }}
        </div>
      </div>
    </div>
    <!--  左二-->
    <div class="card"
         flex="box:first">
      <img src="@/assets/images/top3/dayuser.png">
      <div class="card-main">
        <div class="card-main-title">
          {{ option.countList[1].title }}
        </div>
        <div class="card-main-count">
          {{ option.countList[1].value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { _GetAspScreenFormData } from '@/api/form/screenFormData' // 大屏数据接口
import option from './option'
export default {
  name: 'MainTopRight',
  data () {
    return {
      option
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    // 获取数据
    async getData () {
      // 产业化运营平台交易中上右
      const { code, data } = await _GetAspScreenFormData('KEL-CYHYY-PTJY-ZSY')
      const arr = []
      if (code === 0 && data) {
        data.data.forEach(item => {
          arr.push({
            title: item.data_title,
            value: item.data_value
          })
        })

        this.option.countList = arr
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  img {
    width: 52.5px;
    height: 52.5px;
  }
  .card-main {
    margin-left: 22.5px;
    .card-main-title {
      font-size: 12px;
      color: #fff;
    }
    .card-main-count {
      font-size: 27px;
      color: rgb(216, 202, 6);
    }
  }
}
</style>
