<!--
 * @Description: 缩放组件
 * @Author: Pancras
 * @Date: 2022-12-31 17:54:24
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-03 18:42:17
-->
<template>
  <div id="container"
       :style="`width:${width}px;
                height:${height}px`">
    <div class="screen"
         :style="`width:${zoomable?2560:screenWidth}px;
                height:${zoomable?1080:screenHeight}px;
                zoom: ${zoom};`">
      <slot />
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  name: 'App',
  props: {
    screenFull: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    screenFull () {
      this.toggleScreenFll()
    }
  },
  data () {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, // 浏览器宽
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, // 浏览器高
      screenWidth: '100%', // 大屏宽
      screenHeight: '100%', // 大屏高
      zoom: 1, // 缩放比例
      zoomable: false // 是否缩放
    }
  },
  methods: {
    /**
     * toggleScreenFll
     * @description 全屏切换
     */
    toggleScreenFll () {
      console.log('toggleScreenFll');
      // 切换全屏
      screenfull.toggle()
      // 重新计算尺寸
      this.calcScreen()
    },

    /**
     * calcScreen
     * @description 计算大屏显示尺寸
     * 一、当屏幕尺寸宽或高小于2560*1080时，使用2560*1080进行渲染，然后使用Zoom属性缩放至屏幕大小，方法如下：
     * 1、宽高比小于2.37:宽不够用，设置宽100%，缩放比例为：屏幕宽度/2560
     * 2、宽高比大于2.37:高不够用，设置高100%，缩放比例为：屏幕高度/1080
     * 二、当屏幕尺寸宽或高大于2560*1080时，设置宽度100%，高度自动计算
     * 1、当计算高度大于屏幕高度时，宽度100%，高度为屏幕高度取模1080
     * 2、当计算高度小于等于屏幕高度时，宽度100%，高度为实际计算高度
     */
    calcScreen () {
      // this.screenWidth = "100%"
      // this.screenHeight = "100%"
      if (this.width < 2560 || this.height < 1080) {
        this.zoomable = true
        this.screenWidth = 2560
        this.screenHeight = 1080
        // 以长宽比2.37为临界点计算缩放比例
        if ((this.width / this.height) < (2560 / 1080)) {
          // 宽度不够用
          this.zoom = this.width / 2560
        } else {
          // 高度不够用
          this.zoom = this.height / 1080
        }
      } else {
        // 计算宽度
        const temWidth = this.width
        // 计算高度
        let temHeight = this.width / (2560 / 1080)
        // 当高度大于屏幕实际高度时，高度为：屏幕高度取模1080后*1080
        if (temHeight > this.height) {
          temHeight = Math.floor(this.height / 1080) * 1080
        }
        this.screenWidth = temWidth
        this.screenHeight = temHeight
      }
    }
  },
  mounted () {
    const _this = this

    _this.calcScreen()
    window.onresize = () => {
      return (() => {
        _this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        _this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        _this.calcScreen()
      })()
    }
  }

}
</script>

<style lang="scss">
#container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .screen {
    width: 100% !important;
    height: 100% !important;
    // border: 1px solid red;
    // background-color: grey;
    // box-shadow: inset 0px 0px 200px 0px #000000, inset 0px 0px 400px 0px #000000,
    //   inset 0px 0px 1600px 114px rgba(0, 0, 0, 0.7);
  }
}
</style>
