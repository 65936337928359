<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="row"
           flex="main:justify">
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon1.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            温度
          </div>
          <div class="txt2">
            {{ one }}
          </div>
        </div>
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon2.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            湿度
          </div>
          <div class="txt2">
            {{ two }}
          </div>
        </div>
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon3.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            风向
          </div>
          <div class="txt2">
            {{ three }}
          </div>
        </div>
      </div>
      <div class="row"
           flex="main:justify">
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon3.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            气压
          </div>
          <div class="txt2">
            {{ four }}
          </div>
        </div>
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon4.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            光照
          </div>
          <div class="txt2">
            {{ six }}
          </div>
        </div>
        <div class="item"
             flex="dir:top cross:center">
          <div class="icon">
            <img src="~@/assets/images/weather/icon5.png"
                 alt=""
                 srcset="">
          </div>
          <div class="txt1">
            风速
          </div>
          <div class="txt2">
            {{ seven }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      one: '',
      two: '',
      three: '',
      four: '',
      six: '',
      seven: '',
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-ZNHJC-QXSJ').then(res => {
        if (res) {
          console.log(res.data)
          this.one = res.data.data[0].data_wendu
          this.two = res.data.data[0].data_shidu
          this.three = res.data.data[0].data_xifen
          this.four = res.data.data[0].data_qiya
          this.six = res.data.data[0].data_guangzhao
          this.seven = res.data.data[0].data_fensu
          this.title = res.data.form.name
        }
      })
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.left-top-container {
  .card-body {
    .row {
      width: 100%;
      margin: 15px auto 0;
      .item {
        width: 30%;
        line-height: 22px;
        .icon {
          width: 35%;
          height: 30%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .txt1 {
          font-size: 12px;
          font-weight: 600;
          color: #f2f2f2;
        }

        .txt2 {
          font-size: 12px;
          font-weight: 400;
          color: #f2f2f2;
        }
      }
    }
  }
}
</style>