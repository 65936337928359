<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: yjk
 * @LastEditTime: 2023-11-27 11:46:37
-->
<template>
  <div class="aside-left-container" flex="dir:top box:mean">
    <left-top-item @deatil="getDeatil" />
    <left-middle-item @deatil="getDeatil" />
    <left-bottom-item :region="regionId" />
  </div>
</template>

<script>
import leftTopItem from "./leftTopItem";
import leftMiddleItem from "./leftMiddleItem";
import leftBottomItem from "./leftBottomItem";
export default {
  props: {
    region: {
      type: String,
      default: "",
    },
  },
  name: "AsideLeft",
  components: {
    leftTopItem,
    leftMiddleItem,
    leftBottomItem,
  },

  data() {
    return {
      regionId: "",
    };
  },

  watch: {
    region(val) {
      this.regionId = val;
    },
  },

  methods: {
    getDeatil(e) {
      this.$emit("deatil", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-left-container {
  cursor: default;
  width: 100%;
  height: 100%;
}
</style>
