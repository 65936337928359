export default {
  title: {
    text: '单位: 公顷',
    left: 'center',
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {
    show: false,
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['人工造林', '有林地造林', '成林抚育', '木材'],
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        color: 'white'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        color: 'white'
      },
      splitLine: {
        lineStyle: {
          color: '#E8E8E8',
          type: 'dashed'
        }
      }
    }
  ],
  series: [
    {
      type: 'bar',
      barWidth: '60%',
      data: [933, 2900, 2333, 3709],
      itemStyle: {
        color: '#A8FF24'
      },
      label: {
        show: true,
        position: 'top',
        textStyle: {
          color: 'white'
        } // 标签显示在柱子的顶部
      }
    }
  ]
};