<template>
  <div class="left-top-container mt"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        <a href="http://assp.lztl.cn/?#/wisdom-farm/farm/plant-plan/index"
           target="_blank"> {{ title }}</a>
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div flex
           class="row-1">
        <div class="thumb">
          <img :src="img">
        </div>
        <div class="detail"
             flex="dir:top main:justify">
          <div class="row">
            生长周期：<span>{{ one }}</span>
          </div>
          <div class="row">
            土质：<span>{{ two }}</span>
          </div>
          <div class="row">
            全年日照时数不小于1300个小时
          </div>
        </div>
      </div>
      <div class="row-2"
           flex="dir:top main:justify">
        <div class="row"
             flex>
          <span>专家建议：</span>
          {{ three }}
        </div>
        <div class="row"
             flex>
          <span>施肥建议：</span>
          <div flex="dir:top">
            <div>{{ four }}</div>
            <div style="margin-top:20px;">
              {{ five }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      title: '',
      one: '',
      two: '',
      three: '',
      four: '',
      img: '',
      five: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-ZNHJC-SZSJ').then(res => {
        console.log(res);
        if (res) {
          this.one = res.data.data[0].msg_one
          this.two = res.data.data[0].data_tuzhi
          this.three = res.data.data[0].data_zjjy
          this.four = res.data.data[0].data_szsfjy
          this.img = res.data.data[0].msg_img
          this.five = res.data.data[0].data_msga
          this.title = res.data.form.name
        }
      })
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
a {
  text-decoration: none;
  outline: none;
  color: #fff;
}
.left-top-container {
  .card-body {
    width: 90%;
    .row-1 {
      margin-top: 15px;
      .thumb {
        width: 130px;
        height: 130px;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
          background-size: cover;
        }
      }

      .detail {
        padding-left: 30px;
        .row {
          font-size: 12px;
          font-weight: 400;
          color: #979592;

          span {
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            line-height: 12px;
          }
        }
      }
    }

    .row-2 {
      .row {
        margin-top: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        line-height: 12px;
        span {
          font-size: 12px;
          font-weight: 400;
          color: #979592;
        }
      }
    }
  }
}
</style>