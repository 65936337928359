<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-19 15:05:39
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="公检记录"
                 :visible.sync="examineListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="tableData"
                    @cell-click="onExamineDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="batchId"
                             label="批次号"
                             width="120" />
            <el-table-column prop="factoryName"
                             label="仓库名称"
                             width="120" />
            <el-table-column prop="time"
                             label="公检日期"
                             width="100" />
          </el-table>
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="公检详情"
                 :visible.sync="examineDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              公检单号
            </div>
            <div class="right">
              1055565255255
            </div>
          </div>
          <div class="line" />
          <div class="box">
            <div class="left">
              公检日期
            </div>
            <div class="right">
              2022-6-2
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              合计毛重
            </div>
            <div class="right">
              300
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              合计皮重
            </div>
            <div class="right">
              200
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              合计净重
            </div>
            <div class="right">
              100
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              平均回顾
            </div>
            <div class="right">
              30
            </div>
          </div>
          <div class="line" />
          <div class="box">
            <div class="left">
              合计公重
            </div>
            <div class="right">
              200
            </div>
          </div>
          <div class="line" />
        </div>
        <!-- <div class="hintTxt"
             v-if="!inspectionData">
          暂无数据
        </div> -->
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      examineListDrawer: false,
      examineDrawer: false,
      tableData: [],
      inspectionData: {}//公检详情数据
    }
  },

  watch: {
    id (val) {
      if (val != '') {
        this.tableData = [
          {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          },
          {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          },
          {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          }, {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          }, {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          }, {
            id: 1,
            batchId: 1255625522,
            factoryName: '乌鲁木齐加工厂',
            time: 2022 - 1 - 2
          }
        ]
        this.examineListDrawer = true
      }
    },
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('examineShow', '')
    },

    onExamineDetail () {
      this.examineDrawer = true
    }
  }
}
</script>

<style lang="scss">
.txtboxItem {
  width: 100%;

  .el-table__empty-text {
    color: #f3f3f3ff;
  }

  .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }

  // 鼠标悬停样式
  .el-table tbody tr:hover > td {
    background-color: #00256bff !important;
  }

  .el-table tr {
    background-color: transparent !important;
  }
  .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }
  .el-table::before {
    height: 0;
  }
}
</style>
<style lang="scss" scoped>
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .hintTxt {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    opacity: 0.9;
    text-align: center;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
