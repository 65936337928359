export default {
  title: {
    text: '单位: 元/公斤',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  color: ['#68FFD6', '#EB5E00', '#5AFF2F', '#FF2DFF', '#008CFF'],
  legend: {
    textStyle: {
      color: '#FFFFFF'
    },
    itemWidth: 26,
    itemHeight: 6,
    padding: [
      20,  // 上
      0, // 右
      20,  // 下
      0, // 左
    ],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#FFFFFF'
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
    axisPointer: {// 坐标轴指示器，坐标轴触发有效
      type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    },
    formatter: '{b} : {c}元/公斤',
  },
  xAxis: {
    type: 'category',
    data: ['苜蓿', '玉米', '青储', '棉粕', '甘草'],
    axisLabel: {
      color: 'white'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
  },
  grid: {
    top: '17%',
    bottom: '10%',
    left: '10%',
  },
  series: [
    {
      name: '',// '水培',
      data: [2.7, 2.6, 5, 0.7, 3.6, 1.4],// [10, 20, 20, 20, 22],
      type: 'bar',
      barWidth: '12',
      lineStyle: {
        color: '#00FFD4',
      },
      stack: 'total',
      label: {
        show: true,
        position: "top",
        color: 'white'
      },
      itemStyle: {
        normal: {
          barBorderRadius: [0, 3, 3, 0]
        }
      },
    }
  ]
}
