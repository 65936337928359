export default {
  legend: {
    data: ['按现行价格计算', '按可比价格计算'],
    textStyle: {
      color: 'white',
      fontSize: 18,
    }
  },
  xAxis: {
    show: false,
    type: 'category',
    data: ['产业结构'],
    labelStyle: {
      color: 'white'
    }
  },
  yAxis: {
    type: 'value',
    show: false,
    axisLabel: {
      show: false
    },
    axisLine: {
      show: false
    }
  },
  series: [
    {
      name: '按现行价格计算',
      data: [14624.91],
      type: 'bar',
      zIndex: -1,
      barGap: '-100%',
      itemStyle: {
        color: '#02DF82',
        barBorderRadius: [20, 20, 0, 0]
      },
      label: {
        show: true,
        formatter: function({ data }) {
          return `${data}万元`
        },
        position: 'top',
        textStyle: {
          color: 'white',
          fontSize: 18,
        }
      }
    },
    {
      name: '按可比价格计算',
      data: [7797.58],
      type: 'bar',
      itemStyle: {
        color: '#F9F900',
        barBorderRadius: [20, 20, 0, 0]
      },
      label: {
        show: true,
        formatter: function({ data }) {
          return `${data}万元`
        },
        position: 'top',
        textStyle: {
          fontSize: 18,
          color: 'white'
        }
      }
    }
  ]
};