<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="option"
                       style="width:100%;height:100$;" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      // config: {
      //   rowNum: 6,
      //   columnWidth: [120, 110, 110, 110],
      //   header: ['水源类型', '采集量', '采集区域', '水源情况'],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //   ]
      // }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getData()
    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-SL-QXFBLB').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one,
              item.msg_two,
              item.msg_three,
              ]
            )
          });
          this.title = res.data.form.name
          _GetAspScreenComponentData('NEWKEL-SL-QXFBLBBT').then(res => {
            if (res) {
              const msgData2 = []
              res.data.data.forEach(item => {
                msgData2.push(
                  [item.msg_one,
                  item.msg_two,
                  item.msg_three,
                  ]
                )
              });
              let params = {
                rowNum: 6,
                columnWidth: [120, 150, 100],
                header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
                headerBGC: 'transparent',
                oddRowBGC: 'transparent',
                evenRowBGC: 'transparent',
                align: ['left', 'center', 'center'],
                data: msgData,
              }

              this.option = params
            }
          })
        }
      })

    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>