<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        <a href="http://assp.lztl.cn/?#/wisdom-farm/farm/plant-plan/index"
           target="_blank"> {{ title }}</a>
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div style="width: 100%;font-size: 14px;font-weight: bold;color: #FFF;text-align: center;margin-bottom: 30px;margin-top: 20px;">
        {{ title1 }}
      </div>
      <dv-scroll-board :config="option"
                       style="width:100%;height:80%;" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftBottomItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      data: {},//数据
      title: '',
      title1: '',
      option: {},
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      // config: {
      //   rowNum: 6,
      //   columnWidth: [200, 200],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['适宜土壤湿度', '70%-80%'],
      //     ['适宜土壤温度', '8-14℃'],
      //     ['适宜空气温度', '8-14℃'],
      //     ['需水量', '80%'],
      //   ]
      // }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getData()
    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZNHJC-SZBZMX').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push([
              item.msg_one,
              item.msg_two
            ])
          });
          //console.log(res);
          //this.config.data = [msgData][0]
          this.title = res.data.form.name
          this.title1 = res.data.data[0].data_title

          let params = {
            rowNum: 6,
            columnWidth: [200, 200],
            //header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            align: ['center', 'center'],
            data: msgData,
          }

          this.option = params
        }
      })
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
a {
  text-decoration: none;
  outline: none;
  color: #fff;
}
.left-top-container {
  .card-body {
    .dv-scroll-board::v-deep {
      .header {
        .header-item {
          border: 0.5px solid rgba(232, 232, 232, 0.1);
        }
      }
      .rows {
        .row-item {
          font-size: 12px;
          font-weight: 400;
          color: #f2f2f2;
          line-height: 12px;

          .ceil {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
      }
    }
  }
}
</style>