<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:01:38
-->
<template>
  <div style="height:100%">
    <div class="container"
         flex="dir:top main:center"
         style="width: 100%;height:100%;background: #000;">
      <div style="height:100%"
           flex="main:justify">
        <div class="left">
          <aside-left />
        </div>
        <div class="centerWrapper">
          <centerCenter />
        </div>
        <div class="right">
          <aside-right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import centerCenter from './centerCenter'
import asideRight from './asideRight'


export default {
  name: 'App',
  components: {
    asideLeft,
    centerCenter,
    asideRight,
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";

.centerWrapper {
  flex: 1;
  height: 100%;
}
</style>
