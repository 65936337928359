/*
 * @Description: 侧右中 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:01:00
 */
// 农机补贴
// eslint-disable-next-line camelcase
const classxAxis = ['2017', '2018', '2019', '2020', '2021', '2022', '2023'] // 列标
// const classxAxis = [] // 列标
// eslint-disable-next-line camelcase
const classData = [11.21 * 0.69, 11.13 * 0.69, 11.56 * 0.69, 11.79 * 0.69, 12.5 * 0.69, 13.3 * 0.69, 13.4 * 0.69] // 柱图1数据
// const classData = [] // 柱图1数据
// eslint-disable-next-line camelcase
const classData1 = [36.54 * 0.5, 36.97 * 0.5, 37.33 * 0.5, 38.15 * 0.5, 39.7 * 0.5, 40.26 * 0.5, 42.3 * 0.5] // 柱图2数据
// const classData1 = [] // 柱图2数据
// eslint-disable-next-line camelcase
const classData2 = [1.17 * 6, 1.23 * 6, 1.11 * 6, 1.24 * 6, 1.44 * 6, 1.49 * 6, 2.55 * 6] // 柱图2数据
// const classData2 = [] // 柱图2数据
const classType = ['棉花', '香梨', '肉类'] // 图例
export default {
  animationDuration: 10000,
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#FFF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: 50,
    left: 50
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: classxAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#ffffff',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#FFF'
      }
    }
  },
  yAxis: {
    min: 0,
    max: 40,
    inverse: false,
    name: '亿元',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#FFF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#FFF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#495A4D',
        type: 'dashed'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'line',
      lineStyle: {
        color: '#0095ff',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#0095ff"
      },
      symbolSize: 9,
      data: classData
    },
    {
      name: classType[1],
      type: 'line',
      lineStyle: {
        color: '#84f538',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#84f538"
      },
      symbolSize: 9,
      data: classData1
    },
    {
      name: classType[2],
      type: 'line',
      lineStyle: {
        color: '#ffdd00',
        shadowOffsetX: 0,
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: "#ffdd00"
      },
      symbolSize: 9,
      data: classData2
    }
  ]
}
