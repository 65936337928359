<template>
  <div class="left-top-container" flex="dir:top cross:center">
    <div class="card-head" flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body" flex="dir:top">
      <dv-scroll-board :config="option" style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      // config: {
      //   rowNum: 6,
      //   columnWidth: [120, 110, 110, 110],
      //   header: ['水源类型', '采集量', '采集区域', '水源情况'],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //     ['水源类型1', '10', '20', '30'],
      //   ]
      // }
    }
  },

  watch: {
    region(val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted() {
    this.getData()
    this.getSreenData()
  },

  methods: {
    getSreenData() {
      // _GetAspScreenComponentData('NEWKEL-SL-DXSYFB').then(res => {
      //   if (res) {
      //     const msgData = []
      //     res.data.data.forEach(item => {
      //       msgData.push(
      //         [item.msg_one,
      //         item.msg_two,
      //         item.msg_three + '亿立方',
      //         ]
      //       )
      //     });

      //     this.title = res.data.form.name
      //     _GetAspScreenComponentData('NEWKEL-SL-DXSYFBBT').then(res => {
      //       if (res) {
      //         const msgData2 = []
      //         res.data.data.forEach(item => {
      //           msgData2.push(
      //             [item.msg_one,
      //             item.msg_two,
      //             item.msg_three,
      //             ]
      //           )
      //         });
      //         let params = {
      //           rowNum: 6,
      //           columnWidth: [120, 110, 110, 110],
      //           align: ['center', 'center', 'center', 'center'],
      //           header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
      //           headerBGC: 'transparent',
      //           oddRowBGC: 'transparent',
      //           evenRowBGC: 'transparent',
      //           data: msgData,
      //         }

      //         this.option = params
      //       }
      //     })
      //   }
      // })
      this.option = {
        rowNum: 8,
        header: [
          "<div class='header-item' title='乡镇名称'>乡镇名称</div>",
          "<div class='header-item' title='居民生活用水量'>居民生活用水量</div>",
          "<div class='header-item' title='牲畜用水量'>牲畜用水量</div>",
          "<div class='header-item' title='乡镇企业用水量'>乡镇企业用水量</div>",
          "<div class='header-item' title='管网漏失水量'>管网漏失水量</div>",
          "<div class='header-item' title='未预见水量'>未预见水量</div>",
          "<div class='header-item' title='水厂自用水量'>水厂自用水量</div>",
          "<div class='header-item' title='合计'>合计</div>"
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        data: [
          [
          "<div class='ceil' title='铁克其乡'>铁克其乡</div>",
          "<div title='69.85'>69.85</div>",
          "<div title='4.73'>4.73</div>",
          "<div title='7.46'>7.46</div>",
          "<div title='8.20'>8.20</div>",
          "<div title='16.41'>16.41</div>",
          "<div title='4.10'>4.10</div>",
          "<div title='110.75'>110.75</div>"],
          ["<div class='ceil' title='英下乡'>英下乡</div>",
          "<div title='15.44'>15.44</div>",
          "<div title='5.55'>5.55</div>",
          "<div title='2.10'>2.10</div>",
          "<div title='2.31'>2.31</div>",
          "<div title='4.62'>4.62</div>",
          "<div title='4.10'>4.10</div>",
          "<div title='110.75'>110.75</div>"],
          ["<div class='ceil' title='恰尔巴格乡'>恰尔巴格乡</div>",
          "<div title='43.09'>43.09</div>",
          "<div title='5.39'>5.39</div>",
          "<div title='4.85'>4.85</div>",
          "<div title='5.33'>5.33</div>",
          "<div title='10.67'>10.67</div>",
          "<div title='2.67'>2.67</div>",
          "<div title='72.00'>72.00</div>"],
          ["<div class='ceil' title='兰干乡'>兰干乡</div>",
          "<div title='21.79'>21.79</div>",
          "<div title='6.50'>6.50</div>",
          "<div title='2.83'>2.83</div>",
          "<div title='3.11'>3.11</div>",
          "<div title='6.22'>6.22</div>",
          "<div title='1.56'>1.56</div>",
          "<div title='42.01'>42.01</div>"],
          ["<div class='ceil' title='阿瓦提乡'>阿瓦提乡</div>",
          "<div title='28.35'>28.35</div>",
          "<div title='9.53'>9.53</div>",
          "<div title='3.79'>3.79</div>",
          "<div title='4.17'>4.17</div>",
          "<div title='8.33'>8.33</div>",
          "<div title='2.08'>2.08</div>",
          "<div title='56.25'>56.25</div>"],
          ["<div class='ceil' title='哈拉玉宫乡'>哈拉玉宫乡</div>",
          "<div title='20.07'>20.07</div>",
          "<div title='10.64'>10.64</div>",
          "<div title='3.07'>3.07</div>",
          "<div title='3.38'>3.38</div>",
          "<div title='6.76'>6.76</div>",
          "<div title='1.69'>1.69</div>",
          "<div title='45.60'>45.60</div>"],
          ["<div class='ceil' title='和什力克乡'>和什力克乡</div>",
          "<div title='14.64'>14.64</div>",
          "<div title='14.74'>14.74</div>",
          "<div title='2.94'>2.94</div>",
          "<div title='3.23'>3.23</div>",
          "<div title='6.46'>6.46</div>",
          "<div title='1.62'>1.62</div>",
          "<div title='43.63'>43.63</div>"],
          ["<div class='ceil' title='托布力其乡'>托布力其乡</div>",
          "<div title='14.76'>14.76</div>",
          "<div title='9.44'>9.44</div>",
          "<div title='2.42'>2.42</div>",
          "<div title='2.66'>2.66</div>",
          "<div title='5.32'>5.32</div>",
          "<div title='1.33'>1.33</div>",
          "<div title='35.93'>35.93</div>"],
          ["<div class='ceil' title='普惠乡'>普惠乡</div>",
          "<div title='5.79'>5.79</div>",
          "<div title='5.42'>5.42</div>",
          "<div title='1.12'>1.12</div>",
          "<div title='1.23'>1.23</div>",
          "<div title='2.47'>2.47</div>",
          "<div title='0.62'>0.62</div>",
          "<div title='16.65'>16.65</div>"],
          ["<div class='ceil' title='上户镇'>上户镇</div>",
          "<div title='18.28'>18.28</div>",
          "<div title='4.84'>4.84</div>",
          "<div title='2.31'>2.31</div>",
          "<div title='2.54'>2.54</div>",
          "<div title='5.09'>5.09</div>",
          "<div title='1.27'>1.27</div>",
          "<div title='34.33'>34.33</div>"],
          ["<div class='ceil' title='西尼尔镇'>西尼尔镇</div>",
          "<div title='14.83'>14.83</div>",
          "<div title='5.20'>5.20</div>",
          "<div title='2.00'>2.00</div>",
          "<div title='2.20'>2.20</div>",
          "<div title='4.41'>4.41</div>",
          "<div title='1.10'>1.10</div>",
          "<div title='29.74'>29.74</div>"],
          ["<div class='ceil' title='普惠牧场'>普惠牧场</div>",
          "<div title='2.17'>2.17</div>",
          "<div title='6.57'>6.57</div>",
          "<div title='0.87'>0.87</div>",
          "<div title='0.96'>0.96</div>",
          "<div title='1.92'>1.92</div>",
          "<div title='0.48'>0.48</div>",
          "<div title='12.98'>12.98</div>"],
          ["<div class='ceil' title='经济牧场'>经济牧场</div>",
          "<div title='2.51'>2.51</div>",
          "<div title='1.51'>1.51</div>",
          "<div title='0.40'>0.40</div>",
          "<div title='0.44'>0.44</div>",
          "<div title='0.88'>0.88</div>",
          "<div title='0.22'>0.22</div>",
          "<div title='5.97'>5.97</div>"],
          ["<div class='ceil' title='普惠农场'>普惠农场</div>",
          "<div title='19.27'>19.27</div>",
          "<div title='2.71'>2.71</div>",
          "<div title='2.20'>2.20</div>",
          "<div title='2.42'>2.42</div>",
          "<div title='4.83'>4.83</div>",
          "<div title='1.21'>1.21</div>",
          "<div title='32.63'>32.63</div>"],
          ["<div class='ceil' title='包头湖农场'>包头湖农场</div>",
          "<div title='5.60'>5.60</div>",
          "<div title='4.74'>4.74</div>",
          "<div title='1.03'>1.03</div>",
          "<div title='1.14'>1.14</div>",
          "<div title='2.28'>2.28</div>",
          "<div title='0.57'>0.57</div>",
          "<div title='15.36'>15.36</div>"],
          ["<div class='ceil' title='库尔楚园艺场'>库尔楚园艺场</div>",
          "<div title='7.85'>7.85</div>",
          "<div title='4.49'>4.49</div>",
          "<div title='1.23'>1.23</div>",
          "<div title='1.36'>1.36</div>",
          "<div title='2.71'>2.71</div>",
          "<div title='0.68'>0.68</div>",
          "<div title='18.32'>18.32</div>"],
          ["<div class='ceil' title='巴州沙依东园艺场'>巴州沙依东园艺场</div>",
          "<div title='24.66'>24.66</div>",
          "<div title='4.49'>4.49</div>",
          "<div title='2.91'>2.91</div>",
          "<div title='3.21'>3.21</div>",
          "<div title='6.41'>6.41</div>",
          "<div title='1.60'>1.60</div>",
          "<div title='43.28'>43.28</div>"],
          ["<div class='ceil' title='阿瓦提农场'>阿瓦提农场</div>",
          "<div title='29.59'>29.59</div>",
          "<div title='2.43'>2.43</div>",
          "<div title='3.20'>3.20</div>",
          "<div title='3.52'>3.52</div>",
          "<div title='7.04'>7.04</div>",
          "<div title='1.76'>1.76</div>",
          "<div title='47.55'>47.55</div>"],
          ["<div class='ceil' title='合计'>合计</div>",
          "<div title='358.52'>358.52</div>",
          "<div title='108.92'>108.92</div>",
          "<div title='46.74'>46.74</div>",
          "<div title='51.42'>51.42</div>",
          "<div title='102.84'>102.84</div>",
          "<div title='25.71'>25.71</div>",
          "<div title='694.15'>694.15</div>"],
        ]
      }

    },
    getData() {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>