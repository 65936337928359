<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-15 13:11:28
-->
<template>
  <div class="container">
    <div>
      <messageFarmers @farmerShow="getFarmerShow"
                      :id="farmerId" />
    </div>

    <div>
      <messagetransport @transportShow="getTransportShow"
                        :id="transportId" />
    </div>

    <div>
      <messagesell @sellShow="getSellShow"
                   :id="sellId" />
    </div>
  </div>
</template>
<script>
import messageFarmers from './component/messageFarmers'//农户
import messagetransport from './component/messagetransport'//拉运
import messagesell from './component/messagesell'
export default {
  components: {
    messageFarmers,
    messagetransport,
    messagesell
  },

  props: {
    cottonEggParams: {
      type: Object,
      default: null,
    },
  },
  name: 'CenterPopu',
  data () {
    return {
      transportId: null,//拉运id
      sellId: null,//交售id
      farmerId: null,//农户id
    }
  },

  watch: {
    cottonEggParams (val) {
      if (val.type === 'farmer') {
        this.farmerId = val.id
      }

      if (val.type === 'sell') {
        this.sellId = val.id
      }

      if (val.type === 'transport') {
        this.transportId = val.id
      }
    },
  },

  methods: {
    getFarmerShow (val) {
      this.farmerId = val
    },

    getTransportShow (val) {
      this.transportId = val
    },

    getSellShow (val) {
      this.sellId = val
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
</style>
