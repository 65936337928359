<!--
 * @Description: 用一句话描述文件的作用
 * @Author: yjk
 * @Date: 2023-11-24 14:27:34
 * @LastEditors: yjk
 * @LastEditTime: 2023-11-27 11:57:57
-->
<template>
  <div class="info">
    <div v-if="form">
      <div class="title">基础信息</div>
      <el-form label-position="left" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="项圈编号">
              {{ form.deviceId }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="动物类型">
              {{ form.animalType }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="动物品类">
              {{ form.animalCategory }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="动物品种">
              {{ form.animalBreed }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="历史产胎次数">
              {{ form.historyBirth }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源">
              {{ form.origin }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别">
              {{ form.animalGender }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="动物耳号">
              {{ form.animalEarNo }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生理阶段" v-if="form.animalType === '牛'">
              {{ form.physiologicalStage }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="title" style="margin-top: 20px">出生信息</div>
      <el-form label-position="left" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="出生日期">
              {{ form.birthday ? formatDate(form.birthday) : "无" }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入场日期">
              {{ form.inTime ? formatDate(form.inTime) : "无" }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出生体重（KG）">
              {{ form.birthWeight }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="断奶天数">
              {{ form.ablactationDay }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="断奶体重（KG）">
              {{ form.ablactationWeight }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="乳头数量（左）">
              {{ form.nippleLeftNum }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="乳头数量（右）">
              {{ form.nippleRightNum }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-else>
      <el-empty description="当前设备未绑定" />
    </div>
  </div>
</template>
<script>
import util from "@/libs/util";
import { getIotWhInfo } from "@/api/assp-trace-collectData/index";
export default {
  props: {
    deviceId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    deviceId: {
      handler(val) {
        if (val) {
          this.getDeatil();
        }
      },
      deep: true,
      immediate: true, // 监听的数据 如果是初始值要不要执行
    },
  },
  methods: {
    getDeatil() {
      getIotWhInfo({ deviceId: this.deviceId }).then((res) => {
        this.form = res;
      });
    },

    formatDate(time) {
      return util.formatDate(time);
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  color: #fff;
  ::v-deep .el-form-item__label {
    color: #fff;
  }
  ::v-deep .el-col {
    height: 30px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>
