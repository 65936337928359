<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 17:53:44
-->
<template>
  <div class="aside-center-container"
       flex="dir:top">
    <left-top-item />
    <left-middle-item class="center" />
    <left-bottom-item />
  </div>
</template>

<script>
import leftTopItem from './leftTopItem'
import leftMiddleItem from './leftMiddleItem'
import leftBottomItem from './leftBottomItem'
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  name: 'AsideLeft',
  components: {
    leftTopItem,
    leftMiddleItem,
    leftBottomItem
  },

  data () {
    return {
      zoom: 1
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
@import url("@/assets/style/aside.scss");
</style>