/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 13:20:31
 * @LastEditors: Pancras
 * @LastEditTime: 2022-12-19 17:13:47
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'flex.css'
import store from './store'
import "@/libs/lib-flexible/flexible";

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入图表，图表后缀都为 Chart
import { LineChart, RadarChart, BarChart, PieChart, GaugeChart, CandlestickChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent
} from 'echarts/components';
import 'echarts-gl';
require('echarts/lib/chart/bar.js');
require('echarts/lib/component/title.js');
require('echarts/lib/component/tooltip.js');

// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

import dataV from '@jiaminghi/data-view'
import drag from '@/components/drag/index';
Vue.use(drag)

Vue.use(dataV)
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  RadarChart,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  GaugeChart,
  ToolboxComponent,
  CandlestickChart
]);
Vue.prototype.$echarts = echarts
Vue.prototype.$store = store;

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')