<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2023-02-03 18:12:25
-->
<template>
  <div>
    <div id="mapDiv" />
    <el-dialog title="监控详情" :visible.sync="dialogVisible" width="50%" :modal="false" @closed="dialogClosed">
      <video ref="playerRef" style="width: 100%;" muted autoplay controls></video>
    </el-dialog>
  </div>
</template>
<script>
import { _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import util from '@/libs/util'
import { _GetVideo, _GetDevice } from '@/api/video/video'
import flvjs from "flv.js";
export default {
  props: {
    markState: {
      type: String,
      default: '1'
    }
  },

  data() {
    return {
      map: null,//地图对象
      T: null,//地图实例
      markerList: [],//点数组
      infoWin: null,//信息窗口
      screenHeight: document.body.clientHeight, // 屏幕高度
      cottonDataA: [],
      cottonDataB: [],
      cottonDataC: [],
      cottonDataD: [],
      cottonData: [],//视频监控
      dialogVisible: false,
      player1: null,
      player2: null
    }
  },

  mounted() {
    this.T = window.T
    var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?' +
      'SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles' +
      '&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0e37d14abb5af6aa4caa68578e439c2e'
    // TileLayer添加图层所用的构造函数
    var lay = new this.T.TileLayer(imageURL, { minZoom: 10, maxZoom: 20 }) // 地图图层
    var config = { layers: [lay] }

    this.map = new this.T.Map('mapDiv', config) // 地图实例
    this.map.centerAndZoom(new this.T.LngLat(86.16064373535157, 41.71617988264258), 12)
    // //允许鼠标双击放大地图
    this.map.enableScrollWheelZoom()
    this.map.setMapType(window.TMAP_HYBRID_MAP) // 设置地图位地星混合图层

    this.getCottonDataA()//棉蛋mark


    /*公布方法 */

    //关闭信息窗口
    window.onCloseInfoWindow = () => {
      this.onCloseInfoWindow()
    }
  },

  watch: {
    markState(val) {
      this.map.clearOverLays();
      this.markerList = []
      if (val === '1') {
        this.getCottonDataA()//mark
      }

      if (val === '2') {
        this.getCottonDataB()//mark
      }

      if (val === '3') {
        this.getCottonDataC()//mark
      }

      if (val === '4') {
        this.getCottonDataD()//mark
      }

      if (val === '5') {
        // this.getCottonData()//mark
        this.getVideoList()
      }
    }
  },

  methods: {

    getCottonDataA() {
      _GetAspScreenComponentData('NEWKEL-DT-LZGY').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_name,
              msg1: item.data_msg,
              name2: item.data_namea,
              msg2: item.data_msga,
              name3: item.data_nameb,
              msg3: item.data_msgb,
              name4: item.data_namec,
              msg4: item.data_msgc,
            })
          })

          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonDataA = arr4
          this.onCottonTwoA()
        }
      })
    },
    onCottonTwoA() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/xlgy.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataA.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name4 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg4 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },

    getCottonDataB() {
      _GetAspScreenComponentData('NEWKEL-DT-TRSCQ').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_name,
              msg1: item.data_msg,
              name2: item.data_namea,
              msg2: item.data_msga,
              name3: item.data_nameb,
              msg3: item.data_msgb,
              name4: item.data_namec,
              msg4: item.data_msgc,
            })
          })
          this.cottonDataB = arr4
          this.onCottonTwoB()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonDataB = arr4
          this.onCottonTwoB()
        }
      })
    },
    onCottonTwoB() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/cqy.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataB.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name4 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg4 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },


    getCottonDataC() {
      _GetAspScreenComponentData('NEWKLE-DT-CQY').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_name,
              msg1: item.data_msg,
              name2: item.data_namea,
              msg2: item.data_msga,
              name3: item.data_nameb,
              msg3: item.data_msgb,
              name4: item.data_namec,
              msg4: item.data_msgc,
            })
          })
          this.cottonDataC = arr4
          this.onCottonTwoC()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonDataC = arr4
          this.onCottonTwoC()
        }
      })
    },
    onCottonTwoC() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/cqy.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataC.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name4 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg4 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },

    getCottonDataD() {
      _GetAspScreenComponentData('NEWKEL-DT-QXZ').then(res => {
        if (res) {
          const arr4 = []
          res.data.data.forEach(item => {
            console.log(item);
            arr4.push({
              lng: Number(item.data_lng),
              lat: Number(item.data_lat),
              title: item.data_title,
              name1: item.data_name,
              msg1: item.data_msg,
              name2: item.data_namea,
              msg2: item.data_msga,
              name3: item.data_nameb,
              msg3: item.data_msgb,
              name4: item.data_namec,
              msg4: item.data_msgc,
            })
          })
          this.cottonDataD = arr4
          this.onCottonTwoD()
          // 点位集合获取所有点位中间位置
          const latlng = arr4.length > 1 ? util.getPolygonCenter(arr4) : arr4[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

          var bounds = this.map.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();
          var lngSpan = Math.abs(sw.lng - ne.lng);
          var latSpan = Math.abs(ne.lat - sw.lat);
          var randomPoints = []
          for (var i = 0; i < 15; i++) {
            //创建图片对象
            // 点位经纬度坐标
            const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
            randomPoints.push(point)
          }

          randomPoints.map(item => {
            item.title = arr4[0].title,
              item.name1 = arr4[0].name1,
              item.msg1 = arr4[0].msg1,
              item.name2 = arr4[0].name2,
              item.msg2 = arr4[0].msg2,
              item.value = arr4[0].value
            return item
          })
          arr4.push(...randomPoints)
          this.cottonDataD = arr4
          this.onCottonTwoD()
        }
      })
    },
    onCottonTwoD() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/qxz.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonDataD.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          "<div style='width: 100%;color:#fff'>" +

          "<div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;'>" + item.title + "</div>" +
          "<img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>" +
          "</div>" +
          "<div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +

          "<div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>" +
          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name1 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg1 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name2 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg2 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name3 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg3 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +

          "<div style='width: 43%'>" +
          "<div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>" +
          "<div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9;white-space: nowrap;overflow: hidden;'>" + item.name4 + "</div>" +
          "<div style='font-size: 16px;color: #ffff;opacity:0.7;white-space: nowrap;overflow: hidden;'>" + item.msg4 + "</div>" +
          "</div>" +
          "<div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>" +
          "</div>" +
          "</div>";

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true, // 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口

        })
      })
    },
    //视频监控
    // getCottonData () {
    //   _GetAspScreenComponentData('NEWKEL-DT-SPJK').then(res => {
    //     if (res) {
    //       const arr1 = []
    //       res.data.data.forEach(item => {
    //         arr1.push({
    //           lng: Number(item.data_lng),
    //           lat: Number(item.data_lag),
    //           title: item.data_title,
    //           name1: item.data_namea,
    //           msg1: item.data_msga,
    //           name2: item.data_nameb,
    //           msg2: item.data_msgb,
    //           name3: item.data_namec,
    //           msg3: item.data_msgc,
    //           name4: item.data_named,
    //           msg4: item.data_msgd,
    //           name5: item.data_namee,
    //           msg5: item.data_msge,
    //           value: item.data_value
    //         })
    //       })

    //       // 点位集合获取所有点位中间位置
    //       const latlng = arr1.length > 1 ? util.getPolygonCenter(arr1) : arr1[0]
    //       this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 11)

    //       var bounds = this.map.getBounds();
    //       var sw = bounds.getSouthWest();
    //       var ne = bounds.getNorthEast();
    //       var lngSpan = Math.abs(sw.lng - ne.lng);
    //       var latSpan = Math.abs(ne.lat - sw.lat);
    //       var randomPoints = []
    //       for (var i = 0; i < 15; i++) {
    //         //创建图片对象
    //         // 点位经纬度坐标
    //         const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
    //         randomPoints.push(point)
    //       }

    //       randomPoints.map(item => {
    //         item.title = arr1[0].title,
    //           item.name1 = arr1[0].name1,
    //           item.msg1 = arr1[0].msg1,
    //           item.name2 = arr1[0].name2,
    //           item.msg2 = arr1[0].msg2,
    //           item.value = arr1[0].value
    //         return item
    //       })
    //       arr1.push(...randomPoints)
    //       this.cottonData = arr1
    //       this.onCotton()
    //     }
    //   })
    // },
    //获取视频列表
    getVideoList() {
      _GetDevice().then((res) => {
        if (res.data) {
          console.log('res', res.data)

          const videoList = res.data.map(item => ({
            lng: Number(item.longitude),
            lat: Number(item.latitude),
            name: item.devicename,
            deviceid: item.deviceid,
            online: item.online == '1' ? '在线' : '离线',
            status: item.status == '1' ? '禁用' : '启用',
            address: item.address ? item.address : '暂无数据'
          }));
          // 点位集合获取所有点位中间位置
          const latlng = videoList.length > 1 ? util.getPolygonCenter(videoList) : videoList[0]
          this.map.centerAndZoom(new this.T.LngLat(latlng.lng, latlng.lat), 13)

          // var bounds = this.map.getBounds();
          // var sw = bounds.getSouthWest();
          // var ne = bounds.getNorthEast();
          // var lngSpan = Math.abs(sw.lng - ne.lng);
          // var latSpan = Math.abs(ne.lat - sw.lat);
          // var randomPoints = []
          // for (var i = 0; i < 15; i++) {
          //   //创建图片对象
          //   // 点位经纬度坐标
          //   const point = { lng: sw.lng + lngSpan * (Math.random() * 0.7), lat: ne.lat - latSpan * (Math.random() * 0.7) }
          //   randomPoints.push(point)
          // }

          // randomPoints.map(item => {
          //   item.name = videoList[33].name,
          //     item.deviceid = videoList[33].deviceid,
          //     item.online = videoList[33].online,
          //     item.status = videoList[33].status,
          //     item.address = videoList[33].address
          //   return item
          // })
          // videoList.push(...randomPoints)


          this.markPoint(videoList)

        }
      }).catch((err) => {
        console.log('err', err)
      })
    },

    markPoint(list) {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/spjk.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      list.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        const sContent =
          `<div style='width: 100%;color:#fff'>
        <div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;'> `+ item.name + `</div>
        <img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>
        </div>
        <div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        <div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>在线状态</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.online + `</div>
        </div>

        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>状态</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.status + `</div>
        </div>

        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 100%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9; width: 20%'>地区</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.address + `</div>
        </div>
        
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        </div>
        <div style='width: 100%;margin:15px -25px;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9'>
            <video muted="muted" id="videoPlayer" style="width: 100%;border:2px solid #fff;opacity:1"
            muted autoplay></video>
          </div></div>`

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.getVideo(item.deviceid)
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          document.getElementById('videoPlayer').onclick = () => _this.liveView(item.deviceid)
        })
      })
    },

    // 获取视频数据
    getVideo(id) {
      _GetVideo(id).then((res) => {
        const videoUrl = res.flvuri
        this.playVideo(videoUrl)
      }).catch((err) => {
        console.log('err', err)
      })
    },

    // 播放监控视频
    playVideo(url) {
      if (flvjs.isSupported()) {
        let video = document.getElementById('videoPlayer')
        if (video) {
          this.player1 = flvjs.createPlayer({ type: "flv", isLive: true, url: url, hasAudio: false });

          this.player1.attachMediaElement(video);
          try {
            this.player1.load();
            this.player1.play();
          } catch (error) {
            console.log(error);
          }
        }
      }
    },

    liveView(id) {
      this.dialogVisible = true
      _GetVideo(id).then((res) => {
        const videoUrl = res.flvuri
        if (flvjs.isSupported()) {
          let video = this.$refs.playerRef
          if (video) {
            this.player2 = flvjs.createPlayer({ type: "flv", isLive: true, url: videoUrl, hasAudio: false });
            this.player2.attachMediaElement(video);
            try {
              this.player2.load();
              this.player2.play();
            } catch (error) {
              console.log(error);
            }
          }
        }
      }).catch((err) => {
        console.log('err', err)
      })

    },

    // 关闭监控弹窗
    dialogClosed() {
      this.dialogVisible = false
      this.player2.pause();
      this.player2.unload();
      this.player2.detachMediaElement();
      this.player2.destroy();
      this.player2 = null;
    },


    //坐标点
    onCotton() {
      const icon = new this.T.Icon({
        iconUrl: '/image/icon/spjk.png',
        id: 'test',
        iconSize: new this.T.Point(50, 62),
        iconAnchor: new this.T.Point(0, 0)
      })
      const _this = this
      _this.cottonData.forEach(item => {
        let marker = new this.T.Marker(new this.T.LngLat(item.lng, item.lat), { icon: icon })
        this.markerList.push(marker)
        this.map.addOverLay(marker);
        // const infoWin = new this.T.InfoWindow()

        const sContent =
          `<div style='width: 100%;color:#fff'>
        <div style='margin: 15px auto;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;'> `+ item.title + `</div>
        <img onClick='onCloseInfoWindow()' style='cursor: pointer;width: 16px;height: 16px;' src='https://api.lztl.cn/admin/sys-file/lztl-assp/2022/8/89375f4b46094e4c86ac81eb34b788d1.png'>
        </div>
        <div style='margin: 0 0 0 -20px;width: 110%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        <div style='margin: 0 auto;width: 100%;display:flex;justify-content: space-between;align-items: center;flex-wrap:wrap'>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>` + item.name1 + `</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.msg1 + `</div>
        </div>
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>` + item.name2 + `</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.msg2 + `</div>
        </div>
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>`+ item.name3 + `</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.msg3 + `</div>
        </div>
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>`+ item.name4 + `</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.msg4 + `</div>
        </div>
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        <div style='width: 43%'>
        <div style='margin: 10px auto 2px;width: 100%;display:flex;justify-content: space-between;align-items: center;'>
        <div style='font-size: 16px;font-weight: bold;color: #fff;opacity:0.9'>`+ item.name5 + `</div>
        <div style='font-size: 16px;color: #ffff;opacity:0.7'>`+ item.msg5 + `</div>
        </div>
        <div style='margin: 0 auto;width: 100%;border-bottom: 1px solid #979797;padding-bottom: 8px;opacity:0.2'></div>
        </div>
        </div>
        <div style='width: 100%;margin:15px -25px;font-size: 14px;color: #FFF;text-indent:24px;opacity:0.9'>
            <video muted="muted" style="width: 100%;border:2px solid #fff;opacity:1"
                   autoplay="autoplay"
                   loop="loop"
                   src="`+ item.value + `"></video>
          </div></div>`

        marker.addEventListener("click", function (e) {
          _this.map.panTo(e.lnglat, 12)
          var point = e.lnglat
          _this.infoWin = new _this.T.InfoWindow(e, {
            minWidth: '450',
            closeButton: true, // 显示关闭按钮
            closeOnClick: true,// 开启点击地图关闭弹窗
            offset: new _this.T.Point(20, 0)
          }); // 创建信息窗口对象
          _this.infoWin.setContent(sContent);
          _this.map.openInfoWindow(_this.infoWin, point); //开启信息窗口
          // },

        })
      })
    },

    /*关闭信息窗口 */
    onCloseInfoWindow() {
      this.infoWin.closeInfoWindow()
      this.player1.pause();
      this.player1.unload();
      this.player1.detachMediaElement();
      this.player1.destroy();
      this.player1 = null;
    }
  },

  beforeDestroy() {
    this.player1.pause();
    this.player1.unload();
    this.player1.detachMediaElement();
    this.player1.destroy();
    this.player1 = null;
    this.player2.pause();
    this.player2.unload();
    this.player2.detachMediaElement();
    this.player2.destroy();
    this.player2 = null;
  }
}
</script>
<style>
.tdt-infowindow-content-wrapper,
.tdt-infowindow-tip {
  background: rgba(0, 38, 25, 0.66);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5),
    inset 0px 4px 10px 0px rgba(163, 255, 227, 0.4);
  border: 1px solid #008166;
  backdrop-filter: blur(6px);
}

.tdt-container a.tdt-infowindow-close-button {
  color: #fff;
}
</style>
<style scoped lang="scss">
#mapDiv {
  z-index: 997;
  width: 100%;
  height: 110%;
  position: fixed;
  left: 0;
  top: 7%;
}
</style>
