<template>
  <div style="height:100%">
    <div class="center-top-container"
         flex="dir:top cross:center">
      <div class="card-head"
           flex="main:center">
        <div class="title">
          {{ title }}
        </div>
      </div>
      <div class="txt">
        单位:次
      </div>
      <div class="card-body">
        <div class="cardBox">
          <div class="card"
               @click="dialogShow = true">
            <div class="title">
              {{ name1 }}
            </div>
            <div class="box">
              <img src="@/assets/images/view4/yj.png">
              <div class="number">
                {{ num1 }}
              </div>
            </div>
          </div>

          <div class="card"
               @click="dialogShow = true">
            <div class="title">
              {{ name2 }}
            </div>
            <div class="box">
              <img src="@/assets/images/view4/xm.png">
              <div class="number">
                {{ num2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <centerDialog @show="getdialogShow"
                  :show="dialogShow" />
  </div>
</template>

<script>
import centerDialog from '../../centerDialog'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  components: {
    centerDialog
  },
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      name1: '',
      num1: '',
      name2: '',
      num2: '',
      title: '',
      dialogShow: false,
      data: {},//数据
      lineState: {},//在线状态数据
      screenWeight: document.body.clientWidth - 700, // 屏幕宽度
    }
  },

  mounted () {
    this.getSreenData()
    this.initChart()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-CYYTHYJ').then(res => {
        if (res) {
          this.name1 = res.data.data[0].data_name
          this.num1 = res.data.data[0].data_num
          this.name2 = res.data.data[1].data_name
          this.num2 = res.data.data[1].data_num
          this.title = res.data.form.name
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption(this.option)
    },

    getdialogShow (val) {
      this.dialogShow = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.center-top-container {
  height: 100%;

  .card-body {
    width: 100%;
    height: 100%;

    .cardBox {
      height: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      .card {
        width: 70%;
        height: 70%;
        margin: 30px;
        margin-top: 20px;
        position: relative;
        padding: 7px;
        background: #1e2116;
        border: 1px solid #383329;

        .title {
          position: absolute;
          top: 0;
          left: auto;
          width: 100%;
          text-align: center;
          font-size: 16px;
          color: #ffbc67;
          line-height: 42px;
          background: url("@/assets/images/view4/title.png") no-repeat center
            center;
          background-size: 100% 100%;
        }

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: #26281b;

          img {
            margin-top: 35px;
            width: 60px;
            height: 60px;
          }

          .number {
            margin-top: 35px;
            margin-left: 10px;
            font-size: 50px;
            font-weight: bold;
            color: #ffbc67;
          }
        }
      }
    }
  }
}
</style>