<template>
  <div class="left-top-container2"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        <!-- 库尔勒市域香梨产量情况111 -->
        2023年库尔勒市域香梨产量情况
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div class="chart"
           ref="chart"
           :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
    </div>
  </div>
</template>

<script>
import option from './chartOptions'
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      data: {},//数据
      title: '',
      myChart: null,
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      unit: '亩',
      zoom: 1
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      // this.getData()
    }
  },

  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.initChart()
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
//       let l ={
// farmId: "8c1c5119bd2640cdb630ed8793b242fa", 
// landId: "098682ecc1124a21892ec1dcd4fe0ae9" 
// }
//       getplatform({...l}).then((res)=>{
//         console.log(res,"xxxxxxxx")
//       })
      // _GetAspScreenComponentData('NEWKEL-LY-CCFL').then(res => {
      //   if (res) {
      //     const msgData = []
      //     res.data.data.forEach(item => {
      //       msgData.push(
      //         {
      //           value: item.vaule_data,
      //           name: item.name_data
      //         }

      //       )
      //     });

      //     this.option.series[0].data = msgData
      //     this.title = res.data.form.name
      //     this.myChart.setOption(this.option)
      //     window.addEventListener('resize', () => {
      //       this.myChart.resize()
      //     })
      //   }
      // })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      this.myChart.setOption(this.option)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.txt {
  color: white
}

.card-body {
  overflow: visible;
}
</style>