<template>
  <div class="center-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="txt">
      单位:次
    </div>
    <div class="card-body">
      <div class="cardBox">
        <div class="card">
          <div class="title">
            {{ name1 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num1 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name2 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num2 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name3 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num3 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name4 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num4 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name5 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num5 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name6 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num6 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name7 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num7 }}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="title">
            {{ name8 }}
          </div>
          <div class="box">
            <img src="@/assets/images/view4/shui.png">
            <div class="number">
              {{ num8 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息

export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      name1: '',
      num1: '',
      name2: '',
      num2: '',
      name3: '',
      num3: '',
      name4: '',
      num4: '',
      name5: '',
      num5: '',
      name6: '',
      num6: '',
      name7: '',
      num7: '',
      name8: '',
      num8: '',
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      screenWeight: document.body.clientWidth - 700, // 屏幕宽度
      params: {
        region: '652928'
      }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getSreenData()
    this.initChart()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-QTYJ').then(res => {
        if (res) {
          this.name1 = res.data.data[0].data_name
          this.num1 = res.data.data[0].data_num
          this.name2 = res.data.data[1].data_name
          this.num2 = res.data.data[1].data_num
          this.name3 = res.data.data[2].data_name
          this.num3 = res.data.data[2].data_num
          this.name4 = res.data.data[3].data_name
          this.num4 = res.data.data[3].data_num
          this.name5 = res.data.data[4].data_name
          this.num5 = res.data.data[4].data_num
          this.name6 = res.data.data[5].data_name
          this.num6 = res.data.data[5].data_num
          this.name7 = res.data.data[6].data_name
          this.num7 = res.data.data[6].data_num
          this.name8 = res.data.data[7].data_name
          this.num8 = res.data.data[7].data_num
          this.title = res.data.form.name
        }
      })
    },
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption(this.option)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.center-top-container {
  height: 100%;
  margin-top: 10px;

  .card-body {
    height: 100%;
    width: 90%;

    .cardBox {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      .card {
        height: 30%;
        width: 20%;
        position: relative;
        padding: 6px;
        background: #1e2116;
        border: 1px solid #383329;

        .title {
          position: absolute;
          top: 0;
          width: 100%;
          height: 20px;
          text-align: center;
          font-size: 12px;
          color: #ffbc67;
          line-height: 25px;
          background: url("@/assets/images/view4/title.png") no-repeat center
            center;
          background-size: 100% 100%;
        }

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: #26281b;

          img {
            margin-top: 20px;
            width: 18%;
            height: 65%;
          }

          .number {
            margin-top: 20px;
            margin-left: 10px;
            font-size: 17px;
            font-weight: bold;
            color: #ffbc67;
          }
        }
      }
    }
  }
}
</style>