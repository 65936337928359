<template>
  <div class="popup">
    <el-dialog :modal-append-to-body="false"
               @close="onClose"
               :visible.sync="dialogShow">
      <div class="left-top-container"
           flex="dir:top cross:center">
        <div class="card-head"
             flex="main:center">
          <div class="title">
            产业一体化预警
          </div>
        </div>
        <div class="card-body"
             flex="dir:top">
          <dv-scroll-board :config="config"
                           style="width:100%;height:270px;" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LeftTopItem',
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    show (val) {
      this.dialogShow = val
      console.log(this.dialogShow)
    }
  },

  data () {
    return {
      dialogShow: false,
      config: {
        rowNum: 5,
        columnWidth: [200, 120, 120, 120, 120, 300, 150, 150],
        header: ['报警时间', '报警类别', '报警类型', '报警级别', '报警名称', '报警源', '报警内容', '报警状态'],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        align: ['left', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
        data: [
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
          ['2022-12-22', '设备报警', '状态异常', '重要', '设备离线', '6号泵房温湿盐（03-00-09-98）', '设备离线备份', '报警产生'],
        ]
      },
    }
  },

  methods: {
    onClose () {
      this.$emit('show', this.dialogShow)
    }
  }
}
</script>

<style lang="scss">
.popup {
  .el-dialog {
    position: relative;
    top: 10%;
    height: 373px;
    opacity: 1;
    background: url("@/assets/images/popupImg.png") no-repeat;
    background-size: cover;
    background-position-y: -21px;
  }

  .card-body {
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px;

    .dv-scroll-board::v-deep {
      .header {
        .header-item {
          font-size: 12px;
          border: 0.5px solid rgba(232, 232, 232, 0.1);
        }
      }
      .rows {
        .row-item {
          font-size: 12px;
          font-weight: 400;
          color: #f2f2f2;
          line-height: 12px;

          .ceil {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
      }
    }
    .dv-scroll-board[data-v-220fcd9c] .header .header-item {
      color: #ffbc67 !important;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    margin-right: 20px;
  }
}
</style>
<style lang="scss" scoped>
.popup {
  .left-top-container {
    position: relative;

    .card-head {
      position: absolute;
      top: -55px;
      width: 350px;
      height: 50px;
      background: url("@/assets/images/card-head.png") no-repeat;
      background-position: 0 -2px;

      .title {
        padding-top: 13px;
        font-size: 18px;
        font-weight: 600;
        color: #ffbc67;
        line-height: 18px;
      }
    }

    .card-body {
      width: 100%;

      .dv-scroll-board::v-deep {
        .header {
          .header-item {
            font-size: 12px;
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
        .rows {
          .row-item {
            font-size: 12px;
            font-weight: 400;
            color: #f2f2f2;
            line-height: 12px;

            .ceil {
              border: 0.5px solid rgba(232, 232, 232, 0.1);
            }
          }
        }
      }
    }
  }
}
</style>