<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <div>
        <div class="chart-container">
          <div class="chart"
               ref="chart0"
               :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
          <div class="chart"
               ref="chart1"
               :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
        </div>

        <div class="chart-container">
          <div class="chart"
               ref="chart2"
               :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
          <div class="chart"
               ref="chart3"
               :style="`zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option0 from './option0'
import option1 from './option1'
import option2 from './option2'
import option3 from './option3'
export default {
  name: 'LeftTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option0,
      option1,
      option2,
      option3,
      data: {},//数据
      title: '',
      names1: '',
      nums1: '',
      names2: '',
      nums2: '',
      names3: '',
      nums3: '',
      names4: '',
      nums4: '',
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      zoom: 1
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },


  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.getSreenData()
  },

  methods: {
    //获取数据
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-LY-SBZXSJTJ').then(res => {
        if (res) {

          const msgData = []
          res.data.data.forEach(item => {
            msgData.push([
              item.name,
              item.num_data
            ])
          })
          this.names1 = msgData[0][0]
          this.nums1 = msgData[0][1]
          this.names2 = msgData[1][0]
          this.nums2 = msgData[1][1]
          this.names3 = msgData[2][0]
          this.nums3 = msgData[2][1]
          this.names4 = msgData[3][0]
          this.nums4 = msgData[3][1]
          this.title = res.data.form.name

          this.option0.series[0].data = [
            {
              value: this.nums1,
              name: this.names1,
              title: {
                offsetCenter: ['0%', '-25%'],
                color: "#fff"
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '40%'],
                color: "#fff"
              }
            }
          ]

          this.option1.series[0].data = [
            {
              value: this.nums2,
              name: this.names2,
              title: {
                offsetCenter: ['0%', '-25%'],
                color: "#fff"
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '40%'],
                color: "#fff"
              }
            }
          ]

          this.option2.series[0].data = [
            {
              value: this.nums3,
              name: this.names3,
              title: {
                offsetCenter: ['0%', '-25%'],
                color: "#fff"
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '40%'],
                color: "#fff"
              }
            }
          ]

          this.option3.series[0].data = [
            {
              value: this.nums4,
              name: this.names4,
              title: {
                offsetCenter: ['0%', '-25%'],
                color: "#fff"
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '40%'],
                color: "#fff"
              }
            }
          ]

          this.initChart()
        }
      })
    },
    format (percentage) {
      return percentage + '\n\n' + this.names1
    },

    format2 (percentage) {
      return percentage + '\n\n' + this.names2
    },

    format3 (percentage) {
      return percentage + '\n\n' + this.names3
    },

    format4 (percentage) {
      return percentage + '\n\n' + this.names4
    },
    initChart () {
      var myChart0 = this.$echarts.init(this.$refs.chart0)
      myChart0.setOption(this.option0)
      let sizeFun0 = function () {
        myChart0.resize()
      }
      window.addEventListener('resize', sizeFun0)

      var myChart1 = this.$echarts.init(this.$refs.chart1)
      myChart1.setOption(this.option1)
      let sizeFun1 = function () {
        myChart1.resize()
      }
      window.addEventListener('resize', sizeFun1)

      var myChart2 = this.$echarts.init(this.$refs.chart2)
      myChart2.setOption(this.option2)
      let sizeFun2 = function () {
        myChart2.resize()
      }
      window.addEventListener('resize', sizeFun2)

      var myChart3 = this.$echarts.init(this.$refs.chart3)
      myChart3.setOption(this.option3)
      let sizeFun3 = function () {
        myChart0.resize()
      }
      window.addEventListener('resize', sizeFun3)
    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    },
  }
}

</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>