/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 18:43:04
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 10, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  // header: ['放款年份', '姓名', '亩数', '实际放款额'],
  header: [
    "企业名称",
    "注册资本",
    "总人数",
    "季节性用工人数",
    "设计贮藏数量",
    "实际贮藏数量",
    // "贮藏产品种类(个)",
    "龙头企业情况",
  ],
  columnWidth: [90, 90, 80, 100,90, 80, 100],
  align: ['center', 'center', 'center', 'center'],
  data: [
    ['库尔勒市林业和草原局', '90', '8', '200', '9000', '5000', '否'],
    ['鑫甜冷库', '150', '12', '45', '5000', '500', '否'],
    ['巴州金六合仓储服务有限公司', '120', '10', '62', '2000', '1000', '否'],
    ['新疆神州投资公司果品保鲜库', '1130', '8', '55', '3000', '1500', '自治区级'],
    ['巴州国彬仓储有限公司', '2600', '25', '220', '10000', '5000', '自治区级'],
    ['源田冷库', '128', '11', '180', '8000', '2000', '否'],
    ['卧龙仓储冷库 1库', '50', '9', '160', '8100', '3000', '否'],
    ['胜利冷库', '100', '6', '80', '2000', '2000', '否'],
    ['北方仓储有限责任公司', '1000', '17', '200', '8000', '4000', '否'],
    ['绿锦冷库', '100', '6', '80', '2500', '1500', '否'],
    ['金圣合公司', '1200', '9', '150', '6000', '5000', '否'],
    ['帝泓果品有限公司', '600', '8', '180', '8000', '7570', '否'],

    ["库尔勒康庄农业开发有限公司", "13", "500 ", "60 ", "4,000 ", "2,000 ", "否"],
    [
      "天雪果业",
      "50 ",
      "6 ",
      "50 ",
      "1000 ",
      "1000 ",
      "否",

    ],
    [
      "新疆众力农产品有限责任公司",
      "1,000 ",
      "13 ",
      "130 ",
      "6000 ",
      "3000 ",
      "自治区级",

    ],
    [
      "亚森·尤努斯冷库",

      "50 ",

      "7 ",
      "80 ",
      "900 ",
      "500 ",
      "否",
    ],
    [
      "热合曼·卡地尔冷库",

      "20 ",

      "4 ",
      "40 ",
      "800 ",
      "500 ",

      "否",
    ],
    [
      "阿力甫·亚森冷库",

      "30 ",

      "7 ",
      "70 ",
      "1000 ",
      "1000 ",

      "否",

    ],
    [
      "沙依东（香梨股份）冷库",

      "147,700 ",

      "22 ",
      "120 ",
      "10000 ",
      "8000 ",

      "自治区级",

    ],
    [
      "库尔勒一鸣仓储有限责任公司",
      "500 ",
      "17 ",
      "200 ",
      "10,000 ",
      "6,000 ",

      "否",

    ],
    [
      "夏克扎提冷库",

      "100 ",

      "8 ",
      "80 ",
      "4000 ",
      "2000 ",

      "否",

    ],
    [
      "王玉青冷库",
      "5 ",
      "30 ",
      "200 ",
      "200 ",

      "否",
    ],
    [
      "大地冷库",

      "500 ",

      "23 ",
      "220 ",
      "10000 ",
      "8000 ",

      "否",

    ],
    [
      "库尔勒三丰仓储有限公司",

      "50 ",

      "17 ",
      "150 ",
      "10,000 ",
      "8,000 ",

      "否",

    ],
    [
      "东方晶果农业有限公司",
      "60 ",
      "16 ",
      "130 ",
      "10000 ",
      "8000 ",
      "否",
    ]
    [
    "艾来提·亚森冷库",
    "50 ",
    "7 ",
    "50 ",
    "1,000 ",
    "800 ",
    "否"
    ],
    [
      "三和冷库",
      "48 ",
      "8 ",
      "30 ",
      "1,300 ",
      "500 ",
      "否"
    ],
    [
      "尹文学冷库",
      "100 ",
      "12 ",
      "100 ",
      "4,000 ",
      "3,000 ",
      "否"
    ],
    [
      "库尔勒市土产果品公司冷库",
      "171 ",
      "15 ",
      "160 ",
      "8,000 ",
      "7,000 ",
      "否"
    ],
    [
      "梨乡冷库",
      "50 ",
      "8 ",
      "40 ",
      "3,500 ",
      "2,000 ",
      "否"
    ],
    [
      "旺安冷库",
      "100 ",
      "17 ",
      "150 ",
      "12,000 ",
      "9,000 ",
      "否"
    ],
    [
      "科迪冷库（巴州科迪果业农民专业合作社）",
      "226 ",
      "19 ",
      "200 ",
      "13,000 ",
      "10,000 ",
      "否"
    ],
    [
      "徐公清冷库",
      "200 ",
      "8 ",
      "80 ",
      "3,000 ",
      "2,600 ",
      "否"
    ],
    [
      "泽兰冷库",
      "50 ",
      "10 ",
      "120 ",
      "5,000 ",
      "4,000 ",
      "否"
    ],
    [
      "永兴冷库",
      "100 ",
      "9 ",
      "100 ",
      "6,000 ",
      "3,000 ",
      "否"
    ],
    [
      "西域娇子商贸有限公司冷库",
      "300 ",
      "8 ",
      "115 ",
      "5,000 ",
      "2,000 ",
      "否"
    ],
    [
      "王新民冷库",
      "300 ",
      "7 ",
      "60 ",
      "4,000 ",
      "1,200 ",
      "否"
    ],
    [
      "巴州良丰农产品有限责任公司",
      "1,000 ",
      "15 ",
      "220 ",
      "11,000 ",
      "10,000 ",
      "否"
    ],
    [
      "恒钻冷库",
      "100 ",
      "10 ",
      "150 ",
      "8,000 ",
      "6,000 ",
      "否"
    ],
    [
      "巴州润普果业有限责任公司",
      "500 ",
      "8 ",
      "80 ",
      "4,000 ",
      "3,000 ",
      "否"
    ],
    [
      "深浩源冷库",
      "200 ",
      "7 ",
      "110 ",
      "4,000 ",
      "3,000 ",
      "否"
    ],
    [
      "巴州绿宝石仓储专业合作社",
      "2,200 ",
      "8 ",
      "123 ",
      "4,500 ",
      "4,000 ",
      "否"
    ],
    [
      "绿圣冷库",
      "1,500 ",
      "10 ",
      "90 ",
      "10,000 ",
      "4,000 ",
      "否"
    ],
    [
      "库尔勒金友联果业有限公司",
      "900 ",
      "11 ",
      "88 ",
      "9,000 ",
      "4,000 ",
      "否"
    ],
    [
      "徽润冷库",
      "10 ",
      "13 ",
      "140 ",
      "8,000 ",
      "6,000 ",
      "否"
    ],
    [
      "康宏冷库",
      "100 ",
      "7 ",
      "70 ",
      "4,000 ",
      "3,000 ",
      "否"
    ],
    [
      "库尔勒中宝仓储有限公司",
      "550 ",
      "22 ",
      "230 ",
      "17,000 ",
      "15,000 ",
      "否"
    ],
    [
      "永瑞冷库",
      "10 ",
      "8 ",
      "80 ",
      "4,000 ",
      "4,000 ",
      "否"
    ],
    [
      "冰晶冷库",
      "20 ",
      "7 ",
      "55 ",
      "3,000 ",
      "3,000 ",
      "否"
    ],
    [
      "铂金冷库库",
      "3 ",
      "6 ",
      "75 ",
      "3,000 ",
      "3,000 ",
      "否"
    ],
    [
      "拓普冷库",
      "3,314 ",
      "23 ",
      "190 ",
      "10,000 ",
      "6,000 ",
      "否"
    ],
    [
      "新华冷库",
      "500 ",
      "18 ",
      "85 ",
      "7,000 ",
      "5,000 ",
      "否"
    ],
    [
      "西部冷库",
      "500 ",
      "7 ",
      "75 ",
      "5,000 ",
      "4,500 ",
      "否"
    ],
    [
      "益新冷库",
      "200 ",
      "9 ",
      "88 ",
      "5,000 ",
      "5,000 ",
      "否"
    ],
    [
      "巴州新希望供应链有限公司冷库",
      "3,000 ",
      "10 ",
      "75 ",
      "5,000 ",
      "5,000 ",
      "否"
    ],
    [
      "粤安冷库",
      "50 ",
      "7 ",
      "70 ",
      "4,000 ",
      "4,000 ",
      "否"
    ],
    [
      "库尔勒果荟农产品加工有限公司",
      "5,000 ",
      "12 ",
      "105 ",
      "9,000 ",
      "9,000 ",
      "否"
    ],
    [
      "库尔勒瑞丰果品冷藏有限责任公司",
      "2,000 ",
      "14 ",
      "130 ",
      "10,000 ",
      "6,000 ",
      "否"
    ],
    [
      "库尔勒鲜鲜果品有限公司",
      "1,000 ",
      "12 ",
      "120 ",
      "12,000 ",
      "10,000 ",
      "否"
    ],
    [
      "库尔勒市圣果冷藏有限责任公司",
      "60 ",
      "8 ",
      "50 ",
      "3,500 ",
      "2,500 ",
      "否"
    ],
    [
      "巴州时代仓储服务有限责任公司",
      "1,000 ",
      "19 ",
      "201 ",
      "15,000 ",
      "11,000 ",
      "否"
    ],
    [
      "巴州惠仁商贸有限责任公司",
      "200 ",
      "7 ",
      "50 ",
      "2,000 ",
      "2,000 ",
      "否"
    ],
    [
      "库尔勒金丰利冷藏有限公司",
      "1,000 ",
      "17 ",
      "250 ",
      "17,000 ",
      "15,000 ",
      "自治区级"
    ],
    [
      "库尔勒世光仓储有限公司",
      "1,000 ",
      "8 ",
      "20 ",
      "20,000 ",
      "8,000 ",
      "否"
    ],
    [
      "新疆源兴农业开发有限公司",
      "1,250 ",
      "10 ",
      "90 ",
      "8,000 ",
      "7,000 ",
      "否"
    ],
    [
      "新疆和顺果业有限公司",
      "800 ",
      "11 ",
      "115 ",
      "12,000 ",
      "9,000 ",
      "否"
    ],
    [
      "新疆中联丝路农产品物流股份有限公司",
      "6,150 ",
      "9 ",
      "83 ",
      "4,000 ",
      "4,000 ",
      "否"
    ],
    [
      "铁门关市新辉果蔬有限公司",
      "660 ",
      "14 ",
      "260 ",
      "12,000 ",
      "3,000 ",
      "否"
    ],
    [
      "库尔勒美旭香梨农民专业合作社",
      "100 ",
      "187 ",
      "173 ",
      "30,000 ",
      "3,000 ",
      "国家级"
    ],
    [
      "库尔勒富农香梨种植农民专业合作社",
      "187 ",
      "106 ",
      "300 ",
      "3,000 ",
      "300 ",
      "国家级"
    ],
    [
      "巴音郭楞蒙古自治州库尔勒香梨协会",
      "3 ",
      "8 ",
      "200 ",
      "3,000 ",
      "300 ",
      "否"
    ],
    [
      "库尔勒和顺果品仓储有限公司",
      "800 ",
      "57 ",
      "30 ",
      "10,000 ",
      "500 ",
      "否"
    ],
    [
      "库尔勒果真好香梨种植农民专业合作社",
      "50 ",
      "38 ",
      "60 ",
      "6,000 ",
      "1,000 ",
      "否"
    ],
    [
      "库尔勒民诚香梨专业合作社",
      "11 ",
      "21 ",
      "10 ",
      "3,000 ",
      "500 ",
      "否"
    ],
    [
      "库尔勒兵哥农业专业合作社",
      "100 ",
      "75 ",
      "200 ",
      "200 ",
      "150 ",
      "否"
    ],
    [
      "库尔勒玖利果树种植专业合作社",
      "500 ",
      "63 ",
      "300 ",
      "1,000 ",
      "50 ",
      "否"
    ],
    [
      "库尔勒睿坤香梨专业合作社",
      "60 ",
      "100 ",
      "20 ",
      "2,000 ",
      "100 ",
      "否"
    ],
    [
      "库尔勒梨硕种植农民专业合作社",
      "114 ",
      "215 ",
      "125 ",
      "",
      "",
      "否"
    ],
    [
      "库尔勒天沃种植农民专业合作社",
      "100 ",
      "8 ",
      "50 ",
      "4,000 ",
      "1,200 ",
      "否"
    ],
    [
      "库尔勒英资果业有限公司",
      "500 ",
      "9 ",
      "50 ",
      "4,000 ",
      "1,000 ",
      "否"
    ],
    [
      "新疆维拓生物科技有限公司",
      "100 ",
      "50 ",
      "43 ",
      "800 ",
      "500 ",
      "否"
    ],
    [
      "巴州科迪果业农民专业合作社",
      "226 ",
      "32 ",
      "40 ",
      "6,000 ",
      "1,000 ",
      "否"
    ],
    [
      "巴州小晓农业科技有限公司",
      "200 ",
      "28 ",
      "800 ",
      "1,000 ",
      "30 ",
      "否"
    ],
    [
      "巴州译戈果业农民专业合作社",
      "1,200 ",
      "20 ",
      "6 ",
      "800 ",
      "500 ",
      "否"
    ],
    [
      "新疆天鹏农业科技有限公司",
      "200 ",
      "8 ",
      "80 ",
      "5,000 ",
      "3,000 ",
      "否"
    ],
    [
      "新疆百信香梨种植专业合作社",
      "2,000 ",
      "28 ",
      "80 ",
      "3,500 ",
      "1,500 ",
      "否"
    ],
    [
      "库尔勒龙之源药业有限责任公司",
      "1,758 ",
      "87 ",
      "",
      "",
      "",
      "自治区级"
    ],
    [
      "库尔勒沁知园生物科技有限公司",
      "668 ",
      "17 ",
      "50 ",
      "",
      "",
      "否"
    ],
    [
      "巴州成成妈妈食品有限公司",
      "100 ",
      "12 ",
      "30 ",
      "",
      "",
      "否"
    ]
  ]
}
