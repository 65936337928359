/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: hlc 1664800317@qq.com
 * @LastEditTime: 2022-09-15 00:41:55
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  rowNum: 6,
        columnWidth: [120, 110, 110, 110],
        header:[],// ['项目编号', '项目名称', '需求类型', '是否孵化'],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        data: [
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
          // ['猫科动物', '88', '2', '2'],
        ]
  }