/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2023-02-06 11:48:18
 * @LastEditors: adlj
 * @LastEditTime: 2023-02-06 11:49:31
 */
export default {
  data () {
    return {
      zoom: 1
    }
  },
  mounted () {
    let zoom = document.getElementsByClassName('screen')[0].style.zoom
    if (zoom) {
      this.zoom = 1 / document.getElementsByClassName('screen')[0].style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.getElementsByClassName('screen')[0].style.zoom
      if (zoom) {
        this.zoom = 1 / document.getElementsByClassName('screen')[0].style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
  },
  methods: {
    // 饼图默认高亮显示
    getDefaultSelected (myChart) {
      let index = 0;
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: 0,
      });//默认高亮
      myChart.on('mouseover', (e) => {
        if (e.dataIndex !== index) {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: index,
          });
        }
      });
      myChart.on('mouseout', (e) => {
        index = e.dataIndex;
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
    }
  }
}