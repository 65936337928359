/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 19:12:00
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 3, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['年份', '棉花采收/万亩', '棉花播种/万亩', '耙地/万亩'],
  // columnWidth: [60, 120, 90, 90],
  align: ['center', 'center', 'center', 'center'],
  data: [
    ['2021', '54.33', '81.48', '54.33'],
    ['2020', '50.48', '78.20', '50.48'],
    ['2019', '44.45', '90.48', '44.45'],
    ['2018', '44.45', '90.48', '44.45']
  ]
}
