import request from '@/libs/axios'

export function _GetDevice () {
    return request({
      url: '/platform/telecomVideo/getDeviceList',
      method: 'GET',
    })
  }

  export function _GetVideo (deviceid) {
    return request({
      url: '/platform/telecomVideo/getVideoStream',
      method: 'GET',
      params: {
        deviceid
      }
    })
  }