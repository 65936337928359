<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:04:22
-->
<template>
  <div class="aside-center-container"
       flex="dir:top box:mean">
    <center-top-item />
    <center-bottom-item />
  </div>
</template>

<script>
import centerTopItem from './centerTopItem'
import centerBottomItem from './centerBottomItem'
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  name: 'AsideCenter',
  components: {
    centerTopItem,
    centerBottomItem
  },

  data () {
    return {
      zoom: 1
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.aside-center-container {
  flex: 1;
  height: 100%;
}
</style>