<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 14:52:15
-->
<template>
  <div style="height:100%">
    <div style="height:100%">
      <bottomBtn1 v-if="btnState===0" />
      <bottomBtn2 v-if="btnState===1" />
    </div>
  </div>
</template>

<script>
import bottomBtn1 from './bottom-btn1'
import bottomBtn2 from './bottom-btn2'

export default {
  name: 'App',
  components: {
    bottomBtn1,
    bottomBtn2,
  },

  props: {
    bottomIndex: {
      type: Number,
      default: 0,
    },
    showSubMenu: {
      type: Boolean,
      default: false
    },
    top4Index: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      btnState: 0,//当前按钮
      show: true,
    }
  },

  watch: {
    bottomIndex: {
      handler (val) {
        this.btnState = val
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },

    top4Index: {
      handler (val) {
        console.log(val)
        this.btnState = val
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },
  },

  methods: {
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
      this.$emit('change', !this.showSubMenu)
    }
  }
}
</script>

<style scoped lang="scss">
.botomBtn2 {
  width: 150px;
  position: fixed;
  z-index: 999;
  right: 19%;
  top: 7%;

  .btn3 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 117px;
    width: 150px;
    background: linear-gradient(360deg, #135b44 0%, #448064 100%);
    box-shadow: 0px 0px 0px 0px rgba(0, 64, 32, 0.8),
      inset 0px 0px 35px 0px rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    border: 2px solid #008c70;

    .btn1 {
      margin-bottom: 10px;
      margin-top: 10px;
      padding-bottom: 4px;
      width: 72px;
      font-size: 18px;
      color: #defff6;
      border-bottom: 2px solid #defff6;
    }

    .btn2 {
      margin-bottom: 10px;
      margin-top: 10px;
      width: 72px;
      font-size: 18px;
      color: #7cf8e0;
    }
  }
}
</style>
