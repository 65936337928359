<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="option"
                       style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { getTraceDataCount, getTrVehicleCount } from '@/api/assp-trace-collectData/index'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftBottomItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
      // config: {
      //   rowNum: 10,
      //   columnWidth: [150, 150, 150,],
      //   header: ['报警事件', '报警类别', '报警详情'],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //   ]
      // },
      // config2: {
      //   rowNum: 5,
      //   columnWidth: [150, 150, 150,],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //     ['2022-3-1', '设备报警', '设备离线'],
      //   ]
      // }
    }
  },

  watch: {
    region (val) {
      this.params.region = val
      this.getData()
    }
  },

  mounted () {
    this.getData()
    this.getSreenData()
  },

  methods: {
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZXBJXX').then(res => {
        if (res) {
          const msgData = []
          console.log(res);
          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one,
              item.msg_two,
              item.msg_three,
              ]
            )
          });

          this.title = res.data.form.name

          _GetAspScreenComponentData('NEWKEL-XMY-ZXBJXXBT').then(res => {
            if (res) {
              const msgData2 = []
              res.data.data.forEach(item => {
                msgData2.push(
                  [item.msg_one,
                  item.msg_two,
                  item.msg_three,
                  ]
                )
              })
              let params = {
                rowNum: 10,
                columnWidth: [160, 160, 160,],
                header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
                align: ['center', 'center', 'center'],
                headerBGC: 'transparent',
                oddRowBGC: 'transparent',
                evenRowBGC: 'transparent',
                data: msgData,
              }

              this.option = params
            }

          })
        }
      })

    },
    getData () {
      getTraceDataCount(this.params).then(res => {
        this.data = res
        if (res.landArea) {
          this.data.landArea = res.landArea.toFixed(2)
        }
      })

      getTrVehicleCount(this.params.region).then(res => {
        this.lineState = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.left-top-container {
  .card-body {
    .e1 {
      .el-progress-circle {
        background: linear-gradient(116deg, #005744 0%, #003728 100%);

        box-shadow: inset 0px 0px 17px 7px #00543e;

        border-image: linear-gradient(
            116deg,
            rgba(0, 255, 169, 1),
            rgba(255, 255, 255, 1)
          )
          4 4;
        border-radius: 50%;
      }
      .el-progress__text {
        color: #fff;
        font-size: 16px;
      }
    }

    .e2 {
      margin-left: 40px;
      .el-progress-circle {
        background: linear-gradient(116deg, #572900 0%, #003728 100%);
        box-shadow: inset 0px 0px 17px 7px #542e00;
        border-image: linear-gradient(
            116deg,
            rgba(255, 90, 0, 1),
            rgba(255, 203, 139, 1)
          )
          4 4;
        border-radius: 50%;
      }
      .el-progress__text {
        color: #fff;
        font-size: 16px;
      }
    }

    .row-1 {
      margin: 20px 0;

      div {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 14px;
        text-align: center;
      }

      span {
        color: #96a098;
        font-weight: 400;
        margin-right: 10px;
        font-size: 14px;
      }
    }

    .chart {
      width: 100%;
      height: 300px;
    }
  }
}
</style>