/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-09 19:08:38
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-29 18:48:35
 */
const gaugeData = [];

export default {
  series: [
    {
      radius: '80%',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: false,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: "#00543E",
          borderWidth: 1,
          borderColor: '#00543E'
        }
      },
      axisLine: {
        lineStyle: {
          color: [[1, '#00FFA9']],
          width: 5,
          // shadowBlur: 10,
          // shadowColor: '#fff'
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      data: gaugeData,
      title: {
        fontSize: 14
      },
      detail: {
        width: 50,
        height: 20,
        fontSize: 20,
        color: 'auto',
        formatter: '{value}'
      }
    }
  ]
}
