/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 18:41:41
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#0F5DE3'],
  legend: {
    show: false,
  },
  series: [
    {
      type: 'pie',
      radius: '50%',
      center: ['40%', '60%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        normal: {
          // formatter: '{d}%, {c} \n\n',
          formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
          padding: [0, -10],//取消hr线跟延长线之间的间隙
          rich: {
            a: {
              color: '#999',
              lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
              align: 'center'
            }
          }
        }
      },

      data: [
        {
          name: '棉花',
          value: 13.4
        },
        {
          name: '香梨',
          value: 42.3
        },
        {
          name: '肉类',
          value: 2.55
        }
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
