/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Pancras
 * @LastEditTime: 2022-09-29 20:36:48
 */
export default {
  title: {
    text: '单位: 人',
    textStyle: {
      color: 'white',
    },
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}只',
  },
  legend: {
    left: '5%',
    top: '15%',
    orient: "vertical",
    textStyle: {
      color: '#FFFFFF'
    },
    itemGap: 20
  },
  series: [
    {
      type: 'pie',
      radius: ['30%', '45%'],
      center: ['65%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        normal: {
          color: 'white',
          // formatter: '{d}%, {c} \n\n',
          formatter: ' {c|{c}}\n{hr|}\n{a|}',//这里最后另一行设置了一个空数据是为了能让延长线与hr线对接起来
          padding: [0, -10],//取消hr线跟延长线之间的间隙
          rich: {
            a: {
              color: '#999',
              lineHeight: 20,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
              align: 'center'
            }
          }
        }
      },

      data: [
        { value: 21, name: '市级' },
        { value: 63, name: '乡镇级' },
        { value: 42, name: '确认官方兽医' },
        { value: 112, name: '村级防疫员' },
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
