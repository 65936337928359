<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 07:11:34
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="拉运记录"
                 :visible.sync="transportListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="tableData"
                    @cell-click="onDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="carName"
                             label="机手姓名"
                             width="90" />
            <el-table-column prop="carNumber"
                             label="车牌"
                             width="90" />
            <el-table-column prop="num"
                             label="包数"
                             width="70" />
            <el-table-column prop="time"
                             label="拉运日期"
                             width="110" />
          </el-table>
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="拉运详情"
                 :visible.sync="transportDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              开始时间
            </div>
            <div class="right">
              2022-6-9
            </div>
          </div>

          <div class="box">
            <div class="left">
              结束时间
            </div>
            <div class="right">
              2022-7-9
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              公检单号
            </div>
            <div class="right">
              25625585858
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              车牌号
            </div>
            <div class="right">
              新A jh256
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              车辆类型
            </div>
            <div class="right">
              采收机
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              车辆型号
            </div>
            <div class="right">
              HG
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手姓名
            </div>
            <div class="right">
              张三
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              机手手机号
            </div>
            <div class="right">
              15688958858
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    popuShow: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      transportListDrawer: false,
      transportDrawer: false,
      list: [],
      transportData: {},
      tableData: []
    }
  },

  watch: {
    popuShow (val) {
      this.show = val
    },

    id (val) {
      this.tableData = [
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        },
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        },
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        },
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        },
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        },
        {
          carName: '张三',
          carNumber: '新A JH788',
          num: '20个',
          time: 2022 - 9 - 5
        }
      ]
      this.transportListDrawer = true
    }
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('transportShow', null)
    },

    onDetail (row) {
      if (row.id) {
        getTransportVo(row.id).then(res => {
          this.transportData = res
        })
      }
      this.transportDrawer = true
    },
  }
}
</script>
<style lang="scss" scoped >
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
