<template>
  <div class="farming-task-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex>
      <div class="left"
           flex="main:center">
        <div class="e1">
          <el-progress type="circle"
                       :percentage="taskCount.progress"
                       style="white-space:pre"
                       :format="format"
                       :width="90"
                       :stroke-width="4"
                       color="#00D49E" />
        </div>
        <div class="e2">
          <el-progress type="circle"
                       :format="format2"
                       :percentage="taskCount.complete"
                       style="white-space:pre"
                       :width="90"
                       :stroke-width="4"
                       color="#FF5A00" />
        </div>
      </div>
      <div class="right">
        <dv-scroll-board :config="option"
                         style="width:100%;height:180px;" />
      </div>
    </div>
  </div>
</template>

<script>
import { _GetTaskCount, _GetTaskList } from '@/api/assp-task'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'FarmingTask',
  props: {
    landId: {
      type: Number,
      default: null
    }
  },
  watch: {
    landId: {
      handler (val) {
        if (!val) return
        this.getCount()
        this.getTaskList()
      }
    }
  },
  data () {
    return {
      taskCount: {
        progress: 0,
        complete: 0
      },
      option,
      title: '',
      names1: '',
      nums1: '',
      names2: '',
      nums2: '',
      // config: {
      //   rowNum: 4,
      //   columnWidth: [200, 200, 150, 100],
      //   headerBGC: 'transparent',
      //   oddRowBGC: 'transparent',
      //   evenRowBGC: 'transparent',
      //   data: [
      //     ['2022-12-22 00:12:20', '<span style="color: #12FFAF;">已处理</span>', '间苗和定苗', '王五'],
      //     ['2022-12-22 00:12:20', '<span style="color: #12FFAF;">已处理</span>', '间苗和定苗', '王五'],
      //     ['2022-12-22 00:12:20', '<span style="color: #12FFAF;">已处理</span>', '间苗和定苗', '王五'],
      //     ['2022-12-22 00:12:20', '<span style="color: #FF7A27;">未执行</span>', '间苗和定苗', '王五'],
      //     ['2022-12-22 00:12:20', '<span style="color: #12FFAF;">已处理</span>', '间苗和定苗', '王五'],
      //     ['2022-12-22 00:12:20', '<span style="color: #12FFAF;">已处理</span>', '间苗和定苗', '王五'],
      //   ]
      // }
    }
  },
  mounted () {
    //this.getSreenData()
  },
  methods: {
    // 获取地块当前任务情况
    getCount () {
      _GetTaskCount(this.landId).then(res => {
        this.taskCount = res
      })
    },
    // 获取地块任务列表
    getTaskList () {
      _GetTaskList(this.landId, 10).then(res => {
        if (res) {
          let params = {
            rowNum: 6,
            columnWidth: [250, 120, 150],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            data: [],
          }

          res.forEach(item => {
            params.data.push(
              [item.createTime,
              item.completeStatus === '9' ? '<span style="color: #12FFAF;">已处理</span>' : '<span style="color: #FF7A27;">未执行</span>',
              item.title]
            )
          })

          this.option = params
        }

      })
    },
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-NSRW').then(res => {
        if (res) {
          const msgData = []
          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one, '<span style="color: #12FFAF;">' + item.msg_two + '</span>', item.msg_three, item.msg_four]
            )
          });
          let params = {
            rowNum: 6,
            columnWidth: [250, 120, 150, 100],
            //header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            data: msgData,
          }

          this.option = params
          this.title = res.data.form.name
          _GetAspScreenComponentData('NEWKEL-XMY-YHSJ').then(res => {
            if (res) {
              const msgData = []
              res.data.data.forEach(item => {
                msgData.push([
                  item.name,
                  item.num_data
                ])
              });
              this.names1 = msgData[0][0]
              this.taskCount.progress = Number(msgData[0][1])
              this.names2 = msgData[1][0]
              this.taskCount.complete = Number(msgData[1][1])
              this.title = res.data.form.name
            }
          })
        }
      })

    },
    format (percentage) {
      return percentage + '\n\n' + this.names1
    },

    format2 (percentage) {
      return percentage + '\n\n' + this.names2
    },
  }
}
</script>

<style lang="scss" scoped>
.farming-task-container {
  margin: 0 auto;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: url("@/assets/images/f-task-bg.png") no-repeat;
  background-size: 100% 100%;

  .card-head {
    width: 350px;
    height: 50px;

    .title {
      width: 100%;
      height: 32px;
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 0px 4px #0091ff, 0px 0px 10px rgba(0, 145, 255, 0.5);
      text-align: center;
    }
  }

  .card-body {
    width: 100%;
    height: 190px;
    .left::v-deep {
      width: 40%;
      margin: 0;
      padding-top: 30px;

      .e1 {
        .el-progress-circle {
          background: linear-gradient(116deg, #005744 0%, #003728 100%);

          box-shadow: inset 0px 0px 17px 7px #00543e;

          border-image: linear-gradient(
              116deg,
              rgba(0, 255, 169, 1),
              rgba(255, 255, 255, 1)
            )
            4 4;
          border-radius: 50%;
        }
        .el-progress__text {
          color: #fff;
          font-size: 16px;
        }
      }

      .e2 {
        margin-left: 40px;
        .el-progress-circle {
          background: linear-gradient(116deg, #572900 0%, #003728 100%);
          box-shadow: inset 0px 0px 17px 7px #542e00;
          border-image: linear-gradient(
              116deg,
              rgba(255, 90, 0, 1),
              rgba(255, 203, 139, 1)
            )
            4 4;
          border-radius: 50%;
        }
        .el-progress__text {
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .right {
      width: 60%;
      margin: 0;
      .dv-scroll-board::v-deep {
        .header {
          .header-item {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
        .rows {
          .row-item {
            font-size: 12px;
            font-weight: 400;
            color: #f2f2f2;
            line-height: 12px;

            .ceil {
              border-top: 0.5px solid rgba(232, 232, 232, 0.1);
              border-bottom: 0.5px solid rgba(232, 232, 232, 0.1);
            }
          }
        }
      }
    }
  }
}
</style>