/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-28 17:00:20
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 06:36:57
 */
export default {
  color: ['#0053FF', '#00EBFF'],
  legend: {
    orient: 'horizontal',
    top: 205,
    textStyle: {
      color: '#fff'
    },
    data: ['采收棉蛋', '交售棉蛋']
  },
  radar: {
    radius: '50%',
    center: ['50%', '50%'],
    indicator: [{ text: '阿瓦提', max: 150 },
    { text: '乌鲁却勒', max: 150 },
    { text: '拜什艾日克', max: 150 },
    { text: '英艾日克', max: 120 },
    { text: '阿依巴格', max: 108 },
    { text: '三河镇', max: 72 }],
    axisLabel: {
      fontSize: 10,
      color: '#838D9E',
    },
  },
  series: [
    {
      type: 'radar',
      data: [
        {
          value: [20, 25, 30, 30],
          name: '采收棉蛋'
        },
        {
          value: [20, 25, 30, 30],
          name: '交售棉蛋'
        }
      ]
    }
  ]
}
