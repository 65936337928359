<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 12:48:24
-->
<template>
  <div class="aside-right-container5">
    <div class="box">
      <div class="card-head"
           flex="main:center">
        <div class="txt">
          优质供应商
        </div>
      </div>

      <div style="height:88%"
           flex="dir:top box:mean cross:center">
        <div style="width:83%"
             flex="main:justify cross:center">
          <div style="width:50%;height:100%;">
            <div class="title">
              种子
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>

          <div style="width:50%;height:100%;">
            <div class="title">
              肥料
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>
        </div>

        <div style="width:83%"
             flex="main:justify cross:center">
          <div style="width:50%;height:100%;">
            <div class="title2">
              农机
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>

          <div style="width:50%;height:100%;">
            <div class="title2">
              灌溉带地膜
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>
        </div>

        <div style="width:83%;"
             flex="main:justify cross:center">
          <div style="width:50%;height:100%;">
            <div class="title2">
              农药
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>

          <div style="width:50%;height:100%;">
            <div class="title2">
              智能设备
            </div>
            <div class="logo"
                 flex="cross:center box:mean">
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
              <div class="logoBox"
                   flex="cross:center">
                <img src="@/assets/images/top3/logo2.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AsideRight',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
</style>