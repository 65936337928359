<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-22 16:37:33
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 07:15:04
-->
<template>
  <div class="container">
    <div class="messageDrawer">
      <el-drawer title="交售记录"
                 :visible.sync="sellListDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div class="txtboxItem">
          <el-table :data="tableData"
                    @cell-click="onDetail"
                    :header-cell-style="{background:'transparent',color:'#fff'}"
                    style="width: 100%">
            <el-table-column prop="name"
                             label="农户"
                             width="110" />
            <el-table-column prop="price"
                             label="价格"
                             width="70" />
            <el-table-column prop="time"
                             label="交售日期"
                             width="110" />
            <el-table-column prop="settleWeight"
                             label="净重"
                             width="70" />
          </el-table>
        </div>
      </el-drawer>
    </div>

    <div class="messageDrawer">
      <el-drawer title="交售详情"
                 :visible.sync="sellDrawer"
                 :modal-append-to-body="false"
                 :before-close="handleClose">
        <div>
          <div class="box">
            <div class="left">
              榜票号
            </div>
            <div class="right">
              25665255852
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              垛位
            </div>
            <div class="right">
              20号
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              净重
            </div>
            <div class="right">
              30kg
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              价格
            </div>
            <div class="right">
              ￥100
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              过磅时间
            </div>
            <div class="right">
              2022-5-6
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              交售人姓名
            </div>
            <div class="right">
              李四
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              交售人手机号
            </div>
            <div class="right">
              15895858485
            </div>
          </div>
          <div class="line" />

          <div class="box">
            <div class="left">
              所属工厂
            </div>
            <div class="right">
              乌鲁木齐加工厂
            </div>
          </div>
          <div class="line" />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  name: 'CenterPopu',
  data () {
    return {
      sellListDrawer: false,
      sellDrawer: false,
      list: [],
      sellData: {},
      tableData: []
    }
  },

  watch: {
    popuShow (val) {
      this.show = val
    },
    id (val) {
      if (val != '') {
        this.tableData = [
          {
            id: 1,
            name: '张三',
            price: '¥200',
            time: '2022-3-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥200',
            time: '2022-3-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥200',
            time: '2022-3-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥200',
            time: '2022-3-8',
            settleWeight: '20kg'
          },
          {
            id: 1,
            name: '张三',
            price: '¥200',
            time: '2022-3-8',
            settleWeight: '20kg'
          }
        ]
        this.sellListDrawer = true
      }
    }
  },

  methods: {
    handleClose (done) {
      done();
      this.$emit('sellShow', null)
    },

    onDetail (row) {
      this.sellDrawer = true
    },
  }
}
</script>
<style lang="scss" scoped >
.container {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 19px auto 0;
    width: 98%;

    .left {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }

    .right {
      width: 66%;
      text-align: right;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .line {
    margin: 19px auto;
    width: 90%;
    border-bottom: 1px solid #979797;
    opacity: 0.3;
  }

  .btn {
    width: 100px;
    height: 40px;
    background: #00256b;
    box-shadow: inset 0px 0px 6px 0px rgba(175, 192, 255, 0.8);
    border: 1px solid #82afff;
    font-size: 14px;
    color: #6aa1ff;
    line-height: 40px;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
