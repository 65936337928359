<template>
  <div class="center-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body">
      <div class="chart"
           ref="chart" />
    </div>
  </div>
</template>

<script>
import option from './option'
export default {
  components: {

  },
  name: 'CenterTopItem',
  props: {
    region: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      option,
      title: '重点农产品期货走势',
      zoom: 1
    }
  },

  mounted () {
    let zoom = document.body.style.zoom
    if (zoom) {
      this.zoom = 1 / document.body.style.zoom
    } else {
      this.zoom = 1 / 1
    }
    window.addEventListener("resize", () => {
      let zoom = document.body.style.zoom
      if (zoom) {
        this.zoom = 1 / document.body.style.zoom
      } else {
        this.zoom = 1 / 1
      }
    })
    this.initChart()
  },

  methods: {
    initChart () {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(this.$refs.chart)
      // 绘制图表
      myChart.setOption(this.option)
    },
  }
}
</script>

<style lang="scss" scoped>
.center-top-container {
  box-sizing: border-box;
  position: relative;
  height: 80%;
  background: url("@/assets/images/card-bodyCenter.png") no-repeat;
  background-size: 100% 112%;
  background-position-y: -35px;

  .txt {
    position: absolute;
    top: 20px;
    right: 40px;
    opacity: 0.5;
    font-size: 12px;
    color: #ffffff;
    line-height: 12px;
  }

  .card-head {
    width: 350px;
    height: 50px;
    background: url("@/assets/images/card-head.png") no-repeat;
    background-position: 0 -2px;
    position: relative;

    .txt {
      position: absolute;
      top: 30px;
      right: 10px;
      opacity: 0.5;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }

    .title {
      padding-top: 13px;
      font-size: 18px;
      font-weight: 600;
      color: #6fffc4;
      line-height: 18px;
    }
  }

  .card-body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 50px;

    .chart {
      width: 100%;
      height: 85%;
    }
  }
}
</style>