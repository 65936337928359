/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 19:12:00
 */
// eslint-disable-next-line camelcase
export default {
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: 'transparent', // 奇数行背景色
  evenRowBGC: 'transparent', // 偶数行背景色
  rowNum: 3, // 轮播表行数
  headerBGC: 'transparent', // 表头背景色
  header: ['干果','林果种植总面积', '年末挂果面积', '总株数', '挂果株数','亩均株数(株/亩)','果品总产量(吨)','亩均产量(千克/亩)'],
  columnWidth: [85,130, 120, 85, 90,140,140,140],
  align: ['center', 'center', 'center', 'center','center', 'center', 'center', 'center'],
  data: [
    ['干果','2707', '1985', '34083', '25015','84','11791','396'],

    ['核桃','31', '13', '93', '39','20','25','128'],
    ['红枣','2656', '1959', '33541', '24683','84','11754','400'],
    // ['鲜食','1258', '136', '2856', '9000','25704000','10281600','25704000'],
    ['香梨','20', '13', '449', '293','150','12','62'],
  ]
}
