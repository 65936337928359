/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 00:13:35
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-20 06:31:58
 */
export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    formatter: (params) => {
      return params[0].name + '<br>' +
        params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data + '吨'
    },
  },
  grid: {
    left: '5%',
    right: '5%',
    top: '10%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'value'
  },
  yAxis: {
    type: 'category',
    data: ['新疆三场丰收棉业有限公司', '阿瓦提县天韵棉业有限公司', '阿克苏天成棉业有限公司'],
    axisLabel: {
      formatter: function (params) {
        var maxLength = 5;
        //判断长度，超出使用...代替
        if (params && params.length > maxLength) {
          return params.substring(0, maxLength - 1) + '...';
        } else {
          return params;
        }
      }
    }
  },
  series: [
    {
      name: '交售',
      type: 'bar',
      data: [20, 30, 20, 30]
    }
  ]
};
