<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 11:45:30
-->
<template>
  <div style="height:100%">
    <div class="container"
         flex="dir:top main:center box:mean">
      <div style="height:100%"
           flex="main:justify cross:center box:mean">
        <div class="left">
          <aside-left />
        </div>

        <div class="right">
          <aside-right />
        </div>
      </div>
    </div>

    <div class="center top3"
         flex="box:mean" />
  </div>
</template>

<script>
import asideLeft from './asideLeft'
import asideRight from './asideRight'

export default {
  name: 'App',
  components: {
    asideLeft,
    asideRight,
  },
  data () {
    return {
      btnState: 0,//当前按钮
    }
  },

  methods: {
    /*点击底部二级标题 */
    onBtn (state) {
      this.btnState = state
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/style/index.scss";
</style>

<style scoped lang="scss">
.top3 {
  background-image: url("@/assets/images/top3/top3.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
