import * as echarts from 'echarts/core';

/*
 * @Author: hlc 1664800317@qq.com
 * @Date: 2022-09-14 22:28:28
 * @LastEditors: hlc 1664800317@qq.com
 * @LastEditTime: 2022-09-14 23:19:04
 * @FilePath: \korlascreen\src\components\main-view0\asideRight\rightMiddleItem\option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// export default {
//   rowNum: 6,
//         columnWidth: [100, 100, 100, 100],
//         header:[],// ['种类', '种群数量', '分布区域', '保护情况'],
//         headerBGC: 'transparent',
//         oddRowBGC: 'transparent',
//         evenRowBGC: 'transparent',
//         data: [
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//           // ['猫科动物', '88', '2', '2'],
//         ]
      
//   }

// const option = {
//   tooltip: {  //提示框设置
//     trigger: 'axis',
//     axisPointer: {    //设置横线的样式
//       type: 'cross',
//       crossStyle: {
//         color: 'red'
//       }
//     },
//     textStyle: {      //设置提示框的对齐方式
//       align: 'left'
//     }
//   },
//   grid: {   //设置内容区域距离周边的距离
//     left: '0',
//     right: '0',
//     top: '10px',
//     bottom: '30px',
//     containLabel: true
//   },
//   toolbox: {
//     show: false
//   },
//   legend: {
//     show: false
//   },
//   xAxis: [
//     {
//       type: 'category',
//       data: ['鲜果','杏', '葡萄', '香梨', '苹果', '桃', '酸(西)梅', '杏李', '樱桃'],
//       // data: ['2', '4', '6', '8', '10', '12', '14', '16', '181', '201', '221', '204'],
//       axisPointer: {
//         type: 'shadow'
//       },
//       axisTick: {           //刻度
//         show: false //不显示刻度线
//       },
//       axisLine: {
//         lineStyle: {
//           color: '#1E2240'  //轴线的颜色
//         }
//       },
//       axisLabel: {          //轴线字体样式设置
//         textStyle: {
//           fontFamily: 'ArialMT',
//           fontSize: '12',
//           color: '#86A5C3',
//         }
//       }
//     }
//   ],
//   yAxis: [
//     {
//       type: 'value',
//       min: 0,
//       max: 0,
//       interval: 0,
//       axisLabel: {
//         formatter: '{value}',
//         textStyle: {
//           fontFamily: 'ArialMT',
//           fontSize: '12',
//           color: '#86A5C3',
//         }
//       },
//       splitLine: {          //去除背景网格线
//         show: false
//       },
//       axisTick: {   //刻度
//         show: false         //不显示刻度线
//       },
//       axisLine: {
//         lineStyle: {
//           color: '#1E2240'  //轴线的颜色
//         }
//       }
//     },
//     {
//       type: 'value',
//       min: 0,
//       max: 0,
//       interval: 0,
//       axisLabel: {
//         formatter: '{value}',
//         textStyle: {
//           fontFamily: 'ArialMT',
//           fontSize: '12',
//           color: '#86A5C3',
//         }
//       },
//       splitLine: {      //去除背景网格线
//         show: true
//       },
//       axisTick: {       //刻度
//         show: true     //不显示刻度线
//       },
//       axisLine: {
//         lineStyle: {
//           color: '#1E2240'  //轴线的颜色
//         }
//       }
//     }
//   ],
//   series: [
//     { //柱状(左边数据)
//       name: '卡口进',
//       type: 'bar',
//       data: [29.018,234,1.258,27.458,52,453,74,72,7],
//       itemStyle: {    //柱状图的背景色
//         normal: {
//           color: '#0060D1'
//         }
//       },
//       barWidth: 15
//     },
//     { //柱状(左边数据)
//       name: '卡口出',
//       type: 'bar',
//       data: [338.456,540,2.850,2.850,330.451,330,4.005,192,52],
//       itemStyle: {    //柱状图的背景色
//         normal: {
//           color: '#00D2FF'
//         }
//       },
//       barWidth: 15
//     },
//     { //折线(右边数据)
//       name: '总量',
//       type: 'line',
//       yAxisIndex: 1,
//       data: [0.112,0.312,0.3,0.6,0.8,0.2,0.7,0.5,0.3,0.6,0.8,0.6],
//       itemStyle: {    //折线颜色
//         normal: {
//           color: '#2A47F8'
//         }
//       }
//     }
//   ]
// data: ['杏', '葡萄', '香梨', '苹果', '桃','酸(西)梅','杏李','樱桃','核桃','红枣','巴旦木','枸杞 '],

// }
const option = {
    tooltip: {  //提示框设置
    trigger: 'axis',
    axisPointer: {    //设置横线的样式
      type: 'cross',
      crossStyle: {
        color: 'red'
      }
    },
  },
  calculable: false,
  legend: {
      //   指示框名字  注意！要和下方series中的name一起改
      data: ['面积(亩)', '产量'],
      // 指示框位置  距离上下左右多少
      right: '0%',
      // top: '50%',
      textStyle: {
          color: '#ffff'   //字体颜色
      }
  },
  xAxis: [
      {
          type: 'category',
          // data: ['2018', '2019', '2020', '2021', '2022'],
          data: ['杏','葡萄', '香梨','苹果', '桃','酸(西)梅','杏李','樱桃'],
          // ,'杏李','樱桃','核桃','红枣','巴旦木','枸杞'
          axisLine: {   //x轴线的颜色以及宽度
              show: false,   //是否显示X轴
              lineStyle: {
                  color: "#ffff",
              }
          },
          axisLabel: {
              interval: 0,  //间隔几条显示
              rotate: 35,  // 切斜度数
              fontSize: 12,   //字体大小
          },
          axisTick: {
              //是否显示刻度线
              show: true
          },
      }
  ],
  yAxis: [
      {
          type: 'value',
          axisTick: {
              //y轴刻度线
              show: true
          },
          axisLine: {
              show: true, // Y轴
              lineStyle: {
                  color: "#ffff",
              }
          },
          splitLine: { // 表格里面Y轴线条
              show: true,
              lineStyle: {
                  type: 'dashed',    //虚线
                  color: '#04072F'    //虚线颜色
              }
          }
      }
  ],
  // 整体大小
  grid: {
      left: '3%',
      right: '4%',
      top: '10%',
      bottom: '5%',
      containLabel: true
  },
  series: [
      {
          barWidth: 12,//柱子宽度
          name: '面积(亩)',
          type: 'bar',
          data: [4289,2371,405500,243,5749,1713,1440,775,7],
          itemStyle: {
              //颜色样式部分
              normal: {
                  barBorderRadius: [20, 20, 0, 0],  //圆角[上左，上右，下右，下左] 
                  label: {
                      show: true, //开启数字显示         
                      position: 'top', //在上方显示数字         
                      textStyle: { //数值样式          
                          color: '#ffff',  //字体颜色
                          fontSize: 10,       //字体大小
                      }
                  },
                  //   柱状图颜色渐变
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      { offset: 0, color: "#8277E9" },
                      { offset: 1, color: "#CC77E9" },
                  ]),
              }
          }

      },
      {
        barWidth: 12,//柱子宽度
        name: '产量',
        type: 'bar',
        data: [ 540, 2856, 330451, 330,4005,192,52,0],
        itemStyle: {
            //颜色样式部分
            normal: {
                barBorderRadius: [20, 20, 0, 0],  //圆角[上左，上右，下右，下左] 
                label: {
                    show: true, //开启数字显示         
                    position: 'top', //在上方显示数字         
                    textStyle: { //数值样式          
                        color: '#ffff',  //字体颜色
                        fontSize: 10       //字体大小
                    }
                },
                //   柱状图颜色渐变
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {  offset: 0, color: "#017CE4" },
                    {  offset: 1, color: "#249EFD" },
                ]),
            }
        }

    },

    ]
    }
export default option
