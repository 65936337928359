<template>
  <div class="left-top-container"
       flex="dir:top cross:center">
    <div class="card-head"
         flex="main:justify cross:center">
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div class="card-body"
         flex="dir:top">
      <dv-scroll-board :config="option"
                       style="width:100%;height:100%;" />
    </div>
  </div>
</template>

<script>
import { _GetTaskFlowNYS } from '@/api/assp-task'
import {/*_GetAspScreenMenu ,*/ _GetAspScreenComponentData } from '@/api/form/screenFormData'//获取大屏表单信息
import option from './option'
export default {
  name: 'LeftTopItem',
  props: {
    landId: {
      type: Number,
      default: null
    }
  },
  watch: {
    landId: {
      handler (val) {
        if (!val) return
        this.getFlowData()
      }
    }
  },

  data () {
    return {
      option,
      title: '',
      data: {},//数据
      lineState: {},//在线状态数据
      params: {
        region: '652928'
      },
    }
  },
  mounted () {
    this.getSreenData()
  },
  methods: {
    // 获取专家任务反馈
    getFlowData () {
      _GetTaskFlowNYS(this.landId, 12).then(res => {
        if (res && res.length) {
          let params = {
            rowNum: 6,
            columnWidth: [120, 230],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            data: [],
          }

          res.forEach(item => {
            params.data.push(
              [item.create_time.split(' ')[0],
              item.remark || '无']
            )
          })

          this.option = params
        } else {
          let params = {
            rowNum: 1,
            align: ['center'],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            data: [['暂无专家建议']],
          }

          this.option = params
        }
      })
    },
    getSreenData () {
      _GetAspScreenComponentData('NEWKEL-XMY-ZJJY').then(res => {
        if (res) {
          const msgData = []

          res.data.data.forEach(item => {
            msgData.push(
              [item.msg_one,
              item.msg_two,
              ]
            )
          });
          this.title = res.data.form.name
          let params = {
            rowNum: 6,
            columnWidth: [120, 230],
            //header: msgData2[0],//['申请单位', '专利数', '申请数', '授权数'],
            headerBGC: 'transparent',
            oddRowBGC: 'transparent',
            evenRowBGC: 'transparent',
            data: msgData,
          }

          this.option = params
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/echartsBox.scss";
.left-top-container {
  .card-body {
    .dv-scroll-board::v-deep {
      .header {
        .header-item {
          border: 0.5px solid rgba(232, 232, 232, 0.1);
        }
      }
      .rows {
        .row-item {
          font-size: 12px;
          font-weight: 400;
          color: #f2f2f2;
          line-height: 12px;

          .ceil {
            border: 0.5px solid rgba(232, 232, 232, 0.1);
          }
        }
      }
    }
  }
}
</style>