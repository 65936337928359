<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-08-23 01:34:01
 * @LastEditors: Pancras
 * @LastEditTime: 2022-11-18 10:41:52
-->
<template>
  <div style="height:100%">
    <topBtn1 v-if="showIndex===0"
             :bottom-index="bottomshowIndex" />
    <topBtn2 v-if="showIndex===1"
             :bottom-index="bottomshowIndex" />
    <topBtn3 v-if="showIndex===2"
             :bottom-index="bottomshowIndex" />
    <topBtn4 v-if="showIndex===3"
             :bottom-index="bottomshowIndex"
             :show-sub-menu="showSubMenu"
             :top4-index="topIndex4"
             @change="subMenuChange" />
  </div>
</template>

<script>
import topBtn1 from './top-btn1'
import topBtn2 from './top-btn2'
import topBtn3 from './top-btn3'
import topBtn4 from '../top-btn4'
export default {
  name: 'App',
  components: {
    topBtn1,
    topBtn2,
    topBtn3,
    topBtn4,
  },

  props: {
    topIndex: {
      type: Number,
      default: 0
    },

    top4Index: {
      type: Number,
      default: null,
    },

    bottomIndex: {
      type: Number,
      default: 0
    },
    showSubMenu: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showIndex: 0,
      topIndex4: 0,
      bottomshowIndex: 0
    }
  },

  watch: {
    topIndex: {
      handler (val) {
        this.showIndex = val
        this.$emit('topIndex', val)
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },

    top4Index: {
      handler (val) {
        console.log(val)
        this.topIndex4 = val
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },

    bottomIndex: {
      handler (val) {
        this.bottomshowIndex = val
      },
      deep: true,
      immediate: true// 监听的数据 如果是初始值要不要执行
    },
  },
  methods: {
    subMenuChange (state) {
      this.$emit('change', state)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
