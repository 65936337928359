/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-09-03 13:25:39
 * @LastEditors: Adlj
 * @LastEditTime: 2022-09-30 18:27:15
 */
export default {
  color: ['#FFFFFF', '#49AC00', '#00CBFF', '#27EBBE', '#0F5DE3'],
  legend: {
    show: false,
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '50%'],
      center: ['40%', '50%'],
      avoidLabelOverlap: false,
      labelLine: {//设置延长线的长度
        normal: {
          length: 10,//设置延长线的长度
          // length2: 10,//设置第二段延长线的长度
        }
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{b}, \n\n{c}% ',
      },

      data: [
        {
          name: '棉花',
          value: 13.4
        },
        {
          name: '香梨',
          value: 42.3
        },
        {
          name: '肉类',
          value: 2.55
        }
      ],

      itemStyle: {
        shadowColor: "#545454",
        shadowBlur: 6,
        shadowOffsetX: 3,
        shadowOffsetY: 1,
      },
    }
  ]
}
